import React, { useEffect, useState } from "react";

import { useIntl } from "react-intl";
import { shallowEqual, useSelector } from "react-redux";
import { RootState } from "../../../../setup";
import DatePicker from "react-date-picker";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
import { setDateState, useDateState } from "../hookState/DateState";
import { Downgraded } from "@hookstate/core";

type ValuePiece = Date | null;

type Value = ValuePiece | [ValuePiece, ValuePiece];

export function MenuInner() {
	const dateState = useDateState();
	console.log("state", dateState?.attach(Downgraded).get().customeDate);

	const intl = useIntl();
	const user: any = useSelector<RootState>(
		({ auth }) => auth.user,
		shallowEqual
	);
	const [propertyName, setPropertyName] = useState<any>();
	useEffect(() => {
		setPropertyName(sessionStorage.getItem("property_name"));
	}, [sessionStorage["property_name"]]);
	const [value, setValue] = useState<Value>(
		new Date(dateState?.attach(Downgraded).get().customeDate)
	);
	console.log("value", value);

	const handleDate = (value: any) => {
		setValue(value);
		setDateState(value);
		console.log("value", value);
	};

	return (
		<>
			<div className="dashHead pe-10">
				<h2>{propertyName}</h2>
			</div>
			<div className="dashHead ps-5">
				<h2>
					{user.role.replace(
						/(\w)(\w*)/g,
						function (g0: any, g1: any, g2: any) {
							return g1.toUpperCase() + g2.toLowerCase();
						}
					)}
					/
					{window.location.pathname
						.split("/")
						[window.location.pathname.split("/").length - 1].replace(
							/(\w)(\w*)/g,
							function (g0, g1, g2) {
								return g1.toUpperCase() + g2.toLowerCase();
							}
						)}
				</h2>
			</div>

			<div className="current-date">
				<DatePicker
					onChange={(value) => handleDate(value)}
					value={value}
					minDate={new Date()}
				/>
			</div>
		</>
	);
}
