import clsx from "clsx";
import { FC, useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { KTSVG, toAbsoluteUrl } from "../../../helpers";
import {
	HeaderNotificationsMenu,
	HeaderUserMenu,
	QuickLinks,
} from "../../../partials";
import { useLayout } from "../../core";
import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import { UserModel } from "../../../../app/modules/auth/models/UserModel";
import { RootState } from "../../../../setup";
import "./notification.css";

import React from "react";

const API_URL = process.env.REACT_APP_API_URL || "";

const toolbarButtonMarginClass = "ms-1 ms-lg-3",
	toolbarButtonHeightClass = "w-30px h-30px w-md-40px h-md-40px",
	toolbarUserAvatarHeightClass = "symbol-30px symbol-md-40px",
	toolbarButtonIconSizeClass = "svg-icon-1";

const Topbar: FC = () => {
	const { config } = useLayout();
	const [notifCount, setNotifCount] = useState(0);
	const [notifcationFlag, setNotificationFlag] = useState<string>("hide");

	const [notifications, setNotifications] = useState<[{}]>([{}]);
	const user: UserModel = useSelector<RootState>(
		({ auth }) => auth.user,
		shallowEqual
	) as UserModel;
	const [profilePic, setProfilePic] = useState("");

	useEffect(() => {
		axios.get(`${API_URL}/v1/user/profilepic`).then((res) => {
			setProfilePic(res.data.data);
		});
	}, [user]);

	const fetchNotifications = () => {
		// console.log("---------1-------", sessionStorage.getItem("vendor_id"));
		if (sessionStorage.getItem("vendor_id")) {
			axios.get(`${API_URL}/v1/vendor/booking`).then((res) => {
				setNotifications(res.data.data?.reverse());
			});
		}
		// axios.get(`${API_URL}/v1/notifications`).then((result) => {
		// 	if (result.data.data !== "no notifications") {
		// 		axios.post(`${API_URL}/v1/notifications`, {
		// 			match: {
		// 				notification_type: "today_checkins",
		// 				notification_pulled_status: false,
		// 			},
		// 			data: {
		// 				notification_type: "today_checkins",
		// 				notification_pulled_status: true,
		// 			},
		// 		});
		// 		setNotifications(
		// 			result.data.data.map((item: any) => {
		// 				return {
		// 					time: item.createdAt,
		// 					type: item.notification_type,
		// 					message: item.notification_message,
		// 				};
		// 			})
		// 		);
		// 		let newNotifs = result.data.data.filter(
		// 			(item: any) => !item.notification_read_status && item._id
		// 		);
		// 		setNotifCount(newNotifs.length);
		// 	}
		// });
	};
	// const handleNotifRead = () => {
	// 	setNotifCount(0);
	// 	axios.post(`${API_URL}/v1/notifications`, {
	// 		match: {
	// 			notification_type: "today_checkins",
	// 			notification_read_status: false,
	// 		},
	// 		data: {
	// 			notification_type: "today_checkins",
	// 			notification_read_status: true,
	// 		},
	// 	});
	// };

	useQuery(["notifications"], fetchNotifications, {
		refetchInterval: 60 * 6000,
	});

	React.useEffect(() => {
		let vendorId: any = sessionStorage.getItem("vendor_id");
		// console.log("---------1-------", sessionStorage.getItem("vendor_id"));
		// console.log("vendor", vendorId);
		// console.log("notifications", notifications);
		if (notifications?.length > 1) {
			let checkIN =
				notifications &&
				notifications?.filter((row: any) => {
					if (vendorId === row.booking_vendor_id) {
						if (row.booking_status === "Success") {
							let today = new Date().setHours(0, 0, 0, 0);
							let checkIn = new Date(row.checkIn_date_time).setHours(
								0,
								0,
								0,
								0
							);
							if (today === checkIn) {
								return row;
							}
						}
					}
				});
			// setTodayCheck(checkIN);
			let upcoming =
				notifications &&
				notifications?.filter((row: any) => {
					if (vendorId === row.booking_vendor_id) {
						if (row.booking_status === "Success") {
							let today = new Date().setHours(0, 0, 0, 0);
							let checkIn = new Date(row.checkIn_date_time).setHours(
								0,
								0,
								0,
								0
							);

							if (today < checkIn) {
								return row;
							}
						}
					}
				});

			// setBooking(upcoming);

			let settlment =
				notifications &&
				notifications?.filter((row: any) => {
					if (vendorId === row.booking_vendor_id) {
						if (row.invoice !== "") {
							return row;
						}
					}
				});
			// setSettlement(settlment);
			let first = sessionStorage.getItem("first");

			let second = sessionStorage.getItem("second");

			let third = sessionStorage.getItem("third");

			// alert(first);
			if (checkIN?.length > Number(first)) {
				setNotificationFlag("bell");
			}
			if (upcoming?.length > Number(second)) {
				setNotificationFlag("bell");
			}
			if (settlment?.length > Number(third)) {
				setNotificationFlag("bell");
			}
			sessionStorage.setItem(
				"first",
				JSON.stringify(checkIN && checkIN.length)
			);
			sessionStorage.setItem(
				"second",
				JSON.stringify(upcoming && upcoming.length)
			);
			sessionStorage.setItem(
				"third",
				JSON.stringify(settlment && settlment.length)
			);
		}
	}, [notifications]);

	// console.log("------------------notifcationFlag------------", notifcationFlag);

	return (
		<div className="d-flex align-items-stretch flex-shrink-0">
			<div
				className={clsx("d-flex align-items-center", toolbarButtonMarginClass)}
				// onClick={handleNotifRead}
			>
				{/* begin::Menu- wrapper */}
				<div
					className={clsx(
						"btn btn-icon btn-active-light-primary position-relative",
						toolbarButtonHeightClass
					)}
					data-kt-menu-trigger="click"
					data-kt-menu-attach="parent"
					data-kt-menu-placement="bottom-end"
					data-kt-menu-flip="bottom"
					onClick={(e: any) => {
						setNotificationFlag("hide");
					}}
				>
					<KTSVG
						path="/media/icons/duotone/General/Notifications1.svg"
						className={notifcationFlag}
					/>
					{!!notifCount && (
						<div
							style={{
								position: "relative",
								top: "-.8rem",
								right: ".6rem",
								width: "1rem",
								backgroundColor: "red",
								color: "#fff",
								borderRadius: ".4rem",
								padding: ".2rem",
								fontSize: ".7rem",
							}}
						>
							{notifCount < 10 ? notifCount : "9+"}
						</div>
					)}
				</div>
				<HeaderNotificationsMenu notifications={notifications} />
				{/* end::Menu wrapper */}
			</div>

			{/* begin::User */}
			<div
				className={clsx("d-flex align-items-center", toolbarButtonMarginClass)}
				id="kt_header_user_menu_toggle"
			>
				{/* begin::Toggle */}
				<div
					className={clsx(
						"cursor-pointer symbol",
						toolbarUserAvatarHeightClass
					)}
					data-kt-menu-trigger="click"
					data-kt-menu-attach="parent"
					data-kt-menu-placement="bottom-end"
					data-kt-menu-flip="bottom"
				>
					<img
						src={
							profilePic
								? `data:image/png;base64,${Buffer.from(profilePic).toString(
										"base64"
								  )}`
								: toAbsoluteUrl("/media/avatars/blank.png")
						}
						alt="metronic"
					/>
				</div>
				<HeaderUserMenu />
				{/* end::Toggle */}
			</div>
			{/* end::User */}

			{/* begin::Aside Toggler */}
			{config.header.left === "menu" && (
				<div
					className="d-flex align-items-center d-lg-none ms-2 me-n3"
					title="Show header menu"
				>
					<div
						className="btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px"
						id="kt_header_menu_mobile_toggle"
					>
						<KTSVG
							path="/media/icons/duotone/Text/Toggle-Right.svg"
							className="svg-icon-1"
						/>
					</div>
				</div>
			)}
		</div>
	);
};

export { Topbar };
