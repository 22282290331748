import "./PaymentConfirm.scss";
import {
	Carousel as Multicarousel,
	Col,
	Container,
	Form,
	Nav,
	Navbar,
	NavDropdown,
	Row,
	Tab,
	Tabs,
} from "react-bootstrap-v5";
import Collapsible from "react-collapsible";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { toAbsoluteUrl } from "../../../../_metronic/helpers";
import ImageGallery from "react-image-gallery";
import UserHeader from "../components/UserHeader";
import UserFooter from "../components/UserFooter";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL || "";
const PROJECT_URL = process.env.REACT_PROJECT_URL || "";

// const Img_Path = `https://myresort.s3.ap-south-1.amazonaws.com/`;
// const Img_Path = "https://myresortsbucket.s3.amazonaws.com/";
const Img_Path = "https://myresortsbucket-prod1.s3.ap-south-1.amazonaws.com/";

export default function Paymentconfirm() {
	const [confirmData, setConfirmData] = useState<any>();
	const [bookingDate, setBookingDate] = useState<any>("");
	const [bookingTime, setBookingTime] = useState<any>("");
	const [formatCheckIn, setFormatCheckIn] = useState<any>("");
	const [formatCheckOut, setFormatCheckOut] = useState<any>("");
	const [apiCheckIn, setApiCheckIn] = useState<any>("");
	const [apiCheckOut, setApiCheckOut] = useState<any>("");

	const { search } = useLocation();
	const params = new URLSearchParams(search);
	const confirmation_id = params.get("confirmation_id")
		? params.get("confirmation_id")
		: "63e79fb3dda10030e42121b5";

	const handleConfirmation = () => {
		axios
			.get(`${API_URL}/v1/book/book_resort?_id=${confirmation_id}`)
			.then((res) => {
				if (res) {
					res.data.map((values: any) => {
						return (
							setConfirmData(values), console.log(values, "payment confirm api")
						);
					});
				}

				handledSMS(
					"success",
					res.data[0].custumer?.mobile,
					res.data[0].custumer?.email,
					res.data[0].custumer?.firstname,
					res.data[0].custumer?.lastname,
					res.data[0].property_name?.property_name,
					res.data[0].roomcatname, // I have changed this
					res.data[0].checkIn_date_time,
					res.data[0].checkOut_date_time,
					res.data[0].total_guest,
					confirmation_id
				);
				// ================ Booking Date & Time Code Start's here  : Date of Booking =================
				const data = res.data.map((values: any) => {
					return values.bookingtime;
				});
				const date = new Date(data);
				const options = { timeZone: "Asia/Kolkata" };
				const istString = date.toLocaleString("en-US", options);
				const [dateString, timeString] = istString.split(", ");

				const [monthStr, dayStr, yearStr] = dateString.split("/");
				const BookingDateString = dayStr + "/" + monthStr + "/" + yearStr;

				setBookingDate(BookingDateString);
				setBookingTime(timeString);
				// ================ Booking Date & Time Code Start's here : Date of Booking =================

				// ====== Api CheckIn and CheckOut Date Code Start's Here ===================

				const dateData = res.data.map((values: any) => {
					return values.checkIn_date_time;
				});

				const date1 = new Date(dateData);
				const options1 = { timeZone: "Asia/Kolkata" };
				const istString1 = date1.toLocaleString("en-US", options1);
				const [dateString1, timeString1] = istString1.split(", ");

				const [monthStr1, dayStr1, yearStr1] = dateString1.split("/");
				const ApiCheckInDate = dayStr1 + "/" + monthStr1 + "/" + yearStr1;

				const dateData2 = res.data.map((values: any) => {
					return values.checkOut_date_time;
				});

				const date2 = new Date(dateData2);
				const options2 = { timeZone: "Asia/Kolkata" };
				const istString2 = date2.toLocaleString("en-US", options2);
				const [dateString2, timeString2] = istString2.split(", ");

				const [monthStr2, dayStr2, yearStr2] = dateString2.split("/");
				const ApiCheckOutDate = dayStr2 + "/" + monthStr2 + "/" + yearStr2;

				setApiCheckIn(ApiCheckInDate);
				setApiCheckOut(ApiCheckOutDate);

				// console.log(data, dateData, "differce date", ApiCheckInDate, ApiCheckOutDate)

				// ====== Api CheckIn and CheckOut Date Code Start's Here ===================

				// =========== Check in Out Time Am to Pm Shown Code Start's here ========================
				const CheckinTime = res.data.map(
					(values: any) => values.property_policy.checkin_time
				);
				const dateIn = new Date(`2023-03-06T${CheckinTime}:00`);

				const formattedTimeIn = dateIn.toLocaleString("en-US", {
					hour: "numeric",
					minute: "numeric",
					hour12: true,
				});
				setFormatCheckIn(formattedTimeIn);

				const CheckoutTime = res.data.map(
					(values: any) => values.property_policy.checkout_time
				);
				const dateOut = new Date(`2023-03-06T${CheckoutTime}:00`);

				const formattedTimeOut = dateOut.toLocaleString("en-US", {
					hour: "numeric",
					minute: "numeric",
					hour12: true,
				});
				setFormatCheckOut(formattedTimeOut);
				// =========== Check in Out Time Am to Pm Shown Code End's here ========================
			});
	};

	const handleBookingStatus = (confirmation_id: any, paymentStatus: any) => {
		if (confirmation_id) {
			axios
				.post(`${API_URL}/v1/book/update_booking`, {
					_id: confirmation_id,
					payment_status: paymentStatus,
					// "booking_status": "Cancelled" // for cancelled booking
					// "booking_status": "Scheduled" // for upcoming booking
					// "booking_status": "Success" // for completed booking
				})
				.then((res) => {
					return res;
				});
		}
	};
	const handledSMS = (
		val: any,
		mobile: any,
		userEmailId: any,
		firstName: any,
		lastName: any,
		propertyName: any,
		roomName: any,
		checkIn: any,
		checkOut: any,
		totalGuest: any,
		bookingId: any
	) => {
		if (val === "success") {
			axios
				.post(`${API_URL}/v1/customer/booking_confirmed`, {
					mobile: mobile,
					userEmailId: userEmailId,
					firstName: firstName,
					lastName: lastName,
					propertyName: propertyName,
					roomName: roomName,
					checkIn: checkIn,
					checkOut: checkOut,
					totalGuest: totalGuest,
					bookingId: bookingId,
				})
				.then((res) => {
					console.log("");
				});
		}
		if (val === "cancelled") {
			axios
				.post(`${API_URL}/v1/customer/booking_cancelled`, {
					mobile: mobile,
					userEmailId: userEmailId,
					firstName: firstName,
					lastName: lastName,
					propertyName: propertyName,
					roomName: roomName,
					checkIn: checkIn,
					checkOut: checkOut,
					totalGuest: totalGuest,
					bookingId: bookingId,
				})
				.then((res) => {
					console.log("");
				});
		}
	};
	const checkPaymentStatus = () => {
		axios
			.get(
				`${API_URL}/v1/book/get_payment_status?booking_id=${confirmation_id}`
			)
			.then((res) => {
				if (res.data.data.order_status === "PAID") {
					handleConfirmation();
					handleBookingStatus(confirmation_id, "Success");
					console.log(confirmData);
					// handledSMS("success");
				} else {
					handleBookingStatus(confirmation_id, "Cancelled");
					// handledSMS("cancelled");
					const url = `${PROJECT_URL}/bookingcancelled?confirmation_id=${confirmation_id}`;
					window.location.href = url;
				}
			})
			.catch((error) => {
				console.error(error);
			});
	};

	useEffect(() => {
		checkPaymentStatus();

		if (window.innerWidth < 550) {
			window.scrollTo({ top: 140, behavior: "smooth" });
		} else if (window.innerWidth > 700 && window.innerWidth < 1200) {
			window.scrollTo({ top: 280, behavior: "smooth" });
		} else if (window.innerWidth >= 1200) {
			window.scrollTo({ top: 410, behavior: "smooth" });
		}
	}, []);

	return (
		<>
			<UserHeader />
			<div className="payment-confirmation-wrapper fix-top-nav-padd">
				<div className="room-details-banner">
					<img src={toAbsoluteUrl("/media/img/banner-2.jpg")} alt="MyResorts.in" />
					<div className="room-details-banner-info">
						<h1>Payment confirmed!</h1>
						<h5></h5>
					</div>
					<div className="overlay-hy">
						<img
							src={toAbsoluteUrl("/media/img/tree-shape.png")}
							alt="MyResorts.in"
						/>
					</div>
				</div>

				<div className="container">
					<Row>
						<Col lg="12">
							{confirmData !== "" ? (
								<>
									<div className="payment-confirm-wrapper">
										<div className="payment-top-box">
											<img
												src={toAbsoluteUrl("/media/img/payment-confirmed.svg")}
												alt="MyResorts.in"
											/>
										</div>

										<div className="payment-guest-name">
											<h2>
												Guest Name: {confirmData?.custumer?.firstname}{" "}
												{confirmData?.custumer.lastname}
											</h2>
											<p>Contact No.: {confirmData?.custumer?.mobile}</p>
											<p>Email: {confirmData?.custumer?.email}</p>
										</div>

										<div className="payment-fonfirmes-details-info-wrap">
											<Row>
												<Col lg="7">
													<div className="payment-conf-details-left-col">
														<div className="addr">
															{/* <h3>Flagship 84994 Jayam Residency - Ecr</h3> */}
															<h3>
																{confirmData?.roomcatname} -{" "}
																{confirmData?.property_name.property_name}
															</h3>
															<p>
																{
																	confirmData?.property_locality
																		.property_nearest_gate
																}
																,{" "}
																{
																	confirmData?.property_locality
																		.property_locality
																}
															</p>
															<p>
																{confirmData?.property_locality.property_city}{" "}
																{
																	confirmData?.property_locality
																		.property_zip_code
																}
															</p>
														</div>

														<div className="pc-check-in">
															<span>
																<p>Check IN</p>

																<p>{apiCheckIn}</p>
																<p> {formatCheckIn}</p>
															</span>

															<span>
																<p>Check OUT</p>
																<p>{apiCheckOut}</p>
																<p> {formatCheckOut}</p>
															</span>
														</div>

														<div className="date-of-booking">
															<h4>{`Date of Booking : ${
																bookingDate ? bookingDate : ""
															}, ${bookingTime ? bookingTime : ""}`}</h4>
														</div>
														<div className="room-type">
															<h4>
																Booking Type : {confirmData?.booking_type}
															</h4>
															<h5>
																Room : {confirmData?.total_room}, &nbsp;
																{confirmData?.booking_type === "Group" ? (
																	<>
																		{" "}
																		Adults : {confirmData?.group_adult},
																		Children : {confirmData?.group_child}{" "}
																	</>
																) : (
																	<>
																		{" "}
																		Adults : {confirmData?.no_of_adult},
																		Children : {confirmData?.no_of_child} Child
																		& {confirmData?.no_of_bigchild} Teens
																	</>
																)}
															</h5>
															<h3>Inclusions: Stay and meals only</h3>
														</div>
														<div className="guidelines">
															<p>1. Carry original ID proof</p>
															<p>
																2. Follow the guidelines provided by the resort{" "}
															</p>
															<p>
																3. be aware of your surroundings and respect the
																jungle
															</p>
														</div>
														<div
															className="pc-hotel-policy"
															style={{ marginTop: "50px" }}
														>
															{/* <h3>Hotel Policy:</h3> */}
															<h4>Cancellation Policy</h4>
															<p>
																{
																	confirmData?.property_policy
																		.cancellation_policy
																}
																.
															</p>
															{/* <p>Non-Refundable if cancelled after Dec 5, 2019 </p>
									<p>
										Travel Cash used in the booking will be
										Non-Refundable.
									</p> */}
														</div>
													</div>
												</Col>

												<Col lg="5">
													<div className="payment-conf-details-right-col">
														<div className="pc-right-image-wrap">
															{/* <img
                                src={toAbsoluteUrl("/media/img/room-big.jpg")}
                                alt="MyResorts.in"
                              /> */}

															<img
																src={
																	confirmData?.images[0] === undefined
																		? "/media/img/icons/defaultImg.png"
																		: `${Img_Path}${confirmData?.images[0]}`
																}
																alt="Myrsorts"
															/>
														</div>

														<span>
															<h2>Booking ID : {confirmData?._id}</h2>
														</span>
														<div className="net-amt-paid-box">
															<ul>
																<li>
																	<p>Total Room Price :</p>
																	<p>Rs. {confirmData?.total_room_price}</p>
																</li>
																<li>
																	<p>Tax & Fees :</p>
																	<p>Rs. {confirmData?.tax_fees}</p>
																</li>

																<li>
																	<p>Coupon Discount :</p>
																	<p>- {confirmData?.coupon_discount}</p>
																</li>

																{/* <li>
                                  <p>Discounts / Wallet:</p>
                                  <p>Rs. 797</p>
                                </li> */}

																<li>
																	<p>Total Amount : </p>
																	<p>Rs. {confirmData?.booking_amount}</p>
																</li>
															</ul>
														</div>
													</div>
												</Col>
											</Row>
										</div>
									</div>
								</>
							) : (
								""
							)}
						</Col>
					</Row>
				</div>

				<UserFooter />
			</div>
		</>
	);
}
