import { createState } from '@hookstate/core';

const checkIn = new Date(); //.toISOString().split("T")[0];
const checkOut = new Date();
checkOut.setDate(checkIn.getDate() + 1);

const checkInDateArr = checkIn.toLocaleString('en-US',{timeZone: process.env.LOCAL_TZ,
    hour12: false,}).split(",")[0].split("/");
const checkOutDateArr = checkOut.toLocaleString('en-US',{timeZone: process.env.LOCAL_TZ,
    hour12: false,}).split(",")[0].split("/");

const checkInDate = checkInDateArr[2] + '-' + (parseInt(checkInDateArr[0])<10?"0":"") + checkInDateArr[0] + '-' + (parseInt(checkInDateArr[1])<10?"0":"") + checkInDateArr[1];
const checkOutDate = checkOutDateArr[2] + '-' + (parseInt(checkOutDateArr[0])<10?"0":"") + checkOutDateArr[0] + '-' + (parseInt(checkOutDateArr[1])<10?"0":"") + checkOutDateArr[1];

const initialState = {
    filters: {
        "place": { "label": "Tadoba", "value": "Tadoba", "data": "Tadoba" },
        // "place": { "label": "Nagpur", "value": "Nagpur", "data": "Nagpur" },
        "checkIn": checkInDate,
        "checkOut": checkOutDate,
        "guests": { "rooms": 1, "adults": 2, "childRange1": 0, "childRange2": 0 },
        "defaultPlaceOptions": [
            { value: "Pench", label: "Pench", data: "Pench" },
            { value: "Nagpur", label: "Nagpur", data: "Nagpur" },
            { value: "Tadoba", label: "Tadoba", data: "Tadoba" },
            { value: "Pune", label: "Pune", data: "Pune" },
        ],
        // "defaultPlaceOptions": [
        //     { value: "Goa", label: "Goa", data: "Goa" },
        //     { value: "Nagpur", label: "Nagpur", data: "Nagpur" },
        //     { value: "Mumbai", label: "Mumbai", data: "Mumbai" },
        //     { value: "Pune", label: "Pune", data: "Pune" },
        // ],
        "maxPrice": 30000,
    },
};

const filtersState = createState(initialState);

export default filtersState;