import { useState, useEffect } from "react";
import { checkMandatoryAmenities } from "../ProfileValidations";

export default function RoomAmenities({
	form,
	category,
	setCategory,
	setChanges,
	checkMandatory,
}: any) {
	const [menu, setMenu] = useState([
		{
			amenity: "Basic Facilities",
			value: "basic_facilities",
			pt: "0",
			list: [
				"Elevator/ Lift",
				"Air Conditioning",
				"Bathroom",
				"Dry Cleaning Services",
				"Housekeeping",
				"Intercom",
				"Ironing services",
				"Kitchen/Kitchenette",
				"LAN",
				"Laundry",
				"Newspaper",
				"Parking",
				"Power Backup",
				"Refrigerator",
				"Room service",
				"Smoke detector",
				"Smoking rooms",
				"Swimming Pool",
				"Telephone",
				"Torch",
				"Umbrellas",
				"Washing Machine",
				"WiFi",
				"Public Restrooms",
				"Laundromat",
				"Vending Machine",
				"Private Entrance",
				"Sanitizers",
				"EV Charging Station",
			],
		},
		{
			amenity: "General Services",
			value: "general_services",
			pt: "20",
			list: [
				"Bellboy service",
				"Caretaker",
				"Concierge",
				"Multilingual Staff",
				"Luggage assistance",
				"Luggage storage",
				"Mail services",
				"Specially Abled Assistance",
				"Wake-up Call / Service",
				"Wheelchair",
				"Electrical Sockets",
				"Postal services",
				"Butler Services",
				"Doctor on call",
				"Medical centre",
				"Ticket/ Tour Assistance",
				"Pool/ Beach towels",
				"Welcome kit",
			],
		},
		{
			amenity: "Outdoor Activities and Sports",
			value: "outdoor_activities_sports",
			pt: "60",
			list: [
				"Beach",
				"Boat ride",
				"Bonfire",
				"Golf",
				"Kayaks",
				"Outdoor sports",
				"Sea plane",
				"Snorkeling",
				"Telescope",
				"Water sports",
				"Vehicle Rentals",
				"Canoeing",
				"Skiing",
				"Jungle Safari",
				"Guided Tours And Treks",
				"Cycling",
			],
		},

		{
			amenity: "Common Area",
			value: "common_area",
			pt: "100",
			list: [
				"Aquarium",
				"Balcony/ Terrace",
				"Fireplace",
				"Lawn",
				"Library",
				"Lounge",
				"Reception",
				"Seating Area",
				"Sun Deck",
				"Temple/ Chapel",
				"Verandah",
				"Jacuzzi",
				"Prayer Room",
				"Living Room",
				"Outdoor Furniture",
				"Picnic area",
				"Game Room",
				"Sitout Area",
				"TV Area",
				"Bonfire Pit",
			],
		},
		{
			amenity: "Food and Drink",
			value: "food_drink",
			pt: "130",
			list: [
				"Bar",
				"Barbecue",
				"Cafe",
				"Coffee shop",
				"Dining Area",
				"Kids meals",
				"Restaurant",
				"Special Diet Meals",
				"Cooking class",
				"Bakery",
			],
		},
		{
			amenity: "Health and wellness",
			value: "health_wellness",
			pt: "150",
			list: [
				"Activity Centre",
				"Gym/Fitness Centre",
				"Reflexology",
				"Yoga",
				"Meditation Room",
				"Aerobics",
				"First-aid services",
				"Solarium",
				"Hot Spring bath",
			],
		},

		{
			amenity: "Business Center and Conferences",
			value: "business_center_conference",
			pt: "190",
			list: [
				"Banquet",
				"Business Center",
				"Business services",
				"Conference room",
				"Photocopying",
				"Fax service",
				"Printer",
			],
		},
		{
			amenity: "Beauty and Spa",
			value: "beauty_spa",
			pt: "220",
			list: [
				"Facial treatments",
				"Hair treatment",
				"Manicure/ Pedicure",
				"Massage",
				"Salon",
				"Spa",
				"Steam and Sauna",
				"Open air bath",
				"Public bath",
				"Hammam",
			],
		},
		{
			amenity: "Security",
			value: "security",
			pt: "255",
			list: [
				"Bodyguards",
				"Electronic Keycard",
				"Emergency exit map",
				"Safe",
				"Security",
				"CCTV",
				"Fire Extinguishers",
				"Safety and Security",
				"Security Alarms",
				"Smoke Alarms",
				"Smart Door Lock",
				"Fire Alarm",
				"Security Guard",
				"Carbon Monoxide Detector",
				"Access Control System",
			],
		},
		{
			amenity: "Transfers",
			value: "transfers",
			pt: "275",
			list: [
				"Airport Transfers",
				"Pickup/Drop",
				"Shuttle Service",
				"Railway Station Transfers",
				"Bus Station Transfers",
				"Public transit tickets",
			],
		},

		{
			amenity: "Shopping",
			value: "shopping",
			pt: "305",
			list: [
				"Book shop",
				"Grocery",
				"Shops",
				"Souvenir shop",
				"Jewellery Shop",
			],
		},
		{
			amenity: "Entertainment",
			value: "entertainment",
			pt: "330",
			list: [
				"Movie Room",
				"Home Theatre",
				"Music System",
				"Events",
				"Pub",
				"Radio",
				"Photo Session",
				"Night Club",
				"Beach club",
			],
		},
		{
			amenity: "Media and Technology",
			value: "media_tech",
			pt: "350",
			list: [
				"Electrical Adapters Available",
				"Electrical chargers",
				"Laptops",
				"TV",
				"Broadband",
				"Phone Network",
			],
		},
		{
			amenity: "Payment Services",
			value: "payment_services",
			pt: "395",
			list: ["ATM", "Currency Exchange", "Cash", "Card", "UPI"],
		},
		{
			amenity: "Indoor Activities and Sports",
			value: "Indoor Activities and Sports",
			pt: "425",
			list: ["Casino", "Indoor games"],
		},
		{
			amenity: "Family and kids",
			value: "family_kids",
			pt: "455",
			list: [
				"Childcare service",
				"Children's play area",
				"Kids' Club",
				"Strollers",
				"Playground",
			],
		},
		{
			amenity: "Safety & Hygiene",
			value: "safety_hygiene",
			pt: "495",
			list: [
				"Disinfection",
				"Shoe covers",
				"Hair nets",
				"Face shields",
				"PPE (Personal Protective Equipment) kits",
				"Hospital in the vicinity",
				"Safety authorization certificate",
				"Disposable Serveware",
				"Contactless room service",
				"Thermal screening at entry and exit points",
				"Dispenser for Disinfectant",
				"Sanitizers Installed",
				"Masks",
				"Disinfectant Wipes",
				"Gloves",
				"Sanitizers",
				"Contactless check-in",
				"Safety kit",
			],
		},
		{
			amenity: "Pet essentials",
			value: "pet_essentials",
			pt: "515",
			list: ["Pet bowls", "Pet baskets"],
		},
		{
			amenity: "Room highlights",
			value: "room_highlights",
			pt: "550",
			list: [
				"Machan",
				"Night View",
				"Tent",
				"River / lake view",
				"Open Terrance",
				"Open Shower",
				"Bathtub/Jacuzzi",
				"Open Balcony",
				"Private Pool",
				"Bonfire",
				"Kitchen",
				"Dining ",
			],
		},
		{
			amenity: "Travel Moods",
			value: "travel_moods",
			pt: "580",
			list: [
				"Chillout",
				"Adventure",
				"Luxury",
				"Leisure",
				"City Escape",
				"Weekend gateway",
				"Budget",
				"Safari",
				"Nature",
				"Solo",
			],
		},
	]);
	const [selectedList, setSelectedList] = useState([]);
	const [selectedItem, setSelectedItem] = useState("");
	const [pt, setPt] = useState("");
	const [valid, setValid] = useState(false);
	const setMenuItems = (list: any, item: any) => {
		setSelectedList(list);
		setSelectedItem(item.value);
		setPt(item.pt);
	};

	const handleChange = (event: any, parent: any, value: any) => {
		let obj = JSON.parse(JSON.stringify(category));
		if (!obj[parent][value]) obj[parent][value] = [];
		if (event.target.checked) {
			obj[parent][value].push(event.target.value);
		} else {
			let i = obj[parent][value].indexOf(event.target.value);
			obj[parent][value].splice(i, 1);
		}
		setValid(() => checkMandatoryAmenities(obj.category_amenities));
		checkMandatory(obj);
		setCategory(obj);
		setChanges(true);
	};

	/* useEffect(() => {
    let count = 0;
    for (let amenity in room?.category_amenities) {
      count += room?.category_amenities[amenity].length;
    }
    if (count < 5) {
      setValid(false);
      setDisabled(true);
    }
    setAmenityCount(count);
  }, []); */

	const copyAmenities = () => {
		let obj = { ...category };
		obj.category_amenities = JSON.parse(
			JSON.stringify(form?.property_amenities)
		);
		setCategory(obj);
		setValid(() => checkMandatoryAmenities(obj?.category_amenities));
		checkMandatory(obj);
	};

	useEffect(() => {
		setValid(() => checkMandatoryAmenities(category?.category_amenities));
	}, []);

	return (
		<div>
			<fieldset
				disabled={form?.property_status === "Unverified" ? true : false}
			>
				<div className="card card-custom mb-10">
					{/* <div className="about-resort-wrap">
            <h2>About Resort</h2>
            <textarea></textarea>
          </div> */}
					<div className="card-header">
						<h3 className="card-title align-items-start flex-column">
							<span className="card-label fw-bolder fs-3 mb-1">
								Room Facilities
							</span>
							<span className="text-muted fw-bold fs-7">
								Please select general facilities available with Room. (
								<span className={valid ? "text-muted" : "text-danger"}>
									Please choose at least 5
								</span>
								)
							</span>
							<button
								className="btn btn-sm btn-primary mt-5 fs-8"
								onClick={copyAmenities}
							>
								Copy from Resort Amenities
							</button>
						</h3>
					</div>

					{/* <div className="card-body d-flex faci-wrap" id="wrapper"> */}
					<div className="card-body d-flex faci-wrap">
						<div className="bg-white" id="sidebar-wrapper">
							<div className="list-group list-group-flush amenities-cat">
								{menu.map((item, i) => (
									<a
										className={
											"list-group-item list-group-item-action list-group-item-light p-1" +
											(selectedItem === item.value ? " active" : "")
										}
										onClick={(e) => setMenuItems(item.list, item)}
										style={{ fontSize: "12px" }}
										key={item.amenity}
									>
										{item.amenity} ({item.list.length})
									</a>
								))}
							</div>
						</div>
						<div id="page-content-wrapper position-sticky">
							<div className="container-fluid ps-20">
								{/* <h1 className="mt-4">Simple Sidebar</h1> */}
								{selectedList.map((ele) => (
									<>
										<div className="amenities-list-name">
											<div className="mb-1">
												<div className="form-check form-check-custom form-check-solid">
													<input
														className="form-check-input"
														type="checkbox"
														value={ele}
														id={ele}
														name={ele}
														checked={
															category["category_amenities"][selectedItem] &&
															category["category_amenities"][
																selectedItem
															].includes(ele)
														}
														onChange={(e) =>
															handleChange(
																e,
																"category_amenities",
																selectedItem
															)
														}
														key={ele}
													/>
													<label className="form-check-label" htmlFor={ele}>
														<span style={{ fontSize: "11px" }}>{ele}</span>
													</label>
												</div>
											</div>
										</div>
									</>
								))}
							</div>
						</div>
					</div>
				</div>
			</fieldset>
		</div>
	);
}
