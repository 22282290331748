import React from "react";

export default function TextValue(props: any) {
	const [editState, setEditState] = React.useState(false);

	const [data, setData] = React.useState<any>("");
	const [invoice, setInvoice] = React.useState({ id: "", value: "" });

	const onEditUser = () => {
		// console.log(data);
		// console.log("Wire update action", invoice);
		props?.handleSettle(invoice?.id, invoice?.value);
	};
	React.useEffect(() => {
		setData(props?.data);
	}, [props?.data]);

	return (
		<div
			className="user"
			key={data?._id}
			style={{
				display: "flex",
				flexDirection: "row",
				justifyContent: "space-between",
				margin: "2px",
			}}
		>
			<input
				type="text"
				key={data?._id}
				defaultValue={data?.invoice}
				disabled={!editState}
				onChange={(e) => {
					setInvoice({ id: data?._id, value: e?.target?.value });
				}}
			/>
			{!editState && (
				<button
					className="btn btn-sm btn-info"
					onClick={() => {
						setEditState(true);
					}}
				>
					Edit
				</button>
			)}
			{editState && (
				<div
					style={{
						display: "flex",
						flexDirection: "row",
						justifyContent: "space-evenly",
					}}
				>
					<button
						className="btn btn-sm btn-success"
						style={{ marginRight: "4px" }}
						onClick={onEditUser}
					>
						Save
					</button>
					<button
						className="btn btn-sm btn-danger verify-bt"
						onClick={() => setEditState(false)}
					>
						Cancel
					</button>
				</div>
			)}
		</div>
	);
}
