import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import axios from "axios";
import { checkMandatoryAmenities } from "../ProfileValidations";

const API_URL = process.env.REACT_APP_API_URL || "";

export default function Amenities({
	form,
	setForm,
	propertyValidation,
	setPropertyValidation,
}: any) {
	const history = useHistory();
	const [menu, setMenu] = useState([
		{
			amenity: "Basic Facilities",
			value: "basic_facilities",
			pt: "0",
			list: [
				"Elevator/ Lift",
				"Air Conditioning",
				"Bathroom",
				"Dry Cleaning Services",
				"Housekeeping",
				"Intercom",
				"Ironing Services",
				"Kitchen/Kitchenette",
				"LAN",
				"Laundry",
				"Newspaper",
				"Parking",
				"Power Backup",
				"Refrigerator",
				"Room Service",
				"Smoke Detector",
				"Smoking Rooms",
				"Swimming Pool",
				"Telephone",
				"Torch",
				"Umbrellas",
				"Washing Machine",
				"WiFi",
				"Public Restrooms",
				"Laundromat",
				"Vending Machine",
				"Private Entrance",
				"Sanitizers",
				"EV Charging Station",
			],
		},
		{
			amenity: "General Services",
			value: "general_services",
			pt: "20",
			list: [
				"Bellboy Service",
				"Caretaker",
				"Concierge",
				"Multilingual Staff",
				"Luggage Assistance",
				"Luggage Storage",
				"Mail Services",
				"Specially Abled Assistance",
				"Wake-up Call / Service",
				"Wheelchair",
				"Electrical Sockets",
				"Postal Services",
				"Butler Services",
				"Doctor on call",
				"Medical Centre",
				"Ticket/ Tour Assistance",
				"Pool/ Beach towels",
				"Welcome kit",
			],
		},
		{
			amenity: "Outdoor Activities and Sports",
			value: "outdoor_activities_sports",
			pt: "60",
			list: [
				"Beach",
				"Boat ride",
				"Bonfire",
				"Golf",
				"Kayaks",
				"Outdoor sports",
				"Sea plane",
				"Snorkeling",
				"Telescope",
				"Water sports",
				"Vehicle Rentals",
				"Canoeing",
				"Skiing",
				"Jungle Safari",
				"Guided Tours And Treks",
				"Cycling",
			],
		},
		{
			amenity: "Common Area",
			value: "common_area",
			pt: "100",
			list: [
				"Aquarium",
				"Balcony/ Terrace",
				"Fireplace",
				"Lawn",
				"Library",
				"Lounge",
				"Reception",
				"Seating Area",
				"Sun Deck",
				"Temple/ Chapel",
				"Verandah",
				"Jacuzzi",
				"Prayer Room",
				"Living Room",
				"Outdoor Furniture",
				"Picnic area",
				"Game Room",
				"Sitout Area",
				"TV Area",
				"Bonfire Pit",
			],
		},
		{
			amenity: "Food and Drink",
			value: "food_drink",
			pt: "130",
			list: [
				"Bar",
				"Barbecue",
				"Cafe",
				"Coffee shop",
				"Dining Area",
				"Kids meals",
				"Restaurant",
				"Special Diet Meals",
				"Cooking class",
				"Bakery",
			],
		},
		{
			amenity: "Health and wellness",
			value: "health_wellness",
			pt: "150",
			list: [
				"Activity Centre",
				"Gym/ Fitness centre",
				"Reflexology",
				"Yoga",
				"Meditation Room",
				"Aerobics",
				"First-aid services",
				"Solarium",
				"Hot Spring bath",
			],
		},
		{
			amenity: "Business Center and Conferences",
			value: "business_center_conference",
			pt: "190",
			list: [
				"Banquet",
				"Business Center",
				"Business services",
				"Conference room",
				"Photocopying",
				"Fax service",
				"Printer",
			],
		},
		{
			amenity: "Beauty and Spa",
			value: "beauty_spa",
			pt: "220",
			list: [
				"Facial treatments",
				"Hair treatment",
				"Manicure/ Pedicure",
				"Massage",
				"Salon",
				"Spa",
				"Steam and Sauna",
				"Open air bath",
				"Public bath",
				"Hammam",
			],
		},
		{
			amenity: "Security",
			value: "security",
			pt: "255",
			list: [
				"Bodyguards",
				"Electronic keycard",
				"Emergency exit map",
				"Safe",
				"Security",
				"CCTV",
				"Fire extinguishers",
				"Safety and Security",
				"Security alarms",
				"Smoke alarms",
				"Smart Door Lock",
				"Fire Alarm",
				"Security Guard",
				"Carbon Monoxide Detector",
				"Access Control System",
			],
		},
		{
			amenity: "Transfers",
			value: "transfers",
			pt: "275",
			list: [
				"Airport Transfers",
				"Pickup/ Drop",
				"Shuttle Service",
				"Railway Station Transfers",
				"Bus Station transfers",
				"Public transit tickets",
			],
		},
		{
			amenity: "Shopping",
			value: "shopping",
			pt: "305",
			list: [
				"Book shop",
				"Grocery",
				"Shops",
				"Souvenir shop",
				"Jewellery Shop",
			],
		},
		{
			amenity: "Entertainment",
			value: "entertainment",
			pt: "330",
			list: [
				"Movie Room",
				"Home Theatre",
				"Music System",
				"Events",
				"Pub",
				"Radio",
				"Photo Session",
				"Night Club",
				"Beach club",
			],
		},
		{
			amenity: "Media and Technology",
			value: "media_tech",
			pt: "350",
			list: [
				"Electrical Adapters Available",
				"Electrical chargers",
				"Laptops",
				"TV",
				"Broadband",
				"Phone Network",
			],
		},
		{
			amenity: "Payment Services",
			value: "payment_services",
			pt: "395",
			list: ["ATM", "Currency Exchange", "Cash", "Card", "UPI"],
		},
		{
			amenity: "Indoor Activities and Sports",
			value: "Indoor Activities and Sports",
			pt: "425",
			list: ["Casino", "Indoor games"],
		},
		{
			amenity: "Family and kids",
			value: "family_kids",
			pt: "455",
			list: [
				"Childcare service",
				"Children's play area",
				"Kids' Club",
				"Strollers",
				"Playground",
			],
		},
		{
			amenity: "Safety & Hygiene",
			value: "safety_hygiene",
			pt: "495",
			list: [
				"Disinfection",
				"Shoe covers",
				"Hair nets",
				"Face shields",
				"PPE (Personal Protective Equipment) kits",
				"Hospital in the vicinity",
				"Safety authorization certificate",
				"Disposable Serveware",
				"Contactless room service",
				"Thermal screening at entry and exit points",
				"Dispenser for disinfectants",
				"Sanitizers installed",
				"Masks",
				"Disinfectant wipes",
				"Gloves",
				"Sanitizers",
				"Contactless check-in",
				"Safety kit",
			],
		},
		{
			amenity: "Pet essentials",
			value: "pet_essentials",
			pt: "515",
			list: ["Pet bowls", "Pet baskets"],
		},
		{
			amenity: "Room highlights",
			value: "room_highlights",
			pt: "550",
			list: [
				"Machan",
				"Night View",
				"tent",
				"river / lake view",
				"Open Terrance",
				"Open Shower",
				"Bathtub/ Jacuzzi",
				"open balcony ",
				"Private pool",
				"bonfire",
				"kitchen",
				"dining ",
			],
		},
		{
			amenity: "Travel Moods",
			value: "travel_moods",
			pt: "580",
			list: [
				"Chillout",
				"Adventure",
				"Luxury",
				"Leisure",
				"City Escape",
				"Weekend gateway",
				"Budget",
				"Safari",
				"Nature",
				"Solo",
			],
		},
	]);

	const [totalNames, setTotalNames] = useState<string[]>([]);

	const [selectedList, setSlelectedList] = useState([]);
	const [selectedItem, setSelectedItem] = useState("");
	const [pt, setPt] = useState("");
	const [disabled, setDisabled] = useState(false);

	const setMenuItems = (list: any, item: any) => {
		setSlelectedList(list);
		setSelectedItem(item.value);
		setPt(item.pt);
	};
	const [changes, setChanges] = useState(false);

	const handleChange = (event: any, parent: any, value: any) => {
		let obj = { ...form };
		if (!obj[parent][value]) obj[parent][value] = [];
		if (event.target.checked) {
			obj[parent][value].push(event.target.value);

			setTotalNames((prevNames) => [...prevNames, obj[parent][value]]);
			// console.log(obj[parent][value], "check amenities icon name");
		} else {
			let i = obj[parent][value].indexOf(event.target.value);
			obj[parent][value].splice(i, 1);
		}
		setForm(obj);
		checkMandatory(form);
		setChanges(true);

		// console.log(obj, "handle change function ");

		// console.log(parent, value, selectedItem, selectedList, "handleChange") // parent, value, selectedItem, selectedList,
	};

	const navigate = (e: any) => {
		if (e == "continue") {
			if (!changes) {
				history.push("/crafted/pages/profile/rooms");
				return;
			}
			let body: any = { ...form };
			if (form.hasOwnProperty("_id")) {
				body["property_id"] = body._id;
			}
			if (body?.property_status === "Verified")
				body["property_status"] = "Update";
			changes &&
				axios
					.post(`${API_URL}/v1/vendor/onboarding`, { data: body })
					.then(() => {
						history.push("/crafted/pages/profile/rooms");
						// console.log(totalNames, "total names usestate");
					})
					.catch((err) => {});
		} else history.push("/crafted/pages/profile/Location");
	};
	const checkMandatory = (form: any) => {
		if (checkMandatoryAmenities(form?.property_amenities)) {
			setDisabled(false);
			setPropertyValidation({ ...propertyValidation, amenities: true });
		} else {
			setDisabled(true);
			setPropertyValidation({ ...propertyValidation, amenities: false });
		}
	};

	useEffect(() => {
		checkMandatory(form);
	}, []);

	return (
		<div>
			<fieldset
				disabled={form?.property_status === "Unverified" ? true : false}
			>
				<div className="card card-custom mb-10">
					{/* <div className="about-resort-wrap">
            <h2>About Resort</h2>
            <textarea></textarea>
          </div> */}
					<div className="card-header">
						<h3 className="card-title align-items-start flex-column">
							<span className="card-label fw-bolder fs-3 mb-1">
								Resort Facilities
							</span>
							<span className="text-muted fw-bold fs-7">
								Please select general facilities available at your property. (
								<span className={disabled ? "text-danger" : "text-muted"}>
									Please choose at least 5
								</span>
								)
							</span>
						</h3>
					</div>
					{/* <div className="card-body d-flex faci-wrap" id="wrapper"> */}
					<div className="card-body d-flex faci-wrap">
						<div className="bg-white" id="sidebar-wrapper">
							<div className="list-group list-group-flush amenities-cat">
								{menu.map((item, i) => (
									<a
										className={
											"list-group-item list-group-item-action list-group-item-light p-1" +
											(selectedItem == item.value ? " active" : "")
										}
										style={{ fontSize: "12px" }}
										href="javascript:;"
										onClick={(e) => setMenuItems(item.list, item)}
										key={item.amenity}
									>
										{item.amenity} ({item.list.length})
									</a>
								))}
							</div>
						</div>
						<div id="page-content-wrapper">
							<div className="container-fluid ps-10">
								{/* <h1 className="mt-4">Simple Sidebar</h1> */}
								{selectedList.map((ele) => (
									<>
										<div className="amenities-list-name">
											<div className="mb-1">
												<div className="form-check form-check-custom form-check-solid">
													<input
														className="form-check-input"
														type="checkbox"
														value={ele}
														id={ele}
														name={ele}
														checked={
															form["property_amenities"][selectedItem] &&
															form["property_amenities"][selectedItem].includes(
																ele
															)
														}
														onChange={(e) =>
															handleChange(
																e,
																"property_amenities",
																selectedItem
															)
														}
														key={ele}
													/>
													<label className="form-check-label" htmlFor={ele}>
														<span style={{ fontSize: "11px" }}>{ele}</span>
													</label>
												</div>
											</div>
										</div>
									</>
								))}
							</div>
						</div>
					</div>
				</div>
			</fieldset>
			<div className="d-flex flex-stack">
				<div className="me-2">
					<button
						type="button"
						className="btn btn-lg btn-secondary me-3"
						data-kt-stepper-action="previous"
						onClick={() => navigate("back")}
					>
						<span className="svg-icon svg-icon-4 me-1">
							<svg
								width="24"
								height="24"
								viewBox="0 0 24 24"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
								className="mh-50px"
							>
								<rect
									opacity="0.5"
									x="6"
									y="11"
									width="13"
									height="2"
									rx="1"
									fill="black"
								></rect>
								<path
									d="M8.56569 11.4343L12.75 7.25C13.1642 6.83579 13.1642 6.16421 12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75L5.70711 11.2929C5.31658 11.6834 5.31658 12.3166 5.70711 12.7071L11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25C13.1642 17.8358 13.1642 17.1642 12.75 16.75L8.56569 12.5657C8.25327 12.2533 8.25327 11.7467 8.56569 11.4343Z"
									fill="black"
								></path>
							</svg>
						</span>
						Back
					</button>
				</div>
				<div>
					<button
						type="submit"
						className="btn btn-lg btn-primary me-3"
						onClick={() => navigate("continue")}
						disabled={disabled}
					>
						<span className="indicator-label">
							Continue
							<span className="svg-icon svg-icon-3 ms-2 me-0">
								<svg
									width="24"
									height="24"
									viewBox="0 0 24 24"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
									className="mh-50px"
								>
									<rect
										opacity="0.5"
										x="18"
										y="13"
										width="13"
										height="2"
										rx="1"
										transform="rotate(-180 18 13)"
										fill="black"
									></rect>
									<path
										d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z"
										fill="black"
									></path>
								</svg>
							</span>
						</span>
					</button>
					{/* <button type="submit" className="btn btn-lg btn-success me-3">
                <span className="indicator-label">
                  Submit
                  <span className="svg-icon svg-icon-3 ms-2 me-0">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="mh-50px"
                    >
                      <rect
                        opacity="0.5"
                        x="18"
                        y="13"
                        width="13"
                        height="2"
                        rx="1"
                        transform="rotate(-180 18 13)"
                        fill="black"
                      ></rect>
                      <path
                        d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z"
                        fill="black"
                      ></path>
                    </svg>
                  </span>
                </span>
              </button> */}
				</div>
			</div>
		</div>
	);
}
