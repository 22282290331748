import {all} from 'redux-saga/effects'
import {combineReducers} from 'redux'

import * as auth from '../../app/modules/auth'
import {roomsSlice} from '../../app/pages/dashboard/redux/RoomsReducer'

export const rootReducer = combineReducers({
  auth: auth.reducer,
  room: roomsSlice.reducer
})

export type RootState = ReturnType<typeof rootReducer>

export function* rootSaga() {
  yield all([auth.saga()])
}
