export function SampleNextArrow(props: any) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block", background: "rgb(151 190 69)", borderRadius: "50%", }}
        onClick={onClick}
      />
    );
  }

  export function SamplePrevArrow(props: any) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block", background: "rgb(151 190 69)", borderRadius: "50%", }}
        onClick={onClick}
      />
    );
  }
