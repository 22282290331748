import React, { useEffect, useState } from "react";
import {
	Redirect,
	Route,
	Switch,
	useHistory,
	useLocation,
} from "react-router-dom";
import { PageLink, PageTitle } from "../../../_metronic/layout/core";
import { KTSVG } from "../../../_metronic/helpers";
//import {Overview} from './components/Overview'
import BasicInfo from "./components/BasicInfo";
import Location from "./components/Location";
//import {Projects} from './components/Projects'
import Amenities from "./components/Amenities";
import Rooms from "./components/Rooms";
import Photos from "./components/Photos";
import Policies from "./components/Policies";
import FinanceLegal from "./components/FinanceLegal";
import { ProfileHeader } from "./ProfileHeader";
import axios from "axios";
import "./ProfilePage.css";

const API_URL = process.env.REACT_APP_API_URL || "";

const profileBreadCrumbs: Array<PageLink> = [
	{
		title: "Profile",
		path: "/crafted/pages/profile/basicinfo",
		isSeparator: false,
		isActive: false,
	},
	{
		title: "",
		path: "",
		isSeparator: true,
		isActive: false,
	},
];

const ProfilePage: React.FC = () => {
	const newForm = {
		property_basic_info: {
			property_name: "",
			property_channel_manager: "",
		},
		property_location: {
			property_nearest_gate: "",
		},
		property_contact_details: {},
		property_amenities: {
			//"Basic Facilities": ['Electricity']
		},
		property_room_categories: [],
		property_rules: {
			guest_profile: [],
			id_proof: {
				acceptable_identity_proofs: "Adhaar",
				unacceptable_identity_proofs: "Adhaar",
				allow_same_id: "true",
			},
			general_safety_hygiene_guidelines: [],
			room_safety_hygiene: [],
			social_distancing: [],
			food_drinks_hygiene: [],
			property_restrictions: [],
			pet_policy: [],
			guest_suitabilty: [],
			checkin_checkout_policy: [],
			extra_bed_policy: [
				{
					question: "contactless?",
					value: "subject_of_availability",
				},
			],
			custom_policy: [],
		},
		property_policies: {
			checkin_time: "",
			checkout_time: "",
			cancellation_policy: "",
		},
		property_finance_legal: {
			bank_details: {},
			pan_details: {},
			registration_details: {},
			gst_details: "",
		},
		property_status: "",
		property_tc_agreed: false,
	};

	const [propertyValidation, setPropertyValidation] = useState({
		basicInfo: false,
		location: false,
		amenities: false,
		rooms: { valid: false, roomsFailValidation: [] },
		photos: false,
		policies: false,
		financeLegal: false,
	});

	const [load, setLoad] = useState(false);
	//const [detailsComplete, setDetailsComplete] = useState(false);
	//const [properties, setProperties] = useState<any[]>([{}]);
	const [form, setForm] = useState(newForm);
	// console.log("main newForm 2" , newForm);

	useEffect(() => {
		let propertyId = sessionStorage.getItem("property_id");
		if (!propertyId || propertyId === "_new_property") {
			console.log("trace main form 1", newForm);

			setForm(newForm);
			setLoad(true);
		} else {
			// console.log("main newForm else", newForm);

			axios.get(`${API_URL}/v1/vendor/profile`).then((res) => {
				let properties = res.data.data.vendor_properties;
				let property = properties.filter((p: any) => p._id === propertyId);
				console.log("trace main form 2", property);
				setForm(property[0]);
				setLoad(true);
			});
		}
	}, []);
	console.log("trace main form 3", form);
	return (
		<>
			{form?.property_status === "Unverified" && (
				<div className="text-danger">**Property under verification</div>
			)}
			<ProfileHeader />
			{load && (
				<Switch>
					<Route path="/crafted/pages/profile/basicinfo">
						<PageTitle breadcrumbs={profileBreadCrumbs}>Basic Info</PageTitle>
						<BasicInfo
							form={form}
							setForm={setForm}
							propertyValidation={propertyValidation}
							setPropertyValidation={setPropertyValidation}
							key={sessionStorage.getItem("property_id")}
							//setProperties={setProperties}
						/>
					</Route>
					<Route path="/crafted/pages/profile/location">
						<PageTitle breadcrumbs={profileBreadCrumbs}>Location</PageTitle>
						<Location
							form={form}
							setForm={setForm}
							propertyValidation={propertyValidation}
							setPropertyValidation={setPropertyValidation}
							key={sessionStorage.getItem("property_id")}
						/>
					</Route>
					<Route path="/crafted/pages/profile/Amenities">
						<PageTitle breadcrumbs={profileBreadCrumbs}>Amenities</PageTitle>
						<Amenities
							form={form}
							setForm={setForm}
							propertyValidation={propertyValidation}
							setPropertyValidation={setPropertyValidation}
							key={sessionStorage.getItem("property_id")}
						/>
					</Route>
					<Route path="/crafted/pages/profile/rooms">
						<PageTitle breadcrumbs={profileBreadCrumbs}>Rooms</PageTitle>
						<Rooms
							form={form}
							setForm={setForm}
							propertyValidation={propertyValidation}
							setPropertyValidation={setPropertyValidation}
							key={sessionStorage.getItem("property_id")}
						/>
					</Route>
					<Route path="/crafted/pages/profile/photos">
						<PageTitle breadcrumbs={profileBreadCrumbs}>Photos</PageTitle>
						<Photos
							form={form}
							setForm={setForm}
							propertyValidation={propertyValidation}
							setPropertyValidation={setPropertyValidation}
							key={sessionStorage.getItem("property_id")}
						/>
					</Route>

					<Route path="/crafted/pages/profile/policies">
						<PageTitle breadcrumbs={profileBreadCrumbs}>Policies</PageTitle>
						<Policies
							form={form}
							setForm={setForm}
							propertyValidation={propertyValidation}
							setPropertyValidation={setPropertyValidation}
							key={sessionStorage.getItem("property_id")}
						/>
					</Route>
					<Route path="/crafted/pages/profile/financeLegal">
						<PageTitle breadcrumbs={profileBreadCrumbs}>FinanceLegal</PageTitle>
						<FinanceLegal
							form={form}
							setForm={setForm}
							propertyValidation={propertyValidation}
							setPropertyValidation={setPropertyValidation}
							key={sessionStorage.getItem("property_id")}
						/>
					</Route>

					{/* <Redirect from='/crafted/pages/profile' exact={true} to='/crafted/pages/profile/overview' />
        <Redirect to='/crafted/pages/profile/overview' /> */}
				</Switch>
			)}
		</>
	);
};

export default ProfilePage;
