/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useRef, useState, useEffect } from "react";
import { KTSVG, toAbsoluteUrl } from "../../../_metronic/helpers";
import { Link } from "react-router-dom";
import { Dropdown1 } from "../../../_metronic/partials";
import { useLocation } from "react-router-dom";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../setup";
import axios from "axios";
import * as auth from "../auth/redux/AuthRedux";

const API_URL = process.env.REACT_APP_API_URL || "";

const ProfileHeader: React.FC = () => {
	const location = useLocation();
	const user: any = useSelector<RootState>(
		({ auth }) => auth.user,
		shallowEqual
	);
	const dispatch = useDispatch();
	const [profilePic, setProfilePic] = useState("");
	const [loading, setLoading] = useState(false);

	const selectImage = useRef<HTMLInputElement | null>(null);
	const uploadPhoto = () => {
		// console.log("we are in upload");
		// console.log(selectImage.current);

		// if (!selectImage.current) return;
		if (selectImage.current) {
			selectImage.current.click();
		}
	};

	const handleImageChange = (e: any) => {
		// console.log("HandleImage change");

		setLoading(true);
		let image = e.target.files[0];
		let formData = new FormData();
		formData.append("name", "aksht");
		formData.append("user_profile_pic", image);
		axios.post(`${API_URL}/v1/user/profilepic`, formData).then(() => {
			axios.get(`${API_URL}/v1/user/profilepic`).then((res) => {
				dispatch(auth.actions.requestUser());
				setProfilePic(res.data.data);
				if (res.data.data) {
					setLoading(false);
				}
			});
		});
	};

	useEffect(() => {
		// console.log("here");

		axios.get(`${API_URL}/v1/user/profilepic`).then((res) => {
			// console.log("data", res);

			setProfilePic(res.data.data);
		});
	}, []);

	return (
		user && (
			<div className="card mb-5 mb-xl-10">
				<div className="card-body pt-1 pb-0">
					{location.pathname.lastIndexOf("/") == 14 && (
						<div className="d-flex flex-wrap flex-sm-nowrap mb-3">
							<div className="me-7 mb-4">
								<div className="symbol symbol-100px symbol-lg-160px symbol-fixed position-relative">
									<img
										src={
											profilePic
												? `data:image/png;base64,${Buffer.from(
														profilePic
												  ).toString("base64")}`
												: toAbsoluteUrl("/media/avatars/blank.png")
										}
										alt="Metronic"
									/>
									<div className="position-absolute translate-middle bottom-0 start-100 top-25 bg-success rounded-circle border border-4 border-white h-20px w-20px"></div>
									<div className="position-absolute start-0 top-0 btn btn-icon btn-active-light-primary border border-4 border-white w-30px h-30px">
										<input
											type="file"
											ref={selectImage}
											// style={{ display: "none" }}
											onChange={(e) => handleImageChange(e)}
										/>
										<i
											className="fa fa-edit"
											style={{ color: "green" }}
											onClick={uploadPhoto}
										></i>
									</div>
								</div>
							</div>

							<div className="flex-grow-1">
								<div className="d-flex justify-content-between align-items-start flex-wrap mb-2">
									<div className="d-flex flex-column">
										<div className="d-flex align-items-center mb-2">
											<a
												href="#"
												className="text-gray-800 text-hover-primary fs-2 fw-bolder me-1"
											>
												{user.firstname + " " + user.lastname}
											</a>
											{/* <a href='#'>
                    <KTSVG
                      path='/media/icons/duotone/Design/Verified.svg'
                      className='svg-icon-1 svg-icon-primary'
                    />
                  </a> */}
										</div>

										<div className="d-flex flex-wrap flex-column fw-bold fs-6 mb-4 pe-2">
											<a
												href="#"
												className="d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2"
											>
												<KTSVG
													path="/media/icons/duotone/General/User.svg"
													className="svg-icon-4 me-1"
												/>
												{user.companyname}
											</a>
											{/* <a
                    href='#'
                    className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2'
                  >
                    <KTSVG
                      path='/media/icons/duotone/Map/Marker1.svg'
                      className='svg-icon-4 me-1'
                    />
                    SF, Bay Area
                  </a> */}
											<a
												href="#"
												className="d-flex align-items-center text-gray-400 text-hover-primary mb-2"
											>
												<KTSVG
													path="/media/icons/duotone/Communication/Mail-at.svg"
													className="svg-icon-4 me-1"
												/>
												{user.email}
											</a>

											<a
												href="#"
												className="d-flex align-items-center text-gray-400 text-hover-primary mb-2"
											>
												<KTSVG
													path="/media/icons/duotone/Interface/Phone.svg"
													className="svg-icon-4 me-1"
												/>
												{user.phone}
											</a>
											{loading && (
												<a
													href="#"
													className="d-flex align-items-center text-gray-400 text-hover-primary mb-2"
												>
													<KTSVG
														path="/media/icons/duotone/Files/Upload.svg"
														className="svg-icon-4 me-1"
													/>
													<span> Uploading Profile Pic... </span>
												</a>
											)}
										</div>
									</div>

									{/* <div className='d-flex my-4'>
                <a href='#' className='btn btn-sm btn-light me-2' id='kt_user_follow_button'>
                  <KTSVG
                    path='/media/icons/duotone/Navigation/Double-check.svg'
                    className='svg-icon-3 d-none'
                  />

                  <span className='indicator-label'>Follow</span>
                  <span className='indicator-progress'>
                    Please wait...
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                </a>
                <a
                  href='#'
                  className='btn btn-sm btn-primary me-3'
                  data-bs-toggle='modal'
                  data-bs-target='#kt_modal_offer_a_deal'
                >
                  Hire Me
                </a>
                <div className='me-0'>
                  <button
                    className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'
                    data-kt-menu-trigger='click'
                    data-kt-menu-placement='bottom-end'
                    data-kt-menu-flip='top-end'
                  >
                    <i className='bi bi-three-dots fs-3'></i>
                  </button>
                  <Dropdown1 />
                </div>
              </div>
            
             */}
								</div>

								<div className="d-flex flex-wrap flex-stack align-items-end justify-content-end">
									{/* <div className='d-flex flex-column flex-grow-1 pe-8'>
                <div className='d-flex flex-wrap'>
                  <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                    <div className='d-flex align-items-center'>
                      <KTSVG
                        path='/media/icons/duotone/Navigation/Arrow-up.svg'
                        className='svg-icon-3 svg-icon-success me-2'
                      />
                      <div className='fs-2 fw-bolder'>4500$</div>
                    </div>

                    <div className='fw-bold fs-6 text-gray-400'>Earnings</div>
                  </div>

                  <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                    <div className='d-flex align-items-center'>
                      <KTSVG
                        path='/media/icons/duotone/Navigation/Arrow-down.svg'
                        className='svg-icon-3 svg-icon-danger me-2'
                      />
                      <div className='fs-2 fw-bolder'>75</div>
                    </div>

                    <div className='fw-bold fs-6 text-gray-400'>Projects</div>
                  </div>

                  <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                    <div className='d-flex align-items-center'>
                      <KTSVG
                        path='/media/icons/duotone/Navigation/Arrow-up.svg'
                        className='svg-icon-3 svg-icon-success me-2'
                      />
                      <div className='fs-2 fw-bolder'>60%</div>
                    </div>

                    <div className='fw-bold fs-6 text-gray-400'>Success Rate</div>
                  </div>
                </div>
              </div> */}

									{/* <div className='d-flex align-items-end w-200px w-sm-300px flex-column mt-3'>
                <div className='d-flex justify-content-between w-100 mt-auto mb-2'>
                  <span className='fw-bold fs-6 text-gray-400'>Profile Compleation</span>
                  <span className='fw-bolder fs-6'>100%</span>
                </div>
                <div className='h-5px mx-3 w-100 bg-light mb-3'>
                  <div
                    className='bg-success rounded h-5px'
                    role='progressbar'
                    style={{width: '100%'}}
                  ></div>
                </div>
              </div> */}
								</div>
							</div>
						</div>
					)}

					{location.pathname.lastIndexOf("/") === 22 && (
						<div className="d-flex overflow-auto h-55px">
							<ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap">
								<li className="nav-item">
									<Link
										className={
											`nav-link text-active-primary me-6 ` +
											(location.pathname ===
												"/crafted/pages/profile/BasicInfo" && "active")
										}
										to="/crafted/pages/profile/BasicInfo"
									>
										Basic info
									</Link>
								</li>
								<li className="nav-item">
									<Link
										className={
											`nav-link text-active-primary me-6 ` +
											(location.pathname ===
												"/crafted/pages/profile/Location" && "active")
										}
										to="/crafted/pages/profile/Location"
									>
										Location
									</Link>
								</li>
								<li className="nav-item">
									<Link
										className={
											`nav-link text-active-primary me-6 ` +
											(location.pathname ===
												"/crafted/pages/profile/amenities" && "active")
										}
										to="/crafted/pages/profile/amenities"
									>
										Amenities
									</Link>
								</li>
								<li className="nav-item">
									<Link
										className={
											`nav-link text-active-primary me-6 ` +
											(location.pathname === "/crafted/pages/profile/rooms" &&
												"active")
										}
										to="/crafted/pages/profile/rooms"
									>
										Rooms
									</Link>
								</li>
								<li className="nav-item">
									<Link
										className={
											`nav-link text-active-primary me-6 ` +
											(location.pathname === "/crafted/pages/profile/photos" &&
												"active")
										}
										to="/crafted/pages/profile/photos"
									>
										Photos
									</Link>
								</li>

								<li className="nav-item">
									<Link
										className={
											`nav-link text-active-primary me-6 ` +
											(location.pathname ===
												"/crafted/pages/profile/policies" && "active")
										}
										to="/crafted/pages/profile/policies"
									>
										Policies
									</Link>
								</li>

								<li className="nav-item">
									<Link
										className={
											`nav-link text-active-primary me-6 ` +
											(location.pathname ===
												"/crafted/pages/profile/financeLegal" && "active")
										}
										to="/crafted/pages/profile/financeLegal"
									>
										Finance & Legal
									</Link>
								</li>
							</ul>
						</div>
					)}
				</div>
			</div>
		)
	);
};

export { ProfileHeader };
