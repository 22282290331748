/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useIntl } from "react-intl";
import { PageTitle } from "../../../_metronic/layout/core";
import "./Dashboard.scss";

import { KTSVG } from "../../../_metronic/helpers";
import { useDispatch, useSelector } from "react-redux";
import { fetchRoomsByPropertyId } from "./redux/RoomsReducer";
import axios from "axios";
import { Modal } from "react-bootstrap-v5";
import { useFormik } from "formik";
import * as Yup from "yup";
import { GiConsoleController } from "react-icons/gi";
import { useDateState } from "../../../_metronic/layout/components/hookState/DateState";
import { Downgraded } from "@hookstate/core";
import { enqueueSnackbar } from "notistack";

const API_URL = process.env.REACT_APP_API_URL || "";

const DashboardPage: FC<{
	propertyId: any;
	vendorId: any;
	categories: any;
	rooms: any;
	propertyStatus: string;
}> = ({ propertyId, vendorId, categories, rooms, propertyStatus }) => {
	// console.log("rooms001", rooms);
	const getDigits = (s: any) => (s.match(/\d+/) || [Number.MAX_VALUE])[0];
	const dateState = useDateState();
	const dispatch = useDispatch();
	const [toast, setToast] = useState(false);
	const [failtoast, setFailToast] = useState(false);
	//const [warntoast, setWarnToast] = useState(false);
	const [failToastMsg, setFailToastMsg] = useState("Booking Failed");
	//const [warnToastMsg, setWarnToastMsg] = useState("Warning");
	const [form, setForm] = useState<any>({
		booking: {},
	});
	const [dashboard, setDashboard] = useState<any>({});
	const [disabled, setDisabled] = useState(true);
	const [bookModal, setBookModal] = useState(false);

	const [totalEaring, setTotalEaring] = useState(0);
	const [totalBooking, setTotalBooking] = useState(0);
	const [upcoming, setUpcoming] = useState(0);
	const [todayBooking, setTodayBooking] = useState([]);
	const history = useHistory();

	const bookingSchema = Yup.object().shape({
		booking_full_name: Yup.string()
			.max(50, "Maximum 50 symbols")
			.required("Full name is required field")
			.matches(/^[aA-zZ\s]+$/, "Can't contain numbers or special characters"),
		booking_phone_no: Yup.string()
			.min(10, "Enter Valid 10 digit number")
			.max(10, "Enter Valid 10 digit number")
			.required("Contact Phone is required")
			.matches(/^[0-9]+$/, "Only numbers are allowed for this field"),
		booking_country: Yup.string().required("Country is required field"),
		booking_address: Yup.string().required("Address is required field"),
		booking_email: Yup.string()
			.email("Wrong email format")
			.min(5, "Minimum 5 symbols")
			.max(50, "Maximum 50 symbols"),
		checkIn_date_time: Yup.date()
			.typeError("Check In is required")
			.required("Check In is required"),
		checkOut_date_time: Yup.date()
			.typeError("Check Out is required")
			.required("Check Out is required")
			.when("checkIn_date_time", (checkIn_date_time, schema) => {
				if (checkIn_date_time) {
					return schema
						.min(checkIn_date_time, "Check Out must be after Check In date")
						.test(
							"same_dates_test",
							"Check In and Check Out dates must not be equal.",
							function (value: any) {
								if (value && checkIn_date_time)
									return value.getTime() !== checkIn_date_time.getTime();
							}
						);
				}
			}),
		booking_amount: Yup.string()
			.required("Booking Amount is required field")
			.matches(/^[0-9]+$/, "Only numbers are allowed for this field"),
	});

	const initialValues = {
		booking_full_name: "",
		booking_phone_no: "",
		booking_country: "",
		booking_address: "",
		booking_email: "",
		checkIn_date_time: "",
		checkOut_date_time: "",
		booking_amount: "",
	};

	const closeBookModal = () => {
		formik.resetForm();
		setForm({ booking: {} });
		setBookModal(false);
	};

	const bookRoom = (e: any) => {
		let obj = { ...form };

		obj["booking"]["booking_user_id"] = sessionStorage.getItem("vendor_id");
		obj["booking"]["booking_vendor_id"] = sessionStorage.getItem("vendor_id");

		obj["booking"]["booking_property_id"] = e.property_id;
		obj["booking"]["booking_room_id"] = e.category_id;
		obj["booking"]["room_status"] = e.room_status;
		obj["booking"]["room_description"] = e.room_description;
		obj["booking"]["booking_status"] = "Success";
		obj["booking"]["vendor_payble"] = e?.booking_info?.booking_amount;
		obj["booking"]["booking_type"] = "Couple";

		obj["booking"]["payment_status"] = "Offline";
		obj["booking"]["no_of_adult"] = "0";
		obj["booking"]["no_of_child"] = 0;
		obj["booking"]["no_of_bigchild"] = 0;
		obj["booking"]["total_room"] = "1";
		obj["booking"]["total_guest"] = 1;

		obj["booking"]["group_adult"] = "0";
		obj["booking"]["group_child"] = "0";
		obj["booking"]["total_group_num"] = 0;
		obj["booking"]["tax_fees"] = "0";
		obj["booking"]["coupon_discount"] = 0;
		obj["booking"]["commission"] = "0";

		if (e?.booking_info?.checkOut_date_time)
			obj["booking"]["min"] = new Date(e?.booking_info?.checkOut_date_time)
				.toISOString()
				.split("T")[0];
		else obj["booking"]["min"] = new Date().toISOString().split("T")[0];

		formik.resetForm();
		setForm(obj);
		setBookModal(true);
	};
	const formik = useFormik({
		initialValues,
		validationSchema: bookingSchema,
		onSubmit: (values, { resetForm, setSubmitting }) => {
			form.booking = { ...form.booking, ...values, ...form };
			// console.log("form.booking", form.booking);
			delete form.booking.room_status;
			const dateIn = new Date(form?.booking?.checkIn_date_time);
			const CheckInDate = dateIn.toLocaleDateString("en-GB");

			const dateOut = new Date(form?.booking?.checkOut_date_time);
			const checkOutdate = dateOut.toLocaleDateString("en-GB");
			delete form.booking.checkIn_date_time;
			delete form.booking.checkOut_date_time;
			form.booking = {
				...form.booking,
				checkIn_date_time: CheckInDate,
				checkOut_date_time: checkOutdate,
			};

			axios.post(`${API_URL}/v1/book/create_booking`, form.booking).then(
				(res) => {
					// console.log("------------->", res);
					if (res.data.message !== "Booking created successfully") {
						// alert(res.data.message);
						enqueueSnackbar(res.data.message, {
							variant: "error",
							anchorOrigin: {
								horizontal: "right",
								vertical: "top",
							},
						});
						closeBookModal();
						dispatch(
							fetchRoomsByPropertyId(
								JSON.parse(
									JSON.stringify(sessionStorage.getItem("property_id"))
								)
							)
						);
					} else {
						enqueueSnackbar(res.data.message, {
							variant: "success",
							anchorOrigin: {
								horizontal: "right",
								vertical: "top",
							},
						});
						closeBookModal();
						dispatch(
							fetchRoomsByPropertyId(
								JSON.parse(
									JSON.stringify(sessionStorage.getItem("property_id"))
								)
							)
						);
					}
				},
				(err) => {
					// setFailToastMsg(err.response.data.message);
					// setFailToast(true);
					setSubmitting(false);
					enqueueSnackbar(err.response.data.message, {
						variant: "error",
						anchorOrigin: {
							horizontal: "right",
							vertical: "top",
						},
					});
					dispatch(
						fetchRoomsByPropertyId(
							JSON.parse(JSON.stringify(sessionStorage.getItem("property_id")))
						)
					);
				}
			);
		},
	});

	const blockRoom = (room: any) => {
		const dateIn = new Date(dateState?.attach(Downgraded).get().customeDate);
		const CheckInDate = dateIn.toLocaleDateString("en-GB");
		// dateState?.attach(Downgraded).get().customeDate;

		let data = {
			booking_address: "Nagpur",
			booking_amount: "00",
			booking_country: "IN",
			booking_email: localStorage.getItem("UserEmail"),
			booking_full_name: localStorage.getItem("UserFirstname"),
			booking_phone_no: localStorage.getItem("UserNum"),
			booking_property_id: room.property_id,
			booking_room_id: room.category_id,
			booking_status: room._id,
			booking_type: "Couple",
			booking_user_id: sessionStorage.getItem("vendor_id"),
			booking_vendor_id: sessionStorage.getItem("vendor_id"),
			checkIn_date_time: CheckInDate,
			checkOut_date_time: CheckInDate,
			commission: "0",
			coupon_discount: 0,
			group_adult: "0",
			group_child: "0",
			no_of_adult: "0",
			no_of_bigchild: 0,
			no_of_child: 0,
			payment_status: "Blocked",
			tax_fees: "0",
			total_group_num: 0,
			total_guest: 1,
			total_room: "1",
		};

		axios.post(`${API_URL}/v1/book/create_booking`, data).then(
			(res) => {
				// console.log("------------->", res);
				if (res.data.message !== "Booking created successfully") {
					// alert(res.data.message);
					enqueueSnackbar(res.data.message, {
						variant: "error",
						anchorOrigin: {
							horizontal: "right",
							vertical: "top",
						},
					});
				}
				dispatch(
					fetchRoomsByPropertyId(
						JSON.parse(JSON.stringify(sessionStorage.getItem("property_id")))
					)
				);
			},
			(err) => {
				enqueueSnackbar(err.response.data.message, {
					variant: "error",
					anchorOrigin: {
						horizontal: "right",
						vertical: "top",
					},
				});
			}
		);

		dispatch(
			fetchRoomsByPropertyId(
				JSON.parse(JSON.stringify(sessionStorage.getItem("property_id")))
			)
		);
	};
	const unblockRoom = (room: any) => {
		console.log("room", room);
		console.log("room", room._id);

		axios
			.post(`${API_URL}/v1/book/cancel_booking`, {
				_id: room._id,
				booking_status: "Cancelled",
				payment_status: "Cancelled",
			})
			.then(() => {
				dispatch(
					fetchRoomsByPropertyId(
						JSON.parse(JSON.stringify(sessionStorage.getItem("property_id")))
					)
				);

				// axios
				// 	.post(`${API_URL}/v1/customer/booking_cancelled`, {
				// 		mobile: mobile,
				// 		userEmailId: userEmailId,
				// 		firstName: firstName,
				// 		lastName: lastName,
				// 		propertyName: propertyName,
				// 		roomName: roomName,
				// 		checkIn: checkIn,
				// 		checkOut: checkOut,
				// 		totalGuest: totalGuest,
				// 		bookingId: id,
				// 	})
				// 	.then((res) => {
				// 		console.log(res);
				// 	});
			});
	};
	const navigate = () => {
		setToast(false);
	};
	const handleBooking = () => {
		history.push("/order");
	};
	useEffect(() => {
		if (propertyId !== "")
			propertyId &&
				axios
					.get(
						`${API_URL}/v1/vendor/dashboard?property_id=${propertyId}&vendor_id=${vendorId}`
					)
					.then((res) => {
						let today = new Date().setHours(0, 0, 0, 0);
						let priorDate = new Date(
							new Date().setDate(new Date().getDate() - 30)
						).setHours(0, 0, 0, 0);

						let TodayBooking =
							res &&
							res.data.data &&
							res.data.data.filter((row: any) => {
								if (row.booking_status === "Success") {
									let checkIn = new Date(row.checkIn_date_time).setHours(
										0,
										0,
										0,
										0
									);

									if (today === checkIn) {
										return row;
									}
								}
							});
						// console.log("today----------", Today);
						setTodayBooking(TodayBooking);
						let upcoming =
							res &&
							res.data.data &&
							res.data.data.filter((row: any) => {
								if (row.booking_status === "Success") {
									let checkIn = new Date(row.checkIn_date_time).setHours(
										0,
										0,
										0,
										0
									);

									if (today < checkIn) {
										return row;
									}
								}
							});

						setUpcoming(upcoming && upcoming.length > 0 ? upcoming.length : 0);
						let price = 0;
						let oneMonth =
							res &&
							res.data.data &&
							res.data.data.filter((row: any) => {
								// console.log("111111------", row?.vendor_payble);
								if (row.booking_status === "Success") {
									let checkIn = new Date(row.checkIn_date_time).setHours(
										0,
										0,
										0,
										0
									);

									if (checkIn <= today && checkIn > priorDate) {
										price = Number(price) + Number(row?.vendor_payble);
										return row;
									}
								}
							});
						setTotalBooking(
							oneMonth && oneMonth.length > 0 ? oneMonth.length : 0
						);

						setTotalEaring(price);
						// setDashboard(res.data.data);
						// let valued = dashboard?.upcoming_bookings_list;
						// if (valued !== undefined) {
						// 	console.log(
						// 		"dashhboard api response true",
						// 		dashboard?.upcoming_bookings_list
						// 	);
						// } else {
						// 	console.log(
						// 		"dashhboard api response false",
						// 		dashboard?.upcoming_bookings_list
						// 	);
						// }
					});
	}, [propertyId]);

	return (
		<>
			{dashboard && (
				<>
					<div className="row for-mob-row">
						<div className="col-lg-4 for-mob-col">
							<div className="dash-box">
								<div className="d-flex align-items-start bg-light-warning rounded p-5">
									<span className="svg-icon svg-icon-warning me-5">
										<span className="svg-icon svg-icon-1">
											<svg
												width="24"
												height="24"
												viewBox="0 0 24 24"
												fill="none"
												xmlns="http://www.w3.org/2000/svg"
												className="mh-50px"
											>
												<path
													opacity="0.3"
													d="M21.25 18.525L13.05 21.825C12.35 22.125 11.65 22.125 10.95 21.825L2.75 18.525C1.75 18.125 1.75 16.725 2.75 16.325L4.04999 15.825L10.25 18.325C10.85 18.525 11.45 18.625 12.05 18.625C12.65 18.625 13.25 18.525 13.85 18.325L20.05 15.825L21.35 16.325C22.35 16.725 22.35 18.125 21.25 18.525ZM13.05 16.425L21.25 13.125C22.25 12.725 22.25 11.325 21.25 10.925L13.05 7.62502C12.35 7.32502 11.65 7.32502 10.95 7.62502L2.75 10.925C1.75 11.325 1.75 12.725 2.75 13.125L10.95 16.425C11.65 16.725 12.45 16.725 13.05 16.425Z"
													fill="black"
												></path>
												<path
													d="M11.05 11.025L2.84998 7.725C1.84998 7.325 1.84998 5.925 2.84998 5.525L11.05 2.225C11.75 1.925 12.45 1.925 13.15 2.225L21.35 5.525C22.35 5.925 22.35 7.325 21.35 7.725L13.05 11.025C12.45 11.325 11.65 11.325 11.05 11.025Z"
													fill="black"
												></path>
											</svg>
										</span>
									</span>

									<div className="flex-grow-1 me-2">
										<a
											href="#"
											className="fw-bolder text-gray-800 text-hover-primary fs-6"
										>
											Total Earnings
										</a>
										<div className="total-rs">
											<h2>Rs. {totalEaring || 0}</h2>
											{/* <div>
                    <img
                      src={toAbsoluteUrl("/media/img/arrows.png")}
                      alt="MyResorts.in"
                    />
                    <p>{dashboard?.earning_increase}</p>
                  </div> */}
										</div>
										<span className="text-muted fw-bold d-block">
											Total sales (30 days)
										</span>
									</div>
								</div>
							</div>
						</div>

						<div className="col-lg-4 for-mob-col">
							<div className="dash-box green">
								<div className="d-flex align-items-start bg-light-success rounded p-5">
									<span className="svg-icon svg-icon-success me-5">
										<span className="svg-icon svg-icon-1">
											<svg
												width="24"
												height="24"
												viewBox="0 0 24 24"
												fill="none"
												xmlns="http://www.w3.org/2000/svg"
												className="mh-50px"
											>
												<path
													opacity="0.3"
													d="M21.25 18.525L13.05 21.825C12.35 22.125 11.65 22.125 10.95 21.825L2.75 18.525C1.75 18.125 1.75 16.725 2.75 16.325L4.04999 15.825L10.25 18.325C10.85 18.525 11.45 18.625 12.05 18.625C12.65 18.625 13.25 18.525 13.85 18.325L20.05 15.825L21.35 16.325C22.35 16.725 22.35 18.125 21.25 18.525ZM13.05 16.425L21.25 13.125C22.25 12.725 22.25 11.325 21.25 10.925L13.05 7.62502C12.35 7.32502 11.65 7.32502 10.95 7.62502L2.75 10.925C1.75 11.325 1.75 12.725 2.75 13.125L10.95 16.425C11.65 16.725 12.45 16.725 13.05 16.425Z"
													fill="black"
												></path>
												<path
													d="M11.05 11.025L2.84998 7.725C1.84998 7.325 1.84998 5.925 2.84998 5.525L11.05 2.225C11.75 1.925 12.45 1.925 13.15 2.225L21.35 5.525C22.35 5.925 22.35 7.325 21.35 7.725L13.05 11.025C12.45 11.325 11.65 11.325 11.05 11.025Z"
													fill="black"
												></path>
											</svg>
										</span>
									</span>
									<div
										className="flex-grow-1 me-2"
										style={{ cursor: "pointer" }}
										onClick={() => handleBooking()}
									>
										<a
											href="#"
											className="fw-bolder text-gray-800 text-hover-primary fs-6"
										>
											Total Bookings
										</a>
										<div className="total-rs">
											<h2>{totalBooking || 0}</h2>
										</div>
										<span className="text-muted fw-bold d-block">
											Total bookings (30 days)
										</span>
									</div>
								</div>
							</div>
						</div>

						<div className="col-lg-4">
							<div className="dash-box pink">
								<div
									className="d-flex align-items-start bg-light-danger rounded p-5"
									style={{ overflow: "scroll" }}
								>
									<span className="svg-icon svg-icon-danger me-5">
										<span className="svg-icon svg-icon-1">
											<svg
												width="24"
												height="24"
												viewBox="0 0 24 24"
												fill="none"
												xmlns="http://www.w3.org/2000/svg"
												className="mh-50px"
											>
												<path
													opacity="0.3"
													d="M21.25 18.525L13.05 21.825C12.35 22.125 11.65 22.125 10.95 21.825L2.75 18.525C1.75 18.125 1.75 16.725 2.75 16.325L4.04999 15.825L10.25 18.325C10.85 18.525 11.45 18.625 12.05 18.625C12.65 18.625 13.25 18.525 13.85 18.325L20.05 15.825L21.35 16.325C22.35 16.725 22.35 18.125 21.25 18.525ZM13.05 16.425L21.25 13.125C22.25 12.725 22.25 11.325 21.25 10.925L13.05 7.62502C12.35 7.32502 11.65 7.32502 10.95 7.62502L2.75 10.925C1.75 11.325 1.75 12.725 2.75 13.125L10.95 16.425C11.65 16.725 12.45 16.725 13.05 16.425Z"
													fill="black"
												></path>
												<path
													d="M11.05 11.025L2.84998 7.725C1.84998 7.325 1.84998 5.925 2.84998 5.525L11.05 2.225C11.75 1.925 12.45 1.925 13.15 2.225L21.35 5.525C22.35 5.925 22.35 7.325 21.35 7.725L13.05 11.025C12.45 11.325 11.65 11.325 11.05 11.025Z"
													fill="black"
												></path>
											</svg>
										</span>
									</span>
									<div
										className="me-2 up-book"
										style={{ cursor: "pointer" }}
										onClick={() => handleBooking()}
									>
										<div className="up-more">
											<a
												href="#"
												className="fw-bolder text-gray-800 text-hover-primary fs-6"
											>
												Upcoming Bookings
											</a>

											<Link to="/order" className="more">
												More{" "}
												<i className="fa fa-angle-right" aria-hidden="true"></i>{" "}
											</Link>
										</div>
										<div className="total-rs">
											<h2>{upcoming || 0}</h2>
										</div>
										<div className="booking-list">
											<ul>
												{dashboard?.upcoming_bookings_list?.length !== 0 ? (
													dashboard?.upcoming_bookings_list?.map(
														(value: any) => {
															return (
																<>
																	<li key={value?.booking_full_name}>
																		<a href="#!">
																			<span>{value?.booking_full_name}</span>
																			<p>
																				{new Date(
																					value?.checkIn_date_time
																				).toLocaleDateString("en-GB")}
																				-
																				{new Date(
																					value?.checkOut_date_time
																				).toLocaleDateString("en-GB")}
																			</p>
																		</a>
																	</li>
																</>
															);
														}
													)
												) : (
													<li>
														<a href="#!">
															<span>No Data Found </span> <p></p>
														</a>
													</li>
												)}
											</ul>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</>
			)}

			{/* <div className="total-rs">
                <h2>3002</h2>
                <span> 
                  <img
                  src={toAbsoluteUrl("/media/img/arrows.png")}
                  alt="MyResorts.in"
                />
                <p>1.18%</p>
                </span>
               
              </div> */}
			{categories &&
				categories.map((ele: any) => (
					<div className="col-xxl-12">
						<div className="card card-xxl-stretch">
							<div className="card-header align-items-center border-0 mt-4 low-height-title">
								<h3 className="card-title align-items-start flex-column">
									<span className="fw-bolder mb-2 text-dark">
										{ele.category_basic_info.name}
									</span>
								</h3>
							</div>
							<div className="card-body pt-2 pb-0">
								{/* begin::Row */}

								<div className="row for-mob-row">
									{rooms &&
										rooms.length > 0 &&
										rooms
											.filter(({ category_id }: any) => category_id == ele._id)
											.sort(
												(a: any, b: any) =>
													getDigits(a.room_name) - getDigits(b.room_name)
											)

											.map((e: any) => (
												<div className="col-lg-2 ps-1 pe-1 for-mob-col">
													<div
														className={`category-card ${
															e.room_status == "Offline" ||
															e.room_status == "Booked"
																? "bg-warning"
																: e.room_status == "Available"
																? "bg-success"
																: "bg-danger"
														}`}
													>
														{e.room_status == "Offline" && (
															<span className="badge badge-secondary">
																Offline
															</span>
														)}
														{e.room_status == "Booked" && (
															<span className="badge badge-secondary">
																Online
															</span>
														)}
														<div className="content-overlay"></div>
														<KTSVG
															path="/media/icons/duotone/Home/Home.svg"
															className="svg-icon svg-icon-3x me-10"
														/>

														<h2>{e.room_name}</h2>
														<p>
															{e.room_status == "Booked"
																? "Booked By: " + e?.booking_full_name
																: ele.category_basic_info.description}
														</p>

														<div className="content-details fadeIn-bottom">
															{/* {e.roomStatus == "Booked" ? (
																<button
																	type="button"
																	className="btn btn-success"
																	//data-bs-toggle="modal"
																	//data-bs-target="#kt_modal_2"
																	onClick={(event) => bookRoom(e)}
																	disabled={propertyStatus !== "Published"}
																>
																	Book
																</button>
															) :  */}
															{e.room_status == "Available" ? (
																<>
																	<button
																		type="button"
																		className="btn btn-success"
																		//data-bs-toggle="modal"
																		//data-bs-target="#kt_modal_2"
																		onClick={(event) => bookRoom(e)}
																		disabled={propertyStatus !== "Published"}
																	>
																		Book
																	</button>
																	<button
																		type="button"
																		className="btn btn-danger"
																		onClick={(event) => blockRoom(e)}
																		disabled={
																			form?.property_status === "Published"
																		}
																	>
																		Block
																	</button>
																</>
															) : e.room_status === "Blocked" ? (
																<button
																	type="button"
																	className="btn btn-danger"
																	onClick={(event) => unblockRoom(e)}
																>
																	Unblock
																</button>
															) : null}
														</div>
													</div>
												</div>
											))}
								</div>
							</div>
						</div>
					</div>
				))}

			<Modal show={bookModal} size="lg">
				<form onSubmit={formik.handleSubmit}>
					<Modal.Header className="mw-800px">
						<h5 className="modal-title">Book customer information</h5>
						<div
							className="btn btn-icon btn-sm btn-active-light-primary ms-2"
							onClick={closeBookModal}
							aria-label="Close"
						>
							<i className="fa fa-times" aria-hidden="true"></i>
						</div>
					</Modal.Header>
					<Modal.Body>
						<div className="row mb-6">
							<label className="col-lg-4 col-form-label required fw-bold fs-6">
								Full Name
							</label>
							<div className="col-lg-8 fv-row">
								<input
									type="text"
									className="form-control form-control-solid"
									placeholder="Full Name"
									autoComplete="off"
									{...formik.getFieldProps("booking_full_name")}
								/>
								{formik.touched.booking_full_name &&
									formik.errors.booking_full_name && (
										<span className="text-danger fs-8" role="alert">
											{formik.errors.booking_full_name}
										</span>
									)}
							</div>
						</div>

						<div className="row mb-6">
							<label className="col-lg-4 col-form-label fw-bold fs-6">
								<span className="required">Contact Phone</span>
							</label>
							<div className="col-lg-8 fv-row">
								<input
									type="tel"
									className="form-control form-control-solid"
									placeholder="Phone number"
									autoComplete="off"
									{...formik.getFieldProps("booking_phone_no")}
								/>
								{formik.touched.booking_phone_no &&
									formik.errors.booking_phone_no && (
										<span className="text-danger fs-8" role="alert">
											{formik.errors.booking_phone_no}
										</span>
									)}
							</div>
						</div>

						<div className="row mb-6">
							<label className="col-lg-4 col-form-label fw-bold fs-6">
								<span className="required">Country</span>
							</label>
							<div className="col-lg-8 fv-row">
								<select
									className="form-select form-select-solid form-select-lg fw-bold"
									wtx-context="E23FF5FC-E052-4B87-BBA3-1B718F6C92AF"
									autoComplete="off"
									{...formik.getFieldProps("booking_country")}
								>
									<option value="">Select a Country...</option>
									<option value="AF">Afghanistan</option>
									<option value="AX">Aland Islands</option>
									<option value="AL">Albania</option>
									<option value="DZ">Algeria</option>
									<option value="AS">American Samoa</option>
									<option value="AD">Andorra</option>
									<option value="AO">Angola</option>
									<option value="AI">Anguilla</option>
									<option value="AQ">Antarctica</option>
									<option value="AG">Antigua and Barbuda</option>
									<option value="AR">Argentina</option>
									<option value="AM">Armenia</option>
									<option value="AW">Aruba</option>
									<option value="AU">Australia</option>
									<option value="AT">Austria</option>
									<option value="AZ">Azerbaijan</option>
									<option value="BS">Bahamas</option>
									<option value="BH">Bahrain</option>
									<option value="BD">Bangladesh</option>
									<option value="BB">Barbados</option>
									<option value="BY">Belarus</option>
									<option value="BE">Belgium</option>
									<option value="BZ">Belize</option>
									<option value="BJ">Benin</option>
									<option value="BM">Bermuda</option>
									<option value="BT">Bhutan</option>
									<option value="BO">Bolivia, Plurinational State of</option>
									<option value="BQ">Bonaire, Sint Eustatius and Saba</option>
									<option value="BA">Bosnia and Herzegovina</option>
									<option value="BW">Botswana</option>
									<option value="BV">Bouvet Island</option>
									<option value="BR">Brazil</option>
									<option value="IO">British Indian Ocean Territory</option>
									<option value="BN">Brunei Darussalam</option>
									<option value="BG">Bulgaria</option>
									<option value="BF">Burkina Faso</option>
									<option value="BI">Burundi</option>
									<option value="KH">Cambodia</option>
									<option value="CM">Cameroon</option>
									<option value="CA">Canada</option>
									<option value="CV">Cape Verde</option>
									<option value="KY">Cayman Islands</option>
									<option value="CF">Central African Republic</option>
									<option value="TD">Chad</option>
									<option value="CL">Chile</option>
									<option value="CN">China</option>
									<option value="CX">Christmas Island</option>
									<option value="CC">Cocos (Keeling) Islands</option>
									<option value="CO">Colombia</option>
									<option value="KM">Comoros</option>
									<option value="CG">Congo</option>
									<option value="CD">
										Congo, the Democratic Republic of the
									</option>
									<option value="CK">Cook Islands</option>
									<option value="CR">Costa Rica</option>
									<option value="CI">Côte d'Ivoire</option>
									<option value="HR">Croatia</option>
									<option value="CU">Cuba</option>
									<option value="CW">Curaçao</option>
									<option value="CY">Cyprus</option>
									<option value="CZ">Czech Republic</option>
									<option value="DK">Denmark</option>
									<option value="DJ">Djibouti</option>
									<option value="DM">Dominica</option>
									<option value="DO">Dominican Republic</option>
									<option value="EC">Ecuador</option>
									<option value="EG">Egypt</option>
									<option value="SV">El Salvador</option>
									<option value="GQ">Equatorial Guinea</option>
									<option value="ER">Eritrea</option>
									<option value="EE">Estonia</option>
									<option value="ET">Ethiopia</option>
									<option value="FK">Falkland Islands (Malvinas)</option>
									<option value="FO">Faroe Islands</option>
									<option value="FJ">Fiji</option>
									<option value="FI">Finland</option>
									<option value="FR">France</option>
									<option value="GF">French Guiana</option>
									<option value="PF">French Polynesia</option>
									<option value="TF">French Southern Territories</option>
									<option value="GA">Gabon</option>
									<option value="GM">Gambia</option>
									<option value="GE">Georgia</option>
									<option value="DE">Germany</option>
									<option value="GH">Ghana</option>
									<option value="GI">Gibraltar</option>
									<option value="GR">Greece</option>
									<option value="GL">Greenland</option>
									<option value="GD">Grenada</option>
									<option value="GP">Guadeloupe</option>
									<option value="GU">Guam</option>
									<option value="GT">Guatemala</option>
									<option value="GG">Guernsey</option>
									<option value="GN">Guinea</option>
									<option value="GW">Guinea-Bissau</option>
									<option value="GY">Guyana</option>
									<option value="HT">Haiti</option>
									<option value="HM">Heard Island and McDonald Islands</option>
									<option value="VA">Holy See (Vatican City State)</option>
									<option value="HN">Honduras</option>
									<option value="HK">Hong Kong</option>
									<option value="HU">Hungary</option>
									<option value="IS">Iceland</option>
									<option value="IN">India</option>
									<option value="ID">Indonesia</option>
									<option value="IR">Iran, Islamic Republic of</option>
									<option value="IQ">Iraq</option>
									<option value="IE">Ireland</option>
									<option value="IM">Isle of Man</option>
									<option value="IL">Israel</option>
									<option value="IT">Italy</option>
									<option value="JM">Jamaica</option>
									<option value="JP">Japan</option>
									<option value="JE">Jersey</option>
									<option value="JO">Jordan</option>
									<option value="KZ">Kazakhstan</option>
									<option value="KE">Kenya</option>
									<option value="KI">Kiribati</option>
									<option value="KP">
										Korea, Democratic People's Republic of
									</option>
									<option value="KW">Kuwait</option>
									<option value="KG">Kyrgyzstan</option>
									<option value="LA">Lao People's Democratic Republic</option>
									<option value="LV">Latvia</option>
									<option value="LB">Lebanon</option>
									<option value="LS">Lesotho</option>
									<option value="LR">Liberia</option>
									<option value="LY">Libya</option>
									<option value="LI">Liechtenstein</option>
									<option value="LT">Lithuania</option>
									<option value="LU">Luxembourg</option>
									<option value="MO">Macao</option>
									<option value="MK">
										Macedonia, the former Yugoslav Republic of
									</option>
									<option value="MG">Madagascar</option>
									<option value="MW">Malawi</option>
									<option value="MY">Malaysia</option>
									<option value="MV">Maldives</option>
									<option value="ML">Mali</option>
									<option value="MT">Malta</option>
									<option value="MH">Marshall Islands</option>
									<option value="MQ">Martinique</option>
									<option value="MR">Mauritania</option>
									<option value="MU">Mauritius</option>
									<option value="YT">Mayotte</option>
									<option value="MX">Mexico</option>
									<option value="FM">Micronesia, Federated States of</option>
									<option value="MD">Moldova, Republic of</option>
									<option value="MC">Monaco</option>
									<option value="MN">Mongolia</option>
									<option value="ME">Montenegro</option>
									<option value="MS">Montserrat</option>
									<option value="MA">Morocco</option>
									<option value="MZ">Mozambique</option>
									<option value="MM">Myanmar</option>
									<option value="NA">Namibia</option>
									<option value="NR">Nauru</option>
									<option value="NP">Nepal</option>
									<option value="NL">Netherlands</option>
									<option value="NC">New Caledonia</option>
									<option value="NZ">New Zealand</option>
									<option value="NI">Nicaragua</option>
									<option value="NE">Niger</option>
									<option value="NG">Nigeria</option>
									<option value="NU">Niue</option>
									<option value="NF">Norfolk Island</option>
									<option value="MP">Northern Mariana Islands</option>
									<option value="NO">Norway</option>
									<option value="OM">Oman</option>
									<option value="PK">Pakistan</option>
									<option value="PW">Palau</option>
									<option value="PS">Palestinian Territory, Occupied</option>
									<option value="PA">Panama</option>
									<option value="PG">Papua New Guinea</option>
									<option value="PY">Paraguay</option>
									<option value="PE">Peru</option>
									<option value="PH">Philippines</option>
									<option value="PN">Pitcairn</option>
									<option value="PL">Poland</option>
									<option value="PT">Portugal</option>
									<option value="PR">Puerto Rico</option>
									<option value="QA">Qatar</option>
									<option value="RE">Réunion</option>
									<option value="RO">Romania</option>
									<option value="RU">Russian Federation</option>
									<option value="RW">Rwanda</option>
									<option value="BL">Saint Barthélemy</option>
									<option value="SH">
										Saint Helena, Ascension and Tristan da Cunha
									</option>
									<option value="KN">Saint Kitts and Nevis</option>
									<option value="LC">Saint Lucia</option>
									<option value="MF">Saint Martin (French part)</option>
									<option value="PM">Saint Pierre and Miquelon</option>
									<option value="VC">Saint Vincent and the Grenadines</option>
									<option value="WS">Samoa</option>
									<option value="SM">San Marino</option>
									<option value="ST">Sao Tome and Principe</option>
									<option value="SA">Saudi Arabia</option>
									<option value="SN">Senegal</option>
									<option value="RS">Serbia</option>
									<option value="SC">Seychelles</option>
									<option value="SL">Sierra Leone</option>
									<option value="SG">Singapore</option>
									<option value="SX">Sint Maarten (Dutch part)</option>
									<option value="SK">Slovakia</option>
									<option value="SI">Slovenia</option>
									<option value="SB">Solomon Islands</option>
									<option value="SO">Somalia</option>
									<option value="ZA">South Africa</option>
									<option value="GS">
										South Georgia and the South Sandwich Islands
									</option>
									<option value="KR">South Korea</option>
									<option value="SS">South Sudan</option>
									<option value="ES">Spain</option>
									<option value="LK">Sri Lanka</option>
									<option value="SD">Sudan</option>
									<option value="SR">Suriname</option>
									<option value="SJ">Svalbard and Jan Mayen</option>
									<option value="SZ">Swaziland</option>
									<option value="SE">Sweden</option>
									<option value="CH">Switzerland</option>
									<option value="SY">Syrian Arab Republic</option>
									<option value="TW">Taiwan, Province of China</option>
									<option value="TJ">Tajikistan</option>
									<option value="TZ">Tanzania, United Republic of</option>
									<option value="TH">Thailand</option>
									<option value="TL">Timor-Leste</option>
									<option value="TG">Togo</option>
									<option value="TK">Tokelau</option>
									<option value="TO">Tonga</option>
									<option value="TT">Trinidad and Tobago</option>
									<option value="TN">Tunisia</option>
									<option value="TR">Turkey</option>
									<option value="TM">Turkmenistan</option>
									<option value="TC">Turks and Caicos Islands</option>
									<option value="TV">Tuvalu</option>
									<option value="UG">Uganda</option>
									<option value="UA">Ukraine</option>
									<option value="AE">United Arab Emirates</option>
									<option value="GB">United Kingdom</option>
									<option value="US">United States</option>
									<option value="UY">Uruguay</option>
									<option value="UZ">Uzbekistan</option>
									<option value="VU">Vanuatu</option>
									<option value="VE">Venezuela, Bolivarian Republic of</option>
									<option value="VN">Vietnam</option>
									<option value="VI">Virgin Islands</option>
									<option value="WF">Wallis and Futuna</option>
									<option value="EH">Western Sahara</option>
									<option value="YE">Yemen</option>
									<option value="ZM">Zambia</option>
									<option value="ZW">Zimbabwe</option>
								</select>
								{formik.touched.booking_country &&
									formik.errors.booking_country && (
										<span className="text-danger fs-8" role="alert">
											{formik.errors.booking_country}
										</span>
									)}
							</div>
						</div>
						<div className="row mb-6">
							<label className="col-lg-4 col-form-label fw-bold fs-6">
								<span className="required">Address</span>
							</label>
							<div className="col-lg-8 fv-row">
								<input
									type="text"
									className="form-control form-control-solid"
									placeholder="Address"
									autoComplete="off"
									{...formik.getFieldProps("booking_address")}
								/>
								{formik.touched.booking_address &&
									formik.errors.booking_address && (
										<span className="text-danger fs-8" role="alert">
											{formik.errors.booking_address}
										</span>
									)}
							</div>
						</div>
						<div className="row mb-6">
							<label className="col-lg-4 col-form-label fw-bold fs-6">
								<span>Email</span>
							</label>
							<div className="col-lg-8 fv-row">
								<input
									type="email"
									className="form-control form-control-solid"
									placeholder="Email"
									autoComplete="off"
									{...formik.getFieldProps("booking_email")}
								/>
								{formik.touched.booking_email && formik.errors.booking_email && (
									<span className="text-danger fs-8" role="alert">
										{formik.errors.booking_email}
									</span>
								)}
							</div>
						</div>

						<div className="row mb-6">
							<label className="col-lg-4 col-form-label fw-bold fs-6">
								<span className="required">Check In</span>
							</label>
							<div className="col-lg-8 fv-row">
								<input
									type="date"
									className="form-control form-control-solid"
									placeholder="Check in"
									autoComplete="off"
									{...formik.getFieldProps("checkIn_date_time")}
									min={form["booking"]["min"]}
								/>
								{formik.touched.checkIn_date_time &&
									formik.errors.checkIn_date_time && (
										<span className="text-danger fs-8" role="alert">
											{formik.errors.checkIn_date_time}
										</span>
									)}
							</div>
						</div>
						<div className="row mb-6">
							<label className="col-lg-4 col-form-label fw-bold fs-6">
								<span className="required">Check Out</span>
							</label>
							<div className="col-lg-8 fv-row">
								<input
									type="date"
									className="form-control form-control-solid"
									placeholder="Check out"
									autoComplete="off"
									{...formik.getFieldProps("checkOut_date_time")}
									min={
										form["booking"]["max"]
											? new Date(form["booking"]["max"])
													.toISOString()
													.split("T")[0]
											: new Date().toISOString().split("T")[0]
									}
								/>
								{formik.touched.checkOut_date_time &&
									formik.errors.checkOut_date_time && (
										<span className="text-danger fs-8" role="alert">
											{formik.errors.checkOut_date_time}
										</span>
									)}
							</div>
						</div>

						<div className="row mb-6">
							<label className="col-lg-4 col-form-label fw-bold fs-6">
								<span className="required">Booking Amount</span>
							</label>
							<div className="col-lg-8 fv-row">
								<input
									type="text"
									className="form-control form-control-solid"
									placeholder="Booking Amount"
									autoComplete="off"
									{...formik.getFieldProps("booking_amount")}
								/>

								<input
									type="text"
									className="form-control form-control-solid"
									placeholder="Booking Amount"
									autoComplete="off"
									{...formik.getFieldProps("booking_user_id")}
									hidden
								/>
								<input
									type="text"
									className="form-control form-control-solid"
									placeholder="Booking Amount"
									autoComplete="off"
									{...formik.getFieldProps("booking_vendor_id")}
									hidden
								/>
								<input
									type="text"
									className="form-control form-control-solid"
									placeholder="Booking Amount"
									autoComplete="off"
									{...formik.getFieldProps("booking_property_id")}
									hidden
								/>
								<input
									type="text"
									className="form-control form-control-solid"
									placeholder="Booking Amount"
									autoComplete="off"
									{...formik.getFieldProps("booking_room_id")}
									value={""}
									hidden
								/>
								<input
									type="text"
									className="form-control form-control-solid"
									placeholder="Booking Amount"
									autoComplete="off"
									{...formik.getFieldProps("booking_type")}
									value={"Couple"}
									hidden
								/>

								{formik.touched.booking_amount && formik.errors.booking_amount && (
									<span className="text-danger fs-8" role="alert">
										{formik.errors.booking_amount}
									</span>
								)}
							</div>
						</div>
					</Modal.Body>
					<Modal.Footer>
						<button
							type="button"
							className="btn btn-light"
							//data-bs-dismiss="modal"
							onClick={closeBookModal}
						>
							Close
						</button>
						<button
							type="submit"
							className="btn btn-primary"
							disabled={formik.isSubmitting || !formik.isValid}
						>
							Confirm
						</button>
					</Modal.Footer>
				</form>
			</Modal>

			<Modal
				show={toast}
				onHide={() => {
					setToast(false);
					setBookModal(false);
				}}
				centered
			>
				<Modal.Header className="h-30px bg-success text-white fw-bolder fs-16">
					Success
					<div
						className="btn btn-icon btn-sm btn-active-success ms-2"
						onClick={() => {
							setToast(false);
							setBookModal(false);
						}}
						aria-label="Close"
					>
						<i
							className="fa fa-times"
							style={{ color: "#fff" }}
							aria-hidden="true"
						></i>
					</div>
				</Modal.Header>
				<Modal.Body className="fw-bolder">Booked Sucessfully.</Modal.Body>
			</Modal>

			<Modal show={failtoast} centered>
				<Modal.Header className="h-30px bg-danger text-white fw-bolder fs-16">
					Failure
					<div
						className="btn btn-icon btn-sm btn-active-danger ms-2"
						onClick={() => setFailToast(false)}
						aria-label="Close"
					>
						<i
							className="fa fa-times"
							style={{ color: "#fff" }}
							aria-hidden="true"
						></i>
					</div>
				</Modal.Header>
				<Modal.Body className="fw-bolder">{failToastMsg}</Modal.Body>
			</Modal>
		</>
	);
};

const DashboardWrapper: FC = () => {
	let rooms = useSelector((state: any) => state.room.entities);
	// const []
	const dateState = useDateState();

	const [categories, setCategories] = useState<any>();
	const dispatch = useDispatch();
	const history = useHistory();
	const intl = useIntl();
	const [propertyId, setPropertyId] = useState("");
	const [vendorId, setVendorId] = useState("");
	const [propertyStatus, setPropertyStatus] = useState("");
	const [room, setRoom] = useState([]);
	const [room1, setRoom1] = useState(rooms);

	//const [properties, setProperties] = useState<any>()

	useEffect(() => {
		let propertyId = sessionStorage.getItem("property_id");
		if (propertyId && propertyId === "_new_property")
			history.push("/crafted/pages/profile/basicinfo");
		else
			axios
				.get(`${API_URL}/v1/vendor/profile`)
				.then((res) => {
					let vendorId = res.data.data.vendor_info._id;
					sessionStorage.setItem("vendor_id", vendorId);
					setVendorId(vendorId);
					let properties = res.data.data.vendor_properties;
					if (!propertyId) {
						sessionStorage.setItem("property_id", properties[0]._id);
						sessionStorage.setItem(
							"property_name",
							properties[0].property_basic_info?.property_name
						);
						setPropertyId(properties[0]._id);
						setPropertyStatus(properties[0].property_status);
						setCategories(properties[0].property_room_categories);
						dispatch(fetchRoomsByPropertyId(properties[0]._id));
					} else {
						let categories = properties.filter(
							(property: any) => property._id === propertyId
						)[0].property_room_categories;
						let propertyStatus = properties.filter(
							(property: any) => property._id === propertyId
						)[0].property_status;
						setCategories(categories);
						setPropertyId(propertyId);
						setPropertyStatus(propertyStatus);
						dispatch(fetchRoomsByPropertyId(propertyId));
					}
					history.push("/dashboard");
				})
				.catch((error) => {
					if (error.response) {
						if (
							error.response.data.message === "Vendor Property Data not found"
						) {
							history.push("/crafted/pages/profile/basicinfo");
						}
					}
				});
	}, []);

	useEffect(() => {
		let newRoom: any = [];
		let newlogic: any = [];
		let totalBookings: number = 0;
		let online: any = [];
		let offline: any = [];
		let blockked: any = [];
		let mergedRoom: any = [];
		// let totaloffline: number = 0;

		// let totalBookings: number = 0;

		if (propertyId !== "" && rooms) {
			propertyId &&
				vendorId &&
				axios
					.get(
						`${API_URL}/v1/vendor/dashboard?property_id=${propertyId}&vendor_id=${vendorId}`
					)
					.then((res) => {
						// let today = new Date().setHours(0, 0, 0, 0);

						let today = new Date(
							dateState?.attach(Downgraded).get().customeDate
						).setHours(0, 0, 0, 0);
						// console.log("date", today);
						// console.log("date b4", new Date().setHours(0, 0, 0, 0));

						let priorDate = new Date(
							new Date().setDate(new Date().getDate() - 30)
						).setHours(0, 0, 0, 0);

						let todayBooking =
							res &&
							res.data.data &&
							res.data.data.filter((row: any) => {
								if (row.booking_status) {
									let checkIn = new Date(row.checkIn_date_time).setHours(
										0,
										0,
										0,
										0
									);

									if (today === checkIn) {
										return row;
									}
								}
							});
						let bookingNew: any = [];
						// console.log("trace todayBooking---", todayBooking);

						if (todayBooking && todayBooking.length > 0) {
							todayBooking.map((booking: any, index: number) => {
								if (booking?.payment_status === "Offline") {
									let i = 0;
									while (i < Number(booking?.total_room)) {
										offline.push(booking);
										i++;
									}
								}

								if (booking?.payment_status === "Success") {
									let i = 0;
									while (i < Number(booking?.total_room)) {
										online.push(booking);
										i++;
									}
								}

								if (booking?.payment_status === "Blocked") {
									// let i = 0;
									// while (i < Number(booking?.total_room)) {
									// 	online.push(booking);
									// 	i++;
									// }
									blockked.push(booking);
								}
							});
						}
						// console.log("trace todayBooking ---", todayBooking);
						// console.log("trace rooms---", rooms);
						// console.log("trace online ---", online);
						// console.log("trace offline---", offline);
						// console.log("trace blocked---", blockked);

						online = online.concat(offline);
						// online = online.concat(blockked);

						console.log("trace combine---", online);
						if (blockked && blockked.length > 0) {
							for (let i = 0; i < rooms.length; i++) {
								mergedRoom.push({
									...rooms[i],
									...blockked.find(
										(itmInner: any) => itmInner.booking_status === rooms[i]._id
									),
								});
							}
						} else {
							mergedRoom = rooms;
						}

						if (todayBooking && todayBooking.length > 0) {
							for (let i = 0; i < rooms?.length; i++) {
								if (mergedRoom[i]?.category_id === online[i]?.booking_room_id) {
									console.log("trace aray", mergedRoom[i]);
									newlogic.push({
										...mergedRoom[i],

										room_status:
											mergedRoom[i]?.payment_status === "Blocked"
												? "Blocked"
												: online[i]?.payment_status === "Offline"
												? "Offline"
												: "Booked",
										booking_full_name: online[i]?.booking_full_name,
										index: i,
									});
								} else {
									newlogic.push({
										...mergedRoom[i],
										room_status:
											mergedRoom[i]?.payment_status === "Blocked"
												? "Blocked"
												: "Available",
									});
								}
							}

							console.log("trace final---", newlogic);

							setRoom(newlogic);
						} else {
							setRoom(rooms);
						}
					});
		}
	}, [rooms, dateState && dateState?.attach(Downgraded).get().customeDate]);

	return (
		<>
			<PageTitle breadcrumbs={[]}>
				{intl.formatMessage({ id: "MENU.DASHBOARD" })}
			</PageTitle>
			{propertyId && propertyId !== "_new_property" && (
				<DashboardPage
					propertyId={propertyId}
					vendorId={vendorId}
					categories={categories}
					rooms={room}
					propertyStatus={propertyStatus}
				/>
			)}
		</>
	);
};

export { DashboardWrapper };
