import React, { useEffect, useState } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
// import { PageLink, PageTitle } from "../../../_metronic/layout/core";
import "./FInanaceNLegal.scss";
import { toAbsoluteUrl } from "../../../../_metronic/helpers";
import { Col, Form, Nav, Row, Tab } from "react-bootstrap-v5";
import axios from "axios";
import ReactPaginate from "react-paginate";

const API_URL = process.env.REACT_APP_API_URL || "";

const FinanceNLelgalPage: React.FC = ({ itemsPerPage = 10 }: any) => {
	const [currentItems, setCurrentItems] = useState<any>(null);
	const [pageCount, setPageCount] = useState(0);
	// Here we use item offsets; we could also use page offsets
	// following the API or data you're working with.
	const [itemOffset, setItemOffset] = useState(0);

	useEffect(() => {
		// Fetch items from another resources.
		// axios.get('https://myresorts.in:3000/v1/admin/finance?page_no=0').then(res=>{
		axios.get(`${API_URL}/v1/admin/finance?page_no=0`).then((res) => {
			setCurrentItems(res.data.data.data);
			setPageCount(Math.ceil(res.data.data.metadata.total / itemsPerPage));

			// console.log(res.data.data.data, "finance & legal");
		});
	}, [itemOffset, itemsPerPage]);

	// Invoke when user click to request another page.
	const handlePageClick = (event: any) => {
		// axios.get('https://myresorts.in:3000/v1/admin/finance?page_no='+event.selected).then(res=>{
		axios
			.get(`${API_URL}/v1/admin/finance?page_no=` + event.selected)
			.then((res) => {
				setCurrentItems(res.data.data.data);
				// console.log("admin useEffect api", pageCount,
				//  "page count", res.data.data.metadata.total / itemsPerPage);

				setPageCount(Math.ceil(res.data.data.metadata.total / itemsPerPage));
			});
	};
	return (
		<div>
			<div className="row">
				{/* <div className="col-lg-12 d-flex justify-content-end">
          <div className="d-flex my-2">
            <div className="d-flex align-items-center position-relative me-4">
              <span className="svg-icon svg-icon-3 position-absolute ms-3">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="mh-50px"
                >
                  <rect
                    opacity="0.5"
                    x="17.0365"
                    y="15.1223"
                    width="8.15546"
                    height="2"
                    rx="1"
                    transform="rotate(45 17.0365 15.1223)"
                    fill="black"
                  ></rect>
                  <path
                    d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                    fill="black"
                  ></path>
                </svg>
              </span>
              <input
                type="text"
                id="kt_filter_search"
                className="form-control form-control-white form-control-sm w-250px ps-9 border-secondary"
                placeholder="Search"
                wtx-context="666F8DA7-646C-4188-876B-CA6BA1CAF363"
              />
            </div>
            <button className="btn btn-primary btn-sm">Search</button>
          </div>
        </div> */}

				<div className="col-lg-12">
					<div className="card card-custom">
						<div className="card-header">
							<h3 className="card-title">Finance and legals</h3>
							{/* <div className="card-toolbar">
          <button type="button" className="btn btn-sm btn-light">
              Last 10 bookings
          </button>
      </div> */}
						</div>
						<div className="card-body p-0 manage-pro-table-wrap">
							<div className="table-responsive">
								<table className="table table-hover table-rounded table-striped border gy-4 gs-7">
									<thead>
										<tr className="fw-bold fs-6 text-gray-800 border-bottom-2 border-gray-200">
											<th>Vendor Name</th>
											<th>PAN Number</th>
											<th>TAN Number</th>
											<th>GST Number</th>
											<th>Bank Name</th>
											<th>Bank Account Number</th>
											<th>IFSC Code</th>
											<th>Ownership Type</th>
											<th>Uploaded DOC</th>
										</tr>
									</thead>
									<tbody>
										{currentItems &&
											currentItems.map((ele: any) => {
												const lastLink =
													ele?.property_finance_legal?.registration_details
														?.registration_doc_id[
														ele?.property_finance_legal?.registration_details
															.registration_doc_id.length - 1
													];
												return (
													<>
														<tr>
															<td>
																<span>
																	<b>{ele?.vendor_name}</b>
																	<br />
																</span>
															</td>
															<td>
																{
																	ele?.property_finance_legal?.pan_details
																		?.pan_no
																}
															</td>
															<td>
																{
																	ele?.property_finance_legal?.pan_details
																		?.tan_no
																}
															</td>
															<td>
																{ele?.property_finance_legal?.gst_details}
															</td>
															<td>
																{
																	ele?.property_finance_legal?.bank_details
																		?.bank_name
																}
															</td>
															<td>
																{
																	ele?.property_finance_legal?.bank_details
																		?.account_no
																}
															</td>
															<td>
																{
																	ele?.property_finance_legal?.bank_details
																		?.ifsc_code
																}
															</td>
															<td>
																{
																	ele?.property_finance_legal
																		?.registration_details?.ownership_type
																}
															</td>
															<td>
																{ele?.property_finance_legal
																	?.registration_details?.registration_doc_id
																	.length > 0 &&
																	ele?.property_finance_legal?.registration_details?.registration_doc_id.map(
																		(key: any) => {
																			// key.fileName;
																			return (
																				<a
																					href={key?.path}
																					className="uploaded-doc"
																				>
																					<img
																						src={toAbsoluteUrl(
																							"/media/img/file.png"
																						)}
																						alt="MyResorts.in"
																					/>
																				</a>
																			);
																		}
																	)}
																{/* <span style={{ display: `${lastLink !==undefined?"block":"none"}`}}> */}
																{/* <a href={`${lastLink}`}  className="uploaded-doc">
                        <img
                          src={toAbsoluteUrl("/media/img/file.png")}
                          alt="MyResorts.in"
                        />
                      </a> 
                    </span>*/}
															</td>
														</tr>
													</>
												);
											})}

										{/*   <tr>
                    <td style={{width:"120px"}}>
                      <a href="#!">
                       <b>Tiger Nixon</b><br />
                        <b>#458932</b>
                      </a>
                    </td>
                    <td>DFG235852</td>
                    <td>RAJA99999B</td>
                    <td>18AABCU9603R1ZM</td>
                    <td>Indian Bank</td>
                    <td>4111111111111111</td>
                    <td>SBIN0011513</td>
                    <td>
                      <a href="#!" className="uploaded-doc">
                        <img
                          src={toAbsoluteUrl("/media/img/file.png")}
                          alt="MyResorts.in"
                        />
                      </a>
                    </td>
                  </tr>
                 */}
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
				<div className="col-lg-12">
					<div className="pagination-wrap">
						<ReactPaginate
							breakLabel="..."
							nextLabel=" >"
							breakClassName="page-item"
							breakLinkClassName="page-link"
							pageClassName="page-item"
							pageLinkClassName="page-link"
							previousClassName="page-item"
							previousLinkClassName="page-link"
							nextClassName="page-item"
							nextLinkClassName="page-link"
							activeClassName="active"
							onPageChange={handlePageClick}
							pageRangeDisplayed={5}
							pageCount={pageCount}
							previousLabel="< "
							className="pagination"
						/>
					</div>
				</div>
			</div>
		</div>
	);
};
export default FinanceNLelgalPage;
