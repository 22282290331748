import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import axios from "axios"

const API_URL = process.env.REACT_APP_API_URL || ''

export const ROOMS_URL = `${API_URL}/v1/vendor/rooms`

export const fetchRoomsByPropertyId = createAsyncThunk(
    'users/fetchById',
    // if you type your function argument here
    async (userId: string) => {
      const response = await axios(ROOMS_URL+`?property_id=${userId}`)
      return ( response.data)
    }
  )
  
  interface UsersState {
    entities: []
    loading: 'idle' | 'pending' | 'succeeded' | 'failed'
  }
  
  const initialState = {
    entities: [],
    loading: 'idle',
  } as UsersState
  
  export const roomsSlice = createSlice({
    name: 'users',
    initialState,
    reducers: {
      // fill in primary logic here
    },
    extraReducers: (builder) => {
      builder.addCase(fetchRoomsByPropertyId.pending, (state, action) => {
        // both `state` and `action` are now correctly typed
        // based on the slice state and the `pending` action creator
      })
      builder.addCase(fetchRoomsByPropertyId.fulfilled, (state: any, action) => {
        // both `state` and `action` are now correctly typed
        // based on the slice state and the `pending` action creator
        state.entities = action.payload.data
      })
    },
  })
