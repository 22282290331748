import React from 'react'
import { useState } from 'react';
import "./selectStars.css"

const SelectStars = ({onChange}) => {
  const [rating, setRating] = useState(3);

  const handleChange = (value) => {
    setRating(value);
    onChange(value);
  }

  return (
    <div>
     <div>

     <StarRating 
       count={5}
       size={40}
       value={rating}
       activeColor ={'green'}
       inactiveColor={'grey'}
       onChange={handleChange}  />
    </div>
    </div>
  )
}

export default SelectStars






// const {useState} = React;   // for codepen, jsbin, jsfiddle etc.

// function StarRating({count, value, inactiveColor='#ddd',size=24,activeColor='#f00', onChange}) {


function StarRating({count, value, inactiveColor,size,activeColor, onChange}) {

  // short trick 
  const stars = Array.from({length: count}, () => '🟊')

  // Internal handle change function
  const handleChange = (value) => {
    onChange(value + 1);
  }

  return (
    <div>
      {stars.map((s, index) => {
        let style = inactiveColor;
        if (index < value) {
          style=activeColor;
        }
        return (
          <span className={"star"}  
            key={index}
            style={{color: style, width:size, height:size, fontSize: size}}
            onClick={()=>handleChange(index)}>{s}</span>
        )
      })}
      {/* {value} */}
    </div>
  )
}
