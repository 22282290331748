/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import clsx from "clsx";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as auth from "../redux/AuthRedux";
import { login } from "../redux/AuthCRUD";
import { toAbsoluteUrl } from "../../../../_metronic/helpers";
import "./Login.scss";

const loginSchema = Yup.object().shape({
	email: Yup.string()
		.email("Wrong email format")
		.min(5, "Minimum 3 symbols")
		.max(50, "Maximum 50 symbols")
		.required("Email is required"),
	/* password: Yup.string()
    .min(8, "Minimum 8 symbols")
    .max(16, "Maximum 16 symbols")
    .required("Password is required") */
});

const initialValues = {
	email: "",
	password: "",
};

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function Login() {
	const [loading, setLoading] = useState(false);
	const dispatch = useDispatch();
	const formik = useFormik({
		initialValues,
		validationSchema: loginSchema,
		onSubmit: (values, { setStatus, setSubmitting }) => {
			setLoading(true);
			setTimeout(() => {
				login(values.email, values.password)
					.then(
						({
							data: {
								data: { bearer_token },
							},
						}) => {
							setLoading(false);
							dispatch(auth.actions.login(bearer_token));
							// console.log(bearer_token, "bearer token for login page")
						}
					)
					.catch(() => {
						setLoading(false);
						setSubmitting(false);
						setStatus("The login details are incorrect");
					});
			}, 1000);
		},
	});

	return (
		<form
			className="form w-100 p-5"
			onSubmit={formik.handleSubmit}
			noValidate
			id="kt_login_signin_form"
		>
			{/* begin::Heading */}
			<div className="text-center mb-10">
				<h1 className="mb-3">Sign In to My resorts.in</h1>

				{/* <div className="social-login">
          <div className="soc-ic">
            <a href="#">
              <img src="/media/img/google-icon.svg" alt="" />
            </a>
            <a href="#">
              <img src="/media/img/facebook-icon.svg" alt="" />
            </a>
          </div>
          <p>or use your email account :</p>
        </div> */}

				{/* <div className="text-gray-400 fw-bold fs-4">
          New Here?{" "}
          <Link to="/auth/registration" className="link-primary fw-bolder">
            Create an Account
          </Link>
        </div> */}
			</div>
			{/* begin::Heading */}

			{formik.status ? (
				<div className="mb-lg-15 alert alert-danger">
					<div className="alert-text font-weight-bold">{formik.status}</div>
				</div>
			) : (
				<>
					{/* <div className='mb-10 bg-light-info p-8 rounded'>
          <div className='text-info'>
            Use account <strong>deepmandal107@gmail.com</strong> and password <strong>123abc</strong> to
            continue.
          </div>
        </div> */}
				</>
			)}
			<div className="login-form-user">
				{/* begin::Form group */}
				<div className="fv-row input-box">
					<img src="/media/img/user.svg" alt="" />
					{/* <label className="form-label fs-6 fw-bolder text-dark">Email</label> */}
					<input
						placeholder="Email"
						{...formik.getFieldProps("email")}
						className={clsx(
							"",
							{ "is-invalid": formik.touched.email && formik.errors.email },
							{
								"is-valid": formik.touched.email && !formik.errors.email,
							}
						)}
						type="email"
						name="email"
						// autoComplete="off"
					/>
				</div>
				{formik.touched.email && formik.errors.email && (
					<div className="fv-plugins-message-container">
						<span role="alert">{formik.errors.email}</span>
					</div>
				)}

				<div className="fv-row input-box">
					{/* 
        <div className="d-flex justify-content-between mt-n5">
          <div className="d-flex flex-stack mb-2">
          
            <label className="form-label fw-bolder text-dark fs-6 mb-0">
              Password
            </label>
          
            <Link
              to="/auth/forgot-password"
              className="link-primary fs-6 fw-bolder"
              style={{ marginLeft: "5px" }}
            >
              Forgot Password ?
            </Link>
           
          </div>
        </div> */}

					<img src="/media/img/password.svg" alt="" />
					<input
						placeholder="Password"
						type="password"
						// autoComplete="off"
						{...formik.getFieldProps("password")}
						className={clsx(
							"",
							{
								"is-invalid": formik.touched.password && formik.errors.password,
							},
							{
								"is-valid": formik.touched.password && !formik.errors.password,
							}
						)}
					/>
				</div>
				{formik.touched.password && formik.errors.password && (
					<div className="fv-plugins-message-container">
						<div className="fv-help-block">
							<span role="alert">{formik.errors.password}</span>
						</div>
					</div>
				)}
				<Link
					to="/auth/forgot-password"
					className="forgort-pass-user"
					style={{ marginLeft: "5px" }}
				>
					Forgot Password ?
				</Link>
				{/* end::Form group */}

				{/* begin::Action */}
				<div className="login-bt-box">
					<Link to="/auth/registration">
						<button
							type="button"
							className="login-bt-user sign-up-bt login-btn-ct"
						>
							Signup
						</button>
					</Link>
					<button
						type="submit"
						id="kt_sign_in_submit"
						className="login-bt-user login-btn-ct"
						disabled={formik.isSubmitting || !formik.isValid}
					>
						{!loading && <span className="indicator-label">Continue</span>}
						{loading && (
							<span className="indicator-progress" style={{ display: "block" }}>
								Please wait...
								<span className="spinner-border spinner-border-sm align-middle ms-2"></span>
							</span>
						)}
					</button>

					{/* <div className="text-center text-muted text-uppercase fw-bolder mb-5">
          or
        </div> */}

					{/* <a
          href="#"
          className="btn btn-flex flex-center btn-light btn-lg w-100 mb-5"
        >
          <img
            alt="Logo"
            src={toAbsoluteUrl("/media/svg/brand-logos/google-icon.svg")}
            className="h-20px me-3"
          />
          Continue with Google
        </a>
     
        <a
          href="#"
          className="btn btn-flex flex-center btn-light btn-lg w-100 mb-5"
        >
          <img
            alt="Logo"
            src={toAbsoluteUrl("/media/svg/brand-logos/facebook-4.svg")}
            className="h-20px me-3"
          />
          Continue with Facebook
        </a> */}
				</div>

				{/* <p className="dontHaveAcText">Don't have an account? <Link to="/auth/registration" className="dontHaveAcText">
            Create an Account
          </Link></p> */}
			</div>
			{/* end::Action */}
		</form>
	);
}
