/* eslint-disable react/jsx-no-target-blank */
import React from "react";
import { useIntl } from "react-intl";
import { KTSVG } from "../../../helpers";
import { AsideMenuItemWithSub } from "./AsideMenuItemWithSub";
import { AsideMenuItem } from "./AsideMenuItem";
import { shallowEqual, useSelector } from "react-redux";
import { RootState } from "../../../../setup";
import Faq from "../../../../app/modules/admin/faq/Faq";

export function AsideMenuMain() {
  const intl = useIntl();
  const user: any = useSelector<RootState>(
    ({ auth }) => auth.user,
    shallowEqual
  );

  return (
    <>
      {user && user.role == "Admin" ? (
        <>
          <AsideMenuItem
            to="/admin"
            icon="/media/icons/duotone/General/User.svg"
            title={intl.formatMessage({ id: "MENU.ADMIN" })}
            fontIcon="bi-app-indicator"
          />
          <AsideMenuItem
            to="/admin/manageProperty"
            icon="/media/icons/duotone/Home/Building.svg"
            title={intl.formatMessage({ id: "MENU.MANAGE_PROPERTY" })}
            fontIcon="bi-app-indicator"
          />
          <AsideMenuItem
            to="/admin/financenLegal"
            icon="/media/icons/duotone/Shopping/Money.svg"
            title={intl.formatMessage({ id: "MENU.FINANCE_LEGAL" })}
            fontIcon="bi-app-indicator"
          />
          <AsideMenuItem
            to="/admin/vendorPerformance"
            icon="/media/icons/duotone/Shopping/Chart-line2.svg"
            title={intl.formatMessage({ id: "MENU.VENDOR_PERFORMANCE" })}
            fontIcon="bi-app-indicator"
          />
          <AsideMenuItem
            to="/admin/vendorProfileData"
            icon="/media/icons/duotone/Files/File.svg"
            title={intl.formatMessage({ id: "MENU.VENDOR_PROFILE_DATA" })}
            fontIcon="bi-app-indicator"
          />
          <AsideMenuItem
            to="/admin/blog"
            icon="/media/icons/duotone/Communication/Write.svg"
            title={intl.formatMessage({ id: "MENU.BLOG" })}
            fontIcon="bi-app-indicator"
          />

          <AsideMenuItem
            to="/admin/feature"
            icon="/media/icons/duotone/General/Bookmark.svg"
            title={intl.formatMessage({ id: "MENU.FEATURE" })}
            fontIcon="bi-app-indicator"
          />

          <AsideMenuItem
            to="/admin/pricingNTax"
            icon="/media/icons/duotone/Shopping/Money.svg"
            title={intl.formatMessage({ id: "MENU.PRICING_TAX" })}
            fontIcon="bi-app-indicator"
          />

          <AsideMenuItem
            to="/admin/bookings"
            icon="/media/icons/duotone/Shopping/Bag1.svg"
            title={intl.formatMessage({ id: "MENU.BOOKINGS" })}
            fontIcon="bi-app-indicator"
          />
          <AsideMenuItem
            to="/admin/settlement"
            icon="/media/icons/duotone/General/Like.svg"
            title={intl.formatMessage({ id: "MENU.VENDOR_SETTLEMENT" })}
            fontIcon="bi-app-indicator"
          />

          <AsideMenuItem
            to="/admin/coupon"
            icon="/media/icons/duotone/Shopping/Ticket.svg"
            title={intl.formatMessage({ id: "MENU.COUPON" })}
            fontIcon="bi-app-indicator"
          />

          <AsideMenuItem
            to="/admin/customer"
            icon="/media/icons/duotone/General/User.svg"
            title={intl.formatMessage({ id: "MENU.CUSTOMER" })}
            fontIcon="bi-app-indicator"
          />
          <AsideMenuItem
            to="/admin/faq"
            icon="/media/icons/duotone/General/Edit.svg"
            title={"FAQ"}
            fontIcon="bi-app-indicator"
          />
          {/* <AsideMenuItem
      to="/admin"
      icon="/media/icons/duotone/Files/File.svg"
      title={intl.formatMessage({ id: "MENU.INVOICE" })}
      fontIcon="bi-app-indicator"
    /> */}
        </>
      ) : (
        <>
          <AsideMenuItem
            to="/properties"
            icon="/media/icons/duotone/Home/Home2.svg"
            title={intl.formatMessage({ id: "MENU.MYPROPERTY" })}
            fontIcon="bi-app-indicator"
          />
          <AsideMenuItem
            to="/dashboard"
            icon="/media/icons/duotone/General/Clipboard.svg"
            title={intl.formatMessage({ id: "MENU.DASHBOARD" })}
            fontIcon="bi-app-indicator"
          />
          <AsideMenuItem
            to="/order"
            icon="/media/icons/duotone/Shopping/Cart3.svg"
            title={intl.formatMessage({ id: "MENU.ORDER" })}
            fontIcon="bi-app-indicator"
          />
          <AsideMenuItem
            to="/pricing"
            icon="/media/icons/duotone/Shopping/Money.svg"
            title={intl.formatMessage({ id: "MENU.PRICE" })}
            fontIcon="bi-app-indicator"
          />

          <AsideMenuItemWithSub
            to="/resortinfo"
            icon="/media/icons/duotone/Home/Building.svg"
            title={intl.formatMessage({ id: "MENU.PROPERTY" })}
            fontIcon="bi-app-indicator"
          >
            <AsideMenuItem
              to="/crafted/pages/profile/BasicInfo"
              icon="/media/icons/duotone/Code/Info-circle.svg"
              title={intl.formatMessage({ id: "MENU.BASIC_INFO" })}
              fontIcon="bi-app-indicator"
            />

            <AsideMenuItem
              to="/crafted/pages/profile/Location"
              icon="/media/icons/duotone/Map/Location-arrow.svg"
              title={intl.formatMessage({ id: "MENU.LOCATION" })}
              fontIcon="bi-app-indicator"
            />

            <AsideMenuItem
              to="/crafted/pages/profile/amenities"
              icon="/media/icons/duotone/Home/Bed.svg"
              title={intl.formatMessage({ id: "MENU.AMENITIES" })}
              fontIcon="bi-app-indicator"
            />

            <AsideMenuItem
              to="/crafted/pages/profile/rooms"
              icon="/media/icons/duotone/Home/Building.svg"
              title={intl.formatMessage({ id: "MENU.ROOMS" })}
              fontIcon="bi-app-indicator"
            />
            <AsideMenuItem
              to="/crafted/pages/profile/photos"
              icon="/media/icons/duotone/Home/Home2.svg"
              title={intl.formatMessage({ id: "MENU.PHOTOS" })}
              fontIcon="bi-app-indicator"
            />
            <AsideMenuItem
              to="/crafted/pages/profile/policies"
              icon="/media/icons/duotone/Files/File.svg"
              title={intl.formatMessage({ id: "MENU.POLICIES" })}
              fontIcon="bi-app-indicator"
            />
            <AsideMenuItem
              to="/crafted/pages/profile/financeLegal"
              icon="/media/icons/duotone/Code/Info-circle.svg"
              title={intl.formatMessage({ id: "MENU.FINANCELEGAL" })}
              fontIcon="bi-app-indicator"
            />
          </AsideMenuItemWithSub>

          <AsideMenuItem
            to="/reviews"
            icon="/media/icons/duotone/General/Star.svg"
            title={intl.formatMessage({ id: "MENU.REVIEWS" })}
            fontIcon="bi-app-indicator"
          />

          <AsideMenuItem
            to="/todo"
            icon="/media/icons/duotone/General/Bookmark.svg"
            title={intl.formatMessage({ id: "MENU.TODO" })}
            fontIcon="bi-app-indicator"
          />
        </>
      )}
      {/* <AsideMenuItem
        to='/builder'
        icon='/media/icons/duotone/Interface/Settings-02.svg'
        title='Layout Builder'
        fontIcon='bi-layers'
      /> */}
      {/* <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Crafted</span>
        </div>
      </div> */}
      {/* <AsideMenuItemWithSub
        to='/crafted/pages'
        title='Pages'
        fontIcon='bi-archive'
        icon='/media/icons/duotone/Code/Compiling.svg'
      >
        <AsideMenuItemWithSub to='/crafted/pages/profile' title='Profile' hasBullet={true}>
          <AsideMenuItem to='/crafted/pages/profile/overview' title='Overview' hasBullet={true} />
          <AsideMenuItem to='/crafted/pages/profile/projects' title='Projects' hasBullet={true} />
          <AsideMenuItem to='/crafted/pages/profile/campaigns' title='Campaigns' hasBullet={true} />
          <AsideMenuItem to='/crafted/pages/profile/documents' title='Documents' hasBullet={true} />
          <AsideMenuItem
            to='/crafted/pages/profile/connections'
            title='Connections'
            hasBullet={true}
          />
        </AsideMenuItemWithSub>

        <AsideMenuItemWithSub to='/crafted/pages/wizards' title='Wizards' hasBullet={true}>
          <AsideMenuItem
            to='/crafted/pages/wizards/horizontal'
            title='Horizontal'
            hasBullet={true}
          />
          <AsideMenuItem to='/crafted/pages/wizards/vertical' title='Vertical' hasBullet={true} />
        </AsideMenuItemWithSub>
      </AsideMenuItemWithSub>
      <AsideMenuItemWithSub
        to='/crafted/accounts'
        title='Accounts'
        icon='/media/icons/duotone/General/User.svg'
        fontIcon='bi-person'
      >
        <AsideMenuItem to='/crafted/account/overview' title='Overview' hasBullet={true} />
        <AsideMenuItem to='/crafted/account/settings' title='Settings' hasBullet={true} />
      </AsideMenuItemWithSub>


      <AsideMenuItemWithSub
        to='/error'
        title='Errors'
        fontIcon='bi-sticky'
        icon='/media/icons/duotone/Code/Error-circle.svg'
      >
        <AsideMenuItem to='/error/404' title='Error 404' hasBullet={true} />
        <AsideMenuItem to='/error/500' title='Error 500' hasBullet={true} />
      </AsideMenuItemWithSub>


      
      <AsideMenuItemWithSub
        to='/crafted/widgets'
        title='Widgets'
        icon='/media/icons/duotone/Layout/Layout-4-blocks.svg'
        fontIcon='bi-layers'
      >
        <AsideMenuItem to='/crafted/widgets/lists' title='Lists' hasBullet={true} />
        <AsideMenuItem to='/crafted/widgets/statistics' title='Statistics' hasBullet={true} />
        <AsideMenuItem to='/crafted/widgets/charts' title='Charts' hasBullet={true} />
        <AsideMenuItem to='/crafted/widgets/mixed' title='Mixed' hasBullet={true} />
        <AsideMenuItem to='/crafted/widgets/tables' title='Tables' hasBullet={true} />
        <AsideMenuItem to='/crafted/widgets/feeds' title='Feeds' hasBullet={true} />
      </AsideMenuItemWithSub>
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Apps</span>
        </div>
      </div>
      <AsideMenuItemWithSub
        to='/apps/chat'
        title='Chat'
        fontIcon='bi-chat-left'
        icon='/media/icons/duotone/Communication/Group-chat.svg'
      >
        <AsideMenuItem to='/apps/chat/private-chat' title='Private Chat' hasBullet={true} />
        <AsideMenuItem to='/apps/chat/group-chat' title='Group Chart' hasBullet={true} />
        <AsideMenuItem to='/apps/chat/drawer-chat' title='Drawer Chart' hasBullet={true} />
      </AsideMenuItemWithSub>
      <div className='menu-item'>
        <div className='menu-content'>
          <div className='separator mx-1 my-4'></div>
        </div>
      </div>*/}

      {/* <div className="menu-item">
        <a
          target="_blank"
          className="menu-link"
          href={"/order"}
        >
          <span className="menu-icon">
            <KTSVG
              path="/media/icons/duotone/Files/File.svg"
              className="svg-icon-2"
            />
          </span>
          <span className="menu-title">Order History</span>
        </a>
      </div>

      <div className="menu-item">
        <a
          target="_blank"
          className="menu-link"
          href={"/resortinfo"}
        >
          <span className="menu-icon">
            <KTSVG
              path="/media/icons/duotone/Files/File.svg"
              className="svg-icon-2"
            />
          </span>
          <span className="menu-title">Resort Info</span>
        </a>
      </div>

      <div className="menu-item">
        <a
          target="_blank"
          className="menu-link"
          href={"/pricing"}
        >
          <span className="menu-icon">
            <KTSVG
              path="/media/icons/duotone/Files/File.svg"
              className="svg-icon-2"
            />
          </span>
          <span className="menu-title">
            Category Wise Pricing {process.env.REACT_APP_VERSION}
          </span>
        </a>
      </div> */}
    </>
  );
}
