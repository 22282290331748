/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useRef, useState, useEffect } from "react";
import { toAbsoluteUrl, KTSVG } from "../../../../_metronic/helpers";
import { Link } from "react-router-dom";
// import { Dropdown1 } from '../../../_metronic/partials'
import { useLocation } from "react-router-dom";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../../setup";
import axios from "axios";
import * as auth from "../../auth/redux/AuthRedux";

const API_URL = process.env.REACT_APP_API_URL || "";

const AdminProfile = () => {
	// const location = useLocation();
	const user: any = useSelector<RootState>(
		({ auth }) => auth.user,
		shallowEqual
	);
	const dispatch = useDispatch();
	const [profilePic, setProfilePic] = useState("");
	const [loading, setLoading] = useState(false);

	const selectImage = useRef<HTMLInputElement | null>(null);
	const uploadPhoto = () => {
		if (!selectImage.current) return;
		selectImage.current.click();
	};

	const handleImageChange = (e: any) => {
		setLoading(true);
		let image = e.target.files[0];
		let formData = new FormData();
		formData.append("user_profile_pic", image);
		axios.post(`${API_URL}/v1/user/profilepic`, formData).then((res) => {
			axios.get(`${API_URL}/v1/user/profilepic`).then((res) => {
				dispatch(auth.actions.requestUser());
				setProfilePic(res.data.data);
				if (res.data.data) {
					setLoading(false);
				}
			});
		});
	};

	useEffect(() => {
		axios.get(`${API_URL}/v1/user/profilepic`).then((res) => {
			setProfilePic(res.data.data);
		});
	}, []);

	return (
		<div className="card mb-5 mb-xl-10">
			<div className="card-body pt-1 pb-0">
				<div className="d-flex flex-wrap flex-sm-nowrap mb-3">
					<div className="me-7 mb-4">
						<div className="symbol symbol-100px symbol-lg-160px symbol-fixed position-relative">
							<img
								alt="Logo"
								src={
									profilePic
										? `data:image/png;base64,${Buffer.from(profilePic).toString(
												"base64"
										  )}`
										: toAbsoluteUrl("/media/avatars/blank.png")
								}
							/>
							<div className="position-absolute translate-middle bottom-0 start-100 top-25 bg-success rounded-circle border border-4 border-white h-20px w-20px"></div>
							<div className="position-absolute start-0 top-0 btn btn-icon btn-active-light-primary border border-4 border-white w-30px h-30px">
								<input
									type="file"
									ref={selectImage}
									style={{ display: "none" }}
									onChange={(e) => handleImageChange(e)}
								/>
								<i
									className="fa fa-edit"
									style={{ color: "green" }}
									onClick={uploadPhoto}
								></i>
							</div>
						</div>
					</div>

					<div className="flex-grow-1">
						<div className="d-flex justify-content-between align-items-start flex-wrap mb-2">
							<div className="d-flex flex-column">
								<div className="d-flex align-items-center mb-2">
									<a
										href="#"
										className="text-gray-800 text-hover-primary fs-2 fw-bolder me-1"
									>
										{user.firstname} {user.lastname}
									</a>
									{/* <a href='#'>
                    <KTSVG
                      path='/media/icons/duotone/Design/Verified.svg'
                      className='svg-icon-1 svg-icon-primary'
                    />
                  </a> */}
								</div>

								<div className="d-flex flex-wrap flex-column fw-bold fs-6 mb-4 pe-2">
									<a
										href="#"
										className="d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2"
									>
										<KTSVG
											path="/media/icons/duotone/General/User.svg"
											className="svg-icon-4 me-1"
										/>
										{user.companyname}
									</a>
									{/* <a
                    href='#'
                    className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2'
                  >
                    <KTSVG
                      path='/media/icons/duotone/Map/Marker1.svg'
                      className='svg-icon-4 me-1'
                    />
                    SF, Bay Area
                  </a> */}
									<a
										href="#"
										className="d-flex align-items-center text-gray-400 text-hover-primary mb-2"
									>
										<KTSVG
											path="/media/icons/duotone/Communication/Mail-at.svg"
											className="svg-icon-4 me-1"
										/>
										{user.email}
									</a>

									<a
										href="#"
										className="d-flex align-items-center text-gray-400 text-hover-primary mb-2"
									>
										<KTSVG
											path="/media/icons/duotone/Interface/Phone.svg"
											className="svg-icon-4 me-1"
										/>
										{user.phone}
									</a>

									{loading && (
										<a
											href="#"
											className="d-flex align-items-center text-gray-400 text-hover-primary mb-2"
										>
											<KTSVG
												path="/media/icons/duotone/Files/Upload.svg"
												className="svg-icon-4 me-1"
											/>
											<span> Uploading Profile Pic... </span>
										</a>
									)}
								</div>
							</div>
						</div>

						<div className="d-flex flex-wrap flex-stack align-items-end justify-content-end"></div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default AdminProfile;
