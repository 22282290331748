import React, { useEffect, useState } from "react";
import { Link, Redirect, Route, Switch } from "react-router-dom";
// import { PageLink, PageTitle } from "../../../_metronic/layout/core";
import "./Bookings.scss";
import { toAbsoluteUrl } from "../../../../_metronic/helpers";
import { Button, Col, Form, Nav, Row, Tab } from "react-bootstrap-v5";
import axios from "axios";
import ReactPaginate from "react-paginate";

const API_URL = process.env.REACT_APP_API_URL || '';

const BookingsPage: React.FC = ({ itemsPerPage = 10 }: any) => {
  const [currentItems, setCurrentItems] = useState<any>(null);
  const [pageCount, setPageCount] = useState(0);
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);
  const [properies, setProperties] = useState<any>(null);
  const [categories, setCategories] = useState<any>(null);
  const [form, setForm] = useState<any>(null);
  const [pageNum, setPageNum] = useState<number>(0);
  const [alertMessage, setAlertMessage] = useState("")


  const getBookings = () => {
    axios.get(`${API_URL}/v1/admin/booking?page_no=0`).then(res => {
      if(res.data.data.data.length > 0 ){
        setAlertMessage("")
        setCurrentItems(res.data.data.data);
        setPageCount(Math.ceil(res.data.data.metadata.total / itemsPerPage));
      }else {
        setAlertMessage("Data Not Found ")
        setCurrentItems(res.data.data.data);
        setPageCount(Math.ceil(0 / itemsPerPage));
      }
    })
  }


  useEffect(() => {
    // Fetch items from another resources.
   getBookings();

    axios.get(`${API_URL}/v1/admin/featured?property_name=true`).then(res => {
      setProperties(res.data.data);
    })
  }, [itemOffset, itemsPerPage]);

  // Invoke when user click to request another page.
  const handlePageClick = (event: any) => {
    axios.get(`${API_URL}/v1/admin/booking?page_no=` + event.selected).then(res => {
      setCurrentItems(res.data.data.data);
      setPageNum(event.selected);
      setPageCount(Math.ceil(res.data.data.metadata.total / itemsPerPage));
    })
  };
  const fetchList = (e: any) => {
    axios.get(`${API_URL}/v1/admin/featured?property_id=` + e._id).then(res => {
      setCategories(res.data.data);
    })
  };
  const onSubmit = () => {
    if (form.pricing_id) {
      // console.log(form, "form modals")
      axios.post(`${API_URL}/v1/admin/booking?page_no=${pageNum}`, form).then(() => {
        axios.get(`${API_URL}/v1/admin/booking?page_no=${pageNum}`).then(res => {
          setCurrentItems(res.data.data.data);
          setPageCount(Math.ceil(res.data.data.metadata.total / itemsPerPage));
        })
      })
    }
  };

  let btnValue = "Booked";
  // let btnValue = "Offline";  
  // let btnValue = "Cancelled";  
  // let btnValue = "Pending";  
  // All Booking Status => Cancelled, Booked, Offline, Pending
  const statusUpdate = () => {
    btnValue = "Cancelled";
  }
  // const statusUpdate = (id: any, status: any) => {
  // axios.put(`${API_URL}/v1/admin/property/` + id + '/' + status).then(() => {
  //   axios.get(`${API_URL}/v1/admin/property?page_no=${pageNum}`).then(res => {
  // setCurrentItems(res.data.data.data);
  // setPageCount(Math.ceil(res.data.data.metadata.total / itemsPerPage));
  //   })
  // });
  // };

  const bookingFilters = (filter:any) => {
    let Url = `${API_URL}/v1/admin/bookingfilters?page_no=0&booking_filter=${filter}`
   
    axios.get(Url).then(res => {

      if(res.data.data.data.length > 0 ){
        setAlertMessage("")
        setCurrentItems(res.data.data.data);
        setPageCount(Math.ceil(res.data.data.metadata.total / itemsPerPage));
      }else {
        setAlertMessage("Data Not Found ")
        setCurrentItems(res.data.data.data);
        setPageCount(Math.ceil(0 / itemsPerPage));
      }
    })
  }

  return (
    <div>
      {/* <div className="row mb-8">
        <div className="col-lg-8" style={{opacity: 0}}>
          <a className="btn btn-success" data-bs-toggle="modal"
            data-bs-target="#kt_modal_1">
            <i className="fa fa-plus" aria-hidden="true"></i> Add new
          </a>
        </div>

        <div className="col-lg-4 d-flex justify-content-end">
          <div className="d-flex my-2">
            <div className="d-flex align-items-center position-relative me-4">
              <span className="svg-icon svg-icon-3 position-absolute ms-3">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="mh-50px"
                >
                  <rect
                    opacity="0.5"
                    x="17.0365"
                    y="15.1223"
                    width="8.15546"
                    height="2"
                    rx="1"
                    transform="rotate(45 17.0365 15.1223)"
                    fill="black"
                  ></rect>
                  <path
                    d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                    fill="black"
                  ></path>
                </svg>
              </span>
              <input
                type="text"
                id="kt_filter_search"
                className="form-control form-control-white form-control-sm w-250px ps-9 border-secondary"
                placeholder="Search"
                wtx-context="666F8DA7-646C-4188-876B-CA6BA1CAF363"
              />
            </div>
            <button className="btn btn-primary btn-sm">Search</button>
          </div>
        </div>

      </div> */}

      <div className="row">
        <div className="col-lg-12">
          <div className="card card-custom">
            <div className="card-header card-filters-name-container">
              <h3 className="card-title">Bookings</h3>
              <div className="card-toolbar">
                <a href="#!" className="btn btn-primary" onClick={getBookings}>
                  All
                </a>
                <a href="#!" className="btn btn-success" onClick={()=>bookingFilters("Upcoming")}>
                  Upcoming 
                </a>
                <a href="#!" className="btn btn-info" onClick={()=>bookingFilters("Completed")}>
                Completed 
                </a>
                <a href="#!" className="btn btn-danger" onClick={()=>bookingFilters("Cancelled")}>
                  Cancelled 
                </a>
              </div>
            </div>
            <div className="card-body p-0 manage-pro-table-wrap">
              <div className="table-responsive">
                <table className="table table-hover table-rounded table-striped border gy-4 gs-7">
                  <thead>
                    <tr className="fw-bold fs-6 text-gray-800 border-bottom-2 border-gray-200">
                      <th style={{ minWidth: '160px' }}>Hotel name</th>
                      <th style={{ minWidth: '160px' }}>Category</th>
                      <th style={{ minWidth: '100px' }}>Type of booking</th>
                      <th style={{ minWidth: '160px' }}>Customer</th>
                      <th style={{ minWidth: '120px' }}>Check in</th>
                      <th style={{ minWidth: '120px' }}>Check out</th>
                      <th>Adults</th>
                      <th>Children Range 1</th>
                      <th>Children Range 2</th>
                      <th>Group Adult</th>
                      <th>Group Childs</th>
                      <th>Rooms</th>
                      <th style={{ minWidth: '120px' }}>Total amount</th>
                      <th>Status</th>
                      <th style={{ minWidth: '120px' }}>Added on</th>
                    </tr>
                  </thead>
                  <tbody>
                  <h5 style={{width: "150px",  display: `${alertMessage?"block": "none"}`}}> {alertMessage} </h5>

                    {currentItems && currentItems.map((ele: any) => (
                      <tr>
                        <td><a href="#!"><b>{/* #4582 */}</b> </a>
                          <br />
                          {/* <Link to={{
                            pathname: "booking-details",
                            search: `bookingDetailsId=${ele._id}`
                          }}> */}
                            <p>{ele.property_name}</p>
                          {/* </Link> */}
                        </td>
                        <td>{ele.roomcatname}</td>
                        <td>{ele.booking_type}</td>
                        <td >{`${ele.custumer.firstname} ${ele.custumer.lastname} `}</td>
                        <td>{new Date(ele.checkIn_date_time).toLocaleDateString('en-GB')}</td>
                        <td>{new Date(ele.checkOut_date_time).toLocaleDateString('en-GB')}</td>
                        <td>{ele.no_of_adult}</td>
                        <td>{ele.no_of_child}</td>
                        <td>{ele.no_of_bigchild}</td>
                        <td>{ele.group_adult}</td>
                        <td>{ele.group_child}</td>
                        <td>{ele.total_room}</td>
                        <td>{ele.booking_amount}</td>
                        <td>
                          <Button size="sm" className={`btn ${ele.booking_status === "Success"? "btn-primary" : ele.booking_status === "Cancelled"? "btn-danger": "btn-primary"}`} > 
                          {ele.booking_status === "Success" ? "Booked" :
                           ele.booking_status === "Cancelled" ? "Cancelled": "Booked" }
                           </Button></td>
                        {/* <td>
                          <a href='#!' className={"btn" + (btnValue === 'Pending' ? ' btn-danger' :
                            btnValue === 'Booked' || btnValue === 'Offline' ? ' btn-primary' : ' btn-success') + " verify-bt"}
                            data-bs-toggle="modal"
                            //  onClick={()=>setForm({id: ele.property_id, status: ele.property_status})} 
                            data-bs-target="#kt_modal_2">
                            {btnValue === 'Offline' ? 'Booked' : btnValue === 'Pending' || btnValue === 'Cancelled' ? 'Booked' : 'Booked'}
                          </a>
                        </td> */}
                        <td>{new Date(ele.bookingtime).toLocaleDateString('en-GB')}</td>

                      </tr>

                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-12">
          <div className="pagination-wrap">
            <ReactPaginate
              breakLabel="..."
              nextLabel=" >"
              breakClassName="page-item"
              breakLinkClassName="page-link"
              pageClassName="page-item"
              pageLinkClassName="page-link"
              previousClassName="page-item"
              previousLinkClassName="page-link"
              nextClassName="page-item"
              nextLinkClassName="page-link"
              activeClassName="active"
              onPageChange={handlePageClick}
              pageRangeDisplayed={5}
              pageCount={pageCount}
              previousLabel="< "
              className="pagination"
            />
          </div>
        </div>
      </div>


      <div className="modal fade" tabIndex={-1} id="kt_modal_2">
        <div className="modal-dialog w-450px mw-400px">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">
                Are You Sure You Want to Change this Property Status ?
              </h5>
              <div
                className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <i className="fa fa-times" aria-hidden="true"></i>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-light"
                data-bs-dismiss="modal"
              >
                No
              </button>
              <button type="button" className="btn btn-primary" data-bs-dismiss="modal"
                //  onClick={()=>statusUpdate(form.id, form.status==='Published'?'Unpublished':form.status==='Verified'||
                //  form.status==='Unpublished'?'Published':'Verified')} 
                onClick={() => statusUpdate}
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      </div>



      <div className="modal fade" tabIndex={-1} id="kt_modal_1">
        <div className="modal-dialog w-600px mw-600px">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Add</h5>
              <div
                className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <i className="fa fa-times" aria-hidden="true"></i>
              </div>
            </div>
            <div className="modal-body">
              <div className="row mb-6">
                <label className="col-lg-4 col-form-label required fw-bold fs-6">
                  Id & Hotel name
                </label>
                <div className="col-lg-8 fv-row">
                  <select
                    className="form-select form-select-solid form-select-lg fw-bold"
                    name="country"
                    wtx-context="E23FF5FC-E052-4B87-BBA3-1B718F6C92AF"
                    onChange={(e: any) => fetchList(JSON.parse(e.target.value))}
                  >
                    <option value="">Select One</option>
                    {properies && properies.map((e: any) => e.property_name && (
                      <option value={JSON.stringify(e)}>{e.property_name}</option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="row mb-6">
                <label className="col-lg-4 col-form-label fw-bold fs-6">
                  <span className="required">Type of booking</span>
                </label>
                <div className="col-lg-8 fv-row">
                  <select
                    className="form-select form-select-solid form-select-lg fw-bold"
                    name="country"
                    wtx-context="E23FF5FC-E052-4B87-BBA3-1B718F6C92AF"
                    onChange={(e: any) => setForm({ ...form, booking_type: e.target.value })}
                  >
                    <option value="Online">Online</option>
                    <option value="Offline">Offline</option>
                  </select>
                </div>
              </div>

              <div className="row mb-6">
                <label className="col-lg-4 col-form-label fw-bold fs-6">
                  <span className="required">Customer</span>
                </label>
                <div className="col-lg-8 fv-row">
                  <input
                    type="text"
                    className="form-control form-control-solid"
                    placeholder="Customer"
                    onChange={(e: any) => setForm({ ...form, booking_full_name: e.target.value })}
                  />
                </div>
              </div>

              <div className="row mb-6">
                <label className="col-lg-4 col-form-label fw-bold fs-6">
                  <span className="required">Address</span>
                </label>
                <div className="col-lg-8 fv-row">
                  <input
                    type="text"
                    className="form-control form-control-solid"
                    placeholder="Address"
                    onChange={(e: any) => setForm({ ...form, booking_address: e.target.value })}
                  />
                </div>
              </div>

              <div className="row mb-6">
                <label className="col-lg-4 col-form-label fw-bold fs-6">
                  <span className="required">Category</span>
                </label>
                <div className="col-lg-8 fv-row">
                  <select
                    className="form-select form-select-solid form-select-lg fw-bold"
                    name="country"
                    wtx-context="E23FF5FC-E052-4B87-BBA3-1B718F6C92AF"
                    onChange={(e: any) => setForm({ ...form, pricing_id: e.target.value })}
                  >
                    <option value="">Select One</option>
                    {categories && categories.map((e: any) => (
                      <option value={e._id}>{e.room_category}</option>))}
                  </select>
                </div>
              </div>

              <div className="row mb-6">
                <label className="col-lg-4 col-form-label fw-bold fs-6">
                  <span className="required">Check In</span>
                </label>
                <div className="col-lg-8 fv-row">
                  <input
                    type="date"
                    className="form-control form-control-solid"
                    placeholder="Check in"
                    onChange={(e: any) => setForm({ ...form, checkIn_date_time: e.target.value })}
                    min={new Date().toISOString().split('T')[0]}
                  />
                </div>
              </div>
              <div className="row mb-6">
                <label className="col-lg-4 col-form-label fw-bold fs-6">
                  <span className="required">Check Out</span>
                </label>
                <div className="col-lg-8 fv-row">
                  <input
                    type="date"
                    className="form-control form-control-solid"
                    placeholder="Check out"
                    onChange={(e: any) => setForm({ ...form, checkOut_date_time: e.target.value })}
                    min={form && form['checkIn_date_time'] ? new Date(form['checkIn_date_time']).toISOString().split('T')[0] : new Date().toISOString().split('T')[0]}
                  />
                </div>
              </div>

              <div className="row mb-6">
                <label className="col-lg-4 col-form-label fw-bold fs-6">
                  <span className="required">Adults</span>
                </label>
                <div className="col-lg-8 fv-row">
                  <input
                    type="text"
                    className="form-control form-control-solid"
                    placeholder="Adults"
                    onChange={(e: any) => setForm({ ...form, no_of_adult: e.target.value })}
                  />
                </div>
              </div>

              <div className="row mb-6">
                <label className="col-lg-4 col-form-label fw-bold fs-6">
                  <span className="">Children</span>
                </label>
                <div className="col-lg-8 fv-row">
                  <input
                    type="text"
                    className="form-control form-control-solid"
                    placeholder="Children"
                    onChange={(e: any) => setForm({ ...form, no_of_child: e.target.value })}
                  />
                </div>
              </div>


              <div className="row mb-6">
                <label className="col-lg-4 col-form-label fw-bold fs-6">
                  <span className="">Total amount	</span>
                </label>
                <div className="col-lg-8 fv-row">
                  <input
                    type="text"
                    className="form-control form-control-solid"
                    placeholder="Amount"
                    onChange={(e: any) => setForm({ ...form, booking_amount: e.target.value })}
                  />
                </div>
              </div>


              <div className="row mb-6">
                <label className="col-lg-4 col-form-label fw-bold fs-6">
                  <span className="required">Status</span>
                </label>
                <div className="col-lg-8 fv-row">
                  <select
                    className="form-select form-select-solid form-select-lg fw-bold"
                    name="country"
                    wtx-context="E23FF5FC-E052-4B87-BBA3-1B718F6C92AF"
                    onChange={(e: any) => setForm({ ...form, booking_status: e.target.value })}
                  >
                    <option value="Pending">Pending</option>
                    <option value="Scheduled">Successfull</option>
                  </select>
                </div>
              </div>

              <div className="row mb-6">
                <label className="col-lg-4 col-form-label fw-bold fs-6">
                  <span className="required">Added on</span>
                </label>
                <div className="col-lg-8 fv-row">
                  <input
                    type="date"
                    className="form-control form-control-solid"
                    placeholder="added on"
                    value={new Date().toISOString().split('T')[0]}
                    disabled
                  />
                </div>
              </div>

            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-light"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button type="button" data-bs-dismiss="modal" className="btn btn-primary" onClick={onSubmit}>
                Create
              </button>
            </div>
          </div>
        </div>
      </div>


    </div>
  );
};
export default BookingsPage;
