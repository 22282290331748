export const checkMandatoryBasicInfo = (form: any): boolean => {
	if (
		form?.property_basic_info?.property_name &&
		form?.property_basic_info?.property_star_rating &&
		form?.property_basic_info?.property_description &&
		form?.property_contact_details?.phone_no &&
		/^\d{10}$$/.test(form?.property_contact_details?.phone_no) &&
		form?.property_contact_details?.email &&
		/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(
			form?.property_contact_details?.email
		)
	) {
		return true;
	} else {
		return false;
	}
};

export const checkMandatoryLocation = (form: any): boolean => {
	if (
		form.hasOwnProperty("_id") &&
		form.property_location?.property_address &&
		form.property_location?.property_zip_code &&
		/^\d{6}$/.test(form?.property_location?.property_zip_code) &&
		form.property_location?.property_nearest_gate &&
		form.property_location?.property_city &&
		/^[a-zA-Z\s]+$/.test(form?.property_location?.property_city) &&
		form.property_location?.property_state &&
		/^[a-zA-Z\s]+$/.test(form?.property_location?.property_state)
	) {
		return true;
	} else {
		return false;
	}
};

// export const checkMandatoryBlogData = (form: any): boolean => {
//     if (form.hasOwnProperty('_id') &&
//         form.form.blog_title &&
//         form.property_location?.property_zip_code &&
//         /^\d{6}$/.test(form?.property_location?.property_zip_code) &&
//         form.property_location?.property_nearest_gate &&
//         form.property_location?.property_city &&
//         /^[a-zA-Z\s]+$/.test(form?.property_location?.property_city) &&
//         form.property_location?.property_state &&
//         /^[a-zA-Z\s]+$/.test(form?.property_location?.property_state)
//     ) {
//         return true;
//     }
//     else {
//         return false;
//     };
// }

export const checkMandatoryAmenities = (amenities: any): boolean => {
	let count = 0;
	for (let amenity in amenities) {
		count += amenities[amenity].length;
	}
	if (count >= 5) return true;
	else return false;
};

export const checkMandatoryCategory = (category: any) => {
	if (
		category?.category_basic_info?.name &&
		category?.category_basic_info?.description &&
		category?.category_basic_info?.bed_type &&
		category?.category_basic_info?.meal_option &&
		((category?.category_basic_info?.room_size?.length &&
			/^\d+$/.test(category?.category_basic_info?.room_size?.length)) ||
			!category?.category_basic_info?.room_size?.length) &&
		((category?.category_basic_info?.room_size?.breadth &&
			/^\d+$/.test(category?.category_basic_info?.room_size?.breadth)) ||
			!category?.category_basic_info?.room_size?.breadth) &&
		category?.category_base_pricing?.base_occupancy &&
		/^\d+$/.test(category?.category_base_pricing?.base_occupancy) &&
		category?.category_base_pricing?.base_price &&
		/^\d+$/.test(category?.category_base_pricing?.base_price) &&
		category?.category_base_pricing?.extra_adult &&
		/^\d+$/.test(category?.category_base_pricing?.extra_adult) &&
		category?.category_base_pricing?.extra_adult_price &&
		/^\d+$/.test(category?.category_base_pricing?.extra_adult_price) &&
		category?.category_base_pricing?.extra_child &&
		/^\d+$/.test(category?.category_base_pricing?.extra_child) &&
		category?.category_base_pricing?.extra_child_price.child_range_one_price &&
		/^\d+$/.test(
			category?.category_base_pricing?.extra_child_price.child_range_one_price
		) &&
		category?.category_base_pricing?.extra_child_price.child_range_two_price &&
		/^\d+$/.test(
			category?.category_base_pricing?.extra_child_price.child_range_two_price
		) &&
		category?.category_base_pricing?.max_guests &&
		/^\d+$/.test(category?.category_base_pricing?.max_guests) &&
		((category?.category_group_booking?.group_booking_allowed &&
			category?.category_group_booking?.booking_capacity &&
			/^\d+$/.test(category?.category_group_booking?.booking_capacity) &&
			category?.category_group_booking?.per_person_cost &&
			/^\d+$/.test(category?.category_group_booking?.per_person_cost) &&
			category?.category_group_booking?.base_price &&
			/^\d+$/.test(category?.category_group_booking?.base_price) &&
			category?.category_group_booking?.no_cost_child &&
			/^\d+$/.test(category?.category_group_booking?.no_cost_child)) ||
			!category?.category_group_booking?.group_booking_allowed) &&
		checkMandatoryAmenities(category?.category_amenities)
	) {
		return true;
	} else {
		return false;
	}
};

/* export const checkMandatoryAllRooms = (form: any) => {
    let rooms: any[] = form?.property_rooms;
    let invalidRooms = [];
    for (let room in rooms){
        let roomFor = {
            'room_id': room?._id,
            'category': room?.room_basic_info?.room_type,
            'roomName': room?.room_basic_info?.room_name,
        };
    }
} */

export const checkMandatoryPhotos = (images: any, categoryNames: []): any => {
	let validationObj: any = { property: 0 };
	for (let cat in categoryNames) {
		validationObj[cat] = 0;
	}
	if (images.length > 0) {
		for (let i = 0; i < images.length; i++) {
			let category: string = images[i]["image_category"];
			let imageCount = images[i]["image_bodies"].length;
			validationObj[category] = imageCount;
		}
	}
	// console.log("validationObj-----", validationObj);
	return validationObj;
};

export const checkMandatoryPolicies = (form: any) => {
	if (
		form.hasOwnProperty("_id") &&
		form?.property_policies?.checkin_time &&
		form?.property_policies?.checkout_time &&
		form?.property_policies?.cancellation_policy
	)
		return true;
	else return false;
};

export const checkMandatoryRules = (form: any): boolean => {
	// console.log(form);
	// console.log(
	// 	form?.property_rules?.room_safety_hygiene.length,
	// 	"policies validation"
	// );

	if (
		form?.property_rules?.guest_profile.length > 6 &&
		form?.property_rules?.general_safety_hygiene_guidelines.length > 7 &&
		form?.property_rules?.room_safety_hygiene.length > 3 &&
		form?.property_rules?.social_distancing.length > 4 &&
		form?.property_rules?.food_drinks_hygiene.length > 3 &&
		form?.property_rules?.property_restrictions.length > 6 &&
		form?.property_rules?.pet_policy.length > 1 &&
		form?.property_rules?.guest_suitabilty.length > 3 &&
		form?.property_rules?.checkin_checkout_policy.length > 1 &&
		form?.property_rules?.extra_bed_policy.length > 1
	)
		return true;
	else return false;
};

// This is the code for only 3 mandatory field
// export const checkMandatoryRules = (form: any): boolean => {
//     let count = 0;
//     for (let rule in form?.property_rules) {
//         rule !== 'id_proof' && form?.property_rules[rule].length > 0 && count++;
//     }
//     if (count > 3)
//     return true;
//     else
//     return false;
// }

export const checkMandatoryFinanceLegal = (form: any): boolean => {
	if (
		form.hasOwnProperty("_id") &&
		form?.property_finance_legal?.bank_details?.account_no &&
		/^\d+$/.test(form?.property_finance_legal?.bank_details?.account_no) &&
		form?.property_finance_legal?.bank_details?.ifsc_code &&
		form?.property_finance_legal?.bank_details?.bank_name &&
		/^[aA-zZ\s\.]+$/.test(
			form?.property_finance_legal?.bank_details?.bank_name
		) &&
		form?.property_finance_legal?.pan_details?.pan_no &&
		form?.property_tc_agreed &&
		form?.property_finance_legal?.registration_details?.ownership_type
	)
		return true;
	else return false;
};
