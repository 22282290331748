import React, { useEffect, useState } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
// import { PageLink, PageTitle } from "../../../_metronic/layout/core";
import "./PricingNTax.scss";
import { toAbsoluteUrl } from "../../../../_metronic/helpers";
import { Col, Form, Nav, Row, Tab } from "react-bootstrap-v5";
import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL || "";

const PricingNTaxPage: React.FC = () => {
	const [commission, setCommission] = useState<any>();
	const [form, setForm] = useState<any>(null);
	useEffect(() => {
		// axios.get('https://myresorts.in:3000/v1/admin/commission').then(res=>{
		axios.get(`${API_URL}/v1/admin/commission`).then((res) => {
			setCommission(res.data.data);
		});
	}, []);
	const handleChange = (e: any, value: any) => {
		let obj = { ...form };
		obj[value] = e.target.value;
		setForm(obj);
	};
	const onSubmit = () => {
		if (form._id) {
			// axios.put('https://myresorts.in:3000/v1/admin/commission', {[form.type]:{...form}}).then(()=>{
			axios
				.put(`${API_URL}/v1/admin/commission`, { [form.type]: { ...form } })
				.then(() => {
					setForm({
						pricing_slab: "",
						commission_rate: "",
						fixed_commission: "",
					});
					// axios.get('https://myresorts.in:3000/v1/admin/commission').then(res=>{
					axios.get(`${API_URL}/v1/admin/commission`).then((res) => {
						setCommission(res.data.data);
					});
				});
		}
		// axios.post('https://myresorts.in:3000/v1/admin/commission', {[form.type]:{...form}}).then(()=>{
		else
			axios
				.post(`${API_URL}/v1/admin/commission`, { [form.type]: { ...form } })
				.then(() => {
					setForm({
						pricing_slab: "",
						commission_rate: "",
						fixed_commission: "",
					});
					// axios.get('https://myresorts.in:3000/v1/admin/commission').then(res=>{
					axios.get(`${API_URL}/v1/admin/commission`).then((res) => {
						setCommission(res.data.data);
					});
				});
	};
	const deleteCommission = (id: any) => {
		// axios.delete('https://myresorts.in:3000/v1/admin/commission/'+id).then(()=>{
		//   axios.get('https://myresorts.in:3000/v1/admin/commission').then(res=>{
		axios.delete(`${API_URL}/v1/admin/commission/` + id).then(() => {
			axios.get(`${API_URL}/v1/admin/commission`).then((res) => {
				setCommission(res.data.data);
			});
		});
	};

	// ================ Only Number can Type IN Input Field ==============
	const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
		// Allow only numbers and backspace
		if (!/^\d$/.test(e.key) && e.key !== "Backspace") {
			e.preventDefault();
		}
	};
	const handleKeyPressSlab = (event: React.KeyboardEvent<HTMLInputElement>) => {
		const allowedKeys = ["-", "0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];

		if (!allowedKeys.includes(event.key)) {
			event.preventDefault();
		}
	};
	// ================ Only Number can Type IN Input Field ==============

	return (
		<div>
			<div className="row mb-8">
				<div className="col-lg-12">
					<a
						className="btn btn-success"
						data-bs-toggle="modal"
						data-bs-target="#kt_modal_1"
					>
						<i className="fa fa-plus" aria-hidden="true"></i> Add new
					</a>
				</div>
			</div>
			<div className="row">
				<div className="col-lg-6">
					<div className="card card-custom">
						<div className="card-header">
							<h3 className="card-title">Pricing</h3>
							{/* <div className="card-toolbar">
        <button type="button" className="btn btn-sm btn-light">
            Last 10 bookings
        </button>
    </div> */}
						</div>
						<div className="card-body p-0 manage-pro-table-wrap">
							<table className="table table-hover table-rounded table-striped border gy-4 gs-7">
								<thead>
									<tr className="fw-bold fs-6 text-gray-800 border-bottom-2 border-gray-200">
										<th>Pricing slabs</th>

										<th>% Commision</th>
										<th>Fixed comission</th>
										<th>Actions</th>
									</tr>
								</thead>
								<tbody>
									{commission &&
										commission.pricing?.map((ele: any) => (
											<tr>
												<td>{ele.pricing_slab}</td>
												<td>{ele.commission_rate}%</td>
												<td> {ele.fixed_commission} Rs.</td>
												<td>
													<div className="d-flex justify-content-start flex-shrink-0">
														<a
															href="javascript:;"
															onClick={() =>
																setForm({ ...ele, type: "pricing" })
															}
															data-bs-toggle="modal"
															data-bs-target="#kt_modal_1"
															className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
														>
															<span className="svg-icon svg-icon-3">
																<svg
																	width="24"
																	height="24"
																	viewBox="0 0 24 24"
																	fill="none"
																	xmlns="http://www.w3.org/2000/svg"
																	className="mh-50px"
																>
																	<path
																		opacity="0.3"
																		d="M21.4 8.35303L19.241 10.511L13.485 4.755L15.643 2.59595C16.0248 2.21423 16.5426 1.99988 17.0825 1.99988C17.6224 1.99988 18.1402 2.21423 18.522 2.59595L21.4 5.474C21.7817 5.85581 21.9962 6.37355 21.9962 6.91345C21.9962 7.45335 21.7817 7.97122 21.4 8.35303ZM3.68699 21.932L9.88699 19.865L4.13099 14.109L2.06399 20.309C1.98815 20.5354 1.97703 20.7787 2.03189 21.0111C2.08674 21.2436 2.2054 21.4561 2.37449 21.6248C2.54359 21.7934 2.75641 21.9115 2.989 21.9658C3.22158 22.0201 3.4647 22.0084 3.69099 21.932H3.68699Z"
																		fill="black"
																	></path>
																	<path
																		d="M5.574 21.3L3.692 21.928C3.46591 22.0032 3.22334 22.0141 2.99144 21.9594C2.75954 21.9046 2.54744 21.7864 2.3789 21.6179C2.21036 21.4495 2.09202 21.2375 2.03711 21.0056C1.9822 20.7737 1.99289 20.5312 2.06799 20.3051L2.696 18.422L5.574 21.3ZM4.13499 14.105L9.891 19.861L19.245 10.507L13.489 4.75098L4.13499 14.105Z"
																		fill="black"
																	></path>
																</svg>
															</span>
														</a>
														<a
															href="javascript:;"
															onClick={() => deleteCommission(ele._id)}
															className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm"
														>
															<span className="svg-icon svg-icon-3">
																<svg
																	width="24"
																	height="24"
																	viewBox="0 0 24 24"
																	fill="none"
																	xmlns="http://www.w3.org/2000/svg"
																	className="mh-50px"
																>
																	<path
																		d="M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z"
																		fill="black"
																	></path>
																	<path
																		opacity="0.5"
																		d="M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z"
																		fill="black"
																	></path>
																	<path
																		opacity="0.5"
																		d="M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z"
																		fill="black"
																	></path>
																</svg>
															</span>
														</a>
													</div>
												</td>
											</tr>
										))}
									{/* <tr>
                    <td>2k-5k</td>
                    <td>2%</td>
                    <td> 3000 Rs.</td>
                  </tr>
                  <tr>
                    <td>5k-10k</td>
                    <td>4%</td>
                    <td> 5000 Rs.</td>
                  </tr>
                  <tr>
                    <td>10k-15k</td>
                    <td>5%</td>
                    <td> 15000 Rs.</td>
                  </tr> */}
								</tbody>
							</table>
						</div>
					</div>
				</div>
				<div className="col-lg-6 tax-container">
					<div className="card card-custom">
						<div className="card-header">
							<h3 className="card-title">Tax</h3>
							{/* <div className="card-toolbar">
        <button type="button" className="btn btn-sm btn-light">
            Last 10 bookings
        </button>
    </div> */}
						</div>
						<div className="card-body p-0 manage-pro-table-wrap">
							<table className="table table-hover table-rounded table-striped border gy-4 gs-7">
								<thead>
									<tr className="fw-bold fs-6 text-gray-800 border-bottom-2 border-gray-200">
										<th>Tax slabs</th>

										<th>GSt</th>
										<th>TDS</th>
									</tr>
								</thead>
								<tbody>
									{commission &&
										commission.tax?.map((ele: any) => (
											<tr>
												<td>{ele.pricing_slab}</td>
												<td>{ele.commission_rate}%</td>
												<td> {ele.fixed_commission} %.</td>
												<td>
													<div className="d-flex justify-content-start flex-shrink-0">
														<a
															href="#!"
															onClick={() => setForm({ ...ele, type: "tax" })}
															data-bs-toggle="modal"
															data-bs-target="#kt_modal_1"
															className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
														>
															<span className="svg-icon svg-icon-3">
																<svg
																	width="24"
																	height="24"
																	viewBox="0 0 24 24"
																	fill="none"
																	xmlns="http://www.w3.org/2000/svg"
																	className="mh-50px"
																>
																	<path
																		opacity="0.3"
																		d="M21.4 8.35303L19.241 10.511L13.485 4.755L15.643 2.59595C16.0248 2.21423 16.5426 1.99988 17.0825 1.99988C17.6224 1.99988 18.1402 2.21423 18.522 2.59595L21.4 5.474C21.7817 5.85581 21.9962 6.37355 21.9962 6.91345C21.9962 7.45335 21.7817 7.97122 21.4 8.35303ZM3.68699 21.932L9.88699 19.865L4.13099 14.109L2.06399 20.309C1.98815 20.5354 1.97703 20.7787 2.03189 21.0111C2.08674 21.2436 2.2054 21.4561 2.37449 21.6248C2.54359 21.7934 2.75641 21.9115 2.989 21.9658C3.22158 22.0201 3.4647 22.0084 3.69099 21.932H3.68699Z"
																		fill="black"
																	></path>
																	<path
																		d="M5.574 21.3L3.692 21.928C3.46591 22.0032 3.22334 22.0141 2.99144 21.9594C2.75954 21.9046 2.54744 21.7864 2.3789 21.6179C2.21036 21.4495 2.09202 21.2375 2.03711 21.0056C1.9822 20.7737 1.99289 20.5312 2.06799 20.3051L2.696 18.422L5.574 21.3ZM4.13499 14.105L9.891 19.861L19.245 10.507L13.489 4.75098L4.13499 14.105Z"
																		fill="black"
																	></path>
																</svg>
															</span>
														</a>
														<a
															href="javascript:;"
															onClick={() => deleteCommission(ele._id)}
															className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm"
														>
															<span className="svg-icon svg-icon-3">
																<svg
																	width="24"
																	height="24"
																	viewBox="0 0 24 24"
																	fill="none"
																	xmlns="http://www.w3.org/2000/svg"
																	className="mh-50px"
																>
																	<path
																		d="M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z"
																		fill="black"
																	></path>
																	<path
																		opacity="0.5"
																		d="M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z"
																		fill="black"
																	></path>
																	<path
																		opacity="0.5"
																		d="M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z"
																		fill="black"
																	></path>
																</svg>
															</span>
														</a>
													</div>
												</td>
											</tr>
										))}
									{/* <tr>
                    <td>2k-5k</td>
                    <td>2%</td>
                    <td> 3000 Rs.</td>
                  </tr>
                  <tr>
                    <td>5k-10k</td>
                    <td>4%</td>
                    <td> 5000 Rs.</td>
                  </tr>
                  <tr>
                    <td>10k-15k</td>
                    <td>5%</td>
                    <td> 15000 Rs.</td>
                  </tr> */}
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>

			<div className="modal fade" tabIndex={-1} id="kt_modal_1">
				<div className="modal-dialog w-600px mw-600px">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title">{form?._id ? "Edit" : "Add"}</h5>
							<div
								className="btn btn-icon btn-sm btn-active-light-primary ms-2"
								data-bs-dismiss="modal"
								aria-label="Close"
							>
								<i className="fa fa-times" aria-hidden="true"></i>
							</div>
						</div>
						<div className="modal-body">
							<div className="row mb-6">
								<label className="col-lg-4 col-form-label required fw-bold fs-6">
									Slab
								</label>
								<div className="col-lg-8">
									<div className="row">
										<div className="col-lg-12 fv-row">
											<input
												type="text"
												className="form-control form-control-solid"
												placeholder="2000-4000"
												// placeholder="Slab"
												value={form?.pricing_slab}
												onChange={(e) => handleChange(e, "pricing_slab")}
												onKeyPress={handleKeyPressSlab}
											/>
										</div>
									</div>
								</div>
							</div>

							<div className="row mb-6">
								<label className="col-lg-4 col-form-label required fw-bold fs-6">
									Commission Rate/GST
								</label>
								<div className="col-lg-8">
									<div className="row">
										<div className="col-lg-12 fv-row">
											<input
												type="text"
												className="form-control form-control-solid"
												placeholder="12"
												// placeholder="Commission Rate/GST"
												value={form?.commission_rate}
												onChange={(e) => handleChange(e, "commission_rate")}
												onKeyPress={handleKeyPress}
											/>
										</div>
									</div>
								</div>
							</div>

							<div className="row mb-6">
								<label className="col-lg-4 col-form-label required fw-bold fs-6">
									FixedCommission/TDS
								</label>
								<div className="col-lg-8">
									<div className="row">
										<div className="col-lg-12 fv-row">
											<input
												type="text"
												className="form-control form-control-solid"
												placeholder="299"
												// placeholder="Fixed Commission/TDS"
												value={form?.fixed_commission}
												onChange={(e) => handleChange(e, "fixed_commission")}
												onKeyPress={handleKeyPress}
											/>
										</div>
									</div>
								</div>
							</div>

							<div className="row mb-6">
								<label className="col-lg-4 col-form-label fw-bold fs-6">
									<span className="required">Pricing type</span>
								</label>
								<div className="col-lg-8 fv-row">
									<div className="mt-4 d-flex">
										<div className="form-check form-check-custom form-check-solid form-check-sm me-10">
											<input
												className="form-check-input"
												name="discount"
												type="radio"
												value="pricing"
												id="flexRadioSm"
												checked={form?.type == "pricing"}
												onChange={(e) => handleChange(e, "type")}
											/>
											<label className="form-check-label" htmlFor="flexRadioSm">
												Pricing
											</label>
										</div>

										<div className="form-check form-check-custom form-check-solid form-check-sm">
											<input
												className="form-check-input"
												name="discount"
												type="radio"
												value="tax"
												id="flexRadioSm"
												checked={form?.type == "tax"}
												onChange={(e) => handleChange(e, "type")}
											/>
											<label
												className="form-check-label"
												htmlFor="flexRadioSm1"
											>
												Tax
											</label>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="modal-footer">
							<button
								type="button"
								className="btn btn-light"
								data-bs-dismiss="modal"
							>
								Close
							</button>
							<button
								type="button"
								className="btn btn-primary"
								data-bs-dismiss="modal"
								onClick={onSubmit}
							>
								{form?._id ? "Edit" : "Add"} coupon
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
export default PricingNTaxPage;
