import React, { useEffect, useRef, useState } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
// import { PageLink, PageTitle } from "../../../_metronic/layout/core";
import "./Blog.scss";
import { toAbsoluteUrl } from "../../../../_metronic/helpers";
import { Col, Form, Nav, Row, Tab } from "react-bootstrap-v5";
import axios from "axios";
import ReactPaginate from "react-paginate";
import JoditEditor from "jodit-react";
// import { checkMandatoryLocation } from "../../profile/ProfileValidations";

const API_URL = process.env.REACT_APP_API_URL || "";

const BlogPage: React.FC = ({ itemsPerPage = 10 }: any) => {
	const editor = useRef(null);

	const [content, setContent] = useState("");

	const config = {
		readonly: false, // all options from https://xdsoft.net/jodit/doc/
	};
	const [currentItems, setCurrentItems] = useState<any>(null);
	const [pageCount, setPageCount] = useState(0);
	// Here we use item offsets; we could also use page offsets
	// following the API or data you're working with.
	const [itemOffset, setItemOffset] = useState(0);
	const [form, setForm] = useState<any>({});

	const [blogMsg, setBlogMsg] = useState("");

	const [blogValidation, setBlogValidation] = useState(false);

	useEffect(() => {
		// Fetch items from another resources.
		axios.get(`${API_URL}/v1/admin/blog?page_no=0`).then((res) => {
			setCurrentItems(res.data.data);
			//setPageCount(Math.ceil(res.data.data.metadata.total / itemsPerPage));
		});
	}, [itemOffset, itemsPerPage]);

	// Invoke when user click to request another page.
	const handlePageClick = (event: any) => {
		axios
			.get(`${API_URL}/v1/admin/blog?page_no=` + event.selected)
			.then((res) => {
				setCurrentItems(res.data.data);
				//setPageCount(Math.ceil(res.data.data.metadata.total / itemsPerPage));
			});
	};

	const handleChange = (event: any, property: any, type: any) => {
		let obj = { ...form };
		if (type == "text") {
			obj[property] = event.target.value;
		} else {
			obj[property] = event.target.files[0];
		}
		setForm(obj);
		// checkMandatory(form)
	};

	const handleBlogMsg = (message: any) => {
		setBlogMsg(message);
		setTimeout(() => {
			setBlogMsg("");
		}, 3000);
	};

	const onSubmit = () => {
		let formdata = new FormData();

		if (form.blog_title) formdata.append("blog_title", form.blog_title);
		if (form.blog_image_file)
			formdata.append("blog_image_file", form.blog_image_file);
		if (form.blog_date) formdata.append("blog_date", form.blog_date);
		if (content) formdata.append("blog_data", content);
		else formdata.append("blog_data", form.blog_data);
		let valued;
		if (form.blog_title === undefined) {
			valued = "disable";
		} else if (form.blog_date === undefined) {
			valued = "disable";
		} else if (content === "") {
			valued = "disable";
		} else if (form.blog_title === "") {
			valued = "disable";
		} else if (form.blog_date === "") {
			valued = "disable";
		} else {
			valued = "able";
		}

		if (form?._id) {
			formdata.append("_id", form._id);
			axios.put(`${API_URL}/v1/admin/blog`, formdata).then((res) => {
				setForm({
					blog_title: "",
					blog_date: "",
					// blog_image: '',
					blog_data: "",
				});
				setContent(" ");
				handleBlogMsg(res.data.message);
				axios.get(`${API_URL}/v1/admin/blog`).then((res) => {
					setCurrentItems(res.data.data);
					//setPageCount(Math.ceil(res.data.data.metadata.total / itemsPerPage));
				});
			});
		} else {
			valued === "able"
				? axios.post(`${API_URL}/v1/admin/blog`, formdata).then((res) => {
						setForm({
							blog_title: "",
							blog_date: "",
							// blog_image: '',
							blog_data: "",
						});
						setContent(" ");
						handleBlogMsg(res.data.message);
						axios.get(`${API_URL}/v1/admin/blog`).then((res) => {
							setCurrentItems(res.data.data);
							//setPageCount(Math.ceil(res.data.data.metadata.total / itemsPerPage));
						});
				  })
				: handleBlogMsg("Please Fill The Blog Details");
		}
	};

	const handleDelete = (id: any) => {
		axios.delete(`${API_URL}/v1/admin/blog/` + id).then((res) => {
			//   console.log(res, "delete response")
			axios.get(`${API_URL}/v1/admin/blog`).then((res) => {
				// console.log(res, "new data response")
				setCurrentItems(res.data.data);
				// setPageCount(Math.ceil(res.data.data.metadata.total / itemsPerPage));
			});
		});
	};

	return (
		<div>
			{form && (
				<div className="card card-custom">
					<div className="card-header">
						<h3 className="card-title">Create blog</h3>
						{/* <div className="card-toolbar">
            <button type="button" className="btn btn-sm btn-light">
              Action
            </button>
          </div> */}
					</div>
					<div className="card-body">
						<div className="row">
							<div className="col-lg-6 mb-10">
								<label className="required form-label">Title</label>
								<input
									type="text"
									className="form-control form-control-solid"
									placeholder=""
									onChange={(e) => handleChange(e, "blog_title", "text")}
									value={form?.blog_title}
								/>
							</div>

							<div className="col-lg-6">
								<label className="required form-label">Image</label>
								<Form.Group controlId="formFile" className="mb-3">
									<Form.Control
										type="file"
										multiple
										onChange={(e) => handleChange(e, "blog_image_file", "file")}
									/>
								</Form.Group>
							</div>
							<div className="col-lg-6 mb-10">
								<label className="required form-label">Date of publish</label>
								<input
									type="date"
									className="form-control form-control-solid"
									placeholder=""
									onChange={(e) => handleChange(e, "blog_date", "text")}
									value={form?.blog_date}
								/>
							</div>
							<div className="mb-10">
								<JoditEditor
									ref={editor}
									value={form?.blog_data}
									config={config}
									onBlur={(newContent) => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
									// onChange={(newContent) => console.log(newContent)}
									// onChange={e => handleChange(e, 'blog_data', 'text')}
								/>
							</div>
							<div>
								<button
									className="btn btn-primary"
									type="button"
									onClick={onSubmit}
								>
									Submit
								</button>
								<span style={{ color: "red", marginLeft: "30px" }}>
									{" "}
									{blogMsg}{" "}
								</span>
							</div>
						</div>
					</div>
				</div>
			)}

			<div className="row mt-5">
				{/* <div className="col-lg-12 d-flex justify-content-end">
          <div className="d-flex my-2">
            <div className="d-flex align-items-center position-relative me-4">
              <span className="svg-icon svg-icon-3 position-absolute ms-3">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="mh-50px"
                >
                  <rect
                    opacity="0.5"
                    x="17.0365"
                    y="15.1223"
                    width="8.15546"
                    height="2"
                    rx="1"
                    transform="rotate(45 17.0365 15.1223)"
                    fill="black"
                  ></rect>
                  <path
                    d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                    fill="black"
                  ></path>
                </svg>
              </span>
              <input
                type="text"
                id="kt_filter_search"
                className="form-control form-control-white form-control-sm w-250px ps-9 border-secondary"
                placeholder="Search"
                wtx-context="666F8DA7-646C-4188-876B-CA6BA1CAF363"
              />
            </div>
            <button className="btn btn-primary btn-sm">Search</button>
          </div>
        </div> */}

				<div className="col-lg-12">
					<div className="card card-custom">
						<div className="card-header">
							<h3 className="card-title">Blog list</h3>
							{/* <div className="card-toolbar">
        <button type="button" className="btn btn-sm btn-light">
            Last 10 bookings
        </button>
    </div> */}
						</div>
						<div className="card-body p-0 manage-pro-table-wrap">
							<table className="table table-hover table-rounded table-striped border gy-4 gs-7">
								<thead>
									<tr className="fw-bold fs-6 text-gray-800 border-bottom-2 border-gray-200">
										<th>Title</th>
										<th>Published on</th>
										<th>Action</th>
									</tr>
								</thead>
								<tbody>
									{currentItems &&
										currentItems.map((ele: any) => (
											<tr>
												<td>{ele.blog_title}</td>
												<td>{ele.blog_date}</td>
												<td>
													<div className="d-flex justify-content-start flex-shrink-0">
														<a
															href="javascript:;"
															className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
															onClick={() => setForm({ ...ele })}
														>
															<span className="svg-icon svg-icon-3">
																<svg
																	width="24"
																	height="24"
																	viewBox="0 0 24 24"
																	fill="none"
																	xmlns="http://www.w3.org/2000/svg"
																	className="mh-50px"
																>
																	<path
																		opacity="0.3"
																		d="M21.4 8.35303L19.241 10.511L13.485 4.755L15.643 2.59595C16.0248 2.21423 16.5426 1.99988 17.0825 1.99988C17.6224 1.99988 18.1402 2.21423 18.522 2.59595L21.4 5.474C21.7817 5.85581 21.9962 6.37355 21.9962 6.91345C21.9962 7.45335 21.7817 7.97122 21.4 8.35303ZM3.68699 21.932L9.88699 19.865L4.13099 14.109L2.06399 20.309C1.98815 20.5354 1.97703 20.7787 2.03189 21.0111C2.08674 21.2436 2.2054 21.4561 2.37449 21.6248C2.54359 21.7934 2.75641 21.9115 2.989 21.9658C3.22158 22.0201 3.4647 22.0084 3.69099 21.932H3.68699Z"
																		fill="black"
																	></path>
																	<path
																		d="M5.574 21.3L3.692 21.928C3.46591 22.0032 3.22334 22.0141 2.99144 21.9594C2.75954 21.9046 2.54744 21.7864 2.3789 21.6179C2.21036 21.4495 2.09202 21.2375 2.03711 21.0056C1.9822 20.7737 1.99289 20.5312 2.06799 20.3051L2.696 18.422L5.574 21.3ZM4.13499 14.105L9.891 19.861L19.245 10.507L13.489 4.75098L4.13499 14.105Z"
																		fill="black"
																	></path>
																</svg>
															</span>
														</a>
														<a
															href="javascript:;"
															className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm"
															onClick={() => handleDelete(ele._id)}
														>
															<span className="svg-icon svg-icon-3">
																<svg
																	width="24"
																	height="24"
																	viewBox="0 0 24 24"
																	fill="none"
																	xmlns="http://www.w3.org/2000/svg"
																	className="mh-50px"
																>
																	<path
																		d="M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z"
																		fill="black"
																	></path>
																	<path
																		opacity="0.5"
																		d="M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z"
																		fill="black"
																	></path>
																	<path
																		opacity="0.5"
																		d="M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z"
																		fill="black"
																	></path>
																</svg>
															</span>
														</a>
													</div>
												</td>
											</tr>
										))}
								</tbody>
							</table>
						</div>
					</div>
				</div>
				<div className="col-lg-12">
					<div className="pagination-wrap">
						<ReactPaginate
							breakLabel="..."
							nextLabel=" >"
							breakClassName="page-item"
							breakLinkClassName="page-link"
							pageClassName="page-item"
							pageLinkClassName="page-link"
							previousClassName="page-item"
							previousLinkClassName="page-link"
							nextClassName="page-item"
							nextLinkClassName="page-link"
							activeClassName="active"
							onPageChange={handlePageClick}
							pageRangeDisplayed={5}
							pageCount={pageCount}
							previousLabel="< "
							className="pagination"
						/>
					</div>
				</div>
			</div>
		</div>
	);
};
export default BlogPage;
