import React, { useEffect, useState } from "react";
import { Link, Redirect, Route, Switch } from "react-router-dom";
// import { PageLink, PageTitle } from "../../../_metronic/layout/core";
import "./Customer.scss";
import { Col, Form, Nav, Row, Tab } from "react-bootstrap-v5";
import { toAbsoluteUrl } from "../../../../_metronic/helpers";
import axios from "axios";
import ReactPaginate from "react-paginate";

const API_URL = process.env.REACT_APP_API_URL || "";

const CustomerPage: React.FC = ({ itemsPerPage = 10 }: any) => {
	const [customerInfo, setCustomerInfo] = useState<any>(null);
	const [pageCount, setPageCount] = useState(0);
	const [itemOffset, setItemOffset] = useState(0);

	useEffect(() => {
		axios.get(`${API_URL}/v1/admin/getcustomer`).then((res) => {
			// console.log(res.data.result, "customer info")
			setCustomerInfo(res.data.result);
			// setPageCount(Math.ceil(res.data.data.metatdata.total / itemsPerPage));
		});
	}, [itemOffset, itemsPerPage]);

	return (
		<div>
			<div className="row">
				{/* <div className="col-lg-12 d-flex justify-content-end">
          <div className="d-flex my-2">
            <div className="d-flex align-items-center position-relative me-4">
              <span className="svg-icon svg-icon-3 position-absolute ms-3">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="mh-50px"
                >
                  <rect
                    opacity="0.5"
                    x="17.0365"
                    y="15.1223"
                    width="8.15546"
                    height="2"
                    rx="1"
                    transform="rotate(45 17.0365 15.1223)"
                    fill="black"
                  ></rect>
                  <path
                    d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                    fill="black"
                  ></path>
                </svg>
              </span>
              <input
                type="text"
                id="kt_filter_search"
                className="form-control form-control-white form-control-sm w-250px ps-9 border-secondary"
                placeholder="Search"
                wtx-context="666F8DA7-646C-4188-876B-CA6BA1CAF363"
              />
            </div>
            <button className="btn btn-primary btn-sm">Search</button>
          </div>
        </div> */}

				<div className="col-lg-12">
					<div className="card card-custom">
						<div className="card-header">
							<h3 className="card-title">Customer</h3>
						</div>
						<div className="card-body p-0 manage-pro-table-wrap">
							<table className="table table-hover table-rounded table-striped border gy-4 gs-7">
								<thead>
									<tr className="fw-bold fs-6 text-gray-800 border-bottom-2 border-gray-200">
										<th>Name & ID</th>
										<th>Email</th>
										<th>Phone</th>
										{/* <th>Location</th>
                  <th>Date of birth</th>
                  <th>Gender</th> */}
									</tr>
								</thead>
								<tbody>
									{customerInfo &&
										customerInfo.map((ele: any) => {
											return (
												<>
													<tr key={ele._id}>
														<td>
															{/* <Link to={{
                              pathname: "customer-details",
                              search: `customerId=${ele._id}`
                            }}> */}
															<b>
																{ele?.firstname || "New User"} {ele?.lastname}
															</b>{" "}
															<br />
															<b>{ele._id}</b>
															{/* </Link> */}
														</td>
														<td>{ele?.email || "New User"}</td>
														<td>{ele.mobile}</td>
														{/* <td>Mauris blandit aliquet</td>
                  <td>21/05/89</td>
                  <td>Mail</td> */}
													</tr>
												</>
											);
										})}

									{/* <tr>
                  <td>
                    <a href="#!">
                      <b>Quisque velit</b> <br />
                      <b>#458932</b>
                    </a>
                  </td>
                  <td>Quisque@gmail.com</td>
                  <td>9478562369</td>
                  <td>Mauris blandit aliquet</td>
                  <td>21/05/89</td>
                  <td>Mail</td>
                </tr> */}
								</tbody>
							</table>
						</div>
					</div>
				</div>

				{/* <div className="col-lg-12">
          <div className="pagination-wrap">
            <ul className="pagination">
              <li className="page-item previous disabled">
                <a href="#" className="page-link">
                  <i className="previous"></i>
                </a>
              </li>
              <li className="page-item">
                <a href="#" className="page-link">
                  1
                </a>
              </li>
              <li className="page-item active">
                <a href="#" className="page-link">
                  2
                </a>
              </li>
              <li className="page-item">
                <a href="#" className="page-link">
                  3
                </a>
              </li>
              <li className="page-item">
                <a href="#" className="page-link">
                  4
                </a>
              </li>
              <li className="page-item">
                <a href="#" className="page-link">
                  5
                </a>
              </li>
              <li className="page-item">
                <a href="#" className="page-link">
                  6
                </a>
              </li>
              <li className="page-item next">
                <a href="#" className="page-link">
                  <i className="next"></i>
                </a>
              </li>
            </ul>
          </div>
        </div> */}
			</div>
		</div>
	);
};
export default CustomerPage;
