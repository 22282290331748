import "./RoomDetails.scss";
import { Carousel as Multicarousel, Col, Modal, Row } from "react-bootstrap-v5";
import Collapsible from "react-collapsible";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { toAbsoluteUrl } from "../../../../_metronic/helpers";
import ImageGallery from "react-image-gallery";
import UserHeader from "../components/UserHeader";
import UserFooter from "../components/UserFooter";
import { AiFillCaretDown } from "react-icons/ai";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";

import { useEffect, useRef, useState } from "react";
import { DateRange } from "react-date-range";
import filtersState from "../userglobalstate/filterstate";
import { none, useState as useGlobalState } from "@hookstate/core";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { useFormik } from "formik";
import * as Yup from "yup";
import "yup-phone";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {
	SampleNextArrow,
	SamplePrevArrow,
} from "../components/CustomArrows/CustomArrows";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faFan,
	faGlobe,
	faSmokingBan,
	faSwimmingPool,
	faPager,
	faTshirt,
	faUserTie,
	faPhone,
	faBatteryFull,
} from "@fortawesome/free-solid-svg-icons";
import Helper from "../../../../helper/helper";
import moment from "moment";
// import { useNavigate } from 'react-router-dom';

const API_URL = process.env.REACT_APP_API_URL || "";

// const Img_Path = `https://myresort.s3.ap-south-1.amazonaws.com/`;
// const Img_Path = "https://myresortsbucket.s3.amazonaws.com/";
const Img_Path = "https://myresortsbucket-prod1.s3.ap-south-1.amazonaws.com/";

// =========== Modal Code Start's here ============
const initialValues = {
	number: "",
};

const signUpSchema = Yup.object({
	number: Yup.string()
		.required("Mobile Number is Required")
		.max(10, "Enter Valid Mobile Number")
		.phone("IN", true, "Phone Number is Invalid"),
});
// =========== Modal Code End's here ============

const responsive = {
	desktop: {
		breakpoint: { max: 3000, min: 1024 },
		items: 4,
		slidesToSlide: 4, // optional, default to 1.
	},
	tablet: {
		breakpoint: { max: 1024, min: 464 },
		items: 2,
		slidesToSlide: 2, // optional, default to 1.
	},
	mobile: {
		breakpoint: { max: 464, min: 0 },
		items: 1,
		slidesToSlide: 1, // optional, default to 1.
	},
};

const imgCarousel = {
	desktop: {
		breakpoint: { max: 3000, min: 1024 },
		items: 1,
		slidesToSlide: 1, // optional, default to 1.
	},
	tablet: {
		breakpoint: { max: 1024, min: 464 },
		items: 1,
		slidesToSlide: 1, // optional, default to 1.
	},
	mobile: {
		breakpoint: { max: 464, min: 0 },
		items: 1,
		slidesToSlide: 1, // optional, default to 1.
	},
};

// const Imgsettings = {
//   dots: false,
//   infinite: true,
//   speed: 500,
//   slidesToShow: 4,
//   slidesToScroll: 1,
//   autoplay: true,
//   autoplaySpeed: 3000,
//   arrows: true,
//   nextArrow: <SampleNextArrow />,
//   prevArrow: <SamplePrevArrow />
// };

// ==== Date Range and Guest room filter Starts here ========

// Date Range Picker Click Outside Close Start's here
function useClickOutside(callback: () => void) {
	const ref = useRef<HTMLDivElement>(null);
	const handleClick = (event: MouseEvent) => {
		if (ref.current && !ref.current.contains(event.target as Node)) {
			callback();
		}
	};
	useEffect(() => {
		document.addEventListener("click", handleClick);
		return () => {
			document.removeEventListener("click", handleClick);
		};
	});
	return ref;
}
// Date Range Picker Click Outside Close End's here

// ==== Date Range and Guest room filter ends here ===========

export default function Roomdetails() {
	const [roomData, setRoomData] = useState<any>("");
	const [similarRoom, setSimilarRoom] = useState<any>("");
	const [similarRoomCity, setSimilarRoomCity] = useState<any>("");
	const [propertyPolicy, setPropertyPolicy] = useState<any>("");
	const [categoryId, setCategoryId] = useState<any>("");
	const [propertyId, setPropertyId] = useState<any>("");
	const [formatCheckIn, setFormatCheckIn] = useState<any>("");
	const [formatCheckOut, setFormatCheckOut] = useState<any>("");

	//Saurabh's code===========================================
	const [mealOption, setMealOption] = useState<any>("");

	const [couponValue, setCoupnValue] = useState<any>("");
	const [couponPrice, setCouponPrice] = useState<any>(0);
	const [couponType, setCouponType] = useState<any>("");
	const [couponMsg, setCouponMsg] = useState<any>("");

	const [totalRoomCharge, setTotalRoomCharge] = useState<any>(0); // total calculation of room price and peoples

	const [commissionedPrice, setCommissionedPrice] = useState<number>(0);
	const [taxationPrice, setTaxationPrice] = useState<number>(0);

	const [taxedAmount, setTaxedAmount] = useState<number>(0);

	const [totalAmount, setTotalAmount] = useState<number>(0);

	const [discountAmount, setDiscountAmount] = useState<any>(0);
	const [roomBasePrice, setRoomBasePrice] = useState<any>(0);
	const [tdsBase, setTdsBase] = useState<any>(0);
	const [tdsBasePrice, setTdsBasePrice] = useState<any>(0);

	const [commisonGst, setCommisonGst] = useState<any>(0);

	const { search } = useLocation();
	const params = new URLSearchParams(search);
	const roomsNum = params.get("rooms") ? params.get("rooms") : 1;
	const adultNum = params.get("adults") ? params.get("adults") : 2;
	const GroupNum = params.get("groupAdults") ? params.get("groupAdults") : 4;
	const childRange1Num = params.get("childRange1")
		? params.get("childRange1")
		: 0;
	const childRange2Num = params.get("childRange2")
		? params.get("childRange2")
		: 0;
	const childRange3Num = params.get("childRange3")
		? params.get("childRange3")
		: 0;
	const catId = params.get("catId")
		? params.get("catId")
		: "633665f110e91088cb403da9";
	const coupleGroupString = params.get("coupleGroupVal")
		? params.get("coupleGroupVal")
		: false; //cgsbc
	const coupleGroupVal =
		coupleGroupString === "true" ? coupleGroupString === "true" : false; //cgsbc
	let n: any = params.get("checkIn");

	let checkInDate: string | number | null = params.get("checkIn");
	let checkOutDate: string | number | null = params.get("checkOut");
	let FirstDate: Date;
	function parseMonth(month1: any) {
		month1 = month1.toLowerCase();
		var months = [
			"jan",
			"feb",
			"mar",
			"apr",
			"may",
			"jun",
			"jul",
			"aug",
			"sep",
			"oct",
			"nov",
			"dec",
		];
		return months.indexOf(month1);
	}
	if (typeof checkInDate === "string") {
		// console.log("Trace1 000 ", checkInDate);

		const checkInDay: any = checkInDate?.slice(8, 10);
		const checkInMonth: any = checkInDate.slice(4, 7);
		const checkInYear: any = checkInDate.slice(11, 15);
		const fullDateIn = checkInDay + checkInMonth + checkInYear;
		const dateObj = new Date(
			Number(checkInYear),
			parseMonth(checkInMonth),
			Number(checkInDay)
		);
		// console.log("Trace1 0002 ", checkInDay);
		// console.log("Trace1 000 2", parseMonth(checkInMonth));
		// console.log("Trace1 000 3", checkInYear);
		// console.log("Trace1 000 4", dateObj);
		// console.log("Trace1 000 5", fullDateIn);

		FirstDate = new Date(dateObj);
	} else {
		FirstDate = new Date();
	}
	let SecondDate: Date;
	if (typeof checkOutDate === "string") {
		const checkInDay: any = checkOutDate.slice(8, 10);
		const checkInMonth: any = checkOutDate.slice(4, 7);
		const checkInYear: any = checkOutDate.slice(11, 15);
		const dateObj = new Date(
			Number(checkInYear),
			parseMonth(checkInMonth),
			Number(checkInDay)
		);

		const fullDateIn = checkInDay + checkInMonth + checkInYear;
		const fullDateStart = new Date(fullDateIn);
		SecondDate = new Date(dateObj);
	} else {
		const today = new Date();
		const nextDay = new Date(today);
		nextDay.setDate(nextDay.getDate() + 1);
		SecondDate = nextDay;
	}
	// console.log(checkInDate, checkOutDate, "params dates")

	// ================ couple group booking states start here ==========================

	const [coupleGroup, setCoupleGroup] = useState<any>(coupleGroupVal); //cgsbc
	const [groupAllowed, setGroupAllowed] = useState<boolean>(true); //cgsbc
	const [costPerHead, setCostPerHead] = useState<number>(0);

	// ================ couple group booking states end here ==========================

	// =========== date range picker and rooms and guests filter start's here ==========
	const filters = useGlobalState(filtersState.filters);
	const [defaultPlaceOptions, setdefaultPlaceOptions] = useState(
		filters.defaultPlaceOptions.get()
	);
	const [resultPlaceOptions, setResultPlaceOptions] = useState<any[]>([]);
	const [guestsFilterShow, setGuestsFilterShow] = useState(false);

	// ========== date range picker code start's here ==========

	const [dateRange, setDateRange] = useState<any[]>([
		{
			startDate: new Date(FirstDate),
			endDate: new Date(SecondDate),
			key: "selection",
		},
	]);

	const [checkedInDate, setCheckedInDate] = useState<any>("");
	const [checkedOutDate, setCheckedOutDate] = useState<any>("");

	const [openCalendar, setOpenCalendar] = useState(false);
	const calenderRef = useClickOutside(() => {
		return setOpenCalendar(false), setGuestsFilterShow(false);
	});

	const InDate = dateRange.map((data) => data.startDate);
	const OutDate = dateRange.map((data) => data.endDate);

	const dateString = InDate.toString();
	const checkInDay = dateString.slice(8, 10);
	const checkInMonth = dateString.slice(4, 7);
	const checkInYear = dateString.slice(11, 15);

	const DateStringEnd = OutDate.toString();
	const checkOutDay = DateStringEnd.slice(8, 10);
	const checkOutMonth = DateStringEnd.slice(4, 7);
	const checkOutYear = DateStringEnd.slice(11, 15);

	let CheckInDate = checkInDay + " " + checkInMonth + " " + checkInYear;
	let CheckOutDate = checkOutDay + " " + checkOutMonth + " " + checkOutYear;

	// ========== date range picker code end's here ==========

	// ======= modal code Start's here =============
	const [show, setShow] = useState(false);
	const [resMsg, setResMsg] = useState<string>(
		"Please Enter Mobile Number to Continue Booking"
	);
	const [resStatus, setResStatus] = useState("");
	const [userId, setUserId] = useState<any>("");
	const [userNum, setUserNum] = useState<string>("");
	// const [localUserId, setLocalUserId] = useState<any>("");
	const [verifyOtp, setVerifyOtp] = useState("");
	// ======= modal code End's here ==============

	// ========= Property Policies Modal Code Start's Here ================
	const [policyShow, setPolicyShow] = useState<any>(false);
	const handlePolicyClose = () => setPolicyShow(false);
	const handlePolicyShow = () => setPolicyShow(true);
	// ========= Property Policies Modal Code End's Here ==================

	// ================= Image Show Modal Code Start's here ====================
	const [count, setCount] = useState<number>();

	const [imgShow, setImgShow] = useState<any>(false);
	const handleImgClose = () => setImgShow(false);
	// const handleImgShow = () => setImgShow(true);
	const handleImgShow = (count: any) => {
		setCount(count);
		setImgShow(true);
	};
	// ================= Image Show Modal Code End's here ======================

	const [guestsFilter, setGuestFilter] = useState<any>({
		rooms: roomsNum,
		adults: adultNum,
		childRange1: childRange1Num,
		childRange2: childRange2Num,
		childRange3: childRange3Num,
		groupAdults: GroupNum,
	});

	const totalGuestsNum =
		coupleGroup && groupAllowed
			? Number(guestsFilter.groupAdults) + Number(guestsFilter.childRange3)
			: Number(guestsFilter.adults) +
			  Number(guestsFilter.childRange1) +
			  Number(guestsFilter.childRange2);

	const handleGuestsNumberChange = (type: string, arith: string) => {
		let obj = { ...guestsFilter };
		if (arith === "add") obj[type]++;
		if (
			arith === "sub" &&
			(type === "adults" || type === "rooms") &&
			obj[type] !== 1
		)
			obj[type]--;
		if (
			arith === "sub" &&
			(type === "childRange1" ||
				type === "childRange2" ||
				type === "childRange3") &&
			obj[type] !== 0
		)
			obj[type]--;
		if (arith === "sub" && type === "groupAdults" && obj[type] !== 4)
			obj[type]--;

		setGuestFilter(obj);
	};

	const guestsFilterApply = () => {
		/* let obj = { ...filters }
    obj['guests'] = guestsFilter */
		filters.guests.set(guestsFilter);
		setGuestsFilterShow(false);
	};
	//============== date range picker and rooms and guests filter end's here=============

	// ===========   Similar Rooms Code Start's Here =====================
	function handleBookNowClick(id: any) {
		const url = `/roomdetails?catId=${id}`;
		window.location.href = url;
	}

	const handleSimilarRooms = (place: any) => {
		let placed = place;
		if (placed === undefined) {
			placed = "pench";
		} else {
			placed = place;
		}
		axios.get(`${API_URL}/v1/user/similar_room?place=${placed}`).then((res) => {
			// console.log("res-----", res);

			setSimilarRoom(res.data.data);
		});
	};
	// ===========   Similar Rooms Code End's Here =====================

	// ------------------ handle Coupon Code Start's here ----------------
	const handleCoupon = () => {
		if (couponValue !== "") {
			axios
				.get(`${API_URL}/v1/user/coupon?coupon_code=${couponValue}`)
				.then((res: any) => {
					setCouponMsg(res.data.data.message);
					// console.log(res.data.data);
					// console.log(res.data.data.data.coupon_status);
					//Active
					if (res.data.data.data.coupon_status !== "Active") {
						setCouponMsg("Coupon expired");
						setCouponPrice(0);
						setTotalAmount(Math.ceil(taxedAmount - Number(0)));
					} else {
						if (res.data.data.message) {
							setCouponMsg(res.data.data.message);
							setCouponPrice(0);
							setTotalAmount(Math.ceil(taxedAmount - Number(0)));
						} else if (res.data.data.data) {
							if (res.data.data.data.coupone_discount_type === "Fixed") {
								setCouponPrice(Number(res.data.data.data.coupone_discount));
								setCouponType("Fixed");
								setTotalAmount(
									Math.ceil(
										taxedAmount - Number(res.data.data.data.coupone_discount)
									)
								);
							}
							if (res.data.data.data.coupone_discount_type === "Rate") {
								setCouponPrice(Number(res.data.data.data.coupone_discount));
								setCouponType("Percentage");
								setTotalAmount(
									Math.ceil(
										taxedAmount *
											(1 - Number(res.data.data.data.coupone_discount) / 100)
									)
								);
							}
							setCouponMsg("Coupon Applied Successfully");
						} else {
							setTotalAmount(Math.ceil(taxedAmount - Number(0)));
							setCouponPrice(0);
							setCouponMsg(res.data.data.message);
						}
					}
				});
		} else {
			setCouponPrice(0);
			setTotalAmount(Math.ceil(taxedAmount - Number(0)));
		}
	};
	// ------------------ handle Coupon Code End's here ------------------

	// ============= Tax And Commission Calculation Start's Here =============
	const getCommissionedPrice = (
		basePrice: number,
		pricingData: any,
		tdsFlag: any,
		roomCount: any
	) => {
		let commissionedPrice: any = basePrice;

		// setTdsBasePrice(basePrice/)

		// Find the appropriate pricing slab
		const pricingSlab = pricingData.find((data: any) => {
			const [min, max] = data.pricingSlab
				.split("-")
				.map((val: any) => Number(val));

			const valuedSlab = min <= commissionedPrice; // min price should be less than commissionedPrice = 2000 should be less than 3000 eg.
			// console.log(valuedSlab, min, commissionedPrice, "data of pricing slab")

			const valuedSlab2 = max >= commissionedPrice; // max price should be greater than commissionedPrice = 5000 should be greater than 3000 eg.
			// console.log(valuedSlab2, max, commissionedPrice, "data of pricing slab")

			const finalValue = valuedSlab && valuedSlab2;
			// console.log(finalValue, "is it true")
			return finalValue;
		});
		if (pricingSlab) {
			// Calculate the commission amount
			let commissionAmount: any = 0;
			// Add the fixed commission amount
			// commissionedPrice = Number(pricingSlab.fixedCommission) + commissionAmount;
			if (tdsFlag) {
				// commissionedPrice = Math.ceil(commissionAmount);
				commissionedPrice = commissionAmount.toFixed(2);
			} else {
				commissionedPrice = Math.ceil(
					Number(pricingSlab.fixedCommission) + commissionAmount
				);
			}

			if (pricingSlab.commissionRate > 0) {
				commissionAmount = (pricingSlab.commissionRate / 100) * basePrice;
			}
			if (pricingSlab.fixedCommission > 0) {
				// commissionAmount = (pricingSlab.commissionRate / 100) * basePrice;

				commissionedPrice =
					Number(pricingSlab.fixedCommission) + commissionAmount;
			}

			// console.log("TDSSSDS 1", );
			// console.log("TDSSSDS 5", commissionedPrice % 18);

			// console.log(Math.round((commissionedPrice * 18) / 100));

			// setCommisonGst(Math.round((commissionedPrice * 18) / 100));
			// if (tdsFlag) {
			// 	let tdsPercent = Number(pricingSlab.fixedCommission) / 100;
			// 	let tdsPriceOn = basePrice;

			// 	console.log("tds percent", tdsPercent);
			// 	console.log("tds tdsPriceOn", tdsPriceOn);

			// 	setTdsBase(tdsPriceOn * tdsPercent);
			// }
		}

		return commissionedPrice.toFixed(2);
	};
	const getCommissionedGst = (
		basePrice: number,
		pricingData: any,
		tdsFlag: any,
		roomCount: any
	) => {
		let commissionedPrice: any = basePrice;
		let gst: any = 0;

		// setTdsBasePrice(basePrice/)

		// Find the appropriate pricing slab
		const pricingSlab = pricingData.find((data: any) => {
			const [min, max] = data.pricingSlab
				.split("-")
				.map((val: any) => Number(val));

			const valuedSlab = min <= commissionedPrice; // min price should be less than commissionedPrice = 2000 should be less than 3000 eg.
			// console.log(valuedSlab, min, commissionedPrice, "data of pricing slab")

			const valuedSlab2 = max >= commissionedPrice; // max price should be greater than commissionedPrice = 5000 should be greater than 3000 eg.
			// console.log(valuedSlab2, max, commissionedPrice, "data of pricing slab")

			const finalValue = valuedSlab && valuedSlab2;
			// console.log(finalValue, "is it true")
			return finalValue;
		});
		if (pricingSlab) {
			// Calculate the commission amount
			const commissionAmount = (pricingSlab.commissionRate / 100) * basePrice;

			// console.log("New Gst Comminsion", commissionAmount);
			// Add the fixed commission amount
			// commissionedPrice = Number(pricingSlab.fixedCommission) + commissionAmount;
			if (tdsFlag) {
				// commissionedPrice = Math.ceil(commissionAmount);
				commissionedPrice = commissionAmount.toFixed(2);
			} else {
				commissionedPrice = Math.ceil(
					Number(pricingSlab.fixedCommission) + commissionAmount
				);
			}

			// console.log("TDSSSDS 1", );
			// console.log("TDSSSDS 5", commissionedPrice % 18);

			// gst = Math.round((commissionedPrice * 18) / 100);
			gst = commissionedPrice;
			setCommisonGst(gst);
			// setCommisonGst(Math.round((commissionedPrice * 18) / 100));
		}

		return gst;
	};
	const getCommissionedTds = (
		basePrice: number,
		pricingData: any,
		tdsFlag: any,
		roomCount: any
	) => {
		let commissionedPrice: any = basePrice;
		let commisionTds: any = 0;

		// setTdsBasePrice(basePrice/)
		// console.log("NEw Tds 2", commissionedPrice);

		// Find the appropriate pricing slab
		const pricingSlab = pricingData.find((data: any) => {
			const [min, max] = data.pricingSlab
				.split("-")
				.map((val: any) => Number(val));

			const valuedSlab = min <= commissionedPrice; // min price should be less than commissionedPrice = 2000 should be less than 3000 eg.
			// console.log(valuedSlab, min, commissionedPrice, "data of pricing slab")

			const valuedSlab2 = max >= commissionedPrice; // max price should be greater than commissionedPrice = 5000 should be greater than 3000 eg.
			// console.log(valuedSlab2, max, commissionedPrice, "data of pricing slab")

			const finalValue = valuedSlab && valuedSlab2;
			// console.log(finalValue, "is it true")
			return finalValue;
		});
		if (pricingSlab) {
			// Calculate the commission amount
			let commissionAmount: any = 0;
			if (pricingSlab.fixedCommission > 0) {
				commissionAmount = (pricingSlab.fixedCommission / 100) * basePrice;
			} else {
				commissionAmount = 0;
			}

			// Add the fixed commission amount
			// commissionedPrice = Number(pricingSlab.fixedCommission) + commissionAmount;
			if (tdsFlag) {
				// commissionedPrice = Math.ceil(commissionAmount);
				commissionedPrice = commissionAmount.toFixed(2);
			} else {
				commissionedPrice = Math.ceil(
					Number(pricingSlab.fixedCommission) + commissionAmount
				);
			}
			// console.log(" NEw tax slab", commissionedPrice);
			setTdsBase(commissionedPrice);
			// console.log("TDSSSDS 1", );
			// console.log("TDSSSDS 5", commissionedPrice % 18);

			// console.log(Math.round((commissionedPrice * 18) / 100));

			// setCommisonGst(Math.round((commissionedPrice * 18) / 100));

			// if (tdsFlag) {
			// 	let tdsPercent = Number(pricingSlab.fixedCommission) / 100;
			// 	let tdsPriceOn = basePrice;

			// 	console.log("tds percent", tdsPercent);
			// 	console.log("tds tdsPriceOn", tdsPriceOn);
			// 	commisionTds = tdsPriceOn * tdsPercent;
			// 	setTdsBase(tdsPriceOn * tdsPercent);
			// }
		}

		return commissionedPrice;
	};
	// ============= Tax And Commission Calculation End's Here ===============

	// ============= Tax Calculation & Coupon Calculation Function Start's here =================
	const handleTaxCouponCalculation = (
		RoomPricing: any,
		totalRoom: any,
		totalPrice: any
	) => {
		// ========== tax commsion calculate code  start's end s=====================
		axios
			.get(`${API_URL}/v1/admin/commission?page_no=1`)
			.then((response: any) => {
				const RoomPrice = Number(RoomPricing);
				const pricingData = response.data.data.pricing.map((data: any) => ({
					// key: index,
					pricingSlab: data.pricing_slab,
					commissionRate: Number(data.commission_rate),
					fixedCommission: Number(data.fixed_commission),
				}));
				const pricingTaxData = response.data.data.tax.map((data: any) => ({
					// key: index,
					pricingSlab: data.pricing_slab,
					commissionRate: Number(data.commission_rate),
					fixedCommission: Number(data.fixed_commission),
				}));

				const gst = getCommissionedGst(
					totalPrice,
					pricingTaxData,
					true,
					totalRoom
				);
				const tds = getCommissionedTds(
					// totalPrice,
					Number(gst) + Number(totalPrice),
					pricingTaxData,
					true,
					totalRoom
				);

				// console.log("Gst New", totalPrice);
				// console.log("Gst New actual", gst);
				// console.log(
				// 	"Tds New actual",
				// 	Number(gst) + Number(tds) + Number(totalPrice)
				// );

				const commissionedPrice: any = getCommissionedPrice(
					// RoomPrice,
					Number(gst) + Number(tds) + Number(totalPrice),
					pricingData,
					false,
					totalRoom
				);
				// console.log("commissionedPrice New ", commissionedPrice);

				// const taxationPrice: any = getCommissionedPrice(
				// 	RoomPrice + commissionedPrice,
				// 	pricingTaxData,
				// 	true,
				// 	totalRoom
				// );
				const taxationPrice: any = (Number(commissionedPrice) * 18) / 100;
				// console.log("New taxationPrice", taxationPrice.toFixed(2));
				const finalCommistion: any = Number(
					parseFloat(commissionedPrice) + parseFloat(taxationPrice)
				).toFixed(2);
				// console.log("New finalCommistion", finalCommistion);

				const finalHotelPrice: any = Number(
					parseFloat(finalCommistion) +
						parseFloat(gst) +
						parseFloat(tds) +
						parseFloat(totalPrice)
				).toFixed(2);
				// console.log("New finalHotelPrice", finalHotelPrice);

				// const gst = getCommissionedGst(
				// 	RoomPrice + commissionedPrice,
				// 	pricingTaxData,
				// 	true,
				// 	totalRoom
				// );
				// const tds = getCommissionedTds(
				// 	totalPrice,
				// 	pricingTaxData,
				// 	true,
				// 	totalRoom
				// );

				// console.log("tds", tds);
				// console.log("gst", gst);
				// console.log("--------------------------------------------");

				// console.log("commisonGst", commisonGst);
				// console.log("tdsBase", tdsBase);

				setCommissionedPrice(commissionedPrice);
				setTaxationPrice(taxationPrice);

				if (RoomPrice) {
					const FullPayableAmount: any = finalHotelPrice;

					// console.log("New FullPayableAmount", FullPayableAmount);

					setTaxedAmount(FullPayableAmount);
					setTotalAmount(FullPayableAmount);

					if (couponType && FullPayableAmount) {
						if (couponType === "Fixed") {
							return setTotalAmount(Math.ceil(FullPayableAmount - couponPrice));
						} else if (couponType === "Percentage") {
							return setTotalAmount(
								Math.ceil(FullPayableAmount * (1 - couponPrice / 100))
							);
						} else {
							return setTotalAmount(Math.ceil(FullPayableAmount));
						}
					}
				}
			});
	};
	// ============= Tax Calculation & Coupon Calculation Function End's here ===================

	const handleRoomData = () => {
		axios
			.get(`${API_URL}/v1/user/room_cat?category_id=${catId}`)
			.then((res) => {
				const data = res.data.data;
				if (data) {
					data.map((values: any) => {
						setRoomData(values);
						setPropertyId(values.property_id);
						setCategoryId(values._id);
						setPropertyPolicy(values.property_rules);
						setSimilarRoomCity(values?.property_location?.property_city);
						handleSimilarRooms(values?.property_location?.property_city);
						const GroupBookingAllowed =
							values.category_group_booking.group_booking_allowed;

						setGroupAllowed(GroupBookingAllowed);
						// totalDateCalculation;
						// ============ Calculating Days code Start's here ==================

						const startingDate = new Date(CheckInDate);
						const endingDate = new Date(CheckOutDate);

						const diffTime = Math.abs(
							endingDate.getTime() - startingDate.getTime()
						);
						const TotalDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

						// ============ Calculating Days code End's here ==================

						// if (coupleGroup && GroupBookingAllowed) {
						// 	// console.log(" Group code will be run");
						// 	// ==================== Couple Code Start's Here ===================
						// 	const groupAdults = Number(guestsFilter.groupAdults);
						// 	let groupChilds = Number(guestsFilter.childRange3);
						// 	let totalRooms = Number(guestsFilter.rooms);
						// 	const totalGuestsNumber = groupAdults + groupChilds;

						// 	// ================= Calculating base price & weekend Price =======================
						// 	let weekdayPrice = Number(
						// 		values?.category_group_booking?.base_price
						// 	);
						// 	let weekendPrice = Number(
						// 		values?.category_weekend_group_booking?.weekend_base_price
						// 	);

						// 	const countWeekendDays = (
						// 		startDate: Date,
						// 		endDate: Date
						// 	): number => {
						// 		let weekendDays = 0;
						// 		let currentDate = new Date(startDate);

						// 		while (currentDate <= endDate) {
						// 			const dayOfWeek = currentDate.getDay();
						// 			if (dayOfWeek === 6 || dayOfWeek === 0) {
						// 				weekendDays++;
						// 			}
						// 			currentDate.setDate(currentDate.getDate() + 1);
						// 		}
						// 		console.log("---------------weekendDays 0", weekendDays);

						// 		return weekendDays;
						// 	};
						// 	let hotelPrice = Number(
						// 		values?.category_group_booking?.base_price
						// 	); // this will be the base Price if weekend are selected or not
						// 	if (startingDate && endingDate) {
						// 		const days = Math.ceil(
						// 			(endingDate.getTime() - startingDate.getTime()) /
						// 				(1000 * 3600 * 24)
						// 		);
						// 		const weekendDays = countWeekendDays(startingDate, endingDate);
						// 		hotelPrice = weekendDays > 0 ? weekendPrice : weekdayPrice;
						// 		// console.log(`Number of days: ${days}\nNumber of weekend days: ${weekendDays}\nHotel price: ${hotelPrice}`);
						// 	}
						// 	// ================= Calculating base price & weekend Price =======================

						// 	// ============ Discount Percentage Code Start's here ===========================
						// 	const originalPrice = 1540 + Number(hotelPrice);
						// 	const discountedPrice = Number(hotelPrice);

						// 	const discount = originalPrice - discountedPrice;
						// 	const discountPercentage = Math.ceil(
						// 		(discount / originalPrice) * 100
						// 	);
						// 	setDiscountAmount(discountPercentage);
						// 	setRoomBasePrice(discountedPrice);
						// 	// ============ Discount Percentage Code End's here ===========================

						// 	const basePrice = hotelPrice;

						// 	const bookingCapacity = Number(
						// 		values?.category_group_booking?.booking_capacity
						// 	);
						// 	const perPersonCost = Number(
						// 		values?.category_group_booking?.per_person_cost
						// 	);
						// 	const freeChild = Number(
						// 		values?.category_group_booking?.no_cost_child
						// 	);

						// 	let totalFreeChild = 0;

						// 	if (groupChilds <= freeChild) {
						// 		totalFreeChild = 0;
						// 	} else if (groupChilds >= freeChild) {
						// 		totalFreeChild = groupChilds - freeChild;
						// 	} else {
						// 		groupChilds = 0;
						// 	}

						// 	let totalFreeGuests = groupAdults + totalFreeChild;

						// 	let totalValue = basePrice;

						// 	if (totalGuestsNumber <= bookingCapacity) {
						// 		totalValue = basePrice + totalFreeGuests * perPersonCost;
						// 		guestsFilter["rooms"] = 1;
						// 	} else if (totalGuestsNumber > bookingCapacity) {
						// 		totalRooms = Math.ceil(totalGuestsNumber / bookingCapacity);
						// 		totalValue =
						// 			basePrice * totalRooms + totalFreeGuests * perPersonCost;
						// 		guestsFilter["rooms"] = totalRooms;
						// 	}

						// 	setTotalRoomCharge(totalValue * TotalDays);
						// 	setCostPerHead((totalValue * TotalDays) / totalGuestsNumber);

						// 	const catPrice = totalValue * TotalDays;

						// 	handleTaxCouponCalculation(catPrice, totalRooms, totalValue);

						// 	// ==================== Group Code End's Here ======================
						// } else {
						// 	console.log(" Couple code will be run");
						// 	// ==================== Couple Code Start's Here ===================
						// 	const selectedAdults = Number(guestsFilter.adults);
						// 	const selectChild1 = Number(guestsFilter.childRange1);
						// 	const selectChild2 = Number(guestsFilter.childRange2);
						// 	let totalRooms = Number(guestsFilter.rooms);

						// 	const totalGuestsNumber =
						// 		selectedAdults + selectChild1 + selectChild2;

						// 	// ================= Calculating base price & weekend Price =======================
						// 	let weekdayPrice = Number(
						// 		values?.category_base_pricing?.base_price
						// 	);
						// 	let weekendPrice = Number(
						// 		values?.weekend_pricing?.base_weekend_price
						// 	);

						// 	const countWeekendDays = (
						// 		startDate: Date,
						// 		endDate: Date
						// 	): number => {
						// 		let weekendDays = 0;
						// 		let currentDate = new Date(startDate);

						// 		while (currentDate <= endDate) {
						// 			const dayOfWeek = currentDate.getDay();
						// 			if (dayOfWeek === 6 || dayOfWeek === 0) {
						// 				weekendDays++;
						// 			}
						// 			currentDate.setDate(currentDate.getDate() + 1);
						// 		}

						// 		return weekendDays;
						// 	};

						// 	let hotelPrice = Number(
						// 		values?.category_base_pricing?.base_price
						// 	); // this will be the base Price if weekend are selected or not

						// 	if (startingDate && endingDate) {
						// 		const days = Math.ceil(
						// 			(endingDate.getTime() - startingDate.getTime()) /
						// 				(1000 * 3600 * 24)
						// 		);
						// 		const weekendDays = countWeekendDays(startingDate, endingDate);
						// 		hotelPrice = weekendDays > 0 ? weekendPrice : weekdayPrice;
						// 		// console.log(`Number of days: ${days}\nNumber of weekend days: ${weekendDays}\nHotel price: ${hotelPrice}`);
						// 	}
						// 	// ================= Calculating base price & weekend Price =======================

						// 	// ============ Discount Percentage Code Start's here ===========================
						// 	const originalPrice = 1540 + Number(hotelPrice);
						// 	const discountedPrice = Number(hotelPrice);

						// 	const discount = originalPrice - discountedPrice;
						// 	const discountPercentage = Math.ceil(
						// 		(discount / originalPrice) * 100
						// 	);
						// 	setDiscountAmount(discountPercentage);
						// 	setRoomBasePrice(discountedPrice);
						// 	// ============ Discount Percentage Code End's here ===========================

						// 	const basePrice = hotelPrice;

						// 	const baseOccupancy = Number(
						// 		values?.category_base_pricing?.base_occupancy
						// 	);
						// 	const extraAdultAllowed = Number(
						// 		values?.category_base_pricing?.extra_adult
						// 	);
						// 	const extraAdultPrice = Number(
						// 		values?.category_base_pricing?.extra_adult_price
						// 	);
						// 	const extraChildAllowed = Number(
						// 		values?.category_base_pricing?.extra_child
						// 	);
						// 	const extraChild1Price = Number(
						// 		values?.category_base_pricing?.extra_child_price
						// 			.child_range_one_price
						// 	);
						// 	const extraChild2Price = Number(
						// 		values?.category_base_pricing?.extra_child_price
						// 			.child_range_two_price
						// 	);
						// 	const maxGuests = Number(
						// 		values?.category_base_pricing?.max_guests
						// 	);

						// 	let totalValue = basePrice;

						// 	if (
						// 		totalGuestsNumber <= maxGuests &&
						// 		selectedAdults > baseOccupancy
						// 	) {
						// 		totalValue =
						// 			basePrice +
						// 			(selectedAdults - baseOccupancy) * extraAdultPrice;
						// 		guestsFilter["rooms"] = 1;
						// 	} else if (totalGuestsNumber > maxGuests) {
						// 		totalRooms = Math.ceil(totalGuestsNumber / maxGuests);
						// 		totalValue = basePrice * totalRooms;

						// 		guestsFilter["rooms"] = totalRooms;
						// 	} else {
						// 		totalValue = basePrice;
						// 	}

						// 	const catPrice = totalValue * TotalDays;
						// 	console.log("Trace 1", totalValue * TotalDays);
						// 	console.log("Trace 2", catPrice, totalRooms);
						// 	handleTaxCouponCalculation(catPrice, totalRooms, totalValue);

						// 	setTotalRoomCharge(totalValue * TotalDays);

						// 	// ==================== Couple Code End's Here ===================
						// }
					});
				}

				const CheckinTime = data.map(
					(values: any) => values.property_policy.checkin_time
				);
				const dateIn = new Date(`2023-03-06T${CheckinTime}:00`);

				const formattedTimeIn = dateIn.toLocaleString("en-US", {
					hour: "numeric",
					minute: "numeric",
					hour12: true,
				});
				setFormatCheckIn(formattedTimeIn);

				const CheckoutTime = data.map(
					(values: any) => values.property_policy.checkout_time
				);
				const dateOut = new Date(`2023-03-06T${CheckoutTime}:00`);

				const formattedTimeOut = dateOut.toLocaleString("en-US", {
					hour: "numeric",
					minute: "numeric",
					hour12: true,
				});
				setFormatCheckOut(formattedTimeOut);
			});
	};

	// ================== Total Calculation Code Start's Here =========================
	const totalDateCalculation = (
		startDateStr: any,
		endDateStr: any,
		coupleGroup: any
	) => {
		// console.log("totalDateCalculation", startDateStr);

		// console.log("totalDateCalculation", roomData);

		if (roomData) {
			// ============ Calculating Days code Start's here ==================
			const startingDate = new Date(startDateStr);
			const endingDate = new Date(endDateStr);

			const diffTime = Math.abs(endingDate.getTime() - startingDate.getTime());
			const TotalDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
			// ============ Calculating Days code End's here ==================

			if (coupleGroup && groupAllowed) {
				// ==================== Couple Code Start's Here ===================
				const groupAdults = Number(guestsFilter.groupAdults);
				let groupChilds = Number(guestsFilter.childRange3);
				// let groupRooms = Number(guestsFilter.rooms);
				let totalRooms = Number(guestsFilter.rooms);

				const totalGuestsNumber = groupAdults + groupChilds;
				let weekendFalg: any = false;
				// ================= Calculating base price & weekend Price =======================
				let weekdayPrice = Number(roomData?.category_group_booking?.base_price);
				let weekendPrice = Number(
					roomData?.category_weekend_group_booking?.weekend_base_price
				);

				const countWeekendDays = (startDate: Date, endDate: Date): number => {
					let weekendDays = 0;
					let currentDate = new Date(startDate);

					while (currentDate <= endDate) {
						const dayOfWeek = currentDate.getDay();
						if (dayOfWeek === 6 || dayOfWeek === 0) {
							weekendDays++;
						}
						currentDate.setDate(currentDate.getDate() + 1);
					}

					return weekendDays;
				};
				const countWeekDays = (startDate: Date, endDate: Date): number => {
					// console.log("imp", startDate);
					// console.log("imp", endDate);
					// console.log("imp1", new Date(startDate));
					// console.log("imp2", new Date(endDate));

					let weekendDays = 0;
					let currentDate = new Date(startDate);

					while (currentDate <= endDate) {
						const dayOfWeek = currentDate.getDay();
						if (
							dayOfWeek === 1 ||
							dayOfWeek === 2 ||
							dayOfWeek === 3 ||
							dayOfWeek === 4 ||
							dayOfWeek === 5
						) {
							weekendDays++;
						}
						currentDate.setDate(currentDate.getDate() + 1);
					}

					return weekendDays;
				};
				let hotelPrice = Number(roomData?.category_group_booking?.base_price); // this will be the base Price if weekend are selected or not
				let newPrice: number = 0;
				if (startingDate && endingDate) {
					const days = Math.ceil(
						(endingDate.getTime() - startingDate.getTime()) / (1000 * 3600 * 24)
					);
					// const weekendDays = countWeekendDays(startingDate, endingDate);
					// const weekDays = countWeekDays(startingDate, endingDate);

					let lastDay: Date = new Date(
						endingDate.setDate(endingDate.getDate() - 1)
					);
					const weekendDays = countWeekendDays(startingDate, lastDay);
					const weekDays = countWeekDays(startingDate, lastDay);

					let newWeekendPrice: number = 0;
					let newWeekPrice: number = 0;
					if (weekDays > 0) {
						newWeekPrice = weekDays * weekdayPrice;
					}
					if (weekendDays > 0) {
						newWeekendPrice = weekendDays * weekendPrice;
					}

					if (weekendDays > 0 && weekDays > 0) {
						newPrice = newWeekendPrice + newWeekPrice;
					} else {
						newPrice = newWeekendPrice + newWeekPrice;
					}

					// if (weekendDays > 0) {
					// 	if (weekendDays > 1) {
					// 		newWeekendPrice = weekendPrice;
					// 	} else {
					// 		newWeekendPrice = weekendPrice;
					// 	}
					// }

					// if (weekDays > 0) {
					// 	if (weekDays > 1) {
					// 		newWeekPrice = weekdayPrice;
					// 	} else {
					// 		newWeekPrice = weekdayPrice;
					// 	}
					// }
					// if (weekendDays > 0 && weekDays > 0) {
					// 	weekendFalg = true;
					// 	newPrice = newWeekendPrice + newWeekPrice;
					// } else {
					// 	newPrice = newWeekendPrice + newWeekPrice;
					// }
					hotelPrice = weekendDays > 0 ? weekendPrice : weekdayPrice;
					// console.log(`Number of days: ${days}\nNumber of weekend days: ${weekendDays}\nHotel price: ${hotelPrice}`);
				}
				// ================= Calculating base price & weekend Price =======================

				// ============ Discount Percentage Code Start's here ===========================
				const originalPrice = 1540 + Number(hotelPrice);
				const discountedPrice = Number(hotelPrice);

				const discount = originalPrice - discountedPrice;
				const discountPercentage = Math.ceil((discount / originalPrice) * 100);
				setDiscountAmount(discountPercentage);
				setRoomBasePrice(discountedPrice);
				// ============ Discount Percentage Code End's here ===========================

				// const basePrice = hotelPrice;
				console.log("New base price", newPrice);
				const basePrice = newPrice;

				const bookingCapacity = Number(
					roomData?.category_group_booking?.booking_capacity
				);
				const perPersonCost = Number(
					roomData?.category_group_booking?.per_person_cost
				);
				const freeChild =
					Number(roomData?.category_group_booking?.no_cost_child) * totalRooms;

				let totalFreeChild = 0;

				if (groupChilds <= freeChild) {
					totalFreeChild = 0;
				} else if (groupChilds >= freeChild) {
					totalFreeChild = groupChilds - freeChild;
				} else {
					groupChilds = 0;
				}

				let totalFreeGuests = groupAdults + totalFreeChild;

				let totalValue = basePrice;
				let taxValuecCal: any = 0;

				if (totalGuestsNumber <= bookingCapacity) {
					let totalVal = totalFreeGuests * perPersonCost;
					console.log("totalVal", totalVal);

					// if (weekendFalg) {
					// 	totalValue = basePrice + TotalDays * totalVal;
					// } else {
					// 	totalValue = basePrice * TotalDays + totalVal * TotalDays;
					// }
					// totalValue = basePrice * TotalDays + totalVal * TotalDays;
					totalValue = basePrice + TotalDays * totalVal;
					guestsFilter["rooms"] = 1;

					setTotalRoomCharge(totalValue);
					setCostPerHead((totalValue * TotalDays) / totalGuestsNumber);
					taxValuecCal = totalValue;
				} else if (totalGuestsNumber > bookingCapacity) {
					totalRooms = Math.ceil(totalGuestsNumber / bookingCapacity);
					// if (totalFreeChild > 0) {
					// 	totalFreeChild = totalFreeChild * totalRooms;
					// }

					totalFreeGuests = groupAdults + totalFreeChild;
					// totalValue = basePrice * totalRooms + totalFreeGuests * perPersonCost;
					// console.log("basePrice", basePrice);

					// totalValue = basePrice * totalRooms + totalFreeGuests * perPersonCost;
					let totalVal = totalFreeGuests * perPersonCost;
					// console.log("totalVal", totalVal);
					// if (weekendFalg) {
					// 	totalValue = basePrice + totalRooms * totalVal;
					// } else {
					// 	totalValue = basePrice * totalRooms + totalVal * TotalDays;
					// }
					// totalValue = basePrice + TotalDays * totalVal;
					totalValue = basePrice * totalRooms + totalVal * TotalDays;

					guestsFilter["rooms"] = totalRooms;

					setTotalRoomCharge(totalValue);
					setCostPerHead(totalValue / totalGuestsNumber);
					taxValuecCal = totalValue;
				}
				// console.log("total value", taxValuecCal);

				// setTotalRoomCharge(totalValue * TotalDays);
				// setCostPerHead((totalValue * TotalDays) / totalGuestsNumber);

				const catPrice = totalValue * TotalDays;

				handleTaxCouponCalculation(
					catPrice,
					totalRooms,
					// totalValue * TotalDays
					// totalValue
					taxValuecCal
				);

				// ==================== Group Code End's Here ======================
			} else {
				console.log(" Couple code will be run");
				// ==================== Couple Code Start's Here ===================
				const selectedAdults = Number(guestsFilter.adults);
				const selectChild1 = Number(guestsFilter.childRange1);
				const selectChild2 = Number(guestsFilter.childRange2);
				let totalRooms = Number(guestsFilter.rooms);
				let totalChildrean = selectChild1 + selectChild2;

				const totalGuestsNumber = selectedAdults + selectChild1 + selectChild2;

				// ================= Calculating base price & weekend Price =======================
				let weekdayPrice = Number(roomData?.category_base_pricing?.base_price);
				let weekendPrice = Number(
					roomData?.weekend_pricing?.base_weekend_price
				);

				const countWeekendDays = (startDate: Date, endDate: Date): number => {
					let weekendDays = 0;
					let currentDate = new Date(startDate);

					while (currentDate <= endDate) {
						const dayOfWeek = currentDate.getDay();
						if (dayOfWeek === 6 || dayOfWeek === 0) {
							weekendDays++;
						}
						currentDate.setDate(currentDate.getDate() + 1);
					}

					return weekendDays;
				};
				const countWeekDays = (startDate: Date, endDate: Date): number => {
					let weekendDays = 0;
					let currentDate = new Date(startDate);

					while (currentDate <= endDate) {
						const dayOfWeek = currentDate.getDay();
						if (
							dayOfWeek === 1 ||
							dayOfWeek === 2 ||
							dayOfWeek === 3 ||
							dayOfWeek === 4 ||
							dayOfWeek === 5
						) {
							weekendDays++;
						}
						currentDate.setDate(currentDate.getDate() + 1);
					}

					return weekendDays;
				};
				let isWeekend: boolean = false;
				const checkWeekendDay = (date: Date): boolean => {
					let today = new Date(date);

					if (today.getDay() == 6 || today.getDay() == 0) {
						return true;
					} else {
						return false;
					}
				};

				isWeekend = checkWeekendDay(startingDate);
				let hotelPrice = Number(roomData?.category_base_pricing?.base_price); // this will be the base Price if weekend are selected or not
				let newPrice: number = 0;
				if (startingDate && endingDate) {
					const days = Math.ceil(
						(endingDate.getTime() - startingDate.getTime()) / (1000 * 3600 * 24)
					);
					// const weekendDays = countWeekendDays(startingDate, endingDate);
					// const weekDays = countWeekDays(startingDate, endingDate);
					// if(weekendDays>0){
					// 	hotelPrice = weekendPrice : weekdayPrice;
					// }
					let lastDay: Date = new Date(
						endingDate.setDate(endingDate.getDate() - 1)
					);

					// console.log("my selected day", day.getDate());
					const weekendDays = countWeekendDays(startingDate, lastDay);
					const weekDays = countWeekDays(startingDate, lastDay);
					let newWeekendPrice: number = 0;
					let newWeekPrice: number = 0;

					if (weekDays > 0) {
						newWeekPrice = weekDays * weekdayPrice;
					}
					if (weekendDays > 0) {
						newWeekendPrice = weekendDays * weekendPrice;
					}

					if (weekendDays > 0 && weekDays > 0) {
						newPrice = newWeekendPrice + newWeekPrice;
					} else {
						newPrice = newWeekendPrice + newWeekPrice;
					}

					hotelPrice = weekendDays > 0 ? weekendPrice : weekdayPrice;

					// console.log("---------------trace 02", weekDays);

					// console.log(`Number of days: ${days}\nNumber of weekend days: ${weekendDays}\nHotel price: ${hotelPrice}`);
				}
				// ================= Calculating base price & weekend Price =======================

				// ============ Discount Percentage Code Start's here ===========================
				const originalPrice = 1540 + Number(hotelPrice);
				const discountedPrice = Number(hotelPrice);

				const discount = originalPrice - discountedPrice;
				const discountPercentage = Math.ceil((discount / originalPrice) * 100);
				setDiscountAmount(discountPercentage);
				setRoomBasePrice(discountedPrice);
				// ============ Discount Percentage Code End's here ===========================

				// const basePrice = hotelPrice;
				// console.log("new price ", newPrice);
				const basePrice = newPrice;

				const baseOccupancy = Number(
					roomData?.category_base_pricing?.base_occupancy
				);
				const extraAdultAllowed = Number(
					roomData?.category_base_pricing?.extra_adult
				);

				const extraAdultPrice = Number(
					roomData?.category_base_pricing?.extra_adult_price
				);
				const extraChildAllowed = Number(
					roomData?.category_base_pricing?.extra_child
				);
				const extraChild1Price = Number(
					roomData?.category_base_pricing?.extra_child_price
						.child_range_one_price
				);
				const extraChild2Price = Number(
					roomData?.category_base_pricing?.extra_child_price
						.child_range_two_price
				);
				const maxGuests = Number(roomData?.category_base_pricing?.max_guests);

				let totalValue = basePrice;

				if (totalGuestsNumber <= maxGuests) {
					let remaingGuest = totalGuestsNumber - baseOccupancy; //1 remaing

					// if (selectedAdults > baseOccupancy) {
					// 	adultCount = selectedAdults - baseOccupancy; //1 adult
					// }

					let roomWise = baseOccupancy * totalRooms; //1 is remaining
					let adultCount = 0;
					let childCount = 0;
					if (selectedAdults > roomWise) {
						//adult
						adultCount = selectedAdults - roomWise; //1 adult
					}

					//children
					if (adultCount > 0) {
						childCount = remaingGuest - adultCount;
					} else {
						if (totalChildrean >= remaingGuest) {
							if (remaingGuest <= totalChildrean) {
								childCount = remaingGuest;
							} else {
								childCount = totalChildrean - roomWise; //1 adult
							}
						} else {
							childCount = totalChildrean;
						}
					}
					let childCount1 = 0;
					let childCount2 = 0;
					if (childCount > 0) {
						if (selectChild1 > 0) {
							if (selectChild2 > 0) {
								if (selectChild1 > childCount) {
									childCount2 = selectChild1 - childCount;
								} else {
									childCount2 = childCount - selectChild1;
								}
								if (selectChild1 > 0 && selectChild2 > 0) {
									if (childCount2 > childCount) {
										childCount1 = childCount2 - childCount;
									} else {
										childCount1 = childCount - childCount2;
									}
								}
							} else {
								childCount1 = childCount;
							}
						} else {
							childCount2 = childCount;
						}
					}

					totalValue =
						basePrice * totalRooms +
						adultCount * extraAdultPrice +
						childCount1 * extraChild1Price +
						childCount2 * extraChild2Price;

					// if (selectedAdults > baseOccupancy) {
					// 	AdultPrice = (selectedAdults - baseOccupancy) * extraAdultPrice;
					// } else {
					// 	AdultPrice = (baseOccupancy - selectedAdults) * extraAdultPrice;
					// }

					guestsFilter["rooms"] = totalRooms;
					let extraRoom = baseOccupancy * totalRooms - totalGuestsNumber;
					if (extraRoom === 0) {
						// const selectedAdults = Number(guestsFilter.adults);
						// const selectChild1 = Number(guestsFilter.childRange1);
						// const selectChild2 = Number(guestsFilter.childRange2);
						// let totalRooms = Number(guestsFilter.rooms);
						// let totalChildrean = selectChild1 + selectChild2;

						let baseOccupancyAllowd = baseOccupancy;
						let totalAdultRoomAllowed = baseOccupancy + extraAdultAllowed;
						let totalChildRoom = baseOccupancy + extraChildAllowed;

						if (selectedAdults > totalAdultRoomAllowed) {
							totalRooms = Math.ceil(selectedAdults / totalAdultRoomAllowed);
							// console.log("total Rooms no extraRoom", totalRooms);
						}

						totalValue = basePrice * totalRooms;
					} else {
						let baseOccupancyAllowd = baseOccupancy;
						let totalAdultRoomAllowed = baseOccupancy + extraAdultAllowed;
						let totalChildRoom = baseOccupancy + extraChildAllowed;

						if (selectedAdults > totalAdultRoomAllowed) {
							totalRooms = Math.ceil(selectedAdults / totalAdultRoomAllowed);
							// console.log("total Rooms no extraRoom", totalRooms);
							guestsFilter["rooms"] = totalRooms;
							totalValue = basePrice * totalRooms;
						} else {
							totalValue = totalValue;
						}
						// totalValue = basePrice * totalRooms;;
					}
				} else if (totalGuestsNumber > maxGuests) {
					// console.log("else if");
					//extraAdultAllowed
					//extraChildAllowed
					let originalSelectedTotlaroom = totalRooms;
					if (totalGuestsNumber <= baseOccupancy * totalRooms) {
						// console.log("total Rooms 1", totalRooms);

						totalValue = basePrice * totalRooms;

						guestsFilter["rooms"] = totalRooms;
					} else {
						if (totalGuestsNumber >= maxGuests * totalRooms) {
							totalRooms = Math.ceil(totalGuestsNumber / maxGuests);
							// console.log("total Rooms 2", totalRooms);
						}

						let remaingGuest = totalGuestsNumber - baseOccupancy * totalRooms; //1 remaing

						let roomWise = baseOccupancy * totalRooms; //1 is remaining
						let adultCount = 0;
						let childCount = 0;
						if (selectedAdults > roomWise) {
							//adult
							adultCount = selectedAdults - roomWise; //1 adult
						}

						//children
						if (adultCount > 0) {
							childCount = remaingGuest - adultCount;
						} else {
							if (totalChildrean >= remaingGuest) {
								if (remaingGuest <= totalChildrean) {
									childCount = remaingGuest;
								} else {
									childCount = totalChildrean - roomWise; //1 adult
								}
							} else {
								childCount = totalChildrean;
							}
						}
						let childCount1 = 0;
						let childCount2 = 0;
						if (childCount > 0) {
							if (selectChild1 > 0) {
								if (selectChild2 > 0) {
									if (selectChild1 > childCount) {
										if (selectChild1 > selectChild2) {
											childCount1 = selectChild1 - childCount;
										} else {
											childCount2 = selectChild1 - childCount;
										}
										// childCount2 = selectChild1 - childCount;
									} else {
										childCount2 = childCount - selectChild1;
									}
									if (selectChild1 > 0 && selectChild2 > 0) {
										if (childCount2 > childCount) {
											childCount1 = childCount2 - childCount;
										} else {
											if (childCount1 > 0) {
												if (childCount1 > childCount) {
													childCount2 = childCount1 - childCount;
												} else {
													childCount2 = childCount - childCount1;
												}
											} else {
												childCount1 = childCount - childCount2;
											}
											// childCount1 = childCount - childCount2;
										}
									}
								} else {
									childCount1 = childCount;
								}
							} else {
								childCount2 = childCount;
							}
						}

						totalValue =
							basePrice * totalRooms +
							// basePrice +
							adultCount * extraAdultPrice +
							childCount1 * extraChild1Price +
							childCount2 * extraChild2Price;

						guestsFilter["rooms"] = totalRooms;

						//new code of adult and Child Roomwise

						let extraRoom =
							baseOccupancy * originalSelectedTotlaroom - totalGuestsNumber;
						// if (extraRoom === 0) {
						// 	// const selectedAdults = Number(guestsFilter.adults);
						// 	// const selectChild1 = Number(guestsFilter.childRange1);
						// 	// const selectChild2 = Number(guestsFilter.childRange2);
						// 	// let totalRooms = Number(guestsFilter.rooms);
						// 	// let totalChildrean = selectChild1 + selectChild2;

						// 	let baseOccupancyAllowd = baseOccupancy;
						// 	let totalAdultRoomAllowed = baseOccupancy + extraAdultAllowed;
						// 	let totalChildRoom = baseOccupancy + extraChildAllowed;

						// 	if (selectedAdults > totalAdultRoomAllowed) {
						// 		totalRooms = Math.ceil(selectedAdults / totalAdultRoomAllowed);
						// 		console.log("total Rooms no extraRoom", totalRooms);
						// 	}

						// 	totalValue = basePrice * totalRooms;
						// } else {
						// 	let baseOccupancyAllowd = baseOccupancy;
						// 	let totalAdultRoomAllowed = baseOccupancy + extraAdultAllowed;
						// 	let totalChildRoom = baseOccupancy + extraChildAllowed;

						// 	if (selectedAdults > totalAdultRoomAllowed) {
						// 		totalRooms = Math.ceil(selectedAdults / totalAdultRoomAllowed);
						// 		console.log("total Rooms no extraRoom", totalRooms);
						// 		guestsFilter["rooms"] = totalRooms;
						// 		totalValue = basePrice * totalRooms;
						// 	} else {
						// 		totalValue = totalValue;
						// 	}
						// 	// totalValue = basePrice * totalRooms;;
						// }
						//new code ends here
					}
				} else {
					// console.log("else if");

					totalValue = basePrice * totalRooms;
				}

				// setTotalRoomCharge(totalValue * TotalDays);
				setTotalRoomCharge(totalValue);

				const catPrice = totalValue * TotalDays;
				// console.log("Total Days ", totalValue);
				handleTaxCouponCalculation(catPrice, totalRooms, totalValue);
				// setTdsBasePrice(basePrice * totalRooms * basePrice);
				// console.log("TDS tdsBase ", tdsBase);

				// console.log("TDS tdsBase ", (basePrice * totalRooms) / tdsBase);

				// ==================== Couple Code Ends's Here ====================
			}
		} else {
			// console.log("roomdata is empty");
			console.log("");
		}
	};

	useEffect(() => {
		if (roomData) {
			// console.log("useEffect In", coupleGroup);

			const startDate: Date = new Date(dateRange[0]?.startDate);
			const endDate: Date = new Date(dateRange[0]?.endDate);
			let tempDate = startDate
				.toLocaleString("en-US", {
					timeZone: process.env.LOCAL_TZ,
					hour12: false,
				})
				.split(",")[0]
				.split("/");

			let startDateStr =
				tempDate[2] +
				"-" +
				(parseInt(tempDate[0]) < 10 ? "0" : "") +
				tempDate[0] +
				"-" +
				(parseInt(tempDate[1]) < 10 ? "0" : "") +
				tempDate[1];

			tempDate = endDate
				.toLocaleString("en-US", {
					timeZone: process.env.LOCAL_TZ,
					hour12: false,
				})
				.split(",")[0]
				.split("/");

			let endDateStr: any =
				tempDate[2] +
				"-" +
				(parseInt(tempDate[0]) < 10 ? "0" : "") +
				tempDate[0] +
				"-" +
				(parseInt(tempDate[1]) < 10 ? "0" : "") +
				tempDate[1];

			if (startDateStr === endDateStr) {
				let FirstDate = new Date(startDateStr);

				let nextDay = new Date(startDateStr);
				nextDay.setDate(nextDay.getDate() + 1);
				let SecondDate = nextDay;

				const dateString = FirstDate.toString();
				const checkInDay = dateString.slice(8, 10);
				const checkInMonth = dateString.slice(4, 7);
				const checkInYear = dateString.slice(11, 15);

				const DateStringEnd = SecondDate.toString();
				const checkOutDay = DateStringEnd.slice(8, 10);
				const checkOutMonth = DateStringEnd.slice(4, 7);
				const checkOutYear = DateStringEnd.slice(11, 15);

				let CheckInDate = checkInDay + " " + checkInMonth + " " + checkInYear;
				let CheckOutDate =
					checkOutDay + " " + checkOutMonth + " " + checkOutYear;

				endDateStr = CheckOutDate;
				setCheckedInDate(CheckInDate);
				setCheckedOutDate(CheckOutDate);
			} else {
				let FirstDate = new Date(startDateStr);
				let SecondDate = new Date(endDateStr);

				const dateString = FirstDate.toString();
				const checkInDay = dateString.slice(8, 10);
				const checkInMonth = dateString.slice(4, 7);
				const checkInYear = dateString.slice(11, 15);

				const DateStringEnd = SecondDate.toString();
				const checkOutDay = DateStringEnd.slice(8, 10);
				const checkOutMonth = DateStringEnd.slice(4, 7);
				const checkOutYear = DateStringEnd.slice(11, 15);

				let CheckInDate = checkInDay + " " + checkInMonth + " " + checkInYear;
				let CheckOutDate =
					checkOutDay + " " + checkOutMonth + " " + checkOutYear;
				setCheckedInDate(CheckInDate);
				setCheckedOutDate(CheckOutDate);

				startDateStr !== endDateStr && setOpenCalendar(false);
			}
			totalDateCalculation(startDateStr, endDateStr, coupleGroup);
		}
		// let FirstDate = new Date(dateRange[0]?.startDate);
		// let SecondDate = new Date(dateRange[0]?.endDate);

		// totalDateCalculation();
	}, [roomData, coupleGroup]);
	// ================== Total Calculation Code End's Here =========================

	// ======================= Date Range Picker Code Start's here =====================
	// const handleDateChange = (item: any) => {
	// 	setDateRange([item.selection]);
	// 	const startDate: Date = item.selection.startDate as Date;
	// 	const endDate: Date = item.selection.endDate as Date;

	// 	let tempDate = startDate
	// 		.toLocaleString("en-US", {
	// 			timeZone: process.env.LOCAL_TZ,
	// 			hour12: false,
	// 		})
	// 		.split(",")[0]
	// 		.split("/");

	// 	let startDateStr =
	// 		tempDate[2] +
	// 		"-" +
	// 		(parseInt(tempDate[0]) < 10 ? "0" : "") +
	// 		tempDate[0] +
	// 		"-" +
	// 		(parseInt(tempDate[1]) < 10 ? "0" : "") +
	// 		tempDate[1];

	// 	tempDate = endDate
	// 		.toLocaleString("en-US", {
	// 			timeZone: process.env.LOCAL_TZ,
	// 			hour12: false,
	// 		})
	// 		.split(",")[0]
	// 		.split("/");

	// 	let endDateStr: any =
	// 		tempDate[2] +
	// 		"-" +
	// 		(parseInt(tempDate[0]) < 10 ? "0" : "") +
	// 		tempDate[0] +
	// 		"-" +
	// 		(parseInt(tempDate[1]) < 10 ? "0" : "") +
	// 		tempDate[1];

	// 	let FirstDate = new Date(startDateStr);
	// 	let SecondDate = new Date(endDateStr);

	// 	const dateString = FirstDate.toString();
	// 	const checkInDay = dateString.slice(8, 10);
	// 	const checkInMonth = dateString.slice(4, 7);
	// 	const checkInYear = dateString.slice(11, 15);

	// 	const DateStringEnd = SecondDate.toString();
	// 	const checkOutDay = DateStringEnd.slice(8, 10);
	// 	const checkOutMonth = DateStringEnd.slice(4, 7);
	// 	const checkOutYear = DateStringEnd.slice(11, 15);

	// 	let CheckInDate = checkInDay + " " + checkInMonth + " " + checkInYear;
	// 	let CheckOutDate = checkOutDay + " " + checkOutMonth + " " + checkOutYear;

	// 	filters.checkIn.set(startDateStr);
	// 	filters.checkOut.set(endDateStr);
	// 	setCheckedInDate(CheckInDate);
	// 	setCheckedOutDate(CheckOutDate);

	// 	totalDateCalculation(CheckInDate, CheckOutDate, coupleGroup);
	// };
	const handleDateChange = (item: any) => {
		setDateRange([item.selection]);
		const startDate: Date = item.selection.startDate as Date;
		const endDate: Date = item.selection.endDate as Date;

		let tempDate = startDate
			.toLocaleString("en-US", {
				timeZone: process.env.LOCAL_TZ,
				hour12: false,
			})
			.split(",")[0]
			.split("/");

		let startDateStr =
			tempDate[2] +
			"-" +
			(parseInt(tempDate[0]) < 10 ? "0" : "") +
			tempDate[0] +
			"-" +
			(parseInt(tempDate[1]) < 10 ? "0" : "") +
			tempDate[1];

		tempDate = endDate
			.toLocaleString("en-US", {
				timeZone: process.env.LOCAL_TZ,
				hour12: false,
			})
			.split(",")[0]
			.split("/");

		let endDateStr: any =
			tempDate[2] +
			"-" +
			(parseInt(tempDate[0]) < 10 ? "0" : "") +
			tempDate[0] +
			"-" +
			(parseInt(tempDate[1]) < 10 ? "0" : "") +
			tempDate[1];

		if (startDateStr === endDateStr) {
			let FirstDate = new Date(startDateStr);

			let nextDay = new Date(startDateStr);
			nextDay.setDate(nextDay.getDate() + 1);
			let SecondDate = nextDay;

			const dateString = FirstDate.toString();
			const checkInDay = dateString.slice(8, 10);
			const checkInMonth = dateString.slice(4, 7);
			const checkInYear = dateString.slice(11, 15);

			const DateStringEnd = SecondDate.toString();
			const checkOutDay = DateStringEnd.slice(8, 10);
			const checkOutMonth = DateStringEnd.slice(4, 7);
			const checkOutYear = DateStringEnd.slice(11, 15);

			let CheckInDate = checkInDay + " " + checkInMonth + " " + checkInYear;
			let CheckOutDate = checkOutDay + " " + checkOutMonth + " " + checkOutYear;

			endDateStr = CheckOutDate;

			filters.checkIn.set(startDateStr);
			filters.checkOut.set(endDateStr);

			setCheckedInDate(CheckInDate);
			setCheckedOutDate(CheckOutDate);
		} else {
			let FirstDate = new Date(startDateStr);
			let SecondDate = new Date(endDateStr);

			const dateString = FirstDate.toString();
			const checkInDay = dateString.slice(8, 10);
			const checkInMonth = dateString.slice(4, 7);
			const checkInYear = dateString.slice(11, 15);

			const DateStringEnd = SecondDate.toString();
			const checkOutDay = DateStringEnd.slice(8, 10);
			const checkOutMonth = DateStringEnd.slice(4, 7);
			const checkOutYear = DateStringEnd.slice(11, 15);

			let CheckInDate = checkInDay + " " + checkInMonth + " " + checkInYear;
			let CheckOutDate = checkOutDay + " " + checkOutMonth + " " + checkOutYear;

			filters.checkIn.set(startDateStr);
			filters.checkOut.set(endDateStr);

			setCheckedInDate(CheckInDate);
			setCheckedOutDate(CheckOutDate);
			startDateStr !== endDateStr && setOpenCalendar(false);
		}

		totalDateCalculation(startDateStr, endDateStr, coupleGroup);
	};
	// ======================= Date Range Picker Code End's here =====================

	//  couple group switch button code
	const handleChecked = () => {
		setCoupleGroup(!coupleGroup);
	};
	//  couple group switch button code

	const handleBookNow = () => {
		const UserId = localStorage.getItem("UserId") || "empty";
		if (UserId === "empty") {
			handleShow();
		} else {
			const sendData = () => {
				/*	Number(
					parseFloat(finalCommistion) +
						parseFloat(gst) +
						parseFloat(tds) +
						parseFloat(totalPrice)
				).toFixed(2);*/
				const TaxFeesAddition: any = Number(
					Number(commissionedPrice) +
						Number(taxationPrice) +
						Number(commisonGst) +
						Number(tdsBase)
				).toFixed(2);
				// const TaxFeesAddition = commissionedPrice + taxationPrice;
				let TaxFees = TaxFeesAddition;

				let CostPerHead = JSON.stringify(Math.ceil(costPerHead));
				let finalAmount: any = totalAmount;
				const commission: any = commissionedPrice;

				sessionStorage.setItem("PerPersonCost", CostPerHead);
				sessionStorage.setItem("TotalRoomCharge", totalRoomCharge);
				sessionStorage.setItem("TaxFees", TaxFees);
				sessionStorage.setItem("CouponPrice", couponPrice);
				sessionStorage.setItem("CouponType", couponType);
				sessionStorage.setItem("TotalPrice", finalAmount);
				sessionStorage.setItem("Commission", commission);

				// console.log("Trace1", checkInDate);
				// console.log("Trace1", checkedInDate);
				// console.log("Trace2", checkOutDate);
				// console.log("Trace2", checkedOutDate);

				let CheckInDateString =
					checkedInDate === "" ? checkInDate : checkedInDate;
				let CheckOutDateString =
					checkedOutDate === "" ? checkOutDate : checkedOutDate;

				const url = `/confirmation?catName=${
					roomData?.category_basic_info?.name
				}&catId=${catId}&propName=${roomData?.property_name}&propId=${
					roomData?.property_id
				}&vendorId=${roomData?.vendor_id}&rooms=${guestsFilter.rooms}&adults=${
					guestsFilter.adults
				}&childRange1=${guestsFilter.childRange1}&childRange2=${
					guestsFilter.childRange2
				}&groupAdults=${guestsFilter.groupAdults}&childRange3=${
					guestsFilter.childRange3
				}&coupleGroupVal=${coupleGroup}&checkIn=${new Date(
					CheckInDateString
				)}&checkOut=${new Date(CheckOutDateString)}`;
				// if (url) {
				// 	return;
				// }
				window.location.href = url;
			};
			sendData();
		}
	};

	// =============== Modal Code Start's here ================

	const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
		useFormik({
			initialValues: initialValues,
			validationSchema: signUpSchema,

			onSubmit: (values, action) => {
				axios
					.post(`${API_URL}/v1/customer/register_customer`, {
						mobile: values.number,
					})
					.then((res) => {
						// console.log(res.data, values.number, res.data.data);
						setResMsg(
							"We’ve sent an OTP to the mobile number. Please enter it to complete verification"
						);
						setResStatus(res.data.status);
						setUserNum(values.number);
						setUserId(res.data.data);
						// console.log(res.data.data, "userid")
					});
				// action.resetForm()   //to clear the user info after submitting the form
			},
		});

	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);

	const handleOtpVerify = () => {
		const data = {
			mobile: userNum,
			otp: verifyOtp,
		};
		axios.post(`${API_URL}/v1/customer/verify_customer`, data).then((res) => {
			setResMsg(res.data.message);
			if (res.data.status === "Success") {
				res.data.data.map((value: any) => {
					return (
						value.firstname
							? localStorage.setItem("UserFirstname", value.firstname)
							: "",
						value.lastname
							? localStorage.setItem("UserLastname", value.lastname)
							: "",
						value.email ? localStorage.setItem("UserEmail", value.email) : ""
					);
				});
			}

			// console.log(userId, "userId");
			if (res.data.status === "Success") {
				localStorage.setItem("UserNum", userNum);
				localStorage.setItem("UserId", userId);
				setTimeout(handleClose, 1500);
			}
		});
	};

	const handleResendOtp = () => {
		setResMsg("");
		axios
			.post(`${API_URL}/v1/customer/register_customer`, { mobile: userNum })
			.then((res) => {
				// console.log(res, "resend otp button", res.data.message);
				setResMsg(res.data.message);
			});
	};
	// ================ Only Number can Type IN Input Field ==============
	const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
		// Allow only numbers and backspace
		if (!/^\d$/.test(e.key) && e.key !== "Backspace") {
			e.preventDefault();
		}
	};
	// ================ Only Number can Type IN Input Field ==============
	// =============== Modal Code End's here ================

	useEffect(() => {
		handleRoomData();
	}, []);

	useEffect(() => {
		if (window.innerWidth < 550) {
			window.scrollTo({ top: 140, behavior: "smooth" });
		} else if (window.innerWidth > 700 && window.innerWidth < 1200) {
			window.scrollTo({ top: 280, behavior: "smooth" });
		} else if (window.innerWidth >= 1200) {
			window.scrollTo({ top: 410, behavior: "smooth" });
		}
	}, []);
	// useEffect(() => {
	// 	console.log("trace ---1", checkInDate);
	// 	setCheckedInDate(checkedInDate);
	// 	setCheckedOutDate(checkedOutDate);
	// }, [checkedInDate, checkedOutDate]);

	const [isMobile, setIsMobile] = useState(false);

	//choose the screen size
	const handleResize = () => {
		if (window.innerWidth < 720) {
			setIsMobile(true);
		} else {
			setIsMobile(false);
		}
	};

	// create an event listener
	useEffect(() => {
		window.addEventListener("resize", handleResize);
	});

	return (
		<>
			<div style={{ overflow: "hidden" }}>
				<UserHeader />

				<div className="room-detail-container resort-details-page room-details-single-wrap fix-top-nav-padd">
					<div className="room-details-banner">
						<img
							src={toAbsoluteUrl("/media/img/banner-2.jpg")}
							alt="MyResorts.in"
						/>
						<div className="room-details-banner-info">
							<h1>Rooms</h1>
							<h5>
								Discover our luxurious rooms with stunning views and premium
								amenities. From cozy suites to spacious villas, book now for an
								unforgettable getaway!
							</h5>
						</div>
						<div className="overlay-hy">
							<img
								src={toAbsoluteUrl("/media/img/tree-shape.png")}
								alt="MyResorts.in"
							/>
						</div>
					</div>

					<div className="container">
						<Row>
							<Col lg="12">
								<div className="breadcum-wrapper">
									<div className="breadcum">
										<ul>
											<li>
												<a href="home">Home</a>
											</li>
											<li>
												<a href="rooms">Resorts / Rooms</a>
											</li>
											<li>
												<a href="roomdetails">Rooms</a>
											</li>
										</ul>
									</div>
								</div>
							</Col>
						</Row>
					</div>

					<div className="container">
						<div className="rd-room-with-view" style={{ marginTop: "2em" }}>
							<Row>
								<Col lg="12">
									<Row>
										<Col lg="12">
											<div className="room-with-view-container">
												<div className="rd-room-with-view-title ">
													<Link
														to={{
															pathname: "/resortdetails",
															search: `propertyId=${roomData?.property_id}`,
														}}
													>
														{isMobile ? (
															<div
																style={{
																	display: "flex",
																	flexDirection: "column",
																	flexWrap: "wrap",
																}}
															>
																<h2
																	style={{
																		fontWeight: 400,
																		color: "#e63148;",
																		fontSize: "14px",
																	}}
																>
																	{roomData?.category_basic_info?.name} - {" "}
																</h2>
																<h2
																	style={{
																		fontWeight: 400,
																		color: "#e63148",
																		fontSize: "14px",
																	}}
																>
																	&nbsp;{roomData?.property_name}
																</h2>
															</div>
														) : (
															<div
																style={{
																	display: "flex",
																	flexDirection: "row",
																}}
															>
																<h2
																	style={{
																		fontWeight: 600,
																		color: "#e63148;",
																		fontSize: "29px",
																	}}
																>
																	{roomData?.category_basic_info?.name} - {" "}
																</h2>
																<h2
																	style={{
																		fontWeight: 600,
																		color: "#e63148",
																		fontSize: "28px",
																	}}
																>
																	&nbsp;{roomData?.property_name}
																</h2>
															</div>
														)}
													</Link>
												</div>
												{/* <div className="rd-room-with-view-title d-block d-sm-none">
													<Link
														to={{
															pathname: "/resortdetails",
															search: `propertyId=${roomData?.property_id}`,
														}}
													>
														<div
															style={{
																display: "flex",
																flexDirection: "row",
																flexWrap: "wrap",
															}}
														>
															<h2
																style={{
																	fontWeight: 400,
																	color: "#e63148;",
																	fontSize: "18px",
																}}
															>
																{roomData?.category_basic_info?.name} -{" "}
															</h2>
															<h2
																style={{
																	fontWeight: 400,
																	color: "#e63148",
																	fontSize: "18px",
																}}
															>
																{roomData?.property_name}
															</h2>
														</div>
														</Link>
												</div> */}
												<div className="rd-room-address">
													<div className="address-section">
														<h6>
															{" "}
															{
																roomData?.property_location
																	?.property_nearest_gate
															}
															, {roomData?.property_location?.property_address},{" "}
															{roomData?.property_location?.property_locality}{" "}
														</h6>
														<h6>
															{" "}
															{roomData?.property_location?.property_city}{" "}
															{roomData?.property_location?.property_zip_code}
														</h6>
													</div>

													<div className="ame">
														{roomData.category_amenities !== "" ? (
															roomData?.category_amenities?.travel_moods
																.length !== 0 ? (
																roomData?.category_amenities?.travel_moods.map(
																	(value: any, index: any) => {
																		let convertedTextName = value
																			.split(" ")
																			.map(
																				(word: any) =>
																					word.charAt(0).toUpperCase() +
																					word.slice(1)
																			)
																			.join(" ");
																		return (
																			<>
																				<span key={index}>
																					{convertedTextName}
																				</span>
																			</>
																		);
																	}
																)
															) : (
																<>
																	<span>AC</span>
																	<span>Food options</span>
																	<span>Newly renovated</span>
																	<span>Free Wi-Fi .</span>
																</>
															)
														) : (
															""
														)}
													</div>
												</div>

												<div className="ro-multiple-image-gallery-wrap">
													{roomData.images === undefined ? (
														<>
															{/* This Code Show when images is yet to load like default images */}
															<div className="ro-mig-box-1">
																<div className="imgContent">
																	<img
																		className="imgGallery-img"
																		src="/media/img/icons/defaultImg.png"
																		alt="MyResorts.in"
																	/>
																</div>

																<div className="imgContent">
																	<img
																		className="imgGallery-img"
																		src="/media/img/icons/defaultImg.png"
																		alt="MyResorts.in"
																	/>
																</div>

																<div className="imgContent">
																	<img
																		className="imgGallery-img"
																		src="/media/img/icons/defaultImg.png"
																		alt="MyResorts.in"
																	/>
																</div>
															</div>

															<div className="ro-mig-box-2">
																<img
																	src="/media/img/icons/defaultImg.png"
																	alt="MyResorts.in"
																/>
															</div>

															<div className="ro-mig-box-1">
																<div className="imgContent">
																	<img
																		className="imgGallery-img"
																		src="/media/img/icons/defaultImg.png"
																		alt="MyResorts.in"
																	/>
																</div>

																<div className="imgContent">
																	<img
																		className="imgGallery-img"
																		src="/media/img/icons/defaultImg.png"
																		alt="MyResorts.in"
																	/>
																</div>

																<div className="imgContent">
																	<img
																		className="imgGallery-img"
																		src="/media/img/icons/defaultImg.png"
																		alt="MyResorts.in"
																	/>
																</div>
															</div>
														</>
													) : (
														<>
															<div className="ro-mig-box-1">
																<div className="imgContent">
																	<img
																		src={
																			roomData?.images[0] === undefined
																				? "/media/img/icons/defaultImg.png"
																				: `${Img_Path}${roomData?.images[0]}`
																		}
																		alt="MyResorts.in"
																		onClick={() => handleImgShow(0)}
																	/>
																</div>

																<div className="imgContent">
																	<img
																		src={
																			roomData?.images[1] === undefined
																				? "/media/img/icons/defaultImg.png"
																				: `${Img_Path}${roomData?.images[1]}`
																		}
																		alt="MyResorts.in"
																		onClick={() => handleImgShow(1)}
																	/>
																</div>

																<div className="imgContent">
																	<img
																		src={
																			roomData?.images[2] === undefined
																				? "/media/img/icons/defaultImg.png"
																				: `${Img_Path}${roomData?.images[2]}`
																		}
																		alt="MyResorts.in"
																		onClick={() => handleImgShow(2)}
																	/>
																</div>
															</div>

															<div className="ro-mig-box-2">
																<img
																	src={
																		roomData?.images[3] === undefined
																			? "/media/img/icons/defaultImg.png"
																			: `${Img_Path}${roomData?.images[3]}`
																	}
																	alt="MyResorts.in"
																	onClick={() => handleImgShow(3)}
																/>
															</div>

															<div className="ro-mig-box-1">
																<div className="imgContent">
																	<img
																		src={
																			roomData?.images[4] === undefined
																				? "/media/img/icons/defaultImg.png"
																				: `${Img_Path}${roomData?.images[4]}`
																		}
																		alt="MyResorts.in"
																		onClick={() => handleImgShow(4)}
																	/>
																</div>

																<div className="imgContent">
																	<img
																		src={
																			roomData?.images[5] === undefined
																				? "/media/img/icons/defaultImg.png"
																				: `${Img_Path}${roomData?.images[5]}`
																		}
																		alt="MyResorts.in"
																		onClick={() => handleImgShow(5)}
																	/>
																</div>

																<div className="imgContent">
																	<img
																		src={
																			roomData?.images[6] === undefined
																				? "/media/img/icons/defaultImg.png"
																				: `${Img_Path}${roomData?.images[6]}`
																		}
																		alt="MyResorts.in"
																		onClick={() => handleImgShow(6)}
																	/>
																</div>
															</div>
														</>
													)}
												</div>
											</div>
										</Col>
									</Row>
								</Col>

								{/* <Col lg="8" className="pe-0"> */}
								<Col lg="8">
									<div className="description">
										<h2>Description</h2>
										<p>{roomData?.category_basic_info?.description}</p>

										{/* <a href="#">Read More</a> */}
									</div>

									<div className="room-highlight">
										<h2>Room Highlights</h2>
										<div className="room-h-list">
											{roomData.category_amenities !== "" ? (
												roomData?.category_amenities?.room_highlights.length !==
												0 ? (
													roomData?.category_amenities?.room_highlights.map(
														(value: any, index: any) => {
															let convertedTextName = value
																.split(" ")
																.map(
																	(word: any) =>
																		word.charAt(0).toUpperCase() + word.slice(1)
																)
																.join(" ");
															return (
																<>
																	<span
																		key={index}
																		className="room-highlight-span"
																	>
																		<h3>{convertedTextName}</h3>
																	</span>
																</>
															);
														}
													)
												) : (
													<>
														<span>
															<h3>Machaan</h3>
														</span>
														<span>
															<h3>Bonfire</h3>
														</span>
														<span>
															<h3>Outdoor Dining</h3>
														</span>
														<span>
															<h3>Stargazing</h3>
														</span>
														<span>
															<h3>Night View</h3>
														</span>
														<span>
															<h3>Balcony</h3>
														</span>
													</>
												)
											) : (
												""
											)}

											{/* <span>
                        <img
                          src={toAbsoluteUrl("/media/img/icons/balcony.png")}
                          alt="MyResorts.in"
                        />
                        <h3>Spacious Balcony</h3>
                      </span>

                      <span>
                        <img
                          src={toAbsoluteUrl("/media/img/icons/roomh2.png")}
                          alt="MyResorts.in"
                        />
                        <h3>Jungle View</h3>
                      </span>

                      <span>
                        <img
                          src={toAbsoluteUrl("/media/img/icons/roomh3.png")}
                          alt="MyResorts.in"
                        />
                        <h3>open Terrace</h3>
                      </span>

                      <span>
                        <img
                          src={toAbsoluteUrl("/media/img/icons/roomh4.png")}
                          alt="MyResorts.in"
                        />
                        <h3>Bonfire</h3>
                      </span>

                      <span>
                        <img
                          src={toAbsoluteUrl("/media/img/icons/roomh5.png")}
                          alt="MyResorts.in"
                        />
                        <h3>Backyard</h3>
                      </span>

                      <span>
                        <img
                          src={toAbsoluteUrl("/media/img/icons/roomh6.png")}
                          alt="MyResorts.in"
                        />
                        <h3>Riverside View</h3>
                      </span>

                      <span>
                        <img
                          src={toAbsoluteUrl("/media/img/icons/roomh7.png")}
                          alt="MyResorts.in"
                        />
                        <h3>Lake View</h3>
                      </span>

                      <span>
                        <img
                          src={toAbsoluteUrl("/media/img/icons/roomh8.png")}
                          alt="MyResorts.in"
                        />
                        <h3>Open Shower</h3>
                      </span> */}
										</div>
									</div>

									<div className="room-details-amenities rooms-amenities-container ">
										<Row>
											<Col lg="12">
												<h2>Amenities</h2>
											</Col>
											<Col lg="12">
												<div className="rd-amenities-list">
													{roomData.category_amenities !== "" ? (
														roomData?.category_amenities?.basic_facilities
															.length !== 0 ? (
															roomData?.category_amenities?.basic_facilities.map(
																(value: any, index: any) => {
																	let convertedTextName = value
																		.split(" ")
																		.map(
																			(word: any) =>
																				word.charAt(0).toUpperCase() +
																				word.slice(1)
																		)
																		.join(" ");
																	return (
																		<>
																			<div
																				className="room-details-amenities-box rooms-amenities-container"
																				key={index}
																				style={{
																					// border: "1px solid #009933",
																					// borderRadius: 80,
																					padding: 10,
																					margin: 2,
																					textAlign: "center",
																				}}
																			>
																				<h3>{convertedTextName}</h3>
																				{/* <div
																					className="fa-solid fa-gamepad"
																					style={{ color: "#0c1f40" }}
																				></div> */}
																			</div>
																		</>
																	);
																}
															)
														) : (
															<>
																<div className="room-details-amenities-box">
																	<h3>King Size Bed</h3>
																</div>
																<div className="room-details-amenities-box">
																	<h3>Garden</h3>
																</div>
																<div className="room-details-amenities-box">
																	<h3>Free Wifi</h3>
																</div>
															</>
														)
													) : (
														""
													)}

													{/* <div className="room-details-amenities-box rooms-amenities-container">
                              <img
                                src={toAbsoluteUrl("/media/img/ac.svg")}
                                alt="MyResorts.in"
                              />
                              <h3>AC</h3>
                            </div>

                            <div className="room-details-amenities-box">
                              <img
                                src={toAbsoluteUrl("/media/img/tv.svg")}
                                alt="MyResorts.in"
                              />
                              <h3>TV</h3>
                            </div>
                            <div className="room-details-amenities-box">
                              <img
                                src={toAbsoluteUrl("/media/img/window.svg")}
                                alt="MyResorts.in"
                              />
                              <h3>Window</h3>
                            </div>
                            <div className="room-details-amenities-box">
                              <img
                                src={toAbsoluteUrl("/media/img/free-wifi.svg")}
                                alt="MyResorts.in"
                              />
                              <h3>Free Wifi</h3>
                            </div>

                            <div className="room-details-amenities-box">
                              <img
                                src={toAbsoluteUrl("/media/img/p-backup.svg")}
                                alt="MyResorts.in"
                              />
                              <h3>Power backup</h3>
                            </div> */}
												</div>
											</Col>
										</Row>
									</div>

									<div className="hotel-policies-wrap">
										<Row>
											<Col lg="12">
												<h2>Hotel policies</h2>
											</Col>

											<Col lg="12">
												<div className="chk-in-out-wra">
													<div className="check-in">
														<h3>Check - In</h3>
														<h4>{formatCheckIn || "00:00PM"}</h4>
													</div>
													<div className="check-in check-out">
														<h3>Check - Out</h3>
														<h4>{formatCheckOut || "00:00PM"}</h4>
													</div>
												</div>
											</Col>
											{/* =======================================Saurabh's code for Meal options===================================================================================================================== */}
											<Col lg="12">
												<h2>Meals included</h2>
											</Col>

											<Col lg="12">
												<div className="cancellation-policy">
													<h4 style={{ marginBottom: "15px" }}>
														{roomData?.category_basic_info?.meal_option ==
															1 && (
															<span style={{ marginLeft: "12px" }}>
																Breakfast and Dinner
															</span>
														)}
														{roomData?.category_basic_info?.meal_option ==
															2 && (
															<span style={{ marginLeft: "12px" }}>
																Breakfast Only
															</span>
														)}
														{roomData?.category_basic_info?.meal_option ==
															3 && (
															<span style={{ marginLeft: "12px" }}>
																All meals Included <span style={{fontSize: "12px", color: "gray", fontStyle: "italic"}}>(American Plan)</span>
															</span>
														)}
														{roomData?.category_basic_info?.meal_option ==
															"" && (
															<span style={{ marginLeft: "12px" }}>None</span>
														)}
													</h4>
												</div>
											</Col>

											{/* =======================================Saurabh's code for Meal options ends here===================================================================================================================== */}

											<Col lg="12">
												<h2>Cancellation Policy</h2>
											</Col>

											<Col lg="12">
												<div className="cancellation-policy">
													<h4 style={{ marginLeft: "12px" }}>
														{roomData?.property_policy?.cancellation_policy ||
															"Empty"}
													</h4>
												</div>
											</Col>

											{/* <Col lg="12">
                        <div className="policy-list">
                          <ul>
                            <li>Couples are welcome</li>
                            <li>
                              Guests can check in using any local or outstation ID
                              proof (PAN card not accepted).
                            </li>
                            <li>Only Indian Nationals allowed</li>
                            <li>
                              As a complimentary benefit, your stay is now insured.
                            </li>
                            <li>
                              This hotel is serviced under the trade name of Main
                              View Guest House.
                            </li>
                          </ul>
                        </div>
                      </Col> */}
										</Row>
									</div>
								</Col>
								{/* <Col lg="4" className="ps-0"> */}
								<Col lg="4">
									<div className="rd-right-pannel">
										{groupAllowed ? (
											<div className="switch-button" onClick={handleChecked}>
												<input
													className="switch-button-checkbox"
													type="checkbox"
													checked={coupleGroup}
													onChange={(e) =>
														totalDateCalculation(
															checkedInDate === ""
																? CheckInDate
																: checkedInDate,
															checkedOutDate === ""
																? CheckOutDate
																: checkedOutDate,
															e.target.checked
														)
													}
												></input>
												<label className="switch-button-label">
													<span className="switch-button-label-span">
														COUPLE
													</span>
												</label>
											</div>
										) : (
											""
										)}

										<div className="rd-cjeck-in-out-part">
											{/* <div className="off-bt">
												{discountAmount !== 0 ? discountAmount : 60}% Off
											</div> */}
											<div className="price-n-off">
												<h2>
													₹{" "}
													{coupleGroup && groupAllowed
														? roomBasePrice
														: roomBasePrice}
												</h2>
												<h3>
													{" "}
													₹
													{1540 +
														Number(
															coupleGroup && groupAllowed
																? roomBasePrice
																: roomBasePrice
														)}
												</h3>
												{/* <h2>₹ {(coupleGroup && groupAllowed) ? roomData?.category_group_booking?.base_price : roomData?.category_base_pricing?.base_price}</h2>
												<h3> ₹{1540 + Number((coupleGroup && groupAllowed) ? roomData?.category_group_booking?.base_price : roomData?.category_base_pricing?.base_price)}</h3> */}

												<span>
													<h3>+Taxes &amp; Fees</h3>
													<p>per room / night</p>
												</span>
											</div>

											{/*=============== guest room container code starts here =========== */}
											<div
												className="date-room-filters-container"
												ref={calenderRef}
											>
												<div
													className="resort-bookingDate-container"
													onClick={() => setOpenCalendar(!openCalendar)}
												>
													<h3 className="resort-booking-date">
														{checkedInDate === ""
															? `${CheckInDate} - ${CheckOutDate}`
															: `${checkedInDate} - ${checkedOutDate}`}
													</h3>
													<AiFillCaretDown style={{ color: "#fff" }} />
												</div>

												{openCalendar && (
													<div className="resort-dateRange-box">
														<DateRange
															editableDateInputs={true}
															onChange={(item) => handleDateChange(item)} //setDateRange([item.selection])}
															moveRangeOnFirstSelection={false}
															ranges={dateRange}
															minDate={new Date()}
															months={2}
															direction="horizontal"
															className="calendarElement"
															rangeColors={["#009933"]}
														/>
													</div>
												)}

												<div
													className="resort-guests-filter"
													onClick={() => setGuestsFilterShow(!guestsFilterShow)}
												>
													<h3 className="resort-guests-filter-name">
														{guestsFilter.rooms} Room, {totalGuestsNum} Guests{" "}
													</h3>
													<AiFillCaretDown style={{ color: "#fff" }} />
												</div>

												{guestsFilterShow && (
													<>
														{coupleGroup && groupAllowed ? (
															<>
																<div
																	className="guest-filter-show group-filter-show resort-guest-filter-show"
																	onBlur={() => setGuestsFilterShow(false)}
																>
																	<div
																		className="container-fluid user-select-none"
																		style={{
																			cursor: "pointer",
																			margin: "auto",
																		}}
																	>
																		<div className="row p-4 guest-filter-inputs">
																			<div className="col">
																				<label>Adults</label>
																				<div
																					className="row"
																					style={{
																						boxShadow: "0px 0px 5px green",
																						borderRadius: "5px",
																						marginTop: "5px",
																						width: "110px",
																					}}
																				>
																					<div
																						className="col-3 border text-center"
																						onClick={() =>
																							handleGuestsNumberChange(
																								"groupAdults",
																								"sub"
																							)
																						}
																					>
																						<span className="fw-bold fs-3 user-select-none">
																							-
																						</span>
																					</div>
																					<div className="col-6 border text-center">
																						<span className="fw-bold fs-3 user-select-none">
																							{guestsFilter["groupAdults"]}
																						</span>
																					</div>
																					<div
																						className="col-3 border text-center"
																						onClick={() =>
																							handleGuestsNumberChange(
																								"groupAdults",
																								"add"
																							)
																						}
																					>
																						<span className="fw-bold fs-3 user-select-none">
																							+
																						</span>
																					</div>
																				</div>
																			</div>
																			<div className="col">
																				Child (0-12 yrs)
																				<div
																					className="row"
																					style={{
																						boxShadow: "0px 0px 5px green",
																						borderRadius: "5px",
																						marginTop: "5px",
																						width: "110px",
																					}}
																				>
																					<div
																						className="col-3 border text-center"
																						onClick={() =>
																							handleGuestsNumberChange(
																								"childRange3",
																								"sub"
																							)
																						}
																					>
																						<span className="fw-bold fs-3 user-select-none">
																							-
																						</span>
																					</div>
																					<div className="col-6 border text-center">
																						<span className="fw-bold fs-3 user-select-none">
																							{guestsFilter["childRange3"]}
																						</span>
																					</div>
																					<div
																						className="col-3 border text-center"
																						onClick={() =>
																							handleGuestsNumberChange(
																								"childRange3",
																								"add"
																							)
																						}
																					>
																						<span className="fw-bold fs-3 user-select-none">
																							+
																						</span>
																					</div>
																				</div>
																			</div>
																		</div>
																		<div className="d-flex justify-content-around mt-5">
																			<button
																				className="guests_done_apply_button"
																				type="button"
																				onClick={() => {
																					return (
																						guestsFilterApply(),
																						totalDateCalculation(
																							checkedInDate === ""
																								? CheckInDate
																								: checkedInDate,
																							checkedOutDate === ""
																								? CheckOutDate
																								: checkedOutDate,
																							coupleGroup
																						)
																						// totalCalculation() change to date calculation
																					);
																				}}
																			>
																				Apply
																			</button>
																		</div>
																	</div>
																</div>
															</>
														) : (
															<>
																<div
																	className="guest-filter-show resort-guest-filter-show"
																	onBlur={() => setGuestsFilterShow(false)}
																>
																	<div
																		className="container-fluid user-select-none"
																		style={{
																			cursor: "pointer",
																			margin: "auto",
																		}}
																	>
																		<div className="row p-4 guest-filter-inputs">
																			<div className="col">
																				<label>Rooms</label>
																				<div
																					className="row"
																					style={{
																						boxShadow: "0px 0px 5px green",
																						borderRadius: "5px",
																						marginTop: "5px",
																						width: "110px",
																					}}
																				>
																					<div
																						className="col-3 border text-center fw-bold fs-3 user-select-none"
																						onClick={() =>
																							handleGuestsNumberChange(
																								"rooms",
																								"sub"
																							)
																						}
																					>
																						-
																					</div>
																					<div className="col-6 border text-center fw-bold fs-3 user-select-none">
																						{guestsFilter["rooms"]}
																					</div>
																					<div
																						className="col-3 border text-center fw-bold fs-3 user-select-none"
																						onClick={() =>
																							handleGuestsNumberChange(
																								"rooms",
																								"add"
																							)
																						}
																					>
																						+
																					</div>
																				</div>
																			</div>
																			<div className="col">
																				<label>Adults</label>
																				<div
																					className="row"
																					style={{
																						boxShadow: "0px 0px 5px green",
																						borderRadius: "5px",
																						marginTop: "5px",
																						width: "110px",
																					}}
																				>
																					<div
																						className="col-3 border text-center"
																						onClick={() =>
																							handleGuestsNumberChange(
																								"adults",
																								"sub"
																							)
																						}
																					>
																						<span className="fw-bold fs-3 user-select-none">
																							-
																						</span>
																					</div>
																					<div className="col-6 border text-center">
																						<span className="fw-bold fs-3 user-select-none">
																							{guestsFilter["adults"]}
																						</span>
																					</div>
																					<div
																						className="col-3 border text-center"
																						onClick={() =>
																							handleGuestsNumberChange(
																								"adults",
																								"add"
																							)
																						}
																					>
																						<span className="fw-bold fs-3 user-select-none">
																							+
																						</span>
																					</div>
																				</div>
																			</div>
																		</div>
																		<div className="row mt-2 p-4 guest-filter-inputs">
																			<div className="col">
																				Child (0-6 yrs)
																				<div
																					className="row"
																					style={{
																						boxShadow: "0px 0px 5px green",
																						borderRadius: "5px",
																						marginTop: "5px",
																						width: "110px",
																					}}
																				>
																					<div
																						className="col-3 border text-center"
																						onClick={() =>
																							handleGuestsNumberChange(
																								"childRange1",
																								"sub"
																							)
																						}
																					>
																						<span className="fw-bold fs-3 user-select-none">
																							-
																						</span>
																					</div>
																					<div className="col-6 border text-center">
																						<span className="fw-bold fs-3 user-select-none">
																							{guestsFilter["childRange1"]}
																						</span>
																					</div>
																					<div
																						className="col-3 border text-center"
																						onClick={() =>
																							handleGuestsNumberChange(
																								"childRange1",
																								"add"
																							)
																						}
																					>
																						<span className="fw-bold fs-3 user-select-none">
																							+
																						</span>
																					</div>
																				</div>
																			</div>
																			<div className="col">
																				Child (6-12 yrs)
																				<div
																					className="row"
																					style={{
																						boxShadow: "0px 0px 5px green",
																						borderRadius: "5px",
																						marginTop: "5px",
																						width: "110px",
																					}}
																				>
																					<div
																						className="col-3 border text-center"
																						onClick={() =>
																							handleGuestsNumberChange(
																								"childRange2",
																								"sub"
																							)
																						}
																					>
																						<span className="fw-bold fs-3 user-select-none">
																							-
																						</span>
																					</div>
																					<div className="col-6 border text-center">
																						<span className="fw-bold fs-3 user-select-none">
																							{guestsFilter["childRange2"]}
																						</span>
																					</div>
																					<div
																						className="col-3 border text-center"
																						onClick={() =>
																							handleGuestsNumberChange(
																								"childRange2",
																								"add"
																							)
																						}
																					>
																						<span className="fw-bold fs-3 user-select-none">
																							+
																						</span>
																					</div>
																				</div>
																			</div>
																		</div>
																		<div className="d-flex justify-content-around mt-5">
																			<button
																				className="guests_done_apply_button"
																				type="button"
																				onClick={() => {
																					return (
																						guestsFilterApply(),
																						totalDateCalculation(
																							checkedInDate === ""
																								? CheckInDate
																								: checkedInDate,
																							checkedOutDate === ""
																								? CheckOutDate
																								: checkedOutDate,
																							coupleGroup
																						)
																						// totalCalculation() change to date calculation
																					);
																				}}
																			>
																				Apply
																			</button>
																		</div>
																	</div>
																</div>
															</>
														)}
													</>
												)}
											</div>
											{/* ========= guest room container code end here ====== */}

											<div className="coupon-apply-container">
												<input
													className="coupon-apply-input"
													type="text"
													placeholder="Apply Your Coupon"
													onChange={(e) => setCoupnValue(e.target.value)}
												/>
												<button
													className="coupon-button"
													onClick={() => {
														return handleCoupon();
														// handleCoupon(),
														// totalCalculation()
													}}
												>
													{" "}
													Apply{" "}
												</button>
											</div>
											{couponMsg ? (
												<h6 className="coupon-message-text"> {couponMsg}</h6>
											) : (
												""
											)}

											<div className="total-pr">
												<ul>
													{/* {coupleGroup ? <>
                            <li>
                              <h3>Per Person Cost </h3>
                              <p>₹ {Math.ceil(costPerHead)}</p>
                            </li>
                          </> : ""} */}

													<li>
														<h3>Room Charges </h3>
														<p>
															{coupleGroupVal ? "" : ""}
															<span
																style={{ fontSize: "12px", marginRight: "4px" }}
															>
																{" "}
															</span>
															₹ {totalRoomCharge}
														</p>
													</li>
													<li>
														<h3>Taxes & Fees</h3>
														<p>
															₹{" "}
															{`${Number(
																Number(commissionedPrice) +
																	Number(taxationPrice) +
																	Number(commisonGst) +
																	Number(tdsBase)
															).toFixed(2)} `}
														</p>
													</li>
													<li>
														<h3>Your savings</h3>
														<p>
															-{" "}
															{couponType === "Fixed"
																? `₹${couponPrice}`
																: couponType === "Percentage"
																? `${couponPrice}%`
																: 0}
														</p>
													</li>
													<li>
														<h3>Total Price</h3>
														<p>₹ {totalAmount}</p>
													</li>
												</ul>
											</div>
										</div>

										<button onClick={handleBookNow} className="book-now-bt">
											BOOK NOW
										</button>

										{/* <a href="/confirmation">
                        <button onClick={handleBookNow} className="book-now-bt">Book Now</button>
                      </a> */}
										<div className="can-pol">
											<span className="prop-policy" onClick={handlePolicyShow}>
												Property Policies{" "}
												<img
													src={toAbsoluteUrl("/media/img/info.svg")}
													alt="MyResorts.in"
												/>
											</span>
										</div>
									</div>
								</Col>
							</Row>
						</div>
					</div>

					<div className="container">
						<Row>
							<Col lg="12">
								<div className="select-room-titile">
									<h3>Similar Rooms </h3>
								</div>
							</Col>

							<Col lg="12">
								<div className="corousel-cont">
									{similarRoom !== "" ? (
										<Carousel
											responsive={responsive}
											autoPlay={false}
											showDots={true}
											autoPlaySpeed={3000}
											keyBoardControl={true}
										>
											{similarRoom !== ""
												? similarRoom.map((value: any) => {
														let DefaultImg =
															window.innerWidth < 550
																? "/media/img/icons/mobileDefaultImg.png"
																: "/media/img/icons/defaultImg.png";

														return (
															value.property &&
															value.property[0] &&
															value.property[0].property_status ===
																"Published" && (
																<div key={value.property_id}>
																	<div className="select-room-box">
																		<div className="select-room-box-img">
																			{value?.images[0] === undefined ? (
																				<img
																					className="similar-room-img default-img"
																					src="/media/img/icons/SimilardefaultImg.png"
																					alt="MyResorts.in"
																				/>
																			) : (
																				<img
																					className="similar-room-img"
																					src={
																						value?.images[0] === undefined
																							? "/media/img/icons/SimilardefaultImg.png"
																							: `${Img_Path}${value?.images[0]}`
																					}
																					alt="MyResorts.in"
																				/>
																			)}
																		</div>
																		<div className="select-room-info">
																			<a
																				href="#!"
																				onClick={() =>
																					handleBookNowClick(value._id)
																				}
																			>
																				<h2
																				// style={{ height: "40px" }}
																				>
																					{value.category_basic_info.name}
																				</h2>
																			</a>
																			<div className="amenities select-room-amenities">
																				{value.category_amenities.basic_facilities.map(
																					(data: any, index: any) => {
																						return (
																							<span key={index}>
																								<span>{data}</span>
																							</span>
																						);
																					}
																				)}
																			</div>

																			<div
																			// style={{
																			// 	display: "flex",
																			// 	flexDirection: "row",
																			// 	justifyContent: "space-between",
																			// 	alignItems: "center",
																			// }}
																			>
																				<div className="price-n-off">
																					<h2>
																						₹
																						{
																							value.category_base_pricing
																								.base_price
																						}
																					</h2>

																					{/* <a
																						href="#"
																						className="off-bt"
																						style={{ height: "20px" }}
																					>
																						60% Off
																					</a> */}
																					<div className="dummy-price">
																						{" "}
																						₹
																						{1540 +
																							Number(
																								value.category_base_pricing
																									.base_price
																							)}
																					</div>
																					<span>
																						<h3 style={{ textAlign: "right" }}>
																							+taxes & fees
																						</h3>
																						<p> per room / night</p>
																					</span>
																				</div>
																			</div>

																			<button
																				className="book-for-now"
																				onClick={() =>
																					handleBookNowClick(value._id)
																				}
																			>
																				Book Now
																			</button>
																		</div>
																	</div>
																</div>
															)
														);
												  })
												: ""}
										</Carousel>
									) : (
										""
									)}
								</div>
							</Col>
						</Row>
					</div>

					{/* Property Policy Modal Code Start's here */}
					<Modal
						show={policyShow}
						onHide={handlePolicyClose}
						centered
						size="lg"
					>
						<Modal.Body className="loginModal property-policy-modalbody">
							<div className="property-policy-modal">
								{propertyPolicy?.checkin_checkout_policy?.length === 0 ? (
									""
								) : (
									<div className="policy-parent-section">
										<h3 className="policy-heading"> Checkin Checkout Policy</h3>
										{propertyPolicy?.checkin_checkout_policy?.map(
											(value: any, index: any) => {
												return (
													<span key={index}>
														<h5 className="policy-ques">• {value?.question}</h5>
														<h6 className="policy-ans">
															{value.value.toString() === "false"
																? "No"
																: "Yes"}
														</h6>
													</span>
												);
											}
										)}
									</div>
								)}

								{propertyPolicy?.extra_bed_policy?.length === 0 ? (
									""
								) : (
									<div className="policy-parent-section">
										<h3 className="policy-heading">Extra Bed Policy</h3>
										{propertyPolicy?.extra_bed_policy?.map(
											(value: any, index: any) => {
												return (
													<span key={index}>
														<h5 className="policy-ques">• {value?.question}</h5>
														<h6 className="policy-ans">
															{value.value.toString() === "false"
																? "No"
																: "Yes"}
														</h6>
													</span>
												);
											}
										)}
									</div>
								)}

								{propertyPolicy?.food_drinks_hygiene?.length === 0 ? (
									""
								) : (
									<div className="policy-parent-section">
										<h3 className="policy-heading">Food Drinks Hygiene</h3>
										{propertyPolicy?.food_drinks_hygiene?.map(
											(value: any, index: any) => {
												return (
													<span key={index}>
														<h5 className="policy-ques">• {value?.question}</h5>
														<h6 className="policy-ans">
															{value.value.toString() === "false"
																? "No"
																: "Yes"}
														</h6>
													</span>
												);
											}
										)}
									</div>
								)}

								{propertyPolicy?.general_safety_hygiene_guidelines?.length ===
								0 ? (
									""
								) : (
									<div className="policy-parent-section">
										<h3 className="policy-heading">
											General Safety Hygiene Guidelines
										</h3>
										{propertyPolicy?.general_safety_hygiene_guidelines?.map(
											(value: any, index: any) => {
												return (
													<span key={index}>
														<h5 className="policy-ques">• {value?.question}</h5>
														<h6 className="policy-ans">
															{value.value.toString() === "false"
																? "No"
																: "Yes"}
														</h6>
													</span>
												);
											}
										)}
									</div>
								)}

								{propertyPolicy?.guest_profile?.length === 0 ? (
									""
								) : (
									<div className="policy-parent-section">
										<h3 className="policy-heading">Guest Profile</h3>
										{propertyPolicy?.guest_profile?.map(
											(value: any, index: any) => {
												return (
													<span key={index}>
														<h5 className="policy-ques">• {value?.question}</h5>
														<h6 className="policy-ans">
															{value.value.toString() === "false"
																? "No"
																: "Yes"}
														</h6>
													</span>
												);
											}
										)}
									</div>
								)}

								{propertyPolicy?.guest_suitabilty?.length === 0 ? (
									""
								) : (
									<div className="policy-parent-section">
										<h3 className="policy-heading">Guest_Suitabilty</h3>
										{propertyPolicy?.guest_suitabilty?.map(
											(value: any, index: any) => {
												return (
													<span key={index}>
														<h5 className="policy-ques">• {value?.question}</h5>
														<h6 className="policy-ans">
															{value.value.toString() === "false"
																? "No"
																: "Yes"}
														</h6>
													</span>
												);
											}
										)}
									</div>
								)}

								{propertyPolicy?.id_proof === "" ? (
									""
								) : (
									<div className="policy-parent-section">
										<h3 className="policy-heading">Id Proof</h3>
										<h5 className="policy-ques">
											• Acceptable Identity Proofs
										</h5>
										<h6 className="policy-ans">
											{propertyPolicy?.id_proof?.acceptable_identity_proofs}
										</h6>
										<h5 className="policy-ques">
											• Unacceptable Identity Proofs
										</h5>
										<h6 className="policy-ans">
											{propertyPolicy?.id_proof?.unacceptable_identity_proofs}
										</h6>
										<h5 className="policy-ques">
											• Are IDs of the same city as the property allowed?
										</h5>
										<h6 className="policy-ans">
											{propertyPolicy?.id_proof?.allow_same_id}
										</h6>
									</div>
								)}

								{propertyPolicy?.pet_policy?.length === 0 ? (
									""
								) : (
									<div className="policy-parent-section">
										<h3 className="policy-heading">Pet Policy</h3>
										{propertyPolicy?.pet_policy?.map(
											(value: any, index: any) => {
												return (
													<>
														<h5 className="policy-ques">• {value?.question}</h5>
														<h6 className="policy-ans">
															{value.value.toString() === "false"
																? "No"
																: "Yes"}
														</h6>
													</>
												);
											}
										)}
									</div>
								)}

								{propertyPolicy?.property_restrictions?.length === 0 ? (
									""
								) : (
									<div className="policy-parent-section">
										<h3 className="policy-heading">Property Restrictions</h3>
										{propertyPolicy?.property_restrictions?.map(
											(value: any, index: any) => {
												return (
													<span key={index}>
														<h5 className="policy-ques">• {value?.question}</h5>
														<h6 className="policy-ans">
															{value.value.toString() === "false"
																? "No"
																: "Yes"}
														</h6>
													</span>
												);
											}
										)}
									</div>
								)}

								{propertyPolicy?.room_safety_hygiene?.length === 0 ? (
									""
								) : (
									<div className="policy-parent-section">
										<h3 className="policy-heading">Room Safety Hygiene</h3>
										{propertyPolicy?.room_safety_hygiene?.map(
											(value: any, index: any) => {
												return (
													<span key={index}>
														<h5 className="policy-ques">• {value?.question}</h5>
														<h6 className="policy-ans">
															{value.value.toString() === "false"
																? "No"
																: "Yes"}
														</h6>
													</span>
												);
											}
										)}
									</div>
								)}

								{propertyPolicy?.social_distancing?.length === 0 ? (
									""
								) : (
									<div className="policy-parent-section">
										<h3 className="policy-heading">Social Distancing</h3>
										{propertyPolicy?.social_distancing?.map(
											(value: any, index: any) => {
												return (
													<span key={index}>
														<h5 className="policy-ques">• {value?.question}</h5>
														<h6 className="policy-ans">
															{value.value.toString() === "false"
																? "No"
																: "Yes"}
														</h6>
													</span>
												);
											}
										)}
									</div>
								)}
							</div>
						</Modal.Body>
					</Modal>
					{/* Property Policy Code End's here */}

					{/* Login Modal code Start's here */}
					<Modal show={show} onHide={handleClose} centered size="lg">
						<Modal.Body className="loginModal">
							<div className="login-body-content">
								{/* <div className="social-login">
									<div className="soc-ic">
										<a href="#">
											<img
												src={toAbsoluteUrl("/media/img/google-icon.svg")}
												alt=""
											/>
										</a>
										<a href="#">
											<img
												src={toAbsoluteUrl("/media/img/facebook-icon.svg")}
												alt=""
											/>
										</a>
									</div>
									<p>or use your email account :</p>
								</div> */}

								{<div className="res-msg">{resMsg}</div>}

								{resStatus === "" ? (
									<>
										<form onSubmit={handleSubmit}>
											<div className="login-form-user">
												<div className="input-box">
													<img
														src={toAbsoluteUrl("/media/img/user.svg")}
														alt=""
													/>
													<input
														type="text"
														autoComplete="off"
														name="number"
														id="number"
														placeholder="Enter Your Mobile Number"
														value={values.number}
														onChange={handleChange}
														onBlur={handleBlur}
														maxLength={10}
														onKeyPress={handleKeyPress}
													/>
												</div>

												{errors.number && touched.number ? (
													<div className="err-msg"> {errors.number} </div>
												) : null}
												<div className="login-bt-box">
													<button className="login-bt-user" type="submit">
														Login
													</button>
												</div>
											</div>
										</form>
									</>
								) : (
									<>
										<div className="login-form-user">
											<div className="input-box">
												<img
													src={toAbsoluteUrl("/media/img/user.svg")}
													alt=""
												/>
												<input
													type="number"
													autoComplete="off"
													name="number"
													id="number"
													placeholder="Enter Your Mobile Number"
													value={userNum}
													maxLength={10}
												/>
											</div>
											<div className="input-box">
												<img
													src={toAbsoluteUrl("/media/img/call.png")}
													// src={toAbsoluteUrl("/media/img/user.svg")}
													alt=""
												/>
												<input
													type="text"
													onKeyPress={handleKeyPress}
													maxLength={6}
													placeholder="Enter Your OTP Here"
													onChange={(e: any) => {
														setVerifyOtp(e.target.value);
													}}
												/>
											</div>
											<div className="consent-text">
												By Proceeding you are accepting our{" "}
												<a
													href="/privacyPolicy"
													style={{ color: "grey", textDecoration: "underline" }}
												>
													Privacy Policy
												</a>{" "}
												and{" "}
												<a
													href="/termsofuse"
													style={{ color: "grey", textDecoration: "underline" }}
												>
													Terms of Use
												</a>
												.
											</div>

											<div style={{ display: "flex", justifyContent: "right" }}>
												<div
													className="login-bt-box"
													style={{ marginRight: "10px" }}
												>
													<button
														className="login-bt-user"
														onClick={handleResendOtp}
													>
														Resend OTP
													</button>
												</div>
												<div className="login-bt-box">
													<button
														className="login-bt-user"
														onClick={handleOtpVerify}
													>
														Verify
													</button>
												</div>
											</div>
										</div>
									</>
								)}
							</div>
						</Modal.Body>
					</Modal>
					{/* Modal code End's here */}

					{/* Login Modal code Start's here */}
					<Modal show={imgShow} onHide={handleImgClose} centered size="lg">
						<Modal.Body className="img-carousel-container">
							{roomData !== "" ? (
								<Carousel
									responsive={imgCarousel}
									autoPlay={true}
									autoPlaySpeed={3000}
									showDots={false}
									keyBoardControl={true}
									arrows={true}
								>
									<div className="img-carousel-box">
										{count !== undefined && count > 0 ? (
											<img
												src={
													roomData?.images[count] === undefined
														? "/media/img/icons/defaultImg.png"
														: `${Img_Path}${roomData?.images[count]}`
												}
												alt="MyResorts.in"
											/>
										) : (
											<img
												src={
													roomData?.images[0] === undefined
														? "/media/img/icons/defaultImg.png"
														: `${Img_Path}${roomData?.images[0]}`
												}
												alt="MyResorts.in"
											/>
										)}
									</div>

									<div className="img-carousel-box">
										{count !== undefined && count === 1 ? (
											<img
												src={
													roomData?.images[0] === undefined
														? "/media/img/icons/defaultImg.png"
														: `${Img_Path}${roomData?.images[0]}`
												}
												alt="MyResorts.in"
											/>
										) : (
											<img
												src={
													roomData?.images[1] === undefined
														? "/media/img/icons/defaultImg.png"
														: `${Img_Path}${roomData?.images[1]}`
												}
												alt="MyResorts.in"
											/>
										)}
									</div>

									<div className="img-carousel-box">
										{count !== undefined && count === 2 ? (
											<img
												src={
													roomData?.images[0] === undefined
														? "/media/img/icons/defaultImg.png"
														: `${Img_Path}${roomData?.images[0]}`
												}
												alt="MyResorts.in"
											/>
										) : (
											<img
												src={
													roomData?.images[2] === undefined
														? "/media/img/icons/defaultImg.png"
														: `${Img_Path}${roomData?.images[2]}`
												}
												alt="MyResorts.in"
											/>
										)}
									</div>

									<div className="img-carousel-box">
										{count !== undefined && count === 3 ? (
											<img
												src={
													roomData?.images[0] === undefined
														? "/media/img/icons/defaultImg.png"
														: `${Img_Path}${roomData?.images[0]}`
												}
												alt="MyResorts.in"
											/>
										) : (
											<img
												src={
													roomData?.images[3] === undefined
														? "/media/img/icons/defaultImg.png"
														: `${Img_Path}${roomData?.images[3]}`
												}
												alt="MyResorts.in"
											/>
										)}
									</div>

									<div className="img-carousel-box">
										{count !== undefined && count === 4 ? (
											<img
												src={
													roomData?.images[0] === undefined
														? "/media/img/icons/defaultImg.png"
														: `${Img_Path}${roomData?.images[0]}`
												}
												alt="MyResorts.in"
											/>
										) : (
											<img
												src={
													roomData?.images[4] === undefined
														? "/media/img/icons/defaultImg.png"
														: `${Img_Path}${roomData?.images[4]}`
												}
												alt="MyResorts.in"
											/>
										)}
									</div>

									<div className="img-carousel-box">
										{count !== undefined && count === 5 ? (
											<img
												src={
													roomData?.images[0] === undefined
														? "/media/img/icons/defaultImg.png"
														: `${Img_Path}${roomData?.images[0]}`
												}
												alt="MyResorts.in"
											/>
										) : (
											<img
												src={
													roomData?.images[5] === undefined
														? "/media/img/icons/defaultImg.png"
														: `${Img_Path}${roomData?.images[5]}`
												}
												alt="MyResorts.in"
											/>
										)}
									</div>

									<div className="img-carousel-box">
										{count !== undefined && count === 6 ? (
											<img
												src={
													roomData?.images[0] === undefined
														? "/media/img/icons/defaultImg.png"
														: `${Img_Path}${roomData?.images[0]}`
												}
												alt="MyResorts.in"
											/>
										) : (
											<img
												src={
													roomData?.images[6] === undefined
														? "/media/img/icons/defaultImg.png"
														: `${Img_Path}${roomData?.images[6]}`
												}
												alt="MyResorts.in"
											/>
										)}
									</div>
								</Carousel>
							) : (
								""
							)}
						</Modal.Body>
					</Modal>
					{/* Modal code End's here */}

					<UserFooter />
				</div>
			</div>
		</>
	);
}
