import axios from "axios";
import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import RoomAmenities from "./RoomAmenities";
import { checkMandatoryCategory } from "../ProfileValidations";
import { Button, Modal } from "react-bootstrap-v5";

const API_URL = process.env.REACT_APP_API_URL || "";

export const ROOMS_URL = `${API_URL}/v1/vendor/rooms`;
export const CAT_DEL_URL = `${API_URL}/v1/vendor/cat`;

export default function Rooms({
	form,
	setForm,
	propertyValidation,
	setPropertyValidation,
}: any) {
	let categoryInitial = {
		property_location: {
			property_nearest_gate: "",
			property_address: "",
			property_zip_code: "",
			property_city: " ",
			property_state: "",
		},
		category_basic_info: {
			name: "",
			description: "",
			bed_type: "",
			meal_option: "",
			room_size: {
				length: "",
				breadth: "",
				size_unit: "",
			},
			smoking_allowed: "",
			view: "",
		},
		category_extra_bed: {
			extra_bed_provided: false,
			extra_bed_type: "",
		},
		category_base_pricing: {
			base_occupancy: "",
			extra_adult: "",
			extra_child: "",
			max_guests: "",
			base_price: "",
			extra_adult_price: "",
			extra_child_price: {
				child_range_one_price: "",
				child_range_two_price: "",
			},
		},
		category_weekend_base_pricing: {
			base_weekend_price: "",
			// "base_weekend_occupancy": "",
			// "extra_weekend_adult": "",
			// "extra_weekend_child": "",
			// "max_weekend_guests": "",
			// "extra_weekend_adult_price": "",
			// "extra_weekend_child_price": {
			//   "child_range_one_price": "",
			//   "child_range_two_price": "",
			// },
		},
		category_weekend_group_booking: {
			// "weekend_group_booking_allowed": true,
			weekend_booking_capacity: "",
			weekend_per_person_cost: "",
			weekend_base_price: "",
			weekend_no_cost_child: "",
		},
		category_availability: {
			start_date: "",
			end_date: "",
		},
		category_group_booking: {
			group_booking_allowed: false,
			booking_capacity: "",
			per_person_cost: "",
			base_price: "",
			no_cost_child: "",
		},
		category_amenities: {},
		rooms: [],
	};
	// console.log("form", form);
	const [selectedCategory, setSelectedCategory] = useState("");
	const [catagoryIndex, setCategoryIndex] = useState(0);
	const [show, setShow] = useState(false);
	const [showRooms, setShowRooms] = useState(true);
	const [disabled, setDisabled] = useState(true);
	const [category, setCategory] = useState<any>(categoryInitial);
	//const [propertyId, setPropertyId] = useState("");
	const [changes, setChanges] = useState(false);
	const [numRooms, setNumRooms] = useState("");
	const [roomBaseName, setRoomBaseName] = useState("");

	const [roomCategoryChange, setRoomCategoryChange] = useState(false);
	const [roomCategoryIndex, setRoomCategoryIndex] = useState(-1);
	const [roomCategorySelected, setRoomCategorySelected] = useState("");

	const [roomChange, setRoomChange] = useState(false);
	const [roomIndex, setRoomIndex] = useState(-1);
	const [roomNameSelected, setRoomNameSelected] = useState("");

	const [deleteShow, setDeleteShow] = useState(false);

	const handleClose = () => setDeleteShow(false);
	const handleShow = () => setDeleteShow(true);

	const history = useHistory();
	const touchedInitial = {
		nearestGate: false,
		address: false,
		city: false,
		zipcode: false,
		state: false,
		country: false,

		roomName: false,
		roomCount: false,
		roomDescription: false,
		roomType: false,
		roomBedType: false,
		roomMealOption: false,
		roomBaseOccupancy: false,
		roomExtraAdultAllowed: false,
		roomExtraChildAllowed: false,
		roomBasePrice: false,
		roomExtraAdultPrice: false,
		roomChildPriceRange1: false,
		roomChildPriceRange2: false,
		roomMaxGuests: false,
		groupBookingCapacity: false,
		groupBasePrice: false,
		groupPerPersonCost: false,
		groupExtraChildAllowed: false,

		roomWeekendBasePrice: false,

		roomWeekendExtraAdultAllowed: false,
		roomWeekendExtraAdultPrice: false,
		roomWeekendExtraChildAllowed: false,
		roomWeekendChildPriceRange1: false,
		roomWeekendChildPriceRange2: false,

		groupWeekendBasePrice: false,
		groupWeekendBookingCapacity: false,
		groupWeekendPerPersonCost: false,
		groupWeekendExtraChildAllowed: false,
	};
	const [touched, setTouched] = useState(touchedInitial);
	//const [toast, setToast] = useState(false);

	useEffect(() => {
		setCategory(form?.property_room_categories[0]);
		setSelectedCategory(
			form?.property_room_categories[0]?.category_basic_info.name
		);
		// console.log("cattttt--", form?.property_room_categories[0]);

		// console.log(form?.property_room_categories[0], "useEffect text", category);
	}, []);

	const navigate = (e: any) => {
		if (e == "continue") history.push("/crafted/pages/profile/photos");
		else history.push("/crafted/pages/profile/amenities");
	};

	// this function is getting the location data from form from location of property
	//  and then redirecting it to the category object
	const handlelocationAdd = () => {
		let obj = { ...category };
		obj.property_location.property_nearest_gate =
			form?.property_location?.property_nearest_gate;
		obj.property_location.property_address =
			form?.property_location?.property_address;
		obj.property_location.property_city =
			form?.property_location?.property_city;
		obj.property_location.property_state =
			form?.property_location?.property_state;
		obj.property_location.property_zip_code =
			form?.property_location?.property_zip_code;
		setCategory(obj);
		checkMandatory(obj);
		setChanges(true);
	};

	const handleChange = (event: any, parent: any, value: any) => {
		let obj = { ...category };
		// console.log("obj", obj);

		if (!obj[parent]) obj[parent] = {};
		obj[parent][value] = event.target.value;
		setCategory(obj);
		checkMandatory(obj);
		setChanges(true);
		handlelocationAdd();
	};

	const handleDetailsChange = (
		event: any,
		parent: any,
		child: any,
		value: any
	) => {
		let obj = { ...category };
		if (!obj[parent]) obj[parent] = {};
		if (!obj[parent][child]) obj[parent][child] = {};
		obj[parent][child][value] = event.target.value;
		setCategory(obj);
		checkMandatory(obj);
		setChanges(true);
	};

	const handleGroupBooking = (value: boolean) => {
		let obj = { ...category };
		obj["category_group_booking"]["group_booking_allowed"] = value;
		setCategory(obj);
		checkMandatory(obj);
		setChanges(true);
	};

	const handleExtraBed = (value: boolean) => {
		let obj = { ...category };
		// console.log(obj);

		if (obj["category_extra_bed"]) {
			obj["category_extra_bed"]["extra_bed_provided"] = value;
		} else {
			console.log("value");
			// obj["category_extra_bed"]["extra_bed_provided"] = value;
			// obj.category_extra_bed.extra_bed_provided = value;
		}

		// obj["category_group_booking"]["group_booking_allowed"] = value;
		// console.log("object ,," + JSON.parse(obj));

		setCategory(obj);
		checkMandatory(obj);
		setChanges(true);
	};

	const handleRoomCategoryClick = (ind: any, cat: string) => {
		setRoomCategoryIndex(ind);
		setRoomCategoryChange(true);
		setRoomCategorySelected(cat);
	};

	const handleRoomClick = (ind: any, newRoomName: any) => {
		setRoomNameSelected(newRoomName);
		setRoomIndex(ind);
		setRoomChange(true);
	};

	const handleRoomChange = (categoryIndex: number, roomIndex: number) => {
		let obj = { ...form };
		let categoryRooms = obj.property_room_categories[categoryIndex].rooms;

		if (roomNameSelected) {
			axios
				.put(`${ROOMS_URL}/${categoryRooms[roomIndex]._id}`, {
					room_name: roomNameSelected,
				})
				.then(() => {
					categoryRooms[roomIndex].room_name = roomNameSelected;
					setRoomNameSelected("");
					setRoomIndex(-1);
					setRoomChange(false);
					setForm(obj);
				});
		}
		if (roomCategorySelected) {
			axios
				.put(`${ROOMS_URL}/${categoryRooms[roomIndex]._id}`, {
					category_id: roomCategorySelected,
				})
				.then(() => {
					categoryRooms[roomIndex].category_id = roomCategorySelected;
					let roomObj = categoryRooms[roomIndex];
					categoryRooms.splice(roomIndex, 1);
					const targetCatIndex = obj.property_room_categories.findIndex(
						(category: any) => category._id === roomCategorySelected
					);
					obj.property_room_categories[targetCatIndex].rooms.push(roomObj);
					setSelectedCategory(
						obj.property_room_categories[targetCatIndex].category_basic_info
							.name
					);
					setRoomCategorySelected("");
					setRoomCategoryIndex(-1);
					setRoomCategoryChange(false);
					setForm(obj);
				});
		}
	};

	const addCategory = () => {
		if (!changes) return;
		if (!category?._id) {
			let categoryRooms = [];
			for (let i = 0; i < parseInt(numRooms); i++) {
				let roomObj: any = {};
				roomObj.room_name =
					roomBaseName + " " + (i < 10 ? "0" + (i + 1) : i + 1);
				roomObj.room_status = "Available";
				categoryRooms.push(roomObj);
			}
			category.rooms = categoryRooms;
			form.property_room_categories.push(category);
		} else {
			for (let i = 0; i < form.property_room_categories.length; i++)
				if (form.property_room_categories[i]._id === category._id)
					form.property_room_categories[i] = category;
		}
		let body: any = { ...form };
		if (form.hasOwnProperty("_id")) {
			body["property_id"] = body._id;
		}
		// if (body?.property_status === "Verified")
		// 	body["property_status"] = "Update";
		// console.log(body, "my body data");
		axios
			.post(`${API_URL}/v1/vendor/onboarding`, { data: body })
			.then((res) => {
				// console.log("response of add category", res);
				axios.get(`${API_URL}/v1/vendor/profile`).then((res) => {
					let properties = res.data.data.vendor_properties;
					//setForm(properties[properties.length - 1])
					for (const property of properties)
						if (property._id === sessionStorage.getItem("property_id")) {
							setForm(property);
							setSelectedCategory(category.category_basic_info.name);
						}
				});
			})
			.catch((err) => {
				console.log(err);
			});
		setShow(false);
	};
	const deleteRoom = (catIndex: any, roomIndex: any) => {
		let rooms = form?.property_room_categories[catIndex]?.rooms;
		if (rooms[roomIndex]._id) {
			axios.delete(ROOMS_URL + "/" + rooms[roomIndex]._id).catch((err) => {
				console.log(err);
			});
		}
		rooms.splice(roomIndex, 1);
		let obj = { ...form };
		obj.property_room_categories[catIndex].rooms = rooms;
		setForm(obj);
		setShow(false);
	};

	const deleteCategory = (catIndex: any, cat: any) => {
		axios.delete(CAT_DEL_URL + "/" + cat._id).catch((err) => {
			console.log(err);
		});

		// rooms.splice(roomIndex, 1);
		let obj = { ...form };
		delete obj.property_room_categories[catIndex];
		setForm(obj);
		setShow(false);
	};

	const handleCategorySelect = (i: any) => {
		// console.log({ ...form.property_room_categories[i] });

		setCategory({ ...form.property_room_categories[i] });
		setSelectedCategory(
			form.property_room_categories[i].category_basic_info.name
		);
		setCategoryIndex(i);
		//setShow(false)
	};

	const editCategory = (i: any) => {
		//category.category_amenities = form.property_room_categories[i].room_amenities
		setTouched(touchedInitial);
		setCategory({ ...form.property_room_categories[i] });
		setSelectedCategory(
			form.property_room_categories[i].category_basic_info.name
		);
		setCategoryIndex(i);
		setShow(true);
		window.location.href = "#room-details";
	};

	const createCategory = () => {
		setTouched(touchedInitial);
		setCategory(categoryInitial);
		setShow(true);
		window.location.href = "#room-details";
	};

	const checkMandatory = (category: any) => {
		// console.log(checkMandatoryCategory(category));
		if (form.hasOwnProperty("_id") && checkMandatoryCategory(category)) {
			setDisabled(false);
		} else {
			console.log("called disbaled");
			setDisabled(true);
		}
	};
	// console.log("REmote", form?.property_room_categories);

	const getDigits = (s: any) => (s.match(/\d+/) || [Number.MAX_VALUE])[0];

	console.log("Save button ----", form?.property_status);
	console.log("Save button ----2", disabled);

	return (
		<div>
			<div className="card card-custom mb-10">
				<div className="card-header">
					<h3 className="card-title align-items-start flex-column">
						<span className="card-label fw-bolder fs-3 mb-1">Rooms</span>
						<span className="text-muted fw-bold fs-7">
							Add room category level details
						</span>
					</h3>
					{/* <div className="card-toolbar"></div> */}
				</div>
				<div className="card-body">
					<div className="container ps-0 pe-0">
						<div className="row">
							{form?.property_room_categories.length > 0 &&
								form.property_room_categories.map((ele: any, index: any) => (
									<>
										<div className="col-lg-4">
											<div
												className="card card-custom card-stretch-33 shadow mb-5"
												onClick={() => handleCategorySelect(index)}
											>
												<div
													className={
														"card-header flex-row" +
														(selectedCategory === ele.category_basic_info?.name
															? "bg-secondary"
															: "")
													}
												>
													<h3 className="card-title align-items-start flex-column">
														<span className="card-label fw-bolder fs-3 mb-1">
															{ele.category_basic_info?.name}
														</span>
													</h3>
													<a
														className="edit-bt"
														style={{
															// zIndex: 99999,
															marginTop: "32px",
															background: "coral",
														}}
														onClick={handleShow}
													>
														<i
															// aria-hidden="true"
															// data-bs-toggle="modal"
															// data-bs-target={"#deleteCat_modal_2" + index}
															className="fa fa-trash-o flex-row"
														></i>
													</a>
													<a
														onClick={() => editCategory(index)}
														className="edit-bt"
													>
														<i
															className="fa fa-edit fa-lg"
															aria-hidden="true"
														></i>
													</a>
												</div>
												<div className="card-body text-truncate">
													{ele.category_basic_info?.description}
												</div>
											</div>
										</div>
									</>
								))}
						</div>
					</div>

					<div className="container ps-0 pe-0">
						<div className="row">
							{form?.property_room_categories.length > 0 &&
								form.property_room_categories.map(
									(ele: any, index: any) =>
										selectedCategory === ele.category_basic_info?.name &&
										ele.rooms.length > 0 &&
										ele.rooms
											.sort(
												(a: any, b: any) =>
													getDigits(a.room_name) - getDigits(b.room_name) ||
													(a && a.toString().localeCompare(b))
											)
											.map((rm: any, ind: any) => (
												<>
													<div className="col-lg-3">
														<div className="card shadow mb-5">
															<div className="card-header flex h-10">
																<h3 className="card-title align-items-start flex-column">
																	{ind !== roomCategoryIndex && (
																		<span
																			onClick={() =>
																				handleRoomCategoryClick(ind, ele._id)
																			}
																			className="text-muted fw-bold fs-7 pb-1"
																		>
																			{ele.category_basic_info?.name}
																		</span>
																	)}

																	{roomCategoryChange &&
																		ind === roomCategoryIndex && (
																			<select
																				autoFocus
																				onBlur={() =>
																					handleRoomChange(index, ind)
																				}
																				className="form-select form-select-solid text-muted fw-bold w-100px h-40px"
																				value={roomCategorySelected}
																				onChange={(e) =>
																					setRoomCategorySelected(
																						e.target.value
																					)
																				}
																			>
																				{form?.property_room_categories.map(
																					(item: any) => (
																						<option value={item._id}>
																							{item.category_basic_info.name}
																						</option>
																					)
																				)}
																			</select>
																		)}
																</h3>
																<a
																	data-bs-toggle="modal"
																	data-bs-target={"#kt_modal_2" + ind}
																	className="del-bt"
																>
																	{form?.property_status !== "Unverified" && (
																		<i
																			className="fa fa-trash-o"
																			aria-hidden="true"
																		></i>
																	)}
																</a>
															</div>
															{/* <div className='card-body h-50px pt-4'> */}
															<div className="card-body pt-4">
																{ind !== roomIndex && (
																	<span
																		onClick={() =>
																			handleRoomClick(ind, rm.room_name)
																		}
																		className="card-label fw-bolder fs-5"
																	>
																		{rm.room_name}
																	</span>
																)}

																{roomChange && ind === roomIndex && (
																	<input
																		type="text"
																		autoFocus
																		onBlur={() => handleRoomChange(index, ind)}
																		className="form-control form-control-solid fw-bolder fs-5 w-150px h-25px"
																		value={roomNameSelected}
																		onChange={(e) =>
																			setRoomNameSelected(e.target.value)
																		}
																	/>
																)}
															</div>
														</div>
													</div>
													<div
														className="modal fade"
														tabIndex={-2}
														id={"kt_modal_2" + ind}
													>
														<div className="modal-dialog modal-sm">
															<div className="modal-content">
																<div className="modal-body delete-modal-wrap">
																	<svg className="svg-icon" viewBox="0 0 20 20">
																		<path d="M10.185,1.417c-4.741,0-8.583,3.842-8.583,8.583c0,4.74,3.842,8.582,8.583,8.582S18.768,14.74,18.768,10C18.768,5.259,14.926,1.417,10.185,1.417 M10.185,17.68c-4.235,0-7.679-3.445-7.679-7.68c0-4.235,3.444-7.679,7.679-7.679S17.864,5.765,17.864,10C17.864,14.234,14.42,17.68,10.185,17.68 M10.824,10l2.842-2.844c0.178-0.176,0.178-0.46,0-0.637c-0.177-0.178-0.461-0.178-0.637,0l-2.844,2.841L7.341,6.52c-0.176-0.178-0.46-0.178-0.637,0c-0.178,0.176-0.178,0.461,0,0.637L9.546,10l-2.841,2.844c-0.178,0.176-0.178,0.461,0,0.637c0.178,0.178,0.459,0.178,0.637,0l2.844-2.841l2.844,2.841c0.178,0.178,0.459,0.178,0.637,0c0.178-0.176,0.178-0.461,0-0.637L10.824,10z"></path>
																	</svg>
																	<h3>Are you sure ? </h3>

																	<h5>
																		Do you really want to delete this records ?
																		This process cannot be undone
																	</h5>
																</div>

																<div className="modal-footer">
																	<button
																		type="button"
																		className="btn btn-light"
																		data-bs-dismiss="modal"
																	>
																		Cancel
																	</button>
																	<button
																		type="button"
																		className="btn btn-danger"
																		onClick={(e) => deleteRoom(index, ind)}
																		data-bs-dismiss="modal"
																	>
																		Delete
																	</button>
																</div>
															</div>
														</div>
													</div>
													{/* <div
														className="modal show"
														tabIndex={-3}
														id={"deleteCat_modal_2" + ind}
													>
														<div className="modal-dialog modal-sm">
															<div className="modal-content">
																<div className="modal-body delete-modal-wrap">
																	<svg className="svg-icon" viewBox="0 0 20 20">
																		<path d="M10.185,1.417c-4.741,0-8.583,3.842-8.583,8.583c0,4.74,3.842,8.582,8.583,8.582S18.768,14.74,18.768,10C18.768,5.259,14.926,1.417,10.185,1.417 M10.185,17.68c-4.235,0-7.679-3.445-7.679-7.68c0-4.235,3.444-7.679,7.679-7.679S17.864,5.765,17.864,10C17.864,14.234,14.42,17.68,10.185,17.68 M10.824,10l2.842-2.844c0.178-0.176,0.178-0.46,0-0.637c-0.177-0.178-0.461-0.178-0.637,0l-2.844,2.841L7.341,6.52c-0.176-0.178-0.46-0.178-0.637,0c-0.178,0.176-0.178,0.461,0,0.637L9.546,10l-2.841,2.844c-0.178,0.176-0.178,0.461,0,0.637c0.178,0.178,0.459,0.178,0.637,0l2.844-2.841l2.844,2.841c0.178,0.178,0.459,0.178,0.637,0c0.178-0.176,0.178-0.461,0-0.637L10.824,10z"></path>
																	</svg>
																	<h3>Are you sure ? </h3>

																	<h5>
																		Do you really want to delete this category ?
																		This process cannot be undone
																	</h5>
																</div>

																<div className="modal-footer">
																	<button
																		type="button"
																		className="btn btn-light"
																		data-bs-dismiss="modal"
																	>
																		Cancel
																	</button>
																	<button
																		type="button"
																		style={{
																			zIndex: 99999,
																			// color: "#FFFFFF",
																			// borderColor: "#F1416C",
																			// backgroundColor: "#F1416C",
																			// height: " 3rem",
																			// borderRadius: "5px",
																			// width: "6rem",
																		}}
																		className="btn btn-danger"
																		onClick={(e: any) => {
																			deleteCategory(index, ele);
																		}}
																		data-bs-dismiss="modal"
																	>
																		Delete
																	</button>
																</div>
															</div>
														</div>
													</div> */}

													<Modal show={deleteShow} onHide={handleClose}>
														<Modal.Header closeButton>
															<Modal.Title>Delete Category</Modal.Title>
														</Modal.Header>
														<Modal.Body>
															<div>
																{/* <svg
																	className="svg-icon"
																	viewBox="0 0 20 20"
																	style={{
																		width: 50,
																		color: "red",
																		alignItems: "center",
																	}}
																>
																	<path d="M10.185,1.417c-4.741,0-8.583,3.842-8.583,8.583c0,4.74,3.842,8.582,8.583,8.582S18.768,14.74,18.768,10C18.768,5.259,14.926,1.417,10.185,1.417 M10.185,17.68c-4.235,0-7.679-3.445-7.679-7.68c0-4.235,3.444-7.679,7.679-7.679S17.864,5.765,17.864,10C17.864,14.234,14.42,17.68,10.185,17.68 M10.824,10l2.842-2.844c0.178-0.176,0.178-0.46,0-0.637c-0.177-0.178-0.461-0.178-0.637,0l-2.844,2.841L7.341,6.52c-0.176-0.178-0.46-0.178-0.637,0c-0.178,0.176-0.178,0.461,0,0.637L9.546,10l-2.841,2.844c-0.178,0.176-0.178,0.461,0,0.637c0.178,0.178,0.459,0.178,0.637,0l2.844-2.841l2.844,2.841c0.178,0.178,0.459,0.178,0.637,0c0.178-0.176,0.178-0.461,0-0.637L10.824,10z"></path>
																</svg> */}
																<h3>Are you sure ? </h3>

																<h5>
																	Do you really want to delete this category ?
																	This process cannot be undone.
																</h5>
															</div>
														</Modal.Body>
														<Modal.Footer>
															<Button variant="secondary" onClick={handleClose}>
																Close
															</Button>
															<Button
																variant="danger"
																onClick={(e: any) => {
																	deleteCategory(index, ele);
																}}
															>
																Delete
															</Button>
														</Modal.Footer>
													</Modal>
												</>
											))
								)}
						</div>
					</div>
				</div>
				<div className="card-footer">
					<a
						className="btn btn-success me-2 mb-2 add-room-bt"
						onClick={(e) => createCategory()}
					>
						<i className="fa fa-home" aria-hidden="true"></i>Add Room Category
					</a>
				</div>
			</div>
			<div id="room-details"></div>
			{show && (
				<>
					<div className="add-room-wrapper pt-20">
						<fieldset
							disabled={form?.property_status === "Unverified" ? true : false}
						>
							<div className="card card-custom">
								<div className="card-body">
									<div className="basic-info-wrap">
										<div className="row">
											<div className="col-lg-6 mb-10">
												<div className="row">
													<div className="col-lg-12 mb-10">
														<label className="required form-label">
															Category Name
														</label>
														<input
															type="text"
															maxLength={50}
															className="form-control form-control-solid"
															aria-label="Select example"
															wtx-context="F1A8A87A-B11E-444A-96D5-6127F7459523"
															value={
																category &&
																category["category_basic_info"] &&
																category["category_basic_info"]["name"] &&
																category["category_basic_info"]["name"]
															}
															onChange={(e) =>
																handleChange(e, "category_basic_info", "name")
															}
															onBlur={() =>
																setTouched({ ...touched, roomType: true })
															}
														/>
														{touched.roomType &&
															!category?.category_basic_info?.name && (
																<span className="text-danger fs-8" role="alert">
																	Category Name is required field
																</span>
															)}
														{touched.roomType &&
															category?.category_basic_info?.name &&
															form?.property_room_categories.filter(
																(item: any) =>
																	item.category_basic_info.name ===
																	category?.category_basic_info?.name
															).length > 0 && (
																<span className="text-danger fs-8" role="alert">
																	Category Name already exists
																</span>
															)}
													</div>

													{/* ================== Testing code start's here==================== */}
													{/* this code is important is important because it sends data to categories api
                        to then we get the location data in both resorts and categories so just keep it as it is. */}

													{/* <div className="col-lg-12 mb-5 d-none">
                          <label className="required form-label">Nearest Gate</label>
                          <input
                            type="text"
                            className="form-control form-control-solid"
                            value={category['property_location']['property_nearest_gate']}
                            // value={form?.property_location?.property_nearest_gate}
                            onChange={e => handleChange(e, 'property_location', 'property_nearest_gate')}
                            placeholder=""
                            onBlur={(() => setTouched({ ...touched, nearestGate: true }))}
                          />
                          {
                            touched.nearestGate && !category?.property_location?.property_nearest_gate &&
                            <span className="text-danger fs-8" role='alert'>Nearest Gate is required field</span>
                          }
                          <span className="text-white">.</span>
                        </div> */}

													{/* <div className="col-lg-12 mb-5 d-none">
                          <label className="required form-label">Address</label>
                          <input
                            type="text"
                            className="form-control form-control-solid"
                            // value={category['property_location']['property_address']}
                            value={form?.property_location?.property_address}
                            onChange={e => handleChange(e, 'property_location', 'property_address')}
                            placeholder=""
                            onBlur={(() => setTouched({ ...touched, address: true }))}
                          />
                          {
                            touched.address && !category?.property_location?.property_address &&
                            <span className="text-danger fs-8" role='alert'> Address is required field</span>
                          }
                          <span className="text-white">.</span>
                        </div> */}

													{/* <div className="col-lg-6 mb-5 d-none">
                          <label className="required form-label">City</label>
                          <input
                            type="text"
                            className="form-control form-control-solid"
                            // value={category['property_location']['property_city']}
                            value={form?.property_location?.property_city}
                            onChange={e => handleChange(e, 'property_location', 'property_city')}
                            placeholder=""
                            onBlur={(() => setTouched({ ...touched, city: true }))}
                          />
                          {
                            touched.city && !category?.property_location?.property_city &&
                            <span className="text-danger fs-8" role='alert'>City is required field</span>
                          }
                          <span className="text-white">.</span>
                        </div> */}

													{/* <div className="col-lg-6 mb-5 d-none">
                          <label className="required form-label">State</label>
                          <input
                            type="text"
                            className="form-control form-control-solid"
                            // value={category['property_location']['property_state']}
                            value={form?.property_location?.property_state}
                            onChange={e => handleChange(e, 'property_location', 'property_state')}
                            placeholder=""
                            onBlur={(() => setTouched({ ...touched, state: true }))}
                          />
                          {
                            touched.state && !category?.property_location?.property_state &&
                            <span className="text-danger fs-8" role='alert'>State is required field</span>
                          }
                          <span className="text-white">.</span>
                        </div> */}

													{/* <div className="col-lg-6 mb-5 d-none">
                          <label className="required form-label">Pincode</label>
                          <input
                            type="text"
                            className="form-control form-control-solid"
                            // value={category['property_location']['property_zip_code']}
                            value={form?.property_location?.property_zip_code}
                            onChange={e => handleChange(e, 'property_location', 'property_zip_code')}
                            placeholder=""
                            onBlur={(() => setTouched({ ...touched, zipcode: true }))}
                          />
                          {
                            touched.zipcode && !category?.property_location?.property_zip_code &&
                            <span className="text-danger fs-8" role='alert'>Pincode is required field</span>
                          }
                          {
                            category?.property_location?.property_zip_code &&
                            !/^\d{6}$/.test(form?.property_location?.property_zip_code) &&
                            <span className="text-danger fs-8" role='alert'>Enter valid pincode</span>
                          }
                          <span className="text-white">.</span>
                        </div> */}
													{/*=============== Testing code end's here ===================== */}

													{!category?._id && (
														<>
															<div className="col-lg-6 mb-10">
																<label className="required form-label">
																	Number of available room
																</label>
																<input
																	type="text"
																	className="form-control form-control-solid"
																	placeholder=""
																	onChange={(e) => setNumRooms(e.target.value)}
																	onBlur={() =>
																		setTouched({ ...touched, roomCount: true })
																	}
																/>
																{touched.roomCount && !numRooms && (
																	<span
																		className="text-danger fs-8"
																		role="alert"
																	>
																		No. of rooms is required field
																	</span>
																)}
																{touched.roomCount &&
																	numRooms &&
																	!/^\d+$/.test(numRooms) && (
																		<span
																			className="text-danger fs-8"
																			role="alert"
																		>
																			Only numbers allowed
																		</span>
																	)}
															</div>

															<div className="col-lg-12 mb-10">
																<label className="required form-label">
																	Room Base Name
																</label>
																<input
																	type="text"
																	maxLength={50}
																	className="form-control form-control-solid"
																	placeholder=""
																	onChange={(e) =>
																		setRoomBaseName(e.target.value)
																	}
																	onBlur={() =>
																		setTouched({ ...touched, roomName: true })
																	}
																/>
																{touched.roomName && !roomBaseName && (
																	<span
																		className="text-danger fs-8"
																		role="alert"
																	>
																		Room Base Name is required field
																	</span>
																)}
															</div>
														</>
													)}
													<div className="col-lg-6">
														<label className="required form-label">
															Bed Type
														</label>
														<select
															className="form-select form-select-solid"
															aria-label="Select example"
															wtx-context="F1A8A87A-B11E-444A-96D5-6127F7459523"
															value={
																category &&
																category["category_basic_info"]["bed_type"]
															}
															onChange={(e) =>
																handleChange(
																	e,
																	"category_basic_info",
																	"bed_type"
																)
															}
															onBlur={() =>
																setTouched({ ...touched, roomBedType: true })
															}
														>
															<option value="">Select One</option>
															<option value="Type 1">King size</option>
															<option value="Type 2">Queen size</option>
															<option value="Type 3">Single</option>
														</select>
														{touched.roomBedType &&
															!category?.category_basic_info?.bed_type && (
																<span className="text-danger fs-8" role="alert">
																	Bed Type is required field
																</span>
															)}
													</div>
												</div>
											</div>

											<div className="col-lg-6 mb-10">
												{/* <div className="col-lg-12 mb-10"> */}
												<label className="required form-label">
													Category Description
												</label>
												<textarea
													maxLength={1000}
													className="form-control form-control-solid room-desc-ta"
													value={
														category &&
														category["category_basic_info"]["description"]
													}
													onChange={(e) =>
														handleChange(
															e,
															"category_basic_info",
															"description"
														)
													}
													onBlur={() =>
														setTouched({ ...touched, roomDescription: true })
													}
												></textarea>
												{touched.roomDescription &&
													!category?.category_basic_info?.description && (
														<span className="text-danger fs-8" role="alert">
															Room Description is required field
														</span>
													)}
											</div>

											<div className="col-lg-6 mb-10">
												<label className="required form-label">
													Meal Options
												</label>
												<select
													className="form-select form-select-solid"
													aria-label="Select example"
													wtx-context="F1A8A87A-B11E-444A-96D5-6127F7459523"
													value={category["category_basic_info"]["meal_option"]}
													onChange={(e) =>
														handleChange(
															e,
															"category_basic_info",
															"meal_option"
														)
													}
													onBlur={() =>
														setTouched({ ...touched, roomMealOption: true })
													}
												>
													<option value="">Select One</option>
													<option value="2">Breakfast only</option>
													<option value="1">Breakfast and dinner</option>
													<option value="3">All meals</option>
												</select>
												{touched.roomMealOption &&
													!category?.category_basic_info?.meal_option && (
														<span className="text-danger fs-8" role="alert">
															Meal Options is required field
														</span>
													)}
											</div>

											<div className="col-lg-2">
												<label className="form-label">Room Size</label>
												<input
													type="number"
													className="form-control form-control-solid"
													placeholder="Length"
													value={
														category["category_basic_info"]["room_size"]?.length
													}
													onChange={(e) =>
														handleDetailsChange(
															e,
															"category_basic_info",
															"room_size",
															"length"
														)
													}
												/>
												{category?.category_basic_info?.room_size?.length &&
													!/^\d+$/.test(
														category?.category_basic_info?.room_size?.length
													) && (
														<span className="text-danger fs-8" role="alert">
															Only numbers allowed
														</span>
													)}
											</div>

											<div className="col-lg-2">
												<label className="form-label" style={{ color: "#fff" }}>
													.
												</label>
												<input
													type="number"
													className="form-control form-control-solid"
													placeholder="Breadth"
													value={
														category["category_basic_info"]["room_size"]
															?.breadth
													}
													onChange={(e) =>
														handleDetailsChange(
															e,
															"category_basic_info",
															"room_size",
															"breadth"
														)
													}
												/>
												{category?.category_basic_info?.room_size?.breadth &&
													!/^\d+$/.test(
														category?.category_basic_info?.room_size?.breadth
													) && (
														<span className="text-danger fs-8" role="alert">
															Only numbers allowed
														</span>
													)}
											</div>

											<div className="col-lg-2">
												<label className="form-label text-white">.</label>
												<select
													className="form-select form-select-solid"
													aria-label="Select example"
													wtx-context="F1A8A87A-B11E-444A-96D5-6127F7459523"
													value={
														category["category_basic_info"]["room_size"]
															?.size_unit
													}
													onChange={(e) =>
														handleDetailsChange(
															e,
															"category_basic_info",
															"room_size",
															"size_unit"
														)
													}
												>
													<option value="">Select One</option>
													<option value="sq.ft">sq.ft.</option>
													<option value="sq.mtr">sq.mtr.</option>
												</select>
											</div>

											<div className="col-lg-3 mb-10 mt-10">
												<label className="form-label">Smoking allowed</label>
												<select
													className="form-select form-select-solid"
													aria-label="Select example"
													wtx-context="F1A8A87A-B11E-444A-96D5-6127F7459523"
													value={
														category["category_basic_info"]["smoking_allowed"]
													}
													onChange={(e) =>
														handleChange(
															e,
															"category_basic_info",
															"smoking_allowed"
														)
													}
												>
													<option value="">Select One</option>
													<option value="Yes">Yes</option>
													<option value="No">No</option>
												</select>
											</div>

											{/* <div className="col-lg-3 mt-10">
                      <label className="form-label">View</label>
                      <select
                        className="form-select form-select-solid"
                        aria-label="Select example"
                        wtx-context="F1A8A87A-B11E-444A-96D5-6127F7459523"
                        value={category['category_basic_info']['view']}
                        onChange={e => handleChange(e, 'category_basic_info', 'view')}
                      >
                        <option value="">Select One</option>
                        <option value="View 1">View 1</option>
                        <option value="View 2">View 2</option>
                      </select>
                    </div> */}
										</div>
									</div>
								</div>
							</div>

							<div className="card card-custom">
								<div className="card-header">
									<h3 className="card-title align-items-start flex-column">
										<span className="card-label fw-bolder fs-3 mb-1">
											Extra Bed
										</span>
									</h3>
								</div>
								<div className="card-body">
									<div className="basic-info-wrap">
										<div className="row">
											<div className="col-lg-6">
												<label className="form-label">
													Do you provide extra bed?
												</label>
												<div className="d-flex">
													<div className="form-check form-check-custom form-check-solid form-check-sm me-10">
														<input
															onChange={() => handleExtraBed(true)}
															className="form-check-input"
															name="extraBed"
															type="radio"
															value=""
															id="flexRadioSm"
															checked={
																category?.category_extra_bed?.extra_bed_provided
															}
														/>
														<label
															className="form-check-label"
															htmlFor="flexRadioSm"
														>
															Yes
														</label>
													</div>
													<div className="form-check form-check-custom form-check-solid form-check-sm">
														<input
															onChange={() => handleExtraBed(false)}
															className="form-check-input"
															name="extraBed"
															type="radio"
															value=""
															id="flexRadioSm"
															checked={
																!category?.category_extra_bed
																	?.extra_bed_provided
															}
														/>
														<label
															className="form-check-label"
															htmlFor="flexRadioSm1"
														>
															No
														</label>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div className="card card-custom">
								<div className="card-header">
									<h3 className="card-title align-items-start flex-column">
										<span className="card-label fw-bolder fs-3 mb-1">
											Base Room Price
										</span>
									</h3>

									<div className="card-toolbar"></div>
								</div>
								<div className="card-body">
									<div className="basic-info-wrap">
										<div className="row">
											<div className="col-lg-6 mb-10">
												<label className="required form-label">
													Base Occupancy
												</label>
												<input
													type="text"
													className="form-control form-control-solid"
													placeholder="Enter Number"
													value={
														category["category_base_pricing"]["base_occupancy"]
													}
													onChange={(e) =>
														handleChange(
															e,
															"category_base_pricing",
															"base_occupancy"
														)
													}
													onBlur={() =>
														setTouched({ ...touched, roomBaseOccupancy: true })
													}
												/>
												{touched.roomBaseOccupancy &&
													category?.category_base_pricing?.base_occupancy ===
														"" && (
														<span className="text-danger fs-8" role="alert">
															Base Occupancy is required field
														</span>
													)}
												{category?.category_base_pricing?.base_occupancy !==
													"" &&
													!/^\d+$/.test(
														category?.category_base_pricing?.base_occupancy
													) && (
														<span className="text-danger fs-8" role="alert">
															Only numbers allowed
														</span>
													)}
											</div>

											<div className="col-lg-6 mb-10">
												<label className="required form-label">
													Base Price
												</label>
												<input
													type="text"
													min={100}
													className="form-control form-control-solid"
													placeholder="Enter Price"
													value={
														category["category_base_pricing"]["base_price"]
													}
													onChange={(e) =>
														handleChange(
															e,
															"category_base_pricing",
															"base_price"
														)
													}
													onBlur={() =>
														setTouched({ ...touched, roomBasePrice: true })
													}
												/>
												{touched.roomBasePrice &&
													category?.category_base_pricing?.base_price ===
														"" && (
														<span className="text-danger fs-8" role="alert">
															Base Price is required field
														</span>
													)}
												{category?.category_base_pricing?.base_price !== "" &&
													!/^\d+$/.test(
														category?.category_base_pricing?.base_price
													) && (
														<span className="text-danger fs-8" role="alert">
															Only numbers allowed
														</span>
													)}
											</div>

											<div className="col-lg-6 mb-10">
												<label className="required form-label">
													Number of Extra Adult Allowed
												</label>
												<input
													type="text"
													className="form-control form-control-solid"
													placeholder="Enter Number"
													value={
														category["category_base_pricing"]["extra_adult"]
													}
													onChange={(e) =>
														handleChange(
															e,
															"category_base_pricing",
															"extra_adult"
														)
													}
													onBlur={() =>
														setTouched({
															...touched,
															roomExtraAdultAllowed: true,
														})
													}
												/>
												{touched.roomExtraAdultAllowed &&
													category?.category_base_pricing?.extra_adult ===
														"" && (
														<span className="text-danger fs-8" role="alert">
															No. of Extra Adult allowed is required field
														</span>
													)}
												{category?.category_base_pricing?.extra_adult !== "" &&
													!/^\d+$/.test(
														category?.category_base_pricing?.extra_adult
													) && (
														<span className="text-danger fs-8" role="alert">
															Only numbers allowed
														</span>
													)}
											</div>

											<div className="col-lg-6 mb-10">
												<label className="required form-label">
													Price for Extra Adult (12 yrs & above)
												</label>
												<input
													type="text"
													className="form-control form-control-solid"
													placeholder="Enter Price"
													value={
														category["category_base_pricing"][
															"extra_adult_price"
														]
													}
													onChange={(e) =>
														handleChange(
															e,
															"category_base_pricing",
															"extra_adult_price"
														)
													}
													onBlur={() =>
														setTouched({
															...touched,
															roomExtraAdultPrice: true,
														})
													}
												/>
												{touched.roomExtraAdultPrice &&
													category?.category_base_pricing?.extra_adult_price ===
														"" && (
														<span className="text-danger fs-8" role="alert">
															No. of Extra Adult allowed is required field
														</span>
													)}
												{category?.category_base_pricing?.extra_adult_price !==
													0 &&
													!/^\d+$/.test(
														category?.category_base_pricing?.extra_adult_price
													) && (
														<span className="text-danger fs-8" role="alert">
															Only numbers allowed
														</span>
													)}
											</div>

											<div className="col-lg-6 mb-10">
												<label className="required form-label">
													Number of Extra Child Allowed
												</label>
												<input
													type="text"
													className="form-control form-control-solid"
													placeholder="Enter Number"
													value={
														category["category_base_pricing"]["extra_child"]
													}
													onChange={(e) =>
														handleChange(
															e,
															"category_base_pricing",
															"extra_child"
														)
													}
													onBlur={() =>
														setTouched({
															...touched,
															roomExtraChildAllowed: true,
														})
													}
												/>
												{touched.roomExtraChildAllowed &&
													category?.category_base_pricing?.extra_child ===
														"" && (
														<span className="text-danger fs-8" role="alert">
															No. of Extra Child allowed is required field
														</span>
													)}
												{category?.category_base_pricing?.extra_child !== "" &&
													!/^\d+$/.test(
														category?.category_base_pricing?.extra_child
													) && (
														<span className="text-danger fs-8" role="alert">
															Only numbers allowed
														</span>
													)}
											</div>

											<div className="col-lg-6 mb-10">
												<label className="required form-label">
													Price for Child Range 1 (0-5 yrs)
												</label>
												<input
													type="text"
													className="form-control form-control-solid"
													placeholder="Enter Price"
													value={
														category["category_base_pricing"]?.extra_child_price
															?.child_range_one_price
													}
													onChange={(e) =>
														handleDetailsChange(
															e,
															"category_base_pricing",
															"extra_child_price",
															"child_range_one_price"
														)
													}
													onBlur={() =>
														setTouched({
															...touched,
															roomChildPriceRange1: true,
														})
													}
												/>
												{touched.roomChildPriceRange1 &&
													category?.category_base_pricing?.extra_child_price
														.child_range_one_price === "" && (
														<span className="text-danger fs-8" role="alert">
															Price for Child Range 1 is required field
														</span>
													)}
												{category?.category_base_pricing?.extra_child_price
													.child_range_one_price !== "" &&
													!/^\d+$/.test(
														category?.category_base_pricing?.extra_child_price
															.child_range_one_price
													) && (
														<span className="text-danger fs-8" role="alert">
															Only numbers allowed
														</span>
													)}
											</div>

											<div className="col-lg-6 mb-10">
												<label className="required form-label">
													Price for Child Range 2 (6-12 yrs)
												</label>
												<input
													type="text"
													className="form-control form-control-solid"
													value={
														category["category_base_pricing"]?.extra_child_price
															?.child_range_two_price
													}
													onChange={(e) =>
														handleDetailsChange(
															e,
															"category_base_pricing",
															"extra_child_price",
															"child_range_two_price"
														)
													}
													placeholder="Enter Price"
													onBlur={() =>
														setTouched({
															...touched,
															roomChildPriceRange2: true,
														})
													}
												/>
												{touched.roomChildPriceRange2 &&
													category?.category_base_pricing?.extra_child_price
														.child_range_two_price === "" && (
														<span className="text-danger fs-8" role="alert">
															Price for Child Range 2 is required field
														</span>
													)}
												{category?.category_base_pricing?.extra_child_price
													.child_range_two_price !== 0 &&
													!/^\d+$/.test(
														category?.category_base_pricing?.extra_child_price
															.child_range_two_price
													) && (
														<span className="text-danger fs-8" role="alert">
															Only numbers allowed
														</span>
													)}
											</div>

											<div className="col-lg-6 mb-10">
												<label className="required form-label">
													Max Number of Guests Allowed
												</label>
												<input
													type="text"
													className="form-control form-control-solid"
													placeholder="Enter Number"
													value={
														category["category_base_pricing"]["max_guests"]
													}
													onChange={(e) =>
														handleChange(
															e,
															"category_base_pricing",
															"max_guests"
														)
													}
													onBlur={() =>
														setTouched({ ...touched, roomMaxGuests: true })
													}
												/>
												{touched.roomMaxGuests &&
													category?.category_base_pricing?.max_guests ===
														"0" && (
														<span className="text-danger fs-8" role="alert">
															Max no. of Guests Cannot be Zero
														</span>
													)}
												{touched.roomMaxGuests &&
													category?.category_base_pricing?.max_guests ===
														"" && (
														<span className="text-danger fs-8" role="alert">
															Max no. of Guests Allowed is required field
														</span>
													)}
												{category?.category_base_pricing?.max_guests !== "" &&
													!/^\d+$/.test(
														category?.category_base_pricing?.max_guests
													) && (
														<span className="text-danger fs-8" role="alert">
															Only numbers allowed
														</span>
													)}
											</div>

											{/* =========== testing code start's here ================ */}
											<div className="col-lg-6 mb-10">
												<label className="required form-label">
													Weekend Base Price
												</label>
												<input
													type="text"
													min={100}
													className="form-control form-control-solid"
													placeholder="Enter Price"
													value={
														category["category_weekend_base_pricing"]
															? category["category_weekend_base_pricing"][
																	"base_weekend_price"
															  ]
															: 0
													}
													onChange={(e) =>
														handleChange(
															e,
															"category_weekend_base_pricing",
															"base_weekend_price"
														)
													}
													onBlur={() =>
														setTouched({
															...touched,
															roomWeekendBasePrice: true,
														})
													}
												/>
												{touched.roomWeekendBasePrice &&
													category?.category_weekend_base_pricing
														?.base_weekend_price === "" && (
														<span className="text-danger fs-8" role="alert">
															Base Weekend Price is required field
														</span>
													)}
												{category?.category_weekend_base_pricing
													?.base_weekend_price !== "" &&
													!/^\d+$/.test(
														category?.category_weekend_base_pricing
															?.base_weekend_price
													) && (
														<span className="text-danger fs-8" role="alert">
															Only numbers allowed
														</span>
													)}
											</div>
											{/* ============= testing code ends here ================= */}
										</div>
									</div>
								</div>
							</div>

							<div className="card card-custom">
								<div className="card-header">
									<h3 className="card-title align-items-start flex-column">
										<span className="card-label fw-bolder fs-3 mb-1">
											Group Booking
										</span>
									</h3>

									<div className="card-toolbar"></div>
								</div>

								<div className="card-body">
									<div className="basic-info-wrap">
										<div className="row">
											<div className="col-lg-6">
												<label className="form-label">
													Group Booking Allowed
												</label>
												<div className="mb-10 d-flex">
													<div className="form-check form-check-custom form-check-solid form-check-sm me-10">
														<input
															onChange={() => handleGroupBooking(true)}
															className="form-check-input"
															name="groupBooking"
															type="radio"
															value=""
															id="flexRadioSm"
															checked={
																category?.category_group_booking
																	?.group_booking_allowed
															}
														/>
														<label
															className="form-check-label"
															htmlFor="flexRadioSm"
														>
															Yes
														</label>
													</div>

													<div className="form-check form-check-custom form-check-solid form-check-sm">
														<input
															onChange={() => handleGroupBooking(false)}
															className="form-check-input"
															name="groupBooking"
															type="radio"
															value=""
															id="flexRadioSm"
															checked={
																!category?.category_group_booking
																	?.group_booking_allowed
															}
														/>
														<label
															className="form-check-label"
															htmlFor="flexRadioSm1"
														>
															No
														</label>
													</div>
												</div>
											</div>
										</div>

										{category?.category_group_booking
											?.group_booking_allowed && (
											<div className="row">
												<div className="col-lg-6 mb-10">
													<label className="required form-label">
														Booking Capacity
													</label>
													<input
														type="text"
														className="form-control form-control-solid"
														value={
															category["category_group_booking"][
																"booking_capacity"
															]
														}
														onChange={(e) =>
															handleChange(
																e,
																"category_group_booking",
																"booking_capacity"
															)
														}
														placeholder="Enter Number"
														onBlur={() =>
															setTouched({
																...touched,
																groupBookingCapacity: true,
															})
														}
													/>
													{touched.groupBookingCapacity &&
														!category?.category_group_booking
															?.booking_capacity && (
															<span className="text-danger fs-8" role="alert">
																Booking Capacity is required field
															</span>
														)}
													{category?.category_group_booking?.booking_capacity &&
														!/^\d+$/.test(
															category?.category_group_booking?.booking_capacity
														) && (
															<span className="text-danger fs-8" role="alert">
																Only numbers allowed
															</span>
														)}
												</div>

												<div className="col-lg-6 mb-10">
													<label className="required form-label">
														Per Person Cost (Food/Service Cost)
													</label>
													<input
														type="text"
														className="form-control form-control-solid"
														placeholder="Enter Number"
														value={
															category["category_group_booking"][
																"per_person_cost"
															]
														}
														onChange={(e) =>
															handleChange(
																e,
																"category_group_booking",
																"per_person_cost"
															)
														}
														onBlur={() =>
															setTouched({
																...touched,
																groupPerPersonCost: true,
															})
														}
													/>
													{touched.groupPerPersonCost &&
														!category?.category_group_booking
															?.per_person_cost && (
															<span className="text-danger fs-8" role="alert">
																Per Person Cost is required field
															</span>
														)}
													{category?.category_group_booking?.per_person_cost &&
														!/^\d+$/.test(
															category?.category_group_booking?.per_person_cost
														) && (
															<span className="text-danger fs-8" role="alert">
																Only numbers allowed
															</span>
														)}
												</div>

												<div className="col-lg-6 mb-10">
													<label className="required form-label">
														Base Price
													</label>
													<input
														type="text"
														min={100}
														className="form-control form-control-solid"
														placeholder="Enter Price"
														value={
															category["category_group_booking"]["base_price"]
														}
														onChange={(e) =>
															handleChange(
																e,
																"category_group_booking",
																"base_price"
															)
														}
														onBlur={() =>
															setTouched({ ...touched, groupBasePrice: true })
														}
													/>
													{touched.groupBasePrice &&
														!category?.category_group_booking?.base_price && (
															<span className="text-danger fs-8" role="alert">
																Base Price is required field
															</span>
														)}
													{category?.category_group_booking?.base_price &&
														!/^\d+$/.test(
															category?.category_group_booking?.base_price
														) && (
															<span className="text-danger fs-8" role="alert">
																Only numbers allowed
															</span>
														)}
												</div>

												<div className="col-lg-6 mb-10">
													<label className="required form-label">
														Number of No Cost-Child per room allowed(0-12 yrs)
													</label>
													<input
														type="text"
														className="form-control form-control-solid"
														placeholder="Enter Number"
														value={
															category["category_group_booking"][
																"no_cost_child"
															]
														}
														onChange={(e) =>
															handleChange(
																e,
																"category_group_booking",
																"no_cost_child"
															)
														}
														onBlur={() =>
															setTouched({
																...touched,
																groupExtraChildAllowed: true,
															})
														}
													/>
													{touched.groupExtraChildAllowed &&
														!category?.category_group_booking
															?.no_cost_child && (
															<span className="text-danger fs-8" role="alert">
																Number of No Cost Child is required field
															</span>
														)}
													{category?.category_group_booking?.no_cost_child &&
														!/^\d+$/.test(
															category?.category_group_booking?.no_cost_child
														) && (
															<span className="text-danger fs-8" role="alert">
																Only numbers allowed
															</span>
														)}
												</div>

												{/* Group booking Weekends code start's here  */}
												<div className="col-lg-6 mb-10">
													<label className="required form-label">
														Weekend Booking Capacity
													</label>
													<input
														type="text"
														className="form-control form-control-solid"
														value={
															category["category_weekend_group_booking"]
																? category["category_weekend_group_booking"][
																		"weekend_booking_capacity"
																  ]
																: 0
														}
														onChange={(e) =>
															handleChange(
																e,
																"category_weekend_group_booking",
																"weekend_booking_capacity"
															)
														}
														placeholder="Enter Number"
														onBlur={() =>
															setTouched({
																...touched,
																groupWeekendBookingCapacity: true,
															})
														}
													/>
													{touched.groupWeekendBookingCapacity &&
														!category?.category_weekend_group_booking
															?.weekend_booking_capacity && (
															<span className="text-danger fs-8" role="alert">
																Booking Capacity is required field
															</span>
														)}
													{category?.category_weekend_group_booking
														?.weekend_booking_capacity &&
														!/^\d+$/.test(
															category?.category_weekend_group_booking
																?.weekend_booking_capacity
														) && (
															<span className="text-danger fs-8" role="alert">
																Only numbers allowed
															</span>
														)}
												</div>
												<div className="col-lg-6 mb-10">
													<label className="required form-label">
														{" "}
														Weekend Per Person Cost (Food/Service Cost)
													</label>
													<input
														type="text"
														className="form-control form-control-solid"
														placeholder="Enter Number"
														value={
															category["category_weekend_group_booking"]
																? category["category_weekend_group_booking"][
																		"weekend_per_person_cost"
																  ]
																: 0
														}
														onChange={(e) =>
															handleChange(
																e,
																"category_weekend_group_booking",
																"weekend_per_person_cost"
															)
														}
														onBlur={() =>
															setTouched({
																...touched,
																groupWeekendPerPersonCost: true,
															})
														}
													/>
													{touched.groupWeekendPerPersonCost &&
														!category?.category_weekend_group_booking
															?.weekend_per_person_cost && (
															<span className="text-danger fs-8" role="alert">
																Per Person Cost is required field
															</span>
														)}
													{category?.category_weekend_group_booking
														?.weekend_per_person_cost &&
														!/^\d+$/.test(
															category?.category_weekend_group_booking
																?.weekend_per_person_cost
														) && (
															<span className="text-danger fs-8" role="alert">
																Only numbers allowed
															</span>
														)}
												</div>
												<div className="col-lg-6 mb-10">
													<label className="required form-label">
														Weekend Base Price
													</label>
													<input
														type="text"
														min={100}
														className="form-control form-control-solid"
														placeholder="Enter Price"
														value={
															category["category_weekend_group_booking"]
																? category["category_weekend_group_booking"][
																		"weekend_base_price"
																  ]
																: 0
														}
														onChange={(e) =>
															handleChange(
																e,
																"category_weekend_group_booking",
																"weekend_base_price"
															)
														}
														onBlur={() =>
															setTouched({
																...touched,
																groupWeekendBasePrice: true,
															})
														}
													/>
													{touched.groupWeekendBasePrice &&
														!category?.category_weekend_group_booking
															?.weekend_base_price && (
															<span className="text-danger fs-8" role="alert">
																Weekend Base Price is required field
															</span>
														)}
													{category?.category_weekend_group_booking
														?.weekend_base_price &&
														!/^\d+$/.test(
															category?.category_weekend_group_booking
																?.weekend_base_price
														) && (
															<span className="text-danger fs-8" role="alert">
																Only numbers allowed
															</span>
														)}
												</div>

												<div className="col-lg-6 mb-10">
													<label className="required form-label">
														Number of Weekend No Cost-Child per room
														allowed(0-12 yrs)
													</label>
													<input
														type="text"
														className="form-control form-control-solid"
														placeholder="Enter Number"
														value={
															category["category_weekend_group_booking"]
																? category["category_weekend_group_booking"][
																		"weekend_no_cost_child"
																  ]
																: 0
														}
														onChange={(e) =>
															handleChange(
																e,
																"category_weekend_group_booking",
																"weekend_no_cost_child"
															)
														}
														onBlur={() =>
															setTouched({
																...touched,
																groupWeekendExtraChildAllowed: true,
															})
														}
													/>
													{touched.groupWeekendExtraChildAllowed &&
														!category?.category_weekend_group_booking
															?.weekend_no_cost_child && (
															<span className="text-danger fs-8" role="alert">
																Number of No Cost Child is required field
															</span>
														)}
													{category?.category_weekend_group_booking
														?.weekend_no_cost_child &&
														!/^\d+$/.test(
															category?.category_weekend_group_booking
																?.weekend_no_cost_child
														) && (
															<span className="text-danger fs-8" role="alert">
																Only numbers allowed
															</span>
														)}
												</div>
												{/* Group booking Weekends code start's here  */}
											</div>
										)}
									</div>
								</div>
							</div>

							<div className="card card-custom">
								<div className="card-header">
									<h3 className="card-title align-items-start flex-column">
										<span className="card-label fw-bolder fs-3 mb-1">
											Availability
										</span>
									</h3>
								</div>
								<div className="card-body">
									<div className="basic-info-wrap">
										<div className="row">
											<div className="col-lg-12">
												<label className="form-label">
													Please select date range for which your property is
													available for booking for guests
												</label>
											</div>
											<div className="col-lg-3">
												<input
													type="date"
													className="form-control form-control-solid"
													placeholder=""
													value={category["category_availability"]?.start_date}
													onChange={(e) =>
														handleChange(
															e,
															"category_availability",
															"start_date"
														)
													}
												/>
											</div>
											<div className="col-lg-1">
												<span className="to">to</span>
											</div>
											<div className="col-lg-3">
												<input
													type="date"
													className="form-control form-control-solid"
													placeholder=""
													value={category["category_availability"]?.end_date}
													onChange={(e) =>
														handleChange(e, "category_availability", "end_date")
													}
												/>
											</div>
										</div>
									</div>
								</div>
							</div>
						</fieldset>
					</div>
					<div className="amenities-wrap">
						<RoomAmenities
							form={form}
							category={category}
							setCategory={setCategory}
							setChanges={setChanges}
							checkMandatory={checkMandatory}
						/>
					</div>

					<div className="d-flex flex-stack">
						<div>
							<button
								type="submit"
								className="btn btn-lg btn-primary me-3"
								onClick={() => addCategory()}
								// disabled={disabled}
							>
								<span className="indicator-label">
									Save
									<span className="svg-icon svg-icon-3 ms-2 me-0">
										<svg
											width="24"
											height="24"
											viewBox="0 0 24 24"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
											className="mh-50px"
										>
											<rect
												opacity="0.5"
												x="18"
												y="13"
												width="13"
												height="2"
												rx="1"
												transform="rotate(-180 18 13)"
												fill="black"
											></rect>
											<path
												d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z"
												fill="black"
											></path>
										</svg>
									</span>
								</span>
							</button>
						</div>
					</div>
				</>
			)}

			{!show && (
				<div className="d-flex flex-stack">
					<div className="me-2">
						<button
							type="button"
							className="btn btn-lg btn-secondary me-3"
							data-kt-stepper-action="previous"
							onClick={() => navigate("back")}
						>
							<span className="svg-icon svg-icon-4 me-1">
								<svg
									width="24"
									height="24"
									viewBox="0 0 24 24"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
									className="mh-50px"
								>
									<rect
										opacity="0.5"
										x="6"
										y="11"
										width="13"
										height="2"
										rx="1"
										fill="black"
									></rect>
									<path
										d="M8.56569 11.4343L12.75 7.25C13.1642 6.83579 13.1642 6.16421 12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75L5.70711 11.2929C5.31658 11.6834 5.31658 12.3166 5.70711 12.7071L11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25C13.1642 17.8358 13.1642 17.1642 12.75 16.75L8.56569 12.5657C8.25327 12.2533 8.25327 11.7467 8.56569 11.4343Z"
										fill="black"
									></path>
								</svg>
							</span>
							Back
						</button>
					</div>

					<div>
						<button
							type="submit"
							className="btn btn-lg btn-primary me-3"
							onClick={() => navigate("continue")}
						>
							<span className="indicator-label">
								Continue
								<span className="svg-icon svg-icon-3 ms-2 me-0">
									<svg
										width="24"
										height="24"
										viewBox="0 0 24 24"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
										className="mh-50px"
									>
										<rect
											opacity="0.5"
											x="18"
											y="13"
											width="13"
											height="2"
											rx="1"
											transform="rotate(-180 18 13)"
											fill="black"
										></rect>
										<path
											d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z"
											fill="black"
										></path>
									</svg>
								</span>
							</span>
						</button>
					</div>
				</div>
			)}
		</div>
	);
}
