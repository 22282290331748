import React, { useState, useEffect } from "react";
import { KTSVG, toAbsoluteUrl } from "../../../../_metronic/helpers";
import { Form, Toast } from "react-bootstrap-v5";
import { useHistory } from "react-router-dom";
import axios from "axios";
import jsPDF from "jspdf";
import PDFViewer from "./pdfviewer/PDFViewer";
import { PDFDocument } from "pdf-lib";
import { shallowEqual, useSelector } from "react-redux";
import { RootState } from "../../../../setup";
import { SnackbarProvider, useSnackbar } from "notistack";

import {
	checkMandatoryAmenities,
	checkMandatoryBasicInfo,
	checkMandatoryFinanceLegal,
	checkMandatoryLocation,
	checkMandatoryPhotos,
	checkMandatoryPolicies,
	checkMandatoryCategory,
} from "../ProfileValidations";

const API_URL = process.env.REACT_APP_API_URL || "";

const fileMimeType: any = {
	"application/pdf": "pdfsvg" as string,
	"application/msword": "docsvg" as string,
	"application/vnd.openxmlformats-officedocument.wordprocessingml.document":
		"docsvg" as string,
	"image/png": "imagesvg" as string,
	"image/jpeg": "imagesvg" as string,
	"image/jpg": "imagesvg" as string,
};

export default function FinanceLegal({ form, setForm }: any) {
	const user: any = useSelector<RootState>(
		({ auth }) => auth.user,
		shallowEqual
	);
	const [financeDisabled, setFinanceDisabled] = useState(false);
	const [toast, setToast] = useState(false);
	const [errorToast, setErrorToast] = useState(false);
	const [errMessage, setErrMessage] = useState("");
	const [navigateTo, setNavigateTo] = useState("/dashboard");
	const history = useHistory();
	const [hasGST, setHasGST] = useState(false);
	const [hasTAN, setHasTAN] = useState(false);
	const [documents, setDocuments] = useState<any>([]);
	const [recentDoc, setRecentDoc] = useState([]);

	//const [tcPdfFile, setTcPdfFile] = useState(new Blob());
	const [tcFileUrl, setTcFileUrl] = useState("");
	const [fileTypeToast, setFileTypeToast] = useState(false);
	const [touched, setTouched] = useState({
		accountNo: false,
		ifscCode: false,
		bankName: false,
		panNo: false,
		tanNo: false,
		gstDetails: false,
	});
	//const [images, setImages] = useState([])
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [changes, setChanges] = useState(false);
	const [addedInfo, setAddedInfo] = useState(true);

	const [loading, setLoading] = useState(false);
	const [message, setMessage] = useState("");
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();
	const [uploadFlag, setUploadFlag] = useState(false);

	/* 
    const createPDF = (vendorName: string) => {
      const date = new Date();
      let doc = new jsPDF("landscape", "px", "a4", false);
      doc.text("MyResorts.in", 30, 20)
      doc.setFontSize(10)
      doc.text(date.toDateString(), 530, 20)
      doc.setFontSize(16)
      doc.text("Terms and Conditions", 240, 40);
      const textWidth = doc.getTextWidth("Terms and Conditions");
      doc.line(238, 42, 240 + textWidth + 4, 42)
      doc.setFontSize(12)
      doc.text(" - Terms and conditions goes here...", 60, 70)
      doc.text("Signatory - ", 470, 250)
      doc.text(vendorName, 470, 265)
      const blob = doc.output('blob')
      let file: any = blob;
      file.lastModifiedDate = new Date();
      file.name = "tc.pdf"
      setTcPdfFile(blob);
      const tcFileUrl = doc.output('dataurlstring');
      setTcFileUrl(tcFileUrl);
    } */

	const updatePDF = async () => {
		const pdfData = await fetch(
			toAbsoluteUrl("/media/docs/VendorAgreement.pdf")
		).then((res) => res.arrayBuffer());
		const pdfDoc = await PDFDocument.load(pdfData);
		// console.log(pdfDoc);
		let page = pdfDoc.getPage(4);
		const date = new Date();
		const formattedDate = `${date.getDate()}/${date.toLocaleString("default", {
			month: "long",
		})}/${date.getFullYear()}`;
		page.drawText(formattedDate, {
			x: 255,
			y: 343,
			size: 10,
		});
		page.drawText(form?.property_basic_info?.property_name, {
			x: 255,
			y: 330,
			size: 10,
		});
		page.drawText(form?.property_location?.property_address, {
			x: 255,
			y: 317,
			size: 10,
		});
		page.drawText(form?.property_location?.property_city, {
			x: 255,
			y: 304,
			size: 10,
		});
		page.drawText(form?.property_location?.property_zip_code, {
			x: 255,
			y: 291,
			size: 10,
		});
		page.drawText(form?.property_location?.property_state, {
			x: 255,
			y: 278,
			size: 10,
		});
		page.drawText(form?.property_location?.property_country, {
			x: 255,
			y: 265,
			size: 10,
		});
		page.drawText(form?.property_basic_info?.property_star_rating?.toString(), {
			x: 255,
			y: 254,
			size: 10,
		});
		page.drawText(form?.property_contact_details?.email, {
			x: 255,
			y: 243,
			size: 10,
		});

		const gstPdfData = await fetch(
			toAbsoluteUrl("/media/docs/GSTagreement.pdf")
		).then((res) => res.arrayBuffer());
		const gstPdfDoc = await PDFDocument.load(gstPdfData);
		const gstPage = gstPdfDoc.getPage(0);

		page = pdfDoc.addPage();
		const gstPageContent = await pdfDoc.embedPage(gstPage);
		page.drawPage(gstPageContent);

		page = pdfDoc.getPage(5);
		page.drawText(form?.property_basic_info?.property_name, {
			x: 90,
			y: 588,
			size: 9,
		});
		page.drawText(
			`${form?.property_location?.property_address}, ${form?.property_location?.property_city}, ${form?.property_location?.property_state}, ${form?.property_location?.property_country} ${form?.property_location?.property_zip_code}`,
			{
				x: 75,
				y: 576,
				size: 9,
			}
		);

		const handoverPdfData = await fetch(
			toAbsoluteUrl("/media/docs/HandoverAgreement.pdf")
		).then((res) => res.arrayBuffer());
		const handoverPdfDoc = await PDFDocument.load(handoverPdfData);
		const handoverPage = handoverPdfDoc.getPage(0);

		page = pdfDoc.addPage();
		const handoverPageContent = await pdfDoc.embedPage(handoverPage);
		page.drawPage(handoverPageContent);

		page = pdfDoc.getPage(6);
		page.drawText(form?.property_basic_info?.property_name, {
			x: 90,
			y: 595,
			size: 9,
		});
		page.drawText(
			`${form?.property_location?.property_address}, ${
				form?.property_location?.property_city
			}, ${form?.property_location?.property_state}, ${
				form?.property_location?.property_country
					? form?.property_location?.property_country
					: ""
			} ${form?.property_location?.property_zip_code}`,
			{
				x: 75,
				y: 583,
				size: 9,
			}
		);
		page.drawText(`${user?.firstname} ${user?.lastname}`, {
			x: 95,
			y: 572,
			size: 9,
		});
		// console.log("doc URL1");

		const pdfBytes = await pdfDoc.save();
		// console.log(pdfBytes);
		const docUrl = URL.createObjectURL(
			new Blob([pdfBytes], { type: "application/pdf" })
		);
		// console.log("doc URL", docUrl);

		setTcFileUrl(docUrl);
	};

	const handleChange = (event: any, parent: any, value: any, child: any) => {
		let obj = { ...form };
		if (obj.property_tc_agreed) obj["property_tc_agreed"] = false;
		if (!obj[parent][value]) obj[parent][value] = {};
		obj[parent][value][child] = event.target.value;
		checkMandatory(obj);
		setForm(obj);
		setChanges(true);
		setAddedInfo(false);
		// console.log(obj, "form object of legals");
	};

	const handleFileChange = (event: any) => {
		setLoading(true);
		setUploadFlag(false);
		if (documents.length > 4) {
			enqueueSnackbar("Can't upload files more than 5", {
				variant: "error",
				anchorOrigin: {
					horizontal: "right",
					vertical: "top",
				},
			});

			setLoading(false);

			return false;
		}

		for (let i = 0; i < event.target.files.length; i++) {
			let doc = event.target.files[i];
			// console.log(event.target, "event function", doc);
			if (doc?.size > 5242880) {
				enqueueSnackbar("Can't upload files larger than 5Mb", {
					variant: "error",
					anchorOrigin: {
						horizontal: "right",
						vertical: "top",
					},
				});

				setLoading(false);

				return false;
			}
			setMessage("Documents are Uploading ...");
			let docTypeAllowed = Object.keys(fileMimeType);
			if (!docTypeAllowed.includes(doc.type)) {
				setFileTypeToast(true);
				return false;
			}
		}
		// console.log("evnt stargin of documents", event);
		if (!form.hasOwnProperty("_id")) return;
		for (let i = 0; i < event.target.files.length; i++) {
			let doc = event.target.files[i];
			let formData = new FormData();
			formData.append("property_id", form?._id);
			formData.append("property_doc", doc);
			// console.log("has own property id", form._id, doc);
			axios
				.post(`${API_URL}/v1/vendor/documents`, formData)
				.then((res) => {
					setMessage(res.data.message);
					// setMessage("Documents Uploaded Successfully")
					setUploadFlag(true);
					setLoading(false);
					// setRecentDoc(res?.data?.data);
					let obj = res?.data?.data;
					// window.location.reload();
					// history.push("/crafted/pages/profile/financeLegal");
					axios
						.get(`${API_URL}/v1/vendor/documents?property_id=${form?._id}`)
						.then((res) => {
							setDocuments(res.data.data);
							// console.log(res, "finaacne useeffect", form._id);
						})
						.catch((e) => {
							console.log("error", e);
							// setFinanceDisabled(true);
						});

					// console.log("documents", documents);
					// console.log("documents obj", obj);
					// documents.push(obj);
					// setDocuments(documents);
					// axios
					// 	.get(`${API_URL}/v1/vendor/documents?property_id=${form?._id}`)
					// 	.then((res) => {
					// 		console.log("Trace", res.data);
					// 		setDocuments(res.data.data);
					// 	});
				})
				.catch((err) => console.log(err));
		}
		let obj = { ...form };
		if (obj.property_tc_agreed) {
			obj["property_tc_agreed"] = false;
			checkMandatory(obj);
			setForm(obj);
		}
		setChanges(true);
		setAddedInfo(false);
	};

	const handleOwnershipChange = (value: any) => {
		let obj = { ...form };
		obj["property_finance_legal"]["registration_details"]["ownership_type"] =
			value;
		if (obj.property_tc_agreed) obj["property_tc_agreed"] = false;
		checkMandatory(obj);
		setForm(obj);
		setChanges(true);
		setAddedInfo(false);
	};

	const handleGSTChange = (event: any, parent: any, value: any) => {
		let obj = { ...form };
		obj[parent][value] = event.target.value;
		if (obj.property_tc_agreed) obj["property_tc_agreed"] = false;
		checkMandatory(obj);
		setForm(obj);
		setChanges(true);
		setAddedInfo(false);
	};

	const handleTcChange = (event: any) => {
		let obj = { ...form };
		obj["property_tc_agreed"] = event.target.checked;
		checkMandatory(obj);
		setForm(obj);
		setChanges(true);
		setAddedInfo(false);
	};

	const checkMandatory = (form: any) => {
		const financeDisabled = !checkMandatoryFinanceLegal(form);
		if (hasTAN && form?.property_finance_legal?.pan_details?.tan_no === "") {
			setFinanceDisabled(true);
		} else if (hasGST && form?.property_finance_legal?.gst_details === "") {
			setFinanceDisabled(true);
		} else {
			setFinanceDisabled(financeDisabled);
		}
	};

	// const checkMandatory = (form: any) => {
	//   if (checkMandatoryFinanceLegal(form)) {
	//     if(hasTAN) {
	//       if(form?.property_finance_legal?.pan_details?.tan_no !== ''){
	//         setFinanceDisabled(false);
	//       }else {
	//         setFinanceDisabled(true);
	//       }
	//     }else {
	//       setFinanceDisabled(false);
	//     }

	//   if(hasGST) {
	//       if(form?.property_finance_legal?.gst_details !== ''){
	//         setFinanceDisabled(false);
	//       }else {
	//         setFinanceDisabled(true);
	//       }
	//     }else {
	//       setFinanceDisabled(false);
	//     }

	//   } else {
	//     setFinanceDisabled(true);
	//   }

	// }

	// const checkMandatory = (form: any) => {
	//   if (checkMandatoryFinanceLegal(form) && documents.length > 0) {
	//     // if (
	//     //   (hasTAN && (!form?.property_finance_legal?.pan_details?.tan_no
	//     //   || form?.property_finance_legal?.pan_details?.tan_no === ''))
	//     //    ||
	//     //     (hasGST && (!form?.property_finance_legal?.gst_details ||
	//     //     form?.property_finance_legal?.gst_details === ''))
	//     //     )
	//     //   // setFinanceDisabled(true)
	//     //   console.log("checkMandatory1", true)
	//     //   else
	//     //   // setFinanceDisabled(false);
	//     //   console.log("checkMandatory2", false)

	//     // let test = hasTAN;
	//     // console.log(test, "test")

	//     }
	//     else
	//     setFinanceDisabled(true);
	//     console.log("checkMandatory3", true)
	// }

	const handleToggleTAN = (checked: boolean) => {
		let obj = { ...form };
		if (obj.property_tc_agreed) obj["property_tc_agreed"] = false;
		if (checked) obj["property_finance_legal"]["pan_details"]["tan_no"] = "";
		checkMandatory(obj);
		setHasTAN(checked);
		setForm(obj);
		setChanges(true);
		setAddedInfo(false);
	};

	const handleToggleGST = (checked: boolean) => {
		let obj = { ...form };
		if (obj.property_tc_agreed) obj["property_tc_agreed"] = false;
		if (checked) obj["property_finance_legal"]["gst_details"] = "";
		checkMandatory(obj);
		setHasGST(checked);
		setForm(obj);
		setChanges(true);
		setAddedInfo(false);
	};

	const onboarding = async () => {
		// console.log("onboraigng -----", form);

		// console.log("on boarding is true");
		// ------------------------------ commented code---------------------
		setIsSubmitting(true);

		if (!checkMandatoryBasicInfo(form)) {
			setErrMessage("Basic Info Details are Incomplete/Invalid");
			setErrorToast(true);
			setNavigateTo("/crafted/pages/profile/BasicInfo");
			return;
		}
		if (!checkMandatoryLocation(form)) {
			setErrMessage("Location Details are Incomplete/Invalid");
			setErrorToast(true);
			setNavigateTo("/crafted/pages/profile/Location");
			return;
		}

		if (!checkMandatoryAmenities(form.property_amenities)) {
			setErrMessage("Amenities Details are Incomplete");
			setErrorToast(true);
			setNavigateTo("/crafted/pages/profile/amenities");
			return;
		}

		let categoryErrMsg = "Following Category Details are Incomplete/Invalid -";
		let isCategoryError = false;
		// for (let category of form?.property_room_categories) {
		// 	if (!checkMandatoryCategory(category)) {
		// 		categoryErrMsg += ` ${category.category_basic_info.name},`;
		// 		isCategoryError = true;
		// 	}
		// }
		if (isCategoryError) {
			categoryErrMsg = categoryErrMsg.slice(0, -1);
			setErrMessage(categoryErrMsg);
			setErrorToast(true);
			setNavigateTo("/crafted/pages/profile/rooms");
			return false;
		}

		// const categoryNames = form.property_room_categories.map((ele: any) => ele.category_basic_info.name)
		// try {
		//   let res = await axios.get(`${API_URL}/v1/vendor/images?property_id=${form?._id}`)
		//   let isPhotoErr = false;
		//   let photoErrMsg = "";
		//   if (res) {
		//     let validationObj = checkMandatoryPhotos(res.data.data, categoryNames)
		//     photoErrMsg = 'Following Catagories missing minimum required photos - '
		//     for (let cat of categoryNames) {
		//       if (validationObj['property'] < 3) {
		//         photoErrMsg += 'Property,'
		//         isPhotoErr = true
		//       }
		//       if (validationObj[cat] < 3) {
		//         photoErrMsg += ` ${cat},`
		//         isPhotoErr = true
		//       }
		//     }
		// }
		// else {
		//   console.log("finance and legal else")
		// isPhotoErr = true
		// photoErrMsg = 'Property images are missing'
		// }
		// if (isPhotoErr) {
		//   photoErrMsg = photoErrMsg.slice(0, -1)
		//   setErrMessage(photoErrMsg);
		//   setErrorToast(true);
		//   setNavigateTo('/crafted/pages/profile/photos');
		//   return
		// }
		// }
		// catch {
		//   console.log("catch of finance and legal")
		//   setErrMessage("Property images are missing");
		//   setErrorToast(true);
		//   setNavigateTo('/crafted/pages/profile/photos');
		//   return
		// }

		if (!checkMandatoryPolicies(form)) {
			setErrMessage("Policies section has Incomplete/Invalid Details");
			setErrorToast(true);
			setNavigateTo("/crafted/pages/profile/policies");
			return;
		}

		// ------------------------------ commented code---------------------

		if (!changes) return console.log("no chagnes made");
		let body: any = { ...form };
		if (form.hasOwnProperty("_id")) {
			body["property_id"] = body._id;
		}
		if (body["property_status"] === "Incomplete")
			body["property_status"] = "Unverified";
		else if (body["property_status"] === "Verified")
			body["property_status"] = "Update";
		axios
			.post(`${API_URL}/v1/vendor/onboarding`, { data: body })
			.then((res: any) => {
				// console.log(res, "onboarding api of finance ");
				setToast(true);
				setIsSubmitting(false);
			})
			.catch((error) => {
				console.log(error, "onboarding error api of finance ");
			});
	};

	const navigate = () => {
		history.push(navigateTo);
	};

	const deleteDoc = (id: string) => {
		let documentCount = documents.length;
		let body = { property_id: form?._id, doc_id: id };
		axios
			.post(`${API_URL}/v1/vendor/documents/delete`, { data: body })
			.then(() => {
				documentCount--;
				if (documentCount === 0) setDocuments([]);
				else
					axios
						.get(`${API_URL}/v1/vendor/documents?property_id=${form?._id}`)
						.then((res) => {
							setDocuments(res.data.data);
						});
				setChanges(true);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	useEffect(() => {
		// console.log(form, "form info");
		if (
			form?.property_finance_legal?.registration_details.registration_doc_id !==
			""
		) {
			setDocuments(
				form?.property_finance_legal?.registration_details.registration_doc_id
			);
		}
		updatePDF();

		// if (form?._id)
		//   axios.get(`${API_URL}/v1/vendor/documents?property_id=${form?._id}`)
		//     .then((res) => {
		//       setDocuments(res.data.data);
		//       console.log(res, "finaacne useeffect", form._id)
		//     })
		//     .catch(() => {
		//       setFinanceDisabled(true);
		//     });
		if (form?.property_finance_legal?.pan_details?.tan_no !== "")
			setHasTAN(true);

		if (form?.property_finance_legal?.gst_details !== "") setHasGST(true);
		updatePDF();
		checkMandatory(form);
	}, []);

	// useEffect(() => {
	// 	if (form?._id)
	// 		axios
	// 			.get(`${API_URL}/v1/vendor/documents?property_id=${form?._id}`)
	// 			.then((res) => {
	// 				//   setDocuments(res.data.data);
	// 				console.log(res, "finaacne useeffect", form._id);
	// 			})
	// 			.catch(() => {
	// 				setFinanceDisabled(true);
	// 			});
	// }, []);
	// console.log("TcFileUrl", tcFileUrl);

	return (
		<div>
			<div className="card card-custom">
				<div className="card-header">
					<h3 className="card-title align-items-start flex-column">
						<span className="card-label fw-bolder fs-3 mb-1">Finance</span>
						<span className="text-muted fw-bold fs-7">
							Fill in your bank and PAN card details
						</span>
					</h3>
				</div>
				<div className="card-body">
					<div className="accordion" id="kt_accordion_1">
						<div className="accordion-item">
							<h2 className="accordion-header" id="kt_accordion_1_header_1">
								<button
									className="accordion-button fs-4 fw-bold collapsed"
									type="button"
									data-bs-toggle="collapse"
									data-bs-target="#kt_accordion_1_body_1"
									aria-expanded="false"
									aria-controls="kt_accordion_1_body_1"
								>
									Bank Details
								</button>
							</h2>
							<div
								id="kt_accordion_1_body_1"
								className="accordion-collapse collapse"
								aria-labelledby="kt_accordion_1_header_1"
								data-bs-parent="#kt_accordion_1"
							>
								<div className="accordion-body">
									<fieldset
										disabled={
											form?.property_status === "Unverified" ? true : false
										}
									>
										<div className="row">
											<div className="col-lg-5">
												<label className="required form-label">
													Account Number
												</label>
												<input
													value={
														form["property_finance_legal"]["bank_details"]
															?.account_no
													}
													onChange={(e) =>
														handleChange(
															e,
															"property_finance_legal",
															"bank_details",
															"account_no"
														)
													}
													type="number"
													className="form-control form-control-solid"
													placeholder=""
													onBlur={() =>
														setTouched({ ...touched, accountNo: true })
													}
												/>
												{touched.accountNo &&
													!form?.property_finance_legal?.bank_details
														?.account_no && (
														<span className="text-danger fs-8" role="alert">
															Account Number is required field
														</span>
													)}
												{form?.property_finance_legal?.bank_details
													?.account_no &&
													!/^\d+$/.test(
														form?.property_finance_legal?.bank_details
															?.account_no
													) && (
														<span className="text-danger fs-8" role="alert">
															Account Number can contain only numbers
														</span>
													)}
												<span className="text-white">.</span>
											</div>
											<div className="col-lg-3">
												<label className="required form-label">IFSC Code</label>
												<input
													value={
														form["property_finance_legal"]["bank_details"]
															?.ifsc_code
													}
													onChange={(e) =>
														handleChange(
															e,
															"property_finance_legal",
															"bank_details",
															"ifsc_code"
														)
													}
													maxLength={11}
													type="text"
													className="form-control form-control-solid"
													placeholder=""
													onBlur={() =>
														setTouched({ ...touched, ifscCode: true })
													}
												/>
												{touched.ifscCode &&
													!form?.property_finance_legal?.bank_details
														?.ifsc_code && (
														<span className="text-danger fs-8" role="alert">
															IFSC Code is required field
														</span>
													)}
											</div>
											<div className="col-lg-4">
												<label className="required form-label">Bank Name</label>
												<input
													maxLength={50}
													className="form-control form-control-solid"
													aria-label="Select example"
													type="text"
													value={
														form["property_finance_legal"]["bank_details"]
															?.bank_name
													}
													onChange={(e) =>
														handleChange(
															e,
															"property_finance_legal",
															"bank_details",
															"bank_name"
														)
													}
													onBlur={() =>
														setTouched({ ...touched, bankName: true })
													}
												/>
												{touched.bankName &&
													!form?.property_finance_legal?.bank_details
														?.bank_name && (
														<span className="text-danger fs-8" role="alert">
															Bank Name is required field
														</span>
													)}
												{form?.property_finance_legal?.bank_details
													?.bank_name &&
													!/^[aA-zZ\s\.]+$/.test(
														form?.property_finance_legal?.bank_details
															?.bank_name
													) && (
														<span className="text-danger fs-8" role="alert">
															Enter valid name of bank
														</span>
													)}
											</div>
										</div>
									</fieldset>
								</div>
							</div>
						</div>
						<div className="accordion-item">
							<h2 className="accordion-header" id="kt_accordion_1_header_2">
								<button
									className="accordion-button fs-4 fw-bold collapsed"
									type="button"
									data-bs-toggle="collapse"
									data-bs-target="#kt_accordion_1_body_2"
									aria-expanded="false"
									aria-controls="kt_accordion_1_body_2"
								>
									PAN Details
								</button>
							</h2>
							<div
								id="kt_accordion_1_body_2"
								className="accordion-collapse collapse"
								aria-labelledby="kt_accordion_1_header_2"
								data-bs-parent="#kt_accordion_1"
							>
								<div className="accordion-body">
									<fieldset
										disabled={
											form?.property_status === "Unverified" ? true : false
										}
									>
										<div className="row">
											<div className="col-lg-6 mb-5">
												<label className="required form-label">
													PAN Number
												</label>
												<input
													value={
														form["property_finance_legal"]["pan_details"]
															?.pan_no
													}
													onChange={(e) =>
														handleChange(
															e,
															"property_finance_legal",
															"pan_details",
															"pan_no"
														)
													}
													maxLength={10}
													type="text"
													className="form-control form-control-solid"
													placeholder=""
													onBlur={() => setTouched({ ...touched, panNo: true })}
												/>
												{touched.panNo &&
													!form?.property_finance_legal?.pan_details
														?.pan_no && (
														<span className="text-danger fs-8" role="alert">
															Pan Number is required field
														</span>
													)}
												<span className="text-white">.</span>
											</div>

											<div className="col-lg-6">
												<label className="form-label">
													Do you have a TAN number?
												</label>
												<div className="mb-10 d-flex">
													<div className="form-check form-check-custom form-check-solid form-check-sm me-10">
														<input
															onChange={() => handleToggleTAN(true)} //() => { setHasTAN(true); checkMandatory(form) }}
															className="form-check-input"
															name="tan"
															type="radio"
															value=""
															id="flexRadioSm"
															checked={hasTAN}
														/>
														<label
															className="form-check-label"
															htmlFor="flexRadioSm"
														>
															Yes
														</label>
													</div>

													<div className="form-check form-check-custom form-check-solid form-check-sm">
														<input
															onChange={() => handleToggleTAN(false)} //{ setHasTAN(false); checkMandatory(form) }}
															className="form-check-input"
															name="tan"
															type="radio"
															value=""
															id="flexRadioSm"
															checked={!hasTAN}
														/>
														<label
															className="form-check-label"
															htmlFor="flexRadioSm1"
														>
															No
														</label>
													</div>
												</div>
											</div>

											{hasTAN && (
												<div className="col-lg-6 mb-5">
													<label className="required form-label">
														TAN Number
													</label>
													<input
														value={
															form["property_finance_legal"]["pan_details"]
																?.tan_no
														}
														onChange={(e) =>
															handleChange(
																e,
																"property_finance_legal",
																"pan_details",
																"tan_no"
															)
														}
														type="text"
														className="form-control form-control-solid"
														placeholder=""
														onBlur={() =>
															setTouched({ ...touched, tanNo: true })
														}
													/>
													{touched.tanNo &&
														!form?.property_finance_legal?.pan_details
															?.tan_no && (
															<span className="text-danger fs-8" role="alert">
																Tan Number is required field
															</span>
														)}
													<span className="text-white">.</span>
												</div>
											)}
										</div>
									</fieldset>
								</div>
							</div>
						</div>

						<div className="accordion-item">
							<h2 className="accordion-header" id="kt_accordion_1_header_3">
								<button
									className="accordion-button fs-4 fw-bold collapsed"
									type="button"
									data-bs-toggle="collapse"
									data-bs-target="#kt_accordion_1_body_3"
									aria-expanded="false"
									aria-controls="kt_accordion_1_body_3"
								>
									Upload Registration Document
								</button>
							</h2>
							<div
								id="kt_accordion_1_body_3"
								className="accordion-collapse collapse"
								aria-labelledby="kt_accordion_1_header_3"
								data-bs-parent="#kt_accordion_1"
							>
								<div className="accordion-body">
									<fieldset
										disabled={
											form?.property_status === "Unverified" ? true : false
										}
									>
										<div className="row">
											<div className="col-lg-6">
												<label className="form-label">
													Is your property owned or leased
												</label>
												<div className="mb-10 d-flex">
													<div className="form-check form-check-custom form-check-solid form-check-sm me-10">
														<input
															checked={
																form["property_finance_legal"][
																	"registration_details"
																]?.ownership_type === "Owned" && true
															}
															onChange={() => handleOwnershipChange("Owned")}
															className="form-check-input"
															name="owned"
															type="radio"
															id="flexRadioSm"
														/>
														<label
															className="form-check-label"
															htmlFor="flexRadioSm"
														>
															Owned
														</label>
													</div>

													<div className="form-check form-check-custom form-check-solid form-check-sm">
														<input
															checked={
																form["property_finance_legal"][
																	"registration_details"
																]?.ownership_type === "Leased" && true
															}
															onChange={(e) => handleOwnershipChange("Leased")}
															className="form-check-input"
															name="owned"
															type="radio"
															id="flexRadioSm"
														/>
														<label
															className="form-check-label"
															htmlFor="flexRadioSm1"
														>
															Leased
														</label>
													</div>
												</div>
											</div>
											<Form.Group controlId="formFile" className="mb-3">
												{/* <Form.Label>Default file input example</Form.Label> */}
												<label className="required form-label">
													Upload Registration Documents
												</label>
												{documents && documents.length < 1 && (
													<span className="text-danger fs-8 ms-2" role="alert">
														(Registration Documents are required)
													</span>
												)}
												<Form.Control
													type="file"
													// multiple
													onChange={(e) => handleFileChange(e)}
													disabled={!form.hasOwnProperty("_id")}
												/>
											</Form.Group>
											<div>
												Allowed File types PDF, DOC, DOCX, JPEG, JPG, PNG
												<span style={{ color: "red", paddingLeft: "10px" }}>
													{message}
												</span>
											</div>

											{loading && (
												<div
													style={{ display: "flex", justifyContent: "center" }}
												>
													<img
														style={{ width: "50px" }}
														src="/media/img/icons/imgLoader.gif"
														alt="loading"
													/>
												</div>
											)}

											{documents &&
												documents.length > 0 &&
												documents.map((doc: any) => {
													return (
														<div className="col-lg-3">
															<div className="doc-box">
																<div className="doc-box-image-wrap">
																	<div>
																		<KTSVG
																			path={doc?.path}
																			className="svg-icon-3hx"
																		/>
																	</div>
																	<div>{doc?.fileName}</div>
																</div>
															</div>
															<button
																className="btn btn-light"
																style={{ width: "100%" }}
																onClick={() => window.open(doc?.path, "_blank")}
															>
																Download
															</button>
															{/* <button
															className="btn btn-light"
															style={{ width: "100%" }}
															onClick={() => deleteDoc(doc.doc_id)}
														>
															Delete
														</button> */}
														</div>
													);
												})}
											{recentDoc &&
												recentDoc.length > 0 &&
												recentDoc.map((doc: any) => {
													return (
														<div className="col-lg-3">
															<div className="doc-box">
																<div className="doc-box-image-wrap">
																	<div>
																		<KTSVG
																			path={doc?.path}
																			className="svg-icon-3hx"
																		/>
																	</div>
																	<div>{doc?.fileName}</div>
																</div>
															</div>
															<button
																className="btn btn-light"
																style={{ width: "100%" }}
																onClick={() => window.open(doc?.path, "_blank")}
															>
																Download
															</button>
															{/* <button
															className="btn btn-light"
															style={{ width: "100%" }}
															onClick={() => deleteDoc(doc.doc_id)}
														>
															Delete
														</button> */}
														</div>
													);
												})}
										</div>
									</fieldset>
								</div>
							</div>
						</div>

						<div className="accordion-item">
							<h2 className="accordion-header" id="kt_accordion_1_header_4">
								<button
									className="accordion-button fs-4 fw-bold collapsed"
									type="button"
									data-bs-toggle="collapse"
									data-bs-target="#kt_accordion_1_body_4"
									aria-expanded="false"
									aria-controls="kt_accordion_1_body_4"
								>
									GST Details
								</button>
							</h2>
							<div
								id="kt_accordion_1_body_4"
								className="accordion-collapse collapse"
								aria-labelledby="kt_accordion_1_header_4"
								data-bs-parent="#kt_accordion_1"
							>
								<div className="accordion-body">
									<fieldset
										disabled={
											form?.property_status === "Unverified" ? true : false
										}
									>
										<div className="row">
											<div className="col-lg-4">
												<label className="form-label">Do you have GSTIN</label>
												<div className="mb-10 d-flex">
													<div className="form-check form-check-custom form-check-solid form-check-sm me-10">
														<input
															onChange={() => handleToggleGST(true)} //setHasGST(true)}
															className="form-check-input"
															name="manager"
															type="radio"
															value=""
															id="flexRadioSm"
															checked={hasGST}
														/>
														<label
															className="form-check-label"
															htmlFor="flexRadioSm"
														>
															Yes
														</label>
													</div>

													<div className="form-check form-check-custom form-check-solid form-check-sm">
														<input
															onChange={() => handleToggleGST(false)}
															className="form-check-input"
															name="manager"
															type="radio"
															value=""
															id="flexRadioSm"
															checked={!hasGST}
														/>
														<label
															className="form-check-label"
															htmlFor="flexRadioSm1"
														>
															No
														</label>
													</div>
												</div>
											</div>

											{hasGST && (
												<div className="col-lg-6 mb-5">
													<label className="required form-label">
														GST Number
													</label>
													<input
														value={
															form["property_finance_legal"]["gst_details"]
														}
														onChange={(e) =>
															handleGSTChange(
																e,
																"property_finance_legal",
																"gst_details"
															)
														}
														type="text"
														className="form-control form-control-solid"
														placeholder=""
														onBlur={() =>
															setTouched({ ...touched, gstDetails: true })
														}
													/>
													{touched.gstDetails &&
														!form?.property_finance_legal?.gst_details && (
															<span className="text-danger fs-8" role="alert">
																GST Number is required field
															</span>
														)}
													<span className="text-white">.</span>
												</div>
											)}
										</div>
									</fieldset>
								</div>
							</div>
						</div>

						<div className="col-lg-12 mt-5">
							<div className="form-check form-check-solid fv-row">
								<fieldset
									disabled={
										form?.property_status === "Unverified" ? true : false
									}
								>
									<input
										className="form-check-input"
										type="checkbox"
										name="confirm"
										checked={form?.property_tc_agreed}
										onChange={(e) => handleTcChange(e)}
									/>
									<label
										className="form-check-label fw-bold ps-2 fs-6"
										htmlFor="deactivate"
									>
										I agree to all{" "}
										<a
											href="#"
											data-bs-toggle="modal"
											data-bs-target="#kt_modal_1"
										>
											{" "}
											terms of agreement{" "}
										</a>{" "}
										Read our Terms and conditions
									</label>
								</fieldset>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="d-flex flex-stack mt-10">
				<div className="me-2">
					<button
						type="button"
						className="btn btn-lg btn-secondary me-3"
						data-kt-stepper-action="previous"
						onClick={() => history.push("/crafted/pages/profile/policies")}
					>
						<span className="svg-icon svg-icon-4 me-1">
							<svg
								width="24"
								height="24"
								viewBox="0 0 24 24"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
								className="mh-50px"
							>
								<rect
									opacity="0.5"
									x="6"
									y="11"
									width="13"
									height="2"
									rx="1"
									fill="black"
								></rect>
								<path
									d="M8.56569 11.4343L12.75 7.25C13.1642 6.83579 13.1642 6.16421 12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75L5.70711 11.2929C5.31658 11.6834 5.31658 12.3166 5.70711 12.7071L11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25C13.1642 17.8358 13.1642 17.1642 12.75 16.75L8.56569 12.5657C8.25327 12.2533 8.25327 11.7467 8.56569 11.4343Z"
									fill="black"
								></path>
							</svg>
						</span>
						Back
					</button>
				</div>

				<div>
					<button
						type="submit"
						className="btn btn-lg btn-success me-3"
						onClick={onboarding}
						//  disabled={financeDisabled || isSubmitting}
						disabled={financeDisabled}
						// disabled={addedInfo}
					>
						<span className="indicator-label">
							Submit
							<span className="svg-icon svg-icon-3 ms-2 me-0">
								<svg
									width="24"
									height="24"
									viewBox="0 0 24 24"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
									className="mh-50px"
								>
									<rect
										opacity="0.5"
										x="18"
										y="13"
										width="13"
										height="2"
										rx="1"
										transform="rotate(-180 18 13)"
										fill="black"
									></rect>
									<path
										d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z"
										fill="black"
									></path>
								</svg>
							</span>
						</span>
					</button>
				</div>
			</div>

			{toast && (
				<Toast onClose={navigate} className="toast-success">
					<Toast.Header>
						<img
							src="holder.js/20x20?text=%20"
							className="rounded me-2"
							alt=""
						/>
						<strong className="me-auto">Success</strong>
					</Toast.Header>
					<Toast.Body>
						Information Submitted Successfully, You will be notified Once the
						Information is approved.
					</Toast.Body>
				</Toast>
			)}

			{errorToast && (
				<Toast onClose={navigate} className="toast-success">
					<Toast.Header>
						<img
							src="holder.js/20x20?text=%20"
							className="rounded me-2"
							alt=""
						/>
						<strong className="me-auto">Error</strong>
					</Toast.Header>
					<Toast.Body>{errMessage}</Toast.Body>
				</Toast>
			)}

			{fileTypeToast && (
				<Toast
					onClose={() => setFileTypeToast(false)}
					className="toast-success"
				>
					<Toast.Header>
						<img
							src="holder.js/20x20?text=%20"
							className="rounded me-2"
							alt=""
						/>
						<strong className="me-auto">Error</strong>
					</Toast.Header>
					<Toast.Body>
						Only file types PDF, DOC, DOCX, JPEG, JPG, PNG are allowed
					</Toast.Body>
				</Toast>
			)}

			{
				<div className="modal fade" tabIndex={-1} id="kt_modal_1">
					<div className="modal-dialog modal-lg">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title">
									Agreement and Terms and Conditions{" "}
								</h5>
								<div
									className="btn btn-icon btn-sm btn-active-light-primary ms-2"
									data-bs-dismiss="modal"
									aria-label="Close"
								>
									<i className="fa fa-times" aria-hidden="true"></i>
								</div>
							</div>
							<div className="modal-body">
								<PDFViewer fileUrl={tcFileUrl && tcFileUrl} />
							</div>
							<div className="modal-footer">
								<button
									type="button"
									className="btn btn-light"
									data-bs-dismiss="modal"
								>
									Close
								</button>
							</div>
						</div>
					</div>
				</div>
			}
		</div>
	);
}
