import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { AuthPage } from "../modules/auth";
import ProfilePage from "../modules/profile/ProfilePage";
import blog from "../modules/user/blog/blog";
import blogdetails from "../modules/user/blogdetails/blogdetails";
import confirmation from "../modules/user/confirmation/confirmation";
import Contactus from "../modules/user/contactus/Contactus";
import news from "../modules/user/news/news";
import paymentconfirm from "../modules/user/paymentconfirm/paymentconfirm";
import profile from "../modules/user/profile/profile";
import resortdetails from "../modules/user/resortdetails/resortdetails";
import roomdetails from "../modules/user/roomdetails/roomdetails";
import rooms from "../modules/user/rooms/rooms";
import services from "../modules/user/services/services";
import user from "../modules/user/user";
import Bookingdetails from "../modules/user/bookingdetails/bookingdetails";
import BookingCancelled from "../modules/user/bookingCancelled/bookingCancelled";
import TermsOfUse from "../modules/user/components/term/TermModel";
import Privacy from "../modules/user/components/term/Privacy";
import Refund from "../modules/user/components/term/Refund";

export function PublicRoutes() {
	let host = window.location.hostname;
	return (
		<Switch>
			{/* {host.includes("vendor") ? ( 
				- add vendor to use customer panel or remove it to use vendor & admin panel -  */}
			{host.includes("vendor") ? (
				<>
					<Route path="/auth" component={AuthPage} />
					<Route path="/crafted/pages/profile" component={ProfilePage} />
					<Route path="/onboarding" component={ProfilePage} />
					<Redirect to="/auth/login" />
				</>
			) : (
				<Switch>
					<Route path="/home" component={user} />
					<Route path="/news" component={news} />
					<Route path="/services" component={services} />
					<Route path="/contactus" component={Contactus} />

					<Route path="/termsofuse" component={TermsOfUse} />
					<Route path="/PrivacyPolicy" component={Privacy} />
					<Route path="/RefundandCancellationPolicy" component={Refund} />

					<Route path="/rooms" component={rooms} />
					<Route path="/resortdetails" component={resortdetails} />
					<Route path="/roomdetails" component={roomdetails} />
					<Route path="/confirmation" component={confirmation} />
					<Route path="/paymentconfirm" component={paymentconfirm} />
					<Route path="/bookingdetails" component={Bookingdetails} />
					<Route path="/bookingcancelled" component={BookingCancelled} />
					<Route path="/profile" component={profile} />
					<Route path="/blog" component={blog} />
					<Route path="/blogdetails" component={blogdetails} />
					<Redirect from="/" to="/home" />
				</Switch>
			)}
		</Switch>
	);
}
