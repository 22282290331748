import React, { useState } from "react";
import clsx from "clsx";
import { FC } from "react";
import Moment from "moment";
import { Link } from "react-router-dom";
import {
	KTSVG,
	toAbsoluteUrl,
	defaultAlerts,
	defaultLogs,
} from "../../../helpers";

interface Props {
	notifications: any[];
}

export const HeaderNotificationsMenu: FC<Props> = ({ notifications }) => {
	const [todayCheck, setTodayCheck] = useState<any>([]);
	const [booking, setBooking] = useState<any>([]);
	const [settlement, setSettlement] = useState<any>([]);

	React.useEffect(() => {
		let vendorId: any = sessionStorage.getItem("vendor_id");
		// console.log("---------1-------", sessionStorage.getItem("vendor_id"));
		// console.log("vendor", vendorId);

		let checkIN =
			notifications &&
			notifications?.filter((row: any) => {
				if (vendorId === row.booking_vendor_id) {
					if (row.booking_status === "Success") {
						let today = new Date().setHours(0, 0, 0, 0);
						let checkIn = new Date(row.checkIn_date_time).setHours(0, 0, 0, 0);
						if (today === checkIn) {
							return row;
						}
					}
				}
			});
		setTodayCheck(checkIN);
		let upcoming =
			notifications &&
			notifications?.filter((row: any) => {
				if (vendorId === row.booking_vendor_id) {
					if (row.booking_status === "Success") {
						let today = new Date().setHours(0, 0, 0, 0);
						let checkIn = new Date(row.checkIn_date_time).setHours(0, 0, 0, 0);

						if (today < checkIn) {
							return row;
						}
					}
				}
			});

		setBooking(upcoming);

		let settlment =
			notifications &&
			notifications?.filter((row: any) => {
				if (vendorId === row.booking_vendor_id) {
					if (row.invoice !== "") {
						return row;
					}
				}
			});
		setSettlement(settlment);

		// sessionStorage.setItem("first", JSON.stringify(checkIN && checkIN.length));
		// sessionStorage.setItem(
		// 	"second",
		// 	JSON.stringify(upcoming && upcoming.length)
		// );
		// sessionStorage.setItem(
		// 	"third",
		// 	JSON.stringify(settlment && settlment.length)
		// );
	}, [notifications]);

	return (
		<div
			className="menu menu-sub menu-sub-dropdown menu-column w-350px w-lg-375px"
			data-kt-menu="true"
		>
			<div className="d-flex flex-column bgi-no-repeat rounded-top">
				<ul className="nav nav-line-tabs nav-line-tabs-2x nav-stretch fw-bold px-9">
					<li className="nav-item">
						<a
							className="nav-link text-primary opacity-75 opacity-state-100 pb-4 active"
							data-bs-toggle="tab"
							href="#kt_topbar_notifications_1"
						>
							Check-ins
						</a>
					</li>

					<li className="nav-item">
						<a
							className="nav-link text-primary opacity-75 opacity-state-100 pb-4"
							data-bs-toggle="tab"
							href="#kt_topbar_notifications_2"
						>
							Bookings
						</a>
					</li>

					<li className="nav-item">
						<a
							className="nav-link text-primary opacity-75 opacity-state-100 pb-4"
							data-bs-toggle="tab"
							href="#kt_topbar_notifications_3"
						>
							Settlements
						</a>
					</li>
				</ul>
			</div>

			<div className="tab-content">
				<div
					className="tab-pane fade show active"
					id="kt_topbar_notifications_1"
					role="tabpanel"
				>
					<h3 className="text-dark text-center fw-bolder mt-5">
						Today's Check-ins
					</h3>
					<div className="scroll-y mh-325px my-5 px-8">
						{todayCheck &&
							todayCheck.length > 0 &&
							todayCheck.map((key: any, index: number) => (
								<div key={`alert${index}`} className="d-flex flex-stack py-4">
									<div className="d-flex align-items-center">
										<div
											className="mb-0 me-2"
											style={{
												display: "flex",
												flexDirection: "row",
												justifyContent: "space-between",
												width: "25rem",
												// border: "1px solid red",
												height: "42px",
												borderRadius: "14px",
											}}
										>
											<div
												style={{
													display: "flex",
													flexDirection: "column",
													justifyContent: "space-between",
												}}
											>
												<div className="fs-6 text-gray-800 text-hover-primary fw-bolder">
													{key?.booking_full_name}
												</div>
												<div className="text-gray-400 fs-7">
													{key?.booking_prop + "-" + key?.booking_room}
												</div>
											</div>

											<div className="badge badge-light fs-8">
												{Moment(new Date(key?.checkIn_date_time)).format(
													"MMMM DD"
												)}
											</div>
										</div>
									</div>
								</div>
							))}
					</div>

					{/* <div className='py-3 text-center border-top'>
          <Link
            to='/crafted/pages/profile'
            className='btn btn-color-gray-600 btn-active-color-primary'
          >
            View All{' '}
            <KTSVG path='/media/icons/duotone/Navigation/Right-2.svg' className='svg-icon-5' />
          </Link>
        </div> */}
				</div>

				<div
					className="tab-pane fade"
					id="kt_topbar_notifications_2"
					role="tabpanel"
				>
					<h3 className="text-dark text-center fw-bolder mt-5">
						Online Bookings
					</h3>
					<div className="scroll-y mh-325px my-5 px-8">
						{booking &&
							booking.length > 0 &&
							booking.map((key: any, index: number) => (
								<div key={`alert${index}`} className="d-flex flex-stack py-4">
									<div className="d-flex align-items-center">
										<div className="symbol symbol-35px me-4">
											<span className={clsx("symbol-label", `bg-light-info`)}>
												{" "}
												<KTSVG
													path={`/media/icons/duotone/Design/Image.svg`}
													className={`svg-icon-2 svg-icon-info`}
												/>
											</span>
										</div>

										<div className="mb-0 me-2">
											<a
												href="#"
												className="fs-6 text-gray-800 text-hover-primary fw-bolder"
											>
												{key?.booking_full_name}
											</a>
											<div className="text-gray-400 fs-7">
												{key?.booking_prop + "-" + key?.booking_room}
											</div>
										</div>
									</div>

									<span className="badge badge-light fs-8">
										{Moment(new Date(key?.checkIn_date_time)).format("MMMM DD")}
									</span>
								</div>
							))}
					</div>
				</div>

				<div
					className="tab-pane fade"
					id="kt_topbar_notifications_3"
					role="tabpanel"
				>
					<h3 className="text-dark text-center fw-bolder mt-5">
						Settlement Payments
					</h3>
					<div className="scroll-y mh-325px my-5 px-8">
						{settlement &&
							settlement.length > 0 &&
							settlement.map((key: any, index: number) => (
								<div key={`alert${index}`} className="d-flex flex-stack py-4">
									<div className="d-flex align-items-center">
										<div className="symbol symbol-35px me-4">
											<span className={clsx("symbol-label", `bg-light-info`)}>
												{" "}
												<KTSVG
													path={`/media/icons/duotone/Design/Image.svg`}
													className={`svg-icon-2 svg-icon-info`}
												/>
											</span>
										</div>

										<div className="mb-0 me-2">
											<a
												href="#"
												className="fs-6 text-gray-800 text-hover-primary fw-bolder"
											>
												{key?.booking_prop}
											</a>
											<div className="text-gray-400 fs-7">
												{"Invoice" + "-" + key?.invoice}
											</div>
										</div>
									</div>

									<span className="badge badge-light fs-8">
										{"Rs " + key?.booking_amount}
									</span>
								</div>
							))}
					</div>
				</div>
			</div>
		</div>
	);
};
