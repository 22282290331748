import { BsStar } from "react-icons/bs";
import { BsStarFill } from "react-icons/bs";
import { BsStarHalf } from "react-icons/bs";

const StarRating = ({ rating, color }) => {

  const ratingStar = Array.from({ length: 5 }, (elem, index) => {
    let number = index + 0.5;

    let StarPadding = window.innerWidth < 550 ? {padding: "0px 1px"} : {padding: "0px 4px"};
                    
// if(window.innerWidth < 550){
//   console.log("small")
// }
// else{ 
//   console.log("big")
// }
    return (
      <span key={index} style={StarPadding}>
        { rating >= index + 1 ? <BsStarFill style={{ color: `${color}` }} /> : rating >= number ?
         <BsStarHalf style={{ color: `${color}` }} /> : <BsStar style={{ color: `${color}` }} /> }
      </span>
    )
  })

  return (
    <>
      <div> {ratingStar} </div>
    </>
  )
}

export default StarRating



// http://localhost:5000/v1/user/room_cat?category_id=633665f110e91088cb403da9