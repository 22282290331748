import React, { FC, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useIntl } from "react-intl";
import { Col, Form, Nav, Row, Tab, Toast } from "react-bootstrap-v5";
// import { PageTitle } from "../../../_metronic/layout/core";
// import "./vendorDetails.scss";
// import {
//   StatisticsWidget1,
//   StatisticsWidget2,
//   StatisticsWidget3,
//   StatisticsWidget4,
//   StatisticsWidget5,
//   StatisticsWidget6,
// } from "../../../_metronic/partials/widgets";
import { KTSVG, toAbsoluteUrl } from "../../../../_metronic/helpers";
// import { useDispatch, useSelector } from "react-redux";
// import { fetchRoomsByPropertyId } from "./redux/RoomsReducer";
import axios from "axios";
import { Modal } from "react-bootstrap-v5";
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { isConditionalExpression } from "typescript";

const API_URL = process.env.REACT_APP_API_URL || '';

const VendorDetails: React.FC = ({ itemsPerPage = 10 }: any) => {




  return ( 
    <> 
    <div className="row for-mob-row">
    <div className="col-lg-4 for-mob-col">
      <div className="dash-box">
        <div className="d-flex align-items-start bg-light-warning rounded p-5">
          <span className="svg-icon svg-icon-warning me-5">
            <span className="svg-icon svg-icon-1">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="mh-50px"
              >
                <path
                  opacity="0.3"
                  d="M21.25 18.525L13.05 21.825C12.35 22.125 11.65 22.125 10.95 21.825L2.75 18.525C1.75 18.125 1.75 16.725 2.75 16.325L4.04999 15.825L10.25 18.325C10.85 18.525 11.45 18.625 12.05 18.625C12.65 18.625 13.25 18.525 13.85 18.325L20.05 15.825L21.35 16.325C22.35 16.725 22.35 18.125 21.25 18.525ZM13.05 16.425L21.25 13.125C22.25 12.725 22.25 11.325 21.25 10.925L13.05 7.62502C12.35 7.32502 11.65 7.32502 10.95 7.62502L2.75 10.925C1.75 11.325 1.75 12.725 2.75 13.125L10.95 16.425C11.65 16.725 12.45 16.725 13.05 16.425Z"
                  fill="black"
                ></path>
                <path
                  d="M11.05 11.025L2.84998 7.725C1.84998 7.325 1.84998 5.925 2.84998 5.525L11.05 2.225C11.75 1.925 12.45 1.925 13.15 2.225L21.35 5.525C22.35 5.925 22.35 7.325 21.35 7.725L13.05 11.025C12.45 11.325 11.65 11.325 11.05 11.025Z"
                  fill="black"
                ></path>
              </svg>
            </span>
          </span>

          <div className="flex-grow-1 me-2">
            <a
              href="#"
              className="fw-bolder text-gray-800 text-hover-primary fs-6"
            >
              Total Earnings
            </a>
            <div className="total-rs">
              <h2>Rs.6000</h2>
              <div>
                <img
                  src={toAbsoluteUrl("/media/img/arrows.png")}
                  alt="MyResorts.in"
                />
                <p>12%</p>
              </div>
            </div>
            <span className="text-muted fw-bold d-block">Total sales (30 days)</span>

          </div>
        </div>
      </div>
    </div>

    <div className="col-lg-4 for-mob-col">
      <div className="dash-box green">
        <div className="d-flex align-items-start bg-light-success rounded p-5">
          <span className="svg-icon svg-icon-success me-5">
            <span className="svg-icon svg-icon-1">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="mh-50px"
              >
                <path
                  opacity="0.3"
                  d="M21.25 18.525L13.05 21.825C12.35 22.125 11.65 22.125 10.95 21.825L2.75 18.525C1.75 18.125 1.75 16.725 2.75 16.325L4.04999 15.825L10.25 18.325C10.85 18.525 11.45 18.625 12.05 18.625C12.65 18.625 13.25 18.525 13.85 18.325L20.05 15.825L21.35 16.325C22.35 16.725 22.35 18.125 21.25 18.525ZM13.05 16.425L21.25 13.125C22.25 12.725 22.25 11.325 21.25 10.925L13.05 7.62502C12.35 7.32502 11.65 7.32502 10.95 7.62502L2.75 10.925C1.75 11.325 1.75 12.725 2.75 13.125L10.95 16.425C11.65 16.725 12.45 16.725 13.05 16.425Z"
                  fill="black"
                ></path>
                <path
                  d="M11.05 11.025L2.84998 7.725C1.84998 7.325 1.84998 5.925 2.84998 5.525L11.05 2.225C11.75 1.925 12.45 1.925 13.15 2.225L21.35 5.525C22.35 5.925 22.35 7.325 21.35 7.725L13.05 11.025C12.45 11.325 11.65 11.325 11.05 11.025Z"
                  fill="black"
                ></path>
              </svg>
            </span>
          </span>
          <div className="flex-grow-1 me-2">
            <a
              href="#"
              className="fw-bolder text-gray-800 text-hover-primary fs-6"
            >
              Total Bookings
            </a>
            <div className="total-rs">
              <h2>36</h2>


              <div className="green-h">
                <img
                  src={toAbsoluteUrl("/media/img/arrows.png")}
                  alt="MyResorts.in"
                />
                <p>14%</p>
              </div>
            </div>
            <span className="text-muted fw-bold d-block">Total bookings (30 days)</span>
          </div>
        </div>
      </div>
    </div>

    <div className="col-lg-4">
      <div className="dash-box pink">
        <div className="d-flex align-items-start bg-light-danger rounded p-5">
          <span className="svg-icon svg-icon-danger me-5">
            <span className="svg-icon svg-icon-1">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="mh-50px"
              >
                <path
                  opacity="0.3"
                  d="M21.25 18.525L13.05 21.825C12.35 22.125 11.65 22.125 10.95 21.825L2.75 18.525C1.75 18.125 1.75 16.725 2.75 16.325L4.04999 15.825L10.25 18.325C10.85 18.525 11.45 18.625 12.05 18.625C12.65 18.625 13.25 18.525 13.85 18.325L20.05 15.825L21.35 16.325C22.35 16.725 22.35 18.125 21.25 18.525ZM13.05 16.425L21.25 13.125C22.25 12.725 22.25 11.325 21.25 10.925L13.05 7.62502C12.35 7.32502 11.65 7.32502 10.95 7.62502L2.75 10.925C1.75 11.325 1.75 12.725 2.75 13.125L10.95 16.425C11.65 16.725 12.45 16.725 13.05 16.425Z"
                  fill="black"
                ></path>
                <path
                  d="M11.05 11.025L2.84998 7.725C1.84998 7.325 1.84998 5.925 2.84998 5.525L11.05 2.225C11.75 1.925 12.45 1.925 13.15 2.225L21.35 5.525C22.35 5.925 22.35 7.325 21.35 7.725L13.05 11.025C12.45 11.325 11.65 11.325 11.05 11.025Z"
                  fill="black"
                ></path>
              </svg>
            </span>
          </span>
          <div className="me-2 up-book">
            <div className="up-more">
              <a
                href="#"
                className="fw-bolder text-gray-800 text-hover-primary fs-6"
              >
                Upcoming Bookings
              </a>
              {/* <Link to="/order" className="more">More <i className="fa fa-angle-right" aria-hidden="true"></i> </Link> */}
            </div>
            <div className="booking-list">
            <ul>
                   <li>
                      <a href="#!"><span>Priyal Dhuri</span> 
                      <p>22/04/2023 -- 25/03/2023</p></a>
                    </li>
                   <li>
                      <a href="#!"><span>Raju Kumar</span> 
                      <p>24/05/2023 -- 28/03/2023</p></a>
                    </li>
                   <li>
                      <a href="#!"><span>Shubham </span> 
                      <p>29/03/2023 -- 02/04/2023</p></a>
                    </li>
                  </ul>
              {/* <ul>
                {dashboard?.upcoming_booking?.map((e: any) => (
                <li>
                  <a href="#!"><span>{e.booking_full_name}</span> <p>{e.checkIn_date_time} -- {e.checkOut_date_time}</p></a>
                </li>))}
              </ul> */}

            </div>
          </div>
        </div>
      </div>
    </div>

  </div>





<div className="col-xxl-12">
        <div className="card card-xxl-stretch">
          <div className="card-header align-items-center border-0 mt-4 low-height-title">
            <h3 className="card-title align-items-start flex-column">
              <span className="fw-bolder mb-2 text-dark">Pench Supreme</span>
              <span className="text-muted fw-bold fs-7">
              Quisque velit nisi, pretium ut lacinia in, elementum id enim.
            </span>
            </h3>
          </div>
          <div className="card-body pt-2 pb-0">
            {/* begin::Row */}

            <div className="row for-mob-row">
              <div className="col-lg-2 ps-1 pe-1 for-mob-col">
                  <div className={`category-card ${'Booked' == 'Booked' ? 'bg-warning' : 'Available' == 'Available' ? 'bg-success' : 'bg-danger'}`}>
                    {'Booked' == 'Booked' && (<span className="badge badge-secondary">Offline</span>)}
                    <div className="content-overlay"></div>
                    <KTSVG
                      path="/media/icons/duotone/Home/Home.svg"
                      className="svg-icon svg-icon-3x me-10"
                    />

                    <h2>Supreme</h2>
                    <p>Supreme is our best category for staying and enjoying out resorts</p>
                    {/* <p>{e.room_status == 'Booked' ? 'Booked By: ' + e?.booking_info?.booking_full_name : ele.category_basic_info.description}</p> */}

                    <div className="content-details fadeIn-bottom">
                      {/* {e.room_status == 'Booked' ? (
                        <button
                          type="button"
                          className="btn btn-success"
                          //data-bs-toggle="modal"
                          //data-bs-target="#kt_modal_2"
                          onClick={event => bookRoom(e)}
                          disabled={propertyStatus !== 'Verified'}
                        >
                          Book
                        </button>
                      ) : e.room_status == 'Available' ? (
                        <>
                          <button
                            type="button"
                            className="btn btn-success"
                            //data-bs-toggle="modal"
                            //data-bs-target="#kt_modal_2"
                            onClick={event => bookRoom(e)}
                            disabled={propertyStatus !== 'Verified'}
                          >
                            Book
                          </button>
                          <button
                            type="button"
                            className="btn btn-danger"
                            onClick={event => blockRoom(e)}
                            disabled={form?.property_status !== 'Verified'}
                          >
                            Block
                          </button>
                        </>) : (
                        <button
                          type="button"
                          className="btn btn-danger"
                          onClick={event => unblockRoom(e)}
                        >
                          Unblock
                        </button>
                      )} */}
                       <button
                          type="button"
                          className="btn btn-danger"
                        >
                          Unblock
                        </button>
                    </div>
                  </div>
                </div>
                
            </div>
          </div>
        </div>
      </div>
      

  </>
  )
}

export default VendorDetails
