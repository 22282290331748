import React from "react";
import { Button, Modal } from "react-bootstrap-v5";
import { Interface } from "readline";
import UserFooter from "../UserFooter";
import UserHeader from "../UserHeader";

const TermsOfUse: React.FC = () => {
	return (
		<>
	<UserHeader />
		<div style={{ marginLeft: '5rem', marginTop: '9rem', marginRight: '7rem', padding: '5rem' }}>
			<p
			style={{
				marginTop: "0cm",
				marginRight: "0cm",
				marginBottom: "8.0pt",
				marginLeft: "0cm",
				fontSize: "12.0pt",
				fontFamily: '"Montserrat", sans-serif !important;'
			}}
			>
			<strong>
				<span style={{ fontSize: 21, lineHeight: "107%" }}>
				Terms of use of MyResorts.in
				</span>
			</strong>
			</p>
			<p
			style={{
				marginTop: "0cm",
				marginRight: "0cm",
				marginBottom: "8.0pt",
				marginLeft: "0cm",
				fontSize: "12.0pt",
				fontFamily: '"Montserrat", sans-serif !important;'
			}}
			>
			We invite and welcome visitors on{" "}
			<a href="http://www.myresorts.in">www.myresorts.in</a> website. This aim
			of this Website is to assist customers to gather travel information, post
			reviews /opinions on their travel related experiences / issues, engage
			them in travel related forums so that they gather maximum information on
			the travel industry / countries/ cities around the world. By accessing or
			using <a href="http://www.myresorts.in">www.myresorts.in</a> in any
			manner, it is considered that you have agreed to be bound by the
			Agreement, as if you had signed this agreement. Please read the Agreement
			carefully. If you do not accept all of these terms and conditions, please
			do not use this Website. We suggest visitors of{" "}
			<a href="http://www.myresorts.in">www.myresorts.in</a> to return to this
			page periodically to review the most current version of the Agreement.{" "}
			<a href="http://www.myresorts.in">www.myresorts.in</a> reserves the right
			at any time, at our sole discretion, to change or otherwise modify the
			Agreement without prior notice, and your continued access or use of this
			Website signifies your acceptance of the updated or modified Agreement.
			</p>
			<p
			style={{
				marginTop: "0cm",
				marginRight: "0cm",
				marginBottom: "8.0pt",
				marginLeft: "0cm",
				fontSize: "12.0pt",
				fontFamily: '"Montserrat", sans-serif !important;'
			}}
			>
			<strong>Prices</strong>
			</p>
			<p
			style={{
				marginTop: "0cm",
				marginRight: "0cm",
				marginBottom: "8.0pt",
				marginLeft: "0cm",
				fontSize: "12.0pt",
				fontFamily: '"Montserrat", sans-serif !important;'
			}}
			>
			All Prices displayed for the Products and Services on MyResorts.in are
			subject to change without Prior information or notice and prices you see
			while making the booking may not be final until the full Payment for the
			Product or Service is made at the end of the Booking Process.
			</p>
			<p
			style={{
				marginTop: "0cm",
				marginRight: "0cm",
				marginBottom: "8.0pt",
				marginLeft: "0cm",
				fontSize: "12.0pt",
				fontFamily: '"Montserrat", sans-serif !important;'
			}}
			>
			Prices on <a href="http://www.myresorts.in">www.myresorts.in</a> shall be
			inclusive OR exclusive of Booking Fee, Hotel taxes, Airport taxes, Port
			taxes or any other applicable Government taxes (GST and VAT), etc.
			Availability of any Product or Service is subject to change at any point
			in time even while finalizing the booking, which can lead to Price
			variation, depending upon the basis of available room type in the Hotel,
			flight booking class, Cruise Cabin, etc at that point in time.
			</p>
			<p
			style={{
				marginTop: "0cm",
				marginRight: "0cm",
				marginBottom: "8.0pt",
				marginLeft: "0cm",
				fontSize: "12.0pt",
				fontFamily: '"Montserrat", sans-serif !important;'
			}}
			>
			<strong>Booking and Payments</strong>
			</p>
			<p
			style={{
				marginTop: "0cm",
				marginRight: "0cm",
				marginBottom: "8.0pt",
				marginLeft: "0cm",
				fontSize: "12.0pt",
				fontFamily: '"Montserrat", sans-serif !important;'
			}}
			>
			All Online Payments that happen for any of the Products or Services booked
			on <a href="http://www.myresorts.in">www.myresorts.in</a> are secure.
			MyResorts.in does not store the Credit / Debit or any other Payment Card
			details of any customer in the database or in any data backup system. Once
			you are ready to transact you are transferred directly to the Bank
			Website. The card details are captured on the Bank Website and not in
			MyResorts.in. MyResorts.in takes no responsibility, in case of any misuse
			of Credit Card by whomsoever.
			</p>
			<p
			style={{
				marginTop: "0cm",
				marginRight: "0cm",
				marginBottom: "8.0pt",
				marginLeft: "0cm",
				fontSize: "12.0pt",
				fontFamily: '"Montserrat", sans-serif !important;'
			}}
			>
			MyResorts.in acts as an agent for its third party providers and
			distributors for all products and services purchased by you on this
			website. You may place bookings for or may make purchases of products and
			services online at this site. A contract for the sale and purchase of
			products and services may be made online at this site by your acceptance
			of the products and services offered on the site (the "Contract") by third
			party providers and/or distributors and/or where stated, MyResorts.in, and
			you hereby agree to waive any rights to challenge the validity or
			enforceability of Contracts entered into on this site on the grounds that
			it was made in electronic form instead of by paper and/or signed or
			sealed. Please note that by indicating your acceptance to purchase any
			product or service offered on the website, you are obligated to complete
			such transactions. You are prohibited from indicating your acceptance to
			purchase products and services where you do not intend to complete such
			transactions. You may not purchase goods or services that you are
			prohibited from purchasing or possessing by any applicable Indian or local
			laws. The responsibility for ensuring compliance with all applicable laws
			shall be yours alone. By submitting an order to purchase goods or
			services, you represent and warrant that you have the legal right to
			purchase, utilise and/or possess such goods or services
			</p>
			<p
			style={{
				marginTop: "0cm",
				marginRight: "0cm",
				marginBottom: "8.0pt",
				marginLeft: "0cm",
				fontSize: "12.0pt",
				fontFamily: '"Montserrat", sans-serif !important;'
			}}
			>
			In case any user is booking a ticket/hotel or any other online services
			via a credit/debit/gift card which is not directly in the name of the
			person availing these services -{" "}
			<a href="http://www.myresorts.in">www.myresorts.in</a> reserves the right
			to cancel/hold the said service till the time proper verification is
			provided. In case you are booking using someone else`s credit/debit/gift
			card - you are requested to send us a copy of the card used (at the
			following email id: myresortsinfo@gmail.com or call us at 9579557225) a
			photo identification card of the person in whose name the card is, as well
			as the person in whose name the services are being provided and an
			authority letter from the said credit/debit/gift card holder.
			</p>
			<p
			style={{
				marginTop: "0cm",
				marginRight: "0cm",
				marginBottom: "8.0pt",
				marginLeft: "0cm",
				fontSize: "12.0pt",
				fontFamily: '"Montserrat", sans-serif !important;'
			}}
			>
			In case any of the above mentioned conditions are not met,
			www.myresorts.inreserves the right to declare such transactions as
			fraudulent and cancel/hold the service provided. It also holds the right
			to charge the requisite Cancellation Charges that may be applicable before
			refunding any such amount.
			</p>
			<p
			style={{
				marginTop: "0cm",
				marginRight: "0cm",
				marginBottom: "8.0pt",
				marginLeft: "0cm",
				fontSize: "12.0pt",
				fontFamily: '"Montserrat", sans-serif !important;'
			}}
			>
			<strong>Amendment to booking &amp; purchase</strong>
			</p>
			<p
			style={{
				marginTop: "0cm",
				marginRight: "0cm",
				marginBottom: "8.0pt",
				marginLeft: "0cm",
				fontSize: "12.0pt",
				fontFamily: '"Montserrat", sans-serif !important;'
			}}
			>
			Kindly read and note the Terms &amp; Conditions for amendment in bookings
			and Purchases, while making the booking or Purchase, which may vary for
			each product and service. If you don't find any Terms and conditions
			regarding the amendment policy, you may NOT change your booking or
			purchase online. Kindly call our Customer care team to know the amendment
			policy and do the necessary amendment.
			</p>
			<p
			style={{
				marginTop: "0cm",
				marginRight: "0cm",
				marginBottom: "8.0pt",
				marginLeft: "0cm",
				fontSize: "12.0pt",
				fontFamily: '"Montserrat", sans-serif !important;'
			}}
			>
			<strong>Cancellation &amp; refunds</strong>
			</p>
			<p
			style={{
				marginTop: "0cm",
				marginRight: "0cm",
				marginBottom: "8.0pt",
				marginLeft: "0cm",
				fontSize: "12.0pt",
				fontFamily: '"Montserrat", sans-serif !important;'
			}}
			>
			Cancellation of Bookings and Cancellation Policy differs for each Product
			OR Service. When you make a booking, kindly view Terms &amp; Conditions of
			each Product OR Service, which reflects the Cancellation, Refund with
			Booking Terms &amp; Conditions. You can cancel the purchased product or
			service online on our Website - www.myresorts.in. If you find difficulty
			in cancelling, you can get the same cancelled by calling our customer care
			team. Cancellation at your request may require a minimum processing time,
			subject to specific terms and conditions applicable to the type of Product
			or Service booked &amp; purchased.
			</p>
			<p
			style={{
				marginTop: "0cm",
				marginRight: "0cm",
				marginBottom: "8.0pt",
				marginLeft: "0cm",
				fontSize: "12.0pt",
				fontFamily: '"Montserrat", sans-serif !important;'
			}}
			>
			You can cancel the purchased product or service online on our website -{" "}
			<a href="http://www.myresorts.in">www.myresorts.in</a> ; If you find
			difficulty in cancelling, you can get the same cancelled by calling our
			customer care team. Cancellation at your request may require a minimum
			processing time, subject to specific terms and conditions applicable to
			the type of Product or Service booked &amp; purchased.
			</p>
			<p
			style={{
				marginTop: "0cm",
				marginRight: "0cm",
				marginBottom: "8.0pt",
				marginLeft: "0cm",
				fontSize: "12.0pt",
				fontFamily: '"Montserrat", sans-serif !important;'
			}}
			>
			There may be full Cancellation penalty on the Products or services booked
			and purchased, which are Non-utilized or Cancelled after the Cancellation
			deadline mentioned in the Terms and Conditions, while making the purchase.
			</p>
			<p
			style={{
				marginTop: "0cm",
				marginRight: "0cm",
				marginBottom: "8.0pt",
				marginLeft: "0cm",
				fontSize: "12.0pt",
				fontFamily: '"Montserrat", sans-serif !important;'
			}}
			>
			You agree to bear the full cost of any booking, cancellation and
			administration fees for any products or services booked but not utilized
			for any reason. In some cases, MyResorts.in may charge cancellation or
			amendment fees in addition to those imposed by travel services providers.
			</p>
			<p
			style={{
				marginTop: "0cm",
				marginRight: "0cm",
				marginBottom: "8.0pt",
				marginLeft: "0cm",
				fontSize: "12.0pt",
				fontFamily: '"Montserrat", sans-serif !important;'
			}}
			>
			MyResorts.in reserves the right to decline any booking or purchase for any
			reason and shall not be held liable for any resulting claims of losses,
			damages or compensation. In such an event, MyResorts.in shall refund to
			you all unutilized money collected from you for that purchase.
			</p>
			<p
			style={{
				marginTop: "0cm",
				marginRight: "0cm",
				marginBottom: "8.0pt",
				marginLeft: "0cm",
				fontSize: "12.0pt",
				fontFamily: '"Montserrat", sans-serif !important;'
			}}
			>
			For Bookings and Purchases, which has already been paid by you, when
			cancelled, refunds will be made (based on the Refund Policy, mentioned in
			Terms and Conditions, while making the booking or at the time of
			Purchase). For the Hotels it may take between 10 - 15 working days for the
			amount to get credited into your account. Refund policy may vary for every
			Product and Service
			</p>
			<p
			style={{
				marginTop: "0cm",
				marginRight: "0cm",
				marginBottom: "8.0pt",
				marginLeft: "0cm",
				fontSize: "12.0pt",
				fontFamily: '"Montserrat", sans-serif !important;'
			}}
			>
			The preceding refund timeline is a guide for your reference only and shall
			not be binding upon MyResorts.in.
			</p>
			<p
			style={{
				marginTop: "0cm",
				marginRight: "0cm",
				marginBottom: "8.0pt",
				marginLeft: "0cm",
				fontSize: "12.0pt",
				fontFamily: '"Montserrat", sans-serif !important;'
			}}
			>
			<strong>Duplicate bookings</strong>
			</p>
			<p
			style={{
				marginTop: "0cm",
				marginRight: "0cm",
				marginBottom: "8.0pt",
				marginLeft: "0cm",
				fontSize: "12.0pt",
				fontFamily: '"Montserrat", sans-serif !important;'
			}}
			>
			You agree that multiple duplicate bookings for the same traveller/s are
			not allowed and may result in damage and loss to MyResorts.in, which you
			will bear in full.
			</p>
			<p
			style={{
				marginTop: "0cm",
				marginRight: "0cm",
				marginBottom: "8.0pt",
				marginLeft: "0cm",
				fontSize: "12.0pt",
				fontFamily: '"Montserrat", sans-serif !important;'
			}}
			>
			<strong>Products &amp; services</strong>
			</p>
			<p
			style={{
				marginTop: "0cm",
				marginRight: "0cm",
				marginBottom: "8.0pt",
				marginLeft: "0cm",
				fontSize: "12.0pt",
				fontFamily: '"Montserrat", sans-serif !important;'
			}}
			>
			Products &amp; Services statements on this Website are for general
			description purposes only and not all the Products or Services are
			available in every state or country. You can send us an enquiry by
			clicking our Contact us or Send Query section, we will provide you with
			the details regarding terms &amp; conditions, exclusions, products and
			services applicable for you. Please note the Products and Services that
			can be booked and purchased on the Website
			</p>
			<p
			style={{
				marginTop: "0cm",
				marginRight: "0cm",
				marginBottom: "8.0pt",
				marginLeft: "0cm",
				fontSize: "12.0pt",
				fontFamily: '"Montserrat", sans-serif !important;'
			}}
			>
			1. <strong>Hotels</strong> -When you make a Hotel booking on the website,
			kindly go through the fact sheet with terms and conditions carefully to
			ensure the amenities present in the hotel or the category of the hotel is
			as per your requirement. MyResorts.in keeps updating the fact sheets with
			terms and conditions of the hotels, however it is dependent on the hotel
			to provide us with the updated information. Renovation at hotels is
			normally undertaken at sole discretion of hotels and not necessarily is
			informed to us. In case of any disputes, MyResorts.in shall not be held
			liable for any disruption in information
			</p>
			<p
			style={{
				marginTop: "0cm",
				marginRight: "0cm",
				marginBottom: "8.0pt",
				marginLeft: "0cm",
				fontSize: "12.0pt",
				fontFamily: '"Montserrat", sans-serif !important;'
			}}
			>
			2. <strong>Sightseeing Tours</strong> - There is a choice of Seat in Coach
			(SIC) sightseeing and Private sightseeing tours. Seat in Coach tours /
			transfers may have a waiting time at the airport / hotels. Seat In Coach
			tours are as per the fixed timings and does not allow you to stop at any
			site of interest to you, which are not a Part of the Program, as Seat in
			Coach tours are fixed with a Schedule. You have to make a choice according
			to your requirement and budget. Entrance Fees at the monuments or places
			of Interest are not included in the Sightseeing tours unless and until
			specified.
			</p>
			<p
			style={{
				marginTop: "0cm",
				marginRight: "0cm",
				marginBottom: "8.0pt",
				marginLeft: "0cm",
				fontSize: "12.0pt",
				fontFamily: '"Montserrat", sans-serif !important;'
			}}
			>
			3. <strong>Airlines</strong> - There is a choice to book low cost airlines
			and full service airlines options. Low Cost Airlines do not have all the
			services on board in the flight, so kindly choose the airlines carefully
			and please read the terms &amp; conditions / cancellation Policy before
			making any purchase of the airlines ticket. There are paper tickets /
			e-tickets that will be issued to you and both are valid to travel by the
			airlines. With E-tickets, there is a mandatory requirement of Original
			Identity Photo Card for you to carry and show at the check-in counter of
			the Airport. MyResorts.in is only an Intermediary party and hence takes no
			responsibility in case of any delay, change in route or cancellation in
			the flight due to any reasons.
			</p>
			<p
			style={{
				marginTop: "0cm",
				marginRight: "0cm",
				marginBottom: "8.0pt",
				marginLeft: "0cm",
				fontSize: "12.0pt",
				fontFamily: '"Montserrat", sans-serif !important;'
			}}
			>
			4. <strong>Holidays</strong> - Kindly read the Terms &amp; conditions with
			the Inclusions, Package Itinerary and other details before making any
			purchase of the Holiday Packages. In case if the Package is not as per
			your requirement or suits your needs, we can also customize the same for
			you. Kindly click on the Send Query of the Website with all the details
			like Hotel Category, No. of Nights, no. of pax, destinations would like to
			visit, etc and our Customer Care team will get back to you with the
			Package
			</p>
			<p
			style={{
				marginTop: "0cm",
				marginRight: "0cm",
				marginBottom: "8.0pt",
				marginLeft: "0cm",
				fontSize: "12.0pt",
				fontFamily: '"Montserrat", sans-serif !important;'
			}}
			>
			5. <strong>Car Rental (Self drive or Chauffeur drive)</strong> - Please
			read the Terms &amp; conditions before making any purchase of this
			service. MyResorts.in takes no responsibility in case of any break down of
			the car or the quality of car is not up to the level and delays due to bad
			traffic conditions, any penalty due to speed by the driver or by yourself,
			etc.
			</p>
			<p
			style={{
				marginTop: "0cm",
				marginRight: "0cm",
				marginBottom: "8.0pt",
				marginLeft: "0cm",
				fontSize: "12.0pt",
				fontFamily: '"Montserrat", sans-serif !important;'
			}}
			>
			6. <strong>Travel Insurance</strong> - MyResorts.in strongly recommends
			insurance cover and safety for all the traveller/s travelling domestic or
			outside the Country. Traveller/s can purchase the travel Insurance on the
			Website. Please read the terms and conditions given by the Insurance
			Provider before issuing the same. MyResorts.in takes no responsibility and
			liability on the claims and disputes whatsoever as mentioned by the
			Insurance Provider.
			</p>
			<p
			style={{
				marginTop: "0cm",
				marginRight: "0cm",
				marginBottom: "8.0pt",
				marginLeft: "0cm",
				fontSize: "12.0pt",
				fontFamily: '"Montserrat", sans-serif !important;'
			}}
			>
			<strong>Delivery of products &amp; services</strong>
			</p>
			<p
			style={{
				marginTop: "0cm",
				marginRight: "0cm",
				marginBottom: "8.0pt",
				marginLeft: "0cm",
				fontSize: "12.0pt",
				fontFamily: '"Montserrat", sans-serif !important;'
			}}
			>
			1. <strong>Hotels</strong> - When a Hotel booking is made and purchased on
			the Website, it gives an instant confirmation after the Payment is made
			completely and you will receive the hotel confirmation voucher in your
			mentioned email id. Voucher give details about the Hotel Address,
			Telephone number, Hotel Confirmation number, Meal Plan, Payment details,
			Supplier details, other relevant important information etc. Voucher is to
			be given to the Hotel at the Check-in counter.
			</p>
			<p
			style={{
				marginTop: "0cm",
				marginRight: "0cm",
				marginBottom: "8.0pt",
				marginLeft: "0cm",
				fontSize: "12.0pt",
				fontFamily: '"Montserrat", sans-serif !important;'
			}}
			>
			2. <strong>Sightseeing Tours</strong> -When a sightseeing tour is booked
			and purchased online, an Instant confirmation voucher is issued and you
			will receive the same in your mentioned email id after the Payment is made
			completely. Voucher gives details about the pick-up time, pick-up point,
			date of the sightseeing tour, supplier details, Payment details, other
			relevant important information etc. There is a choice of Seat in Coach
			(SIC) sightseeing and Private sightseeing tours. Seat in Coach tours /
			transfers may have waiting time at the airport / hotels.
			</p>
			<p
			style={{
				marginTop: "0cm",
				marginRight: "0cm",
				marginBottom: "8.0pt",
				marginLeft: "0cm",
				fontSize: "12.0pt",
				fontFamily: '"Montserrat", sans-serif !important;'
			}}
			>
			3. <strong>Airlines</strong>
			<br />
			<strong>E-tickets</strong> - An E-ticket is a paperless electronic
			document with a unique confirmation number given to passengers in place of
			Paper ticket. Passengers are required to produce the unique confirmation
			number print-out at the airlines check-in counter to get the boarding
			pass.
			<br />
			<strong>How to receive an E-ticket?</strong> After the complete Payment is
			made online via credit / debit or any other Payment Card, your e-ticket
			details will be mailed to you at your email address mentioned at the time
			of making the Purchase. In case if you don't have an email address, the
			details will be given to you over the phone or you can come to pick up
			your e ticket from our office or directly from the airlines counter at the
			airport by giving them the unique confirmation number - PNR
			<br />
			<strong>Paper Tickets</strong> - Some of the airlines are still issuing
			Paper tickets. You will receive the booking confirmation number
			immediately after you have made full Payment online. All the paper tickets
			will be dispatched to the delivery address mentioned at the time of making
			the Purchase. It takes 3 working days to receive the Paper ticket at your
			address from the day of making the purchase. MyResorts.in would not be
			responsible in case if the customer is not present at the address at the
			time of delivery.
			</p>
			<p
			style={{
				marginTop: "0cm",
				marginRight: "0cm",
				marginBottom: "8.0pt",
				marginLeft: "0cm",
				fontSize: "12.0pt",
				fontFamily: '"Montserrat", sans-serif !important;'
			}}
			>
			4. <strong>Holidays</strong> - Holiday Packages takes minimum 24 Hours in
			getting the Confirmation, once the Payment is made online on the Website,
			our customer care team will get in touch with you and will mail you the
			confirmation vouchers along with the day to day program at the email
			address mentioned by you at the time of making the Purchase or in case if
			there is no email ID, vouchers will be delivered at your address within 3
			working days at no additional cost. MyResorts.in would not be responsible
			in case if the customer is not present at the address at the time of
			delivery.
			</p>
			<p
			style={{
				marginTop: "0cm",
				marginRight: "0cm",
				marginBottom: "8.0pt",
				marginLeft: "0cm",
				fontSize: "12.0pt",
				fontFamily: '"Montserrat", sans-serif !important;'
			}}
			>
			5. <strong>Rail and Cruises</strong> - When a Rail or Cruise booking is
			made and purchased on the Website, it gives an instant confirmation after
			the Payment is made completely and you will receive the rail confirmation
			ticket or Cruise voucher in your email id mentioned at the time of making
			the purchase . If you don't have an email Id, you can call our Customer
			Care team and can get the same collected from our Office or it can also be
			delivered at your doorstep at an additional cost.
			</p>
			<p
			style={{
				marginTop: "0cm",
				marginRight: "0cm",
				marginBottom: "8.0pt",
				marginLeft: "0cm",
				fontSize: "12.0pt",
				fontFamily: '"Montserrat", sans-serif !important;'
			}}
			>
			6. <strong>Car Rental (Self drive or Chauffeur drive)</strong> - It takes
			minimum 4 - 5 Hours for the Car rental confirmation. Once the Payment is
			made online, our customer care team will get in touch with you and give
			you the confirmation details over the phone and will also email you the
			confirmation voucher.
			</p>
			<p
			style={{
				marginTop: "0cm",
				marginRight: "0cm",
				marginBottom: "8.0pt",
				marginLeft: "0cm",
				fontSize: "12.0pt",
				fontFamily: '"Montserrat", sans-serif !important;'
			}}
			>
			7. <strong>Travel Insurance</strong> - Travel Insurance Policy will be
			issued and mailed to you instantly at an email Id given at the time of
			making the purchase. In case if you don't have an email Id, you can get
			the details from our Customer Care Team and same can be collected from our
			Office or can be delivered at your doorstep at an additional cost.
			</p>
			<p
			style={{
				marginTop: "0cm",
				marginRight: "0cm",
				marginBottom: "8.0pt",
				marginLeft: "0cm",
				fontSize: "12.0pt",
				fontFamily: '"Montserrat", sans-serif !important;'
			}}
			>
			<strong>Third party travel service providers</strong>
			</p>
			<p
			style={{
				marginTop: "0cm",
				marginRight: "0cm",
				marginBottom: "8.0pt",
				marginLeft: "0cm",
				fontSize: "12.0pt",
				fontFamily: '"Montserrat", sans-serif !important;'
			}}
			>
			You may be bound by terms and conditions imposed by the travel service
			providers for whom MyResorts.in acts as an agent including conditions of
			carriage, refund and cancellation policies of airlines, cruise lines, car
			rental companies, hotels, vacation rentals, etc.
			</p>
			<p
			style={{
				marginTop: "0cm",
				marginRight: "0cm",
				marginBottom: "8.0pt",
				marginLeft: "0cm",
				fontSize: "12.0pt",
				fontFamily: '"Montserrat", sans-serif !important;'
			}}
			>
			MyResorts.in is not liable for any claims against non-fulfilment or
			unsatisfactory fulfilment of products and services purchased on your
			behalf by MyResorts.in from these third party providers and distributors,
			such as, but not limited to, airlines, hotels, tour operators, car rental
			companies, insurance companies, and other entities. Sometimes, Hotels,
			when they are in an overbooking situation, they move the booked Passengers
			to some other Hotel also with the airlines and other service providers may
			overbook passengers on their flights or re-schedule flight times. In such
			incidents MyResorts.in takes no responsibility and should not be liable
			for any loses, damages that may take place due to the above situation.
			</p>
			<p
			style={{
				marginTop: "0cm",
				marginRight: "0cm",
				marginBottom: "8.0pt",
				marginLeft: "0cm",
				fontSize: "12.0pt",
				fontFamily: '"Montserrat", sans-serif !important;'
			}}
			>
			<strong>Terms &amp; conditions for product &amp; services</strong>
			</p>
			<p
			style={{
				marginTop: "0cm",
				marginRight: "0cm",
				marginBottom: "8.0pt",
				marginLeft: "0cm",
				fontSize: "12.0pt",
				fontFamily: '"Montserrat", sans-serif !important;'
			}}
			>
			Some of the Products and Services have special governing Terms &amp;
			Conditions, which shall apply in addition to the Terms and Conditions
			mentioned separately for each product and service. It is important that
			you read the obtain the information related to these specific Products and
			Services as they may contain exclusions of limitations of liability and
			other terms and conditions, including restrictions on amendment or
			cancellation. Some of these terms and conditions for specific products and
			services shall include terms and conditions found on your screens and when
			using any part of this website.
			</p>
			<p
			style={{
				marginTop: "0cm",
				marginRight: "0cm",
				marginBottom: "8.0pt",
				marginLeft: "0cm",
				fontSize: "12.0pt",
				fontFamily: '"Montserrat", sans-serif !important;'
			}}
			>
			<strong>Jurisdiction</strong>
			</p>
			<p
			style={{
				marginTop: "0cm",
				marginRight: "0cm",
				marginBottom: "8.0pt",
				marginLeft: "0cm",
				fontSize: "12.0pt",
				fontFamily: '"Montserrat", sans-serif !important;'
			}}
			>
			In the event of any dispute, the dispute shall be referred to the sole
			arbitrator to be appointed by MyResorts.in in Delhi and the matter shall
			be subjected to the jurisdiction of Delhi Courts only.
			</p>
			<p
			style={{
				marginTop: "0cm",
				marginRight: "0cm",
				marginBottom: "8.0pt",
				marginLeft: "0cm",
				fontSize: "12.0pt",
				fontFamily: '"Montserrat", sans-serif !important;'
			}}
			>
			<strong>Passport, visa &amp; health requirement</strong>
			</p>
			<p
			style={{
				marginTop: "0cm",
				marginRight: "0cm",
				marginBottom: "8.0pt",
				marginLeft: "0cm",
				fontSize: "12.0pt",
				fontFamily: '"Montserrat", sans-serif !important;'
			}}
			>
			It is mandatory requirement for most of the Countries; foreign nationals
			coming into the country must hold Passport with a validity of minimum 06
			Months. Visa and Health regulations are different from country to country.
			It is the sole responsibility of an Individual Traveller to ensure the
			submission of complete documentation and medical requirements to the
			Country Embassy / consulate they are visiting. MyResorts.in takes no
			responsibility in any rejections or declining of the Visa issuance due to
			any reasons.
			</p>
			<p
			style={{
				marginTop: "0cm",
				marginRight: "0cm",
				marginBottom: "8.0pt",
				marginLeft: "0cm",
				fontSize: "12.0pt",
				fontFamily: '"Montserrat", sans-serif !important;'
			}}
			>
			<strong>Visa/Immigration:</strong>
			</p>
			<p
			style={{
				marginTop: "0cm",
				marginRight: "0cm",
				marginBottom: "8.0pt",
				marginLeft: "0cm",
				fontSize: "12.0pt",
				fontFamily: '"Montserrat", sans-serif !important;'
			}}
			>
			1. <a href="http://www.myresorts.in">www.myresorts.in</a> is only a
			facilitator and is not directly responsible for your Visa and Immigration
			facilities
			</p>
			<p
			style={{
				marginTop: "0cm",
				marginRight: "0cm",
				marginBottom: "8.0pt",
				marginLeft: "0cm",
				fontSize: "12.0pt",
				fontFamily: '"Montserrat", sans-serif !important;'
			}}
			>
			2. Please be advised that Immigration and Visa are directly in the
			jurisdiction of the respective embassies &amp; consulate.{" "}
			<a href="http://www.myresorts.in">www.myresorts.in</a> or any of its
			third-party vendors do not guarantee the confirmation of any Visas or
			related documents.
			</p>
			<p
			style={{
				marginTop: "0cm",
				marginRight: "0cm",
				marginBottom: "8.0pt",
				marginLeft: "0cm",
				fontSize: "12.0pt",
				fontFamily: '"Montserrat", sans-serif !important;'
			}}
			>
			3. We advise you to go through the immigration and travel rules and
			regulations of the country and ensure that you have the requisite
			documents, transit permits and permissions for your holiday.
			www.myresorts.inwill not be responsible in any manner for the same.
			</p>
			<p
			style={{
				marginTop: "0cm",
				marginRight: "0cm",
				marginBottom: "8.0pt",
				marginLeft: "0cm",
				fontSize: "12.0pt",
				fontFamily: '"Montserrat", sans-serif !important;'
			}}
			>
			4. MyResorts.in customers are advised to check for Airport Charges (if
			any) at various international destinations apart from India. MyResorts.in
			shall not be responsible to pay any such charges at various destinations
			that the customer is travelling to.
			</p>
			<p
			style={{
				marginTop: "0cm",
				marginRight: "0cm",
				marginBottom: "8.0pt",
				marginLeft: "0cm",
				fontSize: "12.0pt",
				fontFamily: '"Montserrat", sans-serif !important;'
			}}
			>
			MyResorts.in reserves the right to decline any booking or purchase for any
			reason and shall not be liable for any resulting claims for losses,
			damages or compensation. In such an event, MyResorts.in shall refund to
			you all the Unutilized Payment collected after deducting the Booking Fee /
			Administration charges / Cancellation Charges (if applicable) for that
			purchase.
			</p>
			<p
			style={{
				marginTop: "0cm",
				marginRight: "0cm",
				marginBottom: "8.0pt",
				marginLeft: "0cm",
				fontSize: "12.0pt",
				fontFamily: '"Montserrat", sans-serif !important;'
			}}
			>
			<strong>Content submission</strong>
			</p>
			<p
			style={{
				marginTop: "0cm",
				marginRight: "0cm",
				marginBottom: "8.0pt",
				marginLeft: "0cm",
				fontSize: "12.0pt",
				fontFamily: '"Montserrat", sans-serif !important;'
			}}
			>
			MyResorts.in, enable users to submit, post, exchange, transmit or make
			available for inclusion information, ideas, opinions, images, photos or
			other materials (collectively "Content"). You agree that Responsibility
			for all the Content made Posted or available on MyResorts.in is your
			responsibility. MyResorts.in, its partners, affiliates, subsidiaries or
			employees shall in no way be held liable and responsible for any content
			posted by you or any third party. MyResorts.in at its discretion can
			refuse to post or remove the contents posted by you for any reason
			including, without limitation, violation of these terms or if it is
			otherwise deemed to be unlawful or objectionable.
			</p>
			<p
			style={{
				marginTop: "0cm",
				marginRight: "0cm",
				marginBottom: "8.0pt",
				marginLeft: "0cm",
				fontSize: "12.0pt",
				fontFamily: '"Montserrat", sans-serif !important;'
			}}
			>
			When the content is posted by you, you give permission without any charges
			to MyResorts.in an unrestricted license to use, copy, modify, reformate,
			translate, display publically, republish whole or part of the Content for
			any purpose of Commercial use or in connection with any other services
			offered or in Promotion by MyResorts.in
			</p>
			<p
			style={{
				marginTop: "0cm",
				marginRight: "0cm",
				marginBottom: "8.0pt",
				marginLeft: "0cm",
				fontSize: "12.0pt",
				fontFamily: '"Montserrat", sans-serif !important;'
			}}
			>
			You also confirm that you have all rights to license the Content you
			posted on the Website for the purposes mentioned above.
			</p>
			<p
			style={{
				marginTop: "0cm",
				marginRight: "0cm",
				marginBottom: "8.0pt",
				marginLeft: "0cm",
				fontSize: "12.0pt",
				fontFamily: '"Montserrat", sans-serif !important;'
			}}
			>
			<strong>&nbsp;</strong>
			</p>
			<p
			style={{
				marginTop: "0cm",
				marginRight: "0cm",
				marginBottom: "8.0pt",
				marginLeft: "0cm",
				fontSize: "12.0pt",
				fontFamily: '"Montserrat", sans-serif !important;'
			}}
			>
			<strong>&nbsp;</strong>
			</p>
			<p
			style={{
				marginTop: "0cm",
				marginRight: "0cm",
				marginBottom: "8.0pt",
				marginLeft: "0cm",
				fontSize: "12.0pt",
				fontFamily: '"Montserrat", sans-serif !important;'
			}}
			>
			<strong>
				Use of the website&nbsp;- Visitors / Users of this Website hereby agree
				upon following terms.
			</strong>
			</p>
			<p
			style={{
				marginTop: "0cm",
				marginRight: "0cm",
				marginBottom: "8.0pt",
				marginLeft: "0cm",
				fontSize: "12.0pt",
				fontFamily: '"Montserrat", sans-serif !important;'
			}}
			>
			1. Information/ experience posted by you on MyResorts.in is true,
			accurate, current and complete and it is not a biased opinion for any
			reason due to personal enmity
			</p>
			<p
			style={{
				marginTop: "0cm",
				marginRight: "0cm",
				marginBottom: "8.0pt",
				marginLeft: "0cm",
				fontSize: "12.0pt",
				fontFamily: '"Montserrat", sans-serif !important;'
			}}
			>
			2. If you have a MyResorts.in account, you will safeguard your account
			information and will supervise and be completely responsible for any use
			of your account by anyone other than you.
			</p>
			<p
			style={{
				marginTop: "0cm",
				marginRight: "0cm",
				marginBottom: "8.0pt",
				marginLeft: "0cm",
				fontSize: "12.0pt",
				fontFamily: '"Montserrat", sans-serif !important;'
			}}
			>
			3. You must be 13 years of age or older in order to register for an
			account and contribute to our website. MyResorts.in does not knowingly
			collect the information of anyone under the age of 13. MyResorts.in retain
			sole right and has sole discretion to deny access to anyone to this
			Website and the services we offer, at any time and for any reason,
			including, but not restricted to, for violation of this Agreement.
			</p>
			<p
			style={{
				marginTop: "0cm",
				marginRight: "0cm",
				marginBottom: "8.0pt",
				marginLeft: "0cm",
				fontSize: "12.0pt",
				fontFamily: '"Montserrat", sans-serif !important;'
			}}
			>
			<strong>Prohibited activities on the site</strong>
			</p>
			<p
			style={{
				marginTop: "0cm",
				marginRight: "0cm",
				marginBottom: "8.0pt",
				marginLeft: "0cm",
				fontSize: "12.0pt",
				fontFamily: '"Montserrat", sans-serif !important;'
			}}
			>
			The content and information related to messages, data, information, text,
			music, sound, photos, graphics, video, maps, icons, software, code or
			other material on this website, as well as the infrastructure used to
			provide such content and information, becomes proprietary rights of
			MyResorts.in. You agree that the information posted by you is true and
			complete to your knowledge and is not biased. You also agree not to:
			</p>
			<p
			style={{
				marginTop: "0cm",
				marginRight: "0cm",
				marginBottom: "8.0pt",
				marginLeft: "0cm",
				fontSize: "12.0pt",
				fontFamily: '"Montserrat", sans-serif !important;'
			}}
			>
			1. Use this Website or its contents for any commercial purpose;
			</p>
			<p
			style={{
				marginTop: "0cm",
				marginRight: "0cm",
				marginBottom: "8.0pt",
				marginLeft: "0cm",
				fontSize: "12.0pt",
				fontFamily: '"Montserrat", sans-serif !important;'
			}}
			>
			2. Access, monitor or copy any content or information of this Website
			using any robot, spider, scraper or other automated means or any manual
			process for any purpose without our written permission;
			</p>
			<p
			style={{
				marginTop: "0cm",
				marginRight: "0cm",
				marginBottom: "8.0pt",
				marginLeft: "0cm",
				fontSize: "12.0pt",
				fontFamily: '"Montserrat", sans-serif !important;'
			}}
			>
			3. Violate the restrictions in any robot exclusion headers on this Website
			or bypass or circumvent other measures employed to prevent or limit access
			to this Website;
			</p>
			<p
			style={{
				marginTop: "0cm",
				marginRight: "0cm",
				marginBottom: "8.0pt",
				marginLeft: "0cm",
				fontSize: "12.0pt",
				fontFamily: '"Montserrat", sans-serif !important;'
			}}
			>
			4. Take any action that imposes, or may impose, in our discretion, an
			unreasonable or disproportionately large load on our infrastructure;
			</p>
			<p
			style={{
				marginTop: "0cm",
				marginRight: "0cm",
				marginBottom: "8.0pt",
				marginLeft: "0cm",
				fontSize: "12.0pt",
				fontFamily: '"Montserrat", sans-serif !important;'
			}}
			>
			5. Deep-link to any portion of this Website for any purpose without our
			express written permission; or
			</p>
			<p
			style={{
				marginTop: "0cm",
				marginRight: "0cm",
				marginBottom: "8.0pt",
				marginLeft: "0cm",
				fontSize: "12.0pt",
				fontFamily: '"Montserrat", sans-serif !important;'
			}}
			>
			6. "Frame", "Mirror" or otherwise incorporate any part of this Website
			into any other website without our prior written authorization.
			</p>
			<p
			style={{
				marginTop: "0cm",
				marginRight: "0cm",
				marginBottom: "8.0pt",
				marginLeft: "0cm",
				fontSize: "12.0pt",
				fontFamily: '"Montserrat", sans-serif !important;'
			}}
			>
			7. Attempt to modify, translate, adapt, edit, decompile, disassemble, or
			reverse engineer any software programs used by MyResorts.in in connection
			with the Website or the services
			</p>
			<p
			style={{
				marginTop: "0cm",
				marginRight: "0cm",
				marginBottom: "8.0pt",
				marginLeft: "0cm",
				fontSize: "12.0pt",
				fontFamily: '"Montserrat", sans-serif !important;'
			}}
			>
			<strong>Reviews, comments</strong>
			</p>
			<p
			style={{
				marginTop: "0cm",
				marginRight: "0cm",
				marginBottom: "8.0pt",
				marginLeft: "0cm",
				fontSize: "12.0pt",
				fontFamily: '"Montserrat", sans-serif !important;'
			}}
			>
			MyResorts.in aim is to update travellers by real time experiences of other
			travellers. We are thankful to our visitors for writing their experiences
			on this site. Please be informed that after posting content on this
			Website by any way, including any review, questions, comments,
			suggestions, ideas etc., you grant MyResorts.in and its affiliates a
			nonexclusive, royalty-free, perpetual, transferable, irrevocable and fully
			sub licensable right to (a) use, reproduce, modify, adapt, translate,
			distribute, publish, create derivative works from and publicly display and
			perform such submissions throughout the world in any media and (b) use the
			name that you submit in connection with such submissions. You acknowledge
			that MyResorts.in may choose to provide attribution of your comments or
			reviews at our discretion. You further grant MyResorts.in the right to
			pursue at law any person or entity that violates your or MyResorts.in
			rights in the Submissions by a breach of this Agreement. You also
			acknowledge and agree that all Content Submitted / posted on the website
			by you are non-confidential and non-proprietary once it is posted.
			MyResorts.in has just provided interactive areas on the site as a platform
			for discussions but at the same time all the visitors of MyResorts.in are
			solely responsible for use of interactive areas of the site and are using
			at their own risk
			</p>
			<p
			style={{
				marginTop: "0cm",
				marginRight: "0cm",
				marginBottom: "8.0pt",
				marginLeft: "0cm",
				fontSize: "12.0pt",
				fontFamily: '"Montserrat", sans-serif !important;'
			}}
			>
			<strong>All the visitors and user of&nbsp;</strong>
			<a href="http://www.myresorts.in">
				<strong>www.myresorts.in</strong>
			</a>
			<strong>
				&nbsp;undertakes not to place / upload / transmit, store, create
			</strong>
			</p>
			<p
			style={{
				marginTop: "0cm",
				marginRight: "0cm",
				marginBottom: "8.0pt",
				marginLeft: "0cm",
				fontSize: "12.0pt",
				fontFamily: '"Montserrat", sans-serif !important;'
			}}
			>
			1. Any message, data, information, content, music, sound, photos,
			graphics, code or any other material that is unlawful, libellous,
			defamatory, obscene, pornographic, indecent, lewd, suggestive, harassing,
			threatening, invasive of privacy or publicity rights, abusive,
			inflammatory, fraudulent or otherwise objectionable;
			</p>
			<p
			style={{
				marginTop: "0cm",
				marginRight: "0cm",
				marginBottom: "8.0pt",
				marginLeft: "0cm",
				fontSize: "12.0pt",
				fontFamily: '"Montserrat", sans-serif !important;'
			}}
			>
			2. Content that would constitute, encourage or provide instructions for a
			criminal offense, violate the rights of any party, or that would otherwise
			create liability or violate any local, state, national or international
			law
			</p>
			<p
			style={{
				marginTop: "0cm",
				marginRight: "0cm",
				marginBottom: "8.0pt",
				marginLeft: "0cm",
				fontSize: "12.0pt",
				fontFamily: '"Montserrat", sans-serif !important;'
			}}
			>
			3. Content that may infringe any patent, trademark, trade secret,
			copyright or other intellectual or proprietary right of any party;
			</p>
			<p
			style={{
				marginTop: "0cm",
				marginRight: "0cm",
				marginBottom: "8.0pt",
				marginLeft: "0cm",
				fontSize: "12.0pt",
				fontFamily: '"Montserrat", sans-serif !important;'
			}}
			>
			4. Content that impersonates any person or entity or otherwise
			misrepresents your affiliation with a person or entity, including
			MyResorts.in;
			</p>
			<p
			style={{
				marginTop: "0cm",
				marginRight: "0cm",
				marginBottom: "8.0pt",
				marginLeft: "0cm",
				fontSize: "12.0pt",
				fontFamily: '"Montserrat", sans-serif !important;'
			}}
			>
			5. Unsolicited promotions, political campaigning, advertising, contests,
			raffles, or solicitations;
			</p>
			<p
			style={{
				marginTop: "0cm",
				marginRight: "0cm",
				marginBottom: "8.0pt",
				marginLeft: "0cm",
				fontSize: "12.0pt",
				fontFamily: '"Montserrat", sans-serif !important;'
			}}
			>
			6. Private information of any third party, including, without limitation,
			surname (family name) addresses, phone numbers, email addresses, Social
			Security numbers and credit card numbers;
			</p>
			<p
			style={{
				marginTop: "0cm",
				marginRight: "0cm",
				marginBottom: "8.0pt",
				marginLeft: "0cm",
				fontSize: "12.0pt",
				fontFamily: '"Montserrat", sans-serif !important;'
			}}
			>
			7. Viruses, corrupted data or other harmful, disruptive or destructive
			files;
			</p>
			<p
			style={{
				marginTop: "0cm",
				marginRight: "0cm",
				marginBottom: "8.0pt",
				marginLeft: "0cm",
				fontSize: "12.0pt",
				fontFamily: '"Montserrat", sans-serif !important;'
			}}
			>
			8. Content that is unrelated to the topic of the Interactive Area(s) in
			which such Content is posted; or
			</p>
			<p
			style={{
				marginTop: "0cm",
				marginRight: "0cm",
				marginBottom: "8.0pt",
				marginLeft: "0cm",
				fontSize: "12.0pt",
				fontFamily: '"Montserrat", sans-serif !important;'
			}}
			>
			9. Content or links to content that, in the sole judgment of MyResorts.in,
			(a) violates the previous subsections herein, (b) is objectionable, (c)
			which restricts or inhibits any other person from using or enjoying the
			Interactive Areas or the Site, or (d) which may expose MyResorts.in or its
			affiliates or its users to any harm or liability of any type.
			</p>
			<p
			style={{
				marginTop: "0cm",
				marginRight: "0cm",
				marginBottom: "8.0pt",
				marginLeft: "0cm",
				fontSize: "12.0pt",
				fontFamily: '"Montserrat", sans-serif !important;'
			}}
			>
			MyResorts.in takes no responsibility and assumes no liability for any
			Content posted, stored or uploaded by you or any visitors / third party,
			or for any loss or damage thereto, nor is MyResorts.in liable for any
			mistakes, defamation, slander, libel, omissions, falsehoods, obscenity,
			pornography or profanity you may encounter.
			</p>
			<p
			style={{
				marginTop: "0cm",
				marginRight: "0cm",
				marginBottom: "8.0pt",
				marginLeft: "0cm",
				fontSize: "12.0pt",
				fontFamily: '"Montserrat", sans-serif !important;'
			}}
			>
			MyResorts.in has just provided an interactive platform and is not liable
			for any statements, representations or Content provided by its users in
			any public forum, personal home page or other Interactive Area.
			</p>
			<p
			style={{
				marginTop: "0cm",
				marginRight: "0cm",
				marginBottom: "8.0pt",
				marginLeft: "0cm",
				fontSize: "12.0pt",
				fontFamily: '"Montserrat", sans-serif !important;'
			}}
			>
			MyResorts.in reserves the sole right to remove, screen or edit without
			notice any Content posted or stored on the Site at any time and for any
			reason, and you are solely responsible for creating backup copies of and
			replacing any Content you post or store on the Site at your sole cost and
			expense.
			</p>
			<p
			style={{
				marginTop: "0cm",
				marginRight: "0cm",
				marginBottom: "8.0pt",
				marginLeft: "0cm",
				fontSize: "12.0pt",
				fontFamily: '"Montserrat", sans-serif !important;'
			}}
			>
			If it is determined that you retain moral rights (including rights of
			attribution or integrity) in the Content, you hereby declare that (a) you
			do not require that any personally identifying information be used in
			connection with the Content, or any derivative works of or upgrades or
			updates thereto; (b) you have no objection to the publication, use,
			modification, deletion and exploitation of the Content by MyResorts.in or
			its licensees, successors and assigns; (c) you forever waive and agree not
			to claim or assert any entitlement to any and all moral rights of an
			author in any of the Content; and (d) you forever release MyResorts.in ,
			and its licensees, successors and assigns, from any claims that you could
			otherwise assert against MyResorts.in by virtue of any such moral rights.
			</p>
			<p
			style={{
				marginTop: "0cm",
				marginRight: "0cm",
				marginBottom: "8.0pt",
				marginLeft: "0cm",
				fontSize: "12.0pt",
				fontFamily: '"Montserrat", sans-serif !important;'
			}}
			>
			Any use of the Interactive Areas or other portions of the Site in
			violation of the foregoing violates these Site Terms and may result in,
			among other things, termination or suspension of your rights to use the
			Interactive Areas and/or the Site. In order to cooperate with legitimate
			governmental requests, subpoenas or court orders, to protect MyResorts.in
			systems and customers, or to ensure the integrity and operation of
			MyResorts.in business and systems, MyResorts.in may access and disclose
			any information it considers necessary or appropriate, including, without
			limitation, user profile information (i.e. name, email address, etc.), IP
			addressing and traffic information, usage history, and posted Content.
			MyResorts.in's may right to disclose any such information shall take
			precedence over any terms of MyResorts.in's Privacy Policy. MyResorts.in
			suggest to all its visitors and users of the site / passengers to
			investigate and whether review / content placed is correct or just an
			opinion. MyResorts.in will not be responsible for any wrong information
			placed on the site IS NOT ACCOUNTABLE FOR DAMAGES OR LOSSES THAT MAY
			RESULT FROM TRAVEL TO SUCH DESTINATIONS.
			</p>
			<p
			style={{
				marginTop: "0cm",
				marginRight: "0cm",
				marginBottom: "8.0pt",
				marginLeft: "0cm",
				fontSize: "12.0pt",
				fontFamily: '"Montserrat", sans-serif !important;'
			}}
			>
			<strong>Liability disclaimer</strong>
			</p>
			<p
			style={{
				marginTop: "0cm",
				marginRight: "0cm",
				marginBottom: "8.0pt",
				marginLeft: "0cm",
				fontSize: "12.0pt",
				fontFamily: '"Montserrat", sans-serif !important;'
			}}
			>
			THE CONTENT, INFORMATION, SOFTWARE, PRODUCTS, AND SERVICES PUBLISHED ON
			THIS WEBSITE MAY INCLUDE INACCURACIES OR ERRORS. MyResorts.in , IT’S
			SUBSIDIARIES / PARENT COMPANY AND CORPORATE AFFILIATES, DO NOT GUARANTEE
			THE ACCURACY OF, AND DISCLAIM ALL LIABILITY FOR ANY ERRORS OR OTHER
			INACCURACIES RELATING TO THE INFORMATION AND DESCRIPTION OF THE HOTEL,
			AIR, CRUISE, CAR AND ANY OTHER TRAVEL PRODUCTS DISPLAYED ON THIS WEBSITE
			(INCLUDING, WITHOUT LIMITATION, THE PRICING, PHOTOGRAPHS, LIST OF HOTEL
			AMENITIES, GENERAL PRODUCT DESCRIPTIONS, ETC.).
			</p>
			<p
			style={{
				marginTop: "0cm",
				marginRight: "0cm",
				marginBottom: "8.0pt",
				marginLeft: "0cm",
				fontSize: "12.0pt",
				fontFamily: '"Montserrat", sans-serif !important;'
			}}
			>
			MyResorts.in MAKE NO REPRESENTATIONS ABOUT THE SUITABILITY OF THE
			INFORMATION, SOFTWARE, PRODUCTS, AND SERVICES CONTAINED ON THIS WEBSITE
			FOR ANY PURPOSE, AND THE INCLUSION OR OFFERING OF ANY PRODUCTS OR SERVICES
			ON THIS WEBSITE DOES NOT CONSTITUTE ANY ENDORSEMENT OR RECOMMENDATION OF
			SUCH PRODUCTS OR SERVICES BY THE MyResorts.in. ALL SUCH INFORMATION,
			SOFTWARE, PRODUCTS, AND SERVICES ARE PROVIDED "AS IS" WITHOUT WARRANTY OF
			ANY KIND. MyResorts.in DISCLAIM ALL WARRANTIES AND CONDITIONS THAT THIS
			WEBSITE, ITS SERVERS OR ANY EMAIL SENT FROM MyResorts.in, ARE FREE OF
			VIRUSES OR OTHER HARMFUL COMPONENTS. MyResorts.in HEREBY DISCLAIMS ALL
			WARRANTIES AND CONDITIONS WITH REGARD TO THIS INFORMATION, SOFTWARE,
			PRODUCTS, AND SERVICES, INCLUDING ALL IMPLIED WARRANTIES AND CONDITIONS OF
			MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, AND NON
			INFRINGEMENT.
			</p>
			<p
			style={{
				marginTop: "0cm",
				marginRight: "0cm",
				marginBottom: "8.0pt",
				marginLeft: "0cm",
				fontSize: "12.0pt",
				fontFamily: '"Montserrat", sans-serif !important;'
			}}
			>
			IN NO EVENT SHALL MyResorts.in (ITS OFFICERS, DIRECTORS AND AFFILIATES/
			PARTNERS) BE LIABLE FOR ANY DIRECT, INDIRECT, PUNITIVE, INCIDENTAL,
			SPECIAL, OR CONSEQUENTIAL DAMAGES ARISING OUT OF, OR IN ANY WAY CONNECTED
			WITH, YOUR ACCESS TO, DISPLAY OF OR USE OF THIS WEBSITE OR WITH THE DELAY
			OR INABILITY TO ACCESS, DISPLAY OR USE THIS WEBSITE (INCLUDING, BUT NOT
			LIMITED TO, YOUR RELIANCE UPON OPINIONS APPEARING ON THIS WEB SITE; ANY
			COMPUTER VIRUSES, INFORMATION, SOFTWARE, LINKED SITES, PRODUCTS, AND
			SERVICES OBTAINED THROUGH THIS WEBSITE; OR OTHERWISE ARISING OUT OF THE
			ACCESS TO, DISPLAY OF OR USE OF THIS WEBSITE) WHETHER BASED ON A THEORY OF
			NEGLIGENCE, CONTRACT, TORT, STRICT LIABILITY, OR OTHERWISE, AND EVEN IF
			MyResorts.in HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
			</p>
			<p
			style={{
				marginTop: "0cm",
				marginRight: "0cm",
				marginBottom: "8.0pt",
				marginLeft: "0cm",
				fontSize: "12.0pt",
				fontFamily: '"Montserrat", sans-serif !important;'
			}}
			>
			The limitation of liability reflects the allocation of risk between the
			parties. The limitations specified in this section will survive and apply
			even if any limited remedy specified in these terms is found to have
			failed of its essential purpose. The limitations of liability provided in
			these terms inure to the benefit of MyResorts.in.
			</p>
			<p
			style={{
				marginTop: "0cm",
				marginRight: "0cm",
				marginBottom: "8.0pt",
				marginLeft: "0cm",
				fontSize: "12.0pt",
				fontFamily: '"Montserrat", sans-serif !important;'
			}}
			>
			<strong>Indemnification</strong>
			</p>
			<p
			style={{
				marginTop: "0cm",
				marginRight: "0cm",
				marginBottom: "8.0pt",
				marginLeft: "0cm",
				fontSize: "12.0pt",
				fontFamily: '"Montserrat", sans-serif !important;'
			}}
			>
			You agree to defend and indemnify MyResorts.in and its affiliates and any
			of their officers, directors, employees and agents from and against any
			claims, causes of action, demands, recoveries, losses, damages, fines,
			penalties or other costs or expenses of any kind or nature including but
			not limited to reasonable legal and accounting fees, brought by third
			parties as a result of:
			</p>
			<p
			style={{
				marginTop: "0cm",
				marginRight: "0cm",
				marginBottom: "8.0pt",
				marginLeft: "0cm",
				fontSize: "12.0pt",
				fontFamily: '"Montserrat", sans-serif !important;'
			}}
			>
			1. your breach of this Agreement or the documents referenced herein;
			</p>
			<p
			style={{
				marginTop: "0cm",
				marginRight: "0cm",
				marginBottom: "8.0pt",
				marginLeft: "0cm",
				fontSize: "12.0pt",
				fontFamily: '"Montserrat", sans-serif !important;'
			}}
			>
			2. your violation of any law or the rights of a third party; or
			</p>
			<p
			style={{
				marginTop: "0cm",
				marginRight: "0cm",
				marginBottom: "8.0pt",
				marginLeft: "0cm",
				fontSize: "12.0pt",
				fontFamily: '"Montserrat", sans-serif !important;'
			}}
			>
			3 your use of this Website.
			</p>
			<p
			style={{
				marginTop: "0cm",
				marginRight: "0cm",
				marginBottom: "8.0pt",
				marginLeft: "0cm",
				fontSize: "12.0pt",
				fontFamily: '"Montserrat", sans-serif !important;'
			}}
			>
			<strong>&nbsp;</strong>
			</p>
			<p
			style={{
				marginTop: "0cm",
				marginRight: "0cm",
				marginBottom: "8.0pt",
				marginLeft: "0cm",
				fontSize: "12.0pt",
				fontFamily: '"Montserrat", sans-serif !important;'
			}}
			>
			<strong>Links to third-party sites</strong>
			</p>
			<p
			style={{
				marginTop: "0cm",
				marginRight: "0cm",
				marginBottom: "8.0pt",
				marginLeft: "0cm",
				fontSize: "12.0pt",
				fontFamily: '"Montserrat", sans-serif !important;'
			}}
			>
			This Website may contain hyperlinks to websites operated by parties other
			than MyResorts.in. Such hyperlinks are provided for your reference only.
			We do not control such websites and are not responsible for their contents
			or the privacy or other practices of such websites.
			</p>
			<p
			style={{
				marginTop: "0cm",
				marginRight: "0cm",
				marginBottom: "8.0pt",
				marginLeft: "0cm",
				fontSize: "12.0pt",
				fontFamily: '"Montserrat", sans-serif !important;'
			}}
			>
			<strong>Currency converter</strong>
			</p>
			<p
			style={{
				marginTop: "0cm",
				marginRight: "0cm",
				marginBottom: "8.0pt",
				marginLeft: "0cm",
				fontSize: "12.0pt",
				fontFamily: '"Montserrat", sans-serif !important;'
			}}
			>
			Currency rates are based on various publicly available sources and should
			be used as guidelines only. Rates may not be verified as accurate, and
			actual rates may be different from what is being displayed. The
			information supplied by this application is believed to be accurate, but
			MyResorts.in, and/or its affiliates do not warrant or guarantee such
			accuracy. When using this information for any financial purpose, we advise
			you to consult a qualified professional to verify the accuracy of the
			currency rates. We do not authorize the use of this information for any
			purpose other than your personal use and you are expressly prohibited from
			the resale, redistribution, and use of this information for commercial
			purposes.
			</p>
			<p
			style={{
				marginTop: "0cm",
				marginRight: "0cm",
				marginBottom: "8.0pt",
				marginLeft: "0cm",
				fontSize: "12.0pt",
				fontFamily: '"Montserrat", sans-serif !important;'
			}}
			>
			<strong>Copyright and trademark notices</strong>
			</p>
			<p
			style={{
				marginTop: "0cm",
				marginRight: "0cm",
				marginBottom: "8.0pt",
				marginLeft: "0cm",
				fontSize: "12.0pt",
				fontFamily: '"Montserrat", sans-serif !important;'
			}}
			>
			All contents of this Website are: 2010 MyResorts.in All rights reserved.
			MyResorts.in is not responsible for content on websites operated by
			parties other than MyResorts.in. MyResorts.in, logo and MyResorts.in
			Family - Cartoon Characters and all other product or service names or
			slogans displayed on the Site are registered and/or common law trademarks
			of MyResorts.in, and/or its suppliers or licensors, and may not be copied,
			imitated or used, in whole or in part, without the prior written
			permission of MyResorts.in or the applicable trademark holder. In
			addition, the look and feel of the Site, including all page headers,
			custom graphics, button icons and scripts, is the service mark, trademark
			and/or trade dress of MyResorts.in and may not be copied, imitated or
			used, in whole or in part, without the prior written permission of
			MyResorts.in. All other trademarks, registered trademarks, product names
			and company names or logos mentioned in the Site are the property of their
			respective owners. Reference to any products, services, processes or other
			information, by trade name, trademark, manufacturer, supplier or otherwise
			does not constitute or imply endorsement, sponsorship or recommendation
			thereof by MyResorts.in.
			</p>
			<p
			style={{
				marginTop: "0cm",
				marginRight: "0cm",
				marginBottom: "8.0pt",
				marginLeft: "0cm",
				fontSize: "12.0pt",
				fontFamily: '"Montserrat", sans-serif !important;'
			}}
			>
			Other logos and product and company names mentioned herein may be the
			trademarks of their respective owners.
			</p>
			<p
			style={{
				marginTop: "0cm",
				marginRight: "0cm",
				marginBottom: "8.0pt",
				marginLeft: "0cm",
				fontSize: "12.0pt",
				fontFamily: '"Montserrat", sans-serif !important;'
			}}
			>
			If you are aware of an infringement of either your brand or our brand,
			please let us know by e-mailing us at contact@myresorts.in.
			</p>
			<p
			style={{
				marginTop: "0cm",
				marginRight: "0cm",
				marginBottom: "8.0pt",
				marginLeft: "0cm",
				fontSize: "12.0pt",
				fontFamily: '"Montserrat", sans-serif !important;'
			}}
			>
			You agree that all claims you may have against MyResorts.in arising from
			or relating to the Site must be heard and resolved in a court of competent
			subject matter jurisdiction. Use of this Website is unauthorized in any
			jurisdiction that does not give effect to all provisions of these terms
			and conditions, including, without limitation, this paragraph.
			</p>
			<p
			style={{
				marginTop: "0cm",
				marginRight: "0cm",
				marginBottom: "8.0pt",
				marginLeft: "0cm",
				fontSize: "12.0pt",
				fontFamily: '"Montserrat", sans-serif !important;'
			}}
			>
			You agree that no joint venture, agency, partnership, or employment
			relationship exists between you and MyResorts.in and/or Affiliates as a
			result of this Agreement or use of this Website.
			</p>
			<p
			style={{
				marginTop: "0cm",
				marginRight: "0cm",
				marginBottom: "8.0pt",
				marginLeft: "0cm",
				fontSize: "12.0pt",
				fontFamily: '"Montserrat", sans-serif !important;'
			}}
			>
			Our performance of this Agreement is subject to existing laws and legal
			process, and nothing contained in this Agreement limits our right to
			comply with law enforcement or other governmental or legal requests or
			requirements relating to your use of this Website or information provided
			to or gathered by us with respect to such use. To the extent allowed by
			applicable law, you agree that you will bring any claim or cause of action
			arising from or relating to your access or use of this Website within two
			(2) years from the date on which such claim or action arose or accrued or
			such claim or cause of action will be irrevocably waived.
			</p>
			<p
			style={{
				marginTop: "0cm",
				marginRight: "0cm",
				marginBottom: "8.0pt",
				marginLeft: "0cm",
				fontSize: "12.0pt",
				fontFamily: '"Montserrat", sans-serif !important;'
			}}
			>
			If any part of this Agreement is determined to be invalid or unenforceable
			pursuant to applicable law including, but not limited to, the warranty
			disclaimers and liability limitations set forth above, then the invalid or
			unenforceable provision will be deemed superseded by a valid, enforceable
			provision that most closely matches the intent of the original provision
			and the remaining provisions in the Agreement shall continue in effect.
			</p>
			<p
			style={{
				marginTop: "0cm",
				marginRight: "0cm",
				marginBottom: "8.0pt",
				marginLeft: "0cm",
				fontSize: "12.0pt",
				fontFamily: '"Montserrat", sans-serif !important;'
			}}
			>
			This Agreement (and any other terms and conditions referenced herein)
			constitutes the entire agreement between you and MyResorts.in with respect
			to this Website and it supersedes all prior or contemporaneous
			communications and proposals, whether electronic, oral, or written,
			between the customer and MyResorts.in with respect to this Website. A
			printed version of this Agreement and of any notice given in electronic
			form shall be admissible in judicial or administrative proceedings based
			upon or relating to this Agreement to the same extent and subject to the
			same conditions as other business documents and records originally
			generated and maintained in printed form.
			</p>
			<p
			style={{
				marginTop: "0cm",
				marginRight: "0cm",
				marginBottom: "8.0pt",
				marginLeft: "0cm",
				fontSize: "12.0pt",
				fontFamily: '"Montserrat", sans-serif !important;'
			}}
			>
			Fictitious names of companies, products, people, characters, and/or data
			mentioned on this Website are not intended to represent any real
			individual, company, product, or event.
			</p>
			<p
			style={{
				marginTop: "0cm",
				marginRight: "0cm",
				marginBottom: "8.0pt",
				marginLeft: "0cm",
				fontSize: "12.0pt",
				fontFamily: '"Montserrat", sans-serif !important;'
			}}
			>
			Any rights not expressly granted herein are reserved.
			</p>
			<p
			style={{
				marginTop: "0cm",
				marginRight: "0cm",
				marginBottom: "8.0pt",
				marginLeft: "0cm",
				fontSize: "12.0pt",
				fontFamily: '"Montserrat", sans-serif !important;'
			}}
			>
			<strong>service help</strong>
			</p>
			<p
			style={{
				marginTop: "0cm",
				marginRight: "0cm",
				marginBottom: "8.0pt",
				marginLeft: "0cm",
				fontSize: "12.0pt",
				fontFamily: '"Montserrat", sans-serif !important;'
			}}
			>
			For answers to your questions, you can write to us at:
			contact@myresorts.in
			</p>
			<p
			style={{
				marginTop: "0cm",
				marginRight: "0cm",
				marginBottom: "8.0pt",
				marginLeft: "0cm",
				fontSize: "12.0pt",
				fontFamily: '"Montserrat", sans-serif !important;'
			}}
			>
			&nbsp;
			</p>
			</div>
		<UserFooter />
    </>
  );
};

export default TermsOfUse;