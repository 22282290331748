import { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import './Verify.scss';
import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL || '';

export function Verify() {
    const [verified, setVerified] = useState(0);
    const history = useHistory();
    const id = window.location.search.split('=')[1]
    useEffect(() => {
        id && axios.get(`${API_URL}/v1/verify?id=${id}`)
            .then((res) => {
                if (res.data.message === "User Already Verified") {
                    setVerified(2);
                    setTimeout(() => history.push('/auth/login'), 3000)
                }
                else
                    setVerified(1);
            })
            .catch(() => {
                setVerified(3);
            })
    }, [])
    return (
        <>
            {verified === 1 ? (<div className="verify-container">
                <div className="verify-card">
                    <div className="verify-heading">
                        Account Verified!
                    </div>
                    <div className="verify-content">
                        You can now login to the MyResorts.in with the email and password you've provided at the time of signing up
                    </div>
                    <p className="mt-5">Click below button to login</p>

                    <Link className="text-white" to="/auth/login">
                        <button className='verify-login-bt-user mt-10'>Login</button>
                    </Link>

                </div>
            </div>) : verified === 3 ? <div className="d-flex justify-content-center align-items-center h-100">
                <div className="d-flex justify-content-center align-items-center w-50 h-50 border border-3 border-danger rounded text-danger fw-bold"
                    style={{ fontSize: "20px" }}>
                    Something went wrong, please try after sometime
                </div>
            </div> : <div className="d-flex justify-content-center align-items-center h-100">
                <div className="d-flex justify-content-center align-items-center w-50 h-50 border border-3 border-success rounded">
                    <h1 className="text-success">User Already Verified, Redirecting to Login page.....</h1>
                </div>
            </div>}
        </>
    )
}

