import axios from 'axios'
import {AuthModel} from '../models/AuthModel'
import {UserModel} from '../models/UserModel'

const API_URL = process.env.REACT_APP_API_URL || ''

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/v1/auth/get-user`
export const LOGIN_URL = `${API_URL}/v1/auth/login`
export const REGISTER_URL = `${API_URL}/v1/auth/register`
export const RESET_PASSWORD_URL = `${API_URL}/v1/auth/reset-password`

export const REQUEST_PASSWORD_URL = `${API_URL}/v1/auth/forgot-password`


// Server should return AuthModel
export function login(email: string, password: string) {
  return axios.post(LOGIN_URL, {email, password})
}

// Server should return AuthModel
export function register(email: string, first_name: string, last_name: string, password: string, phone_no: string, vendor_company_name: string, tc_agreed: boolean, role: string) {
  return axios.post<AuthModel>(REGISTER_URL, {
    email,
    first_name,
    last_name,
    password,
    phone_no,
    vendor_company_name,
    tc_agreed,
    role
  })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<{result: boolean}>(REQUEST_PASSWORD_URL, {email})
}

export function resetPassword(user_id: string, password: string){
  return axios.post<{result: boolean}>(RESET_PASSWORD_URL, {user_id, password})
}

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  // Check common redux folder => setupAxios
  return axios.get<UserModel>(GET_USER_BY_ACCESSTOKEN_URL)
}

