import React, { useEffect, useState } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { PageLink, PageTitle } from "../../../_metronic/layout/core";
import "./Reviews.scss";
import { Col, Form, Nav, Row, Tab } from "react-bootstrap-v5";
import { toAbsoluteUrl } from "../../../_metronic/helpers/AssetHelpers";
import { KTSVG } from "../../../_metronic/helpers";
import axios from "axios";
import StarRating from "../user/components/StarRating";

const API_URL = process.env.REACT_APP_API_URL || "";

const ReviewsPage: React.FC = () => {
	// const [form, setForm] = useState<any>();

	const [reviewsData, setReviewsData] = useState<any>();

	let PropertyId = sessionStorage.getItem("property_id");

	const handleGetReviews = () => {
		// if (!form)
		axios
			.get(`${API_URL}/v1/user/getreview`, {
				params: { resort_id: PropertyId },
			})
			.then((res) => {
				setReviewsData(res.data.data.user_reviews);
				// console.log(res.data.data.user_reviews)
			});
	};

	const deleteReview = (id: string) => {
		axios
			.delete(`http://localhost:5000/v1/user/reviewdelete`, {
				data: { _id: id },
			})
			.then((res) => {
				// console.log(res, "delete review", id);
				handleGetReviews();
			})
			.catch((error) => {
				console.error("Error deleting review", error);
			});
	};

	useEffect(() => {
		handleGetReviews();
	}, []);

	return (
		<div>
			{/* <div className="row for-mob-row">
        <div className="col-lg-3 for-mob-col">
          <div className="total-reviews">
            <h2>Total Reviews</h2>
            <h4>445</h4>
          </div>
        </div>

        <div className="col-lg-3 for-mob-col">
          <div className="total-reviews dark-blue">
            <h2>Average user rating</h2>
            <div className="star-rat">
              <h4>
                <small>455</small>
              </h4>
              <span>
                <i className="fa fa-star" aria-hidden="true"></i>
                <i className="fa fa-star" aria-hidden="true"></i>
                <i className="fa fa-star" aria-hidden="true"></i>
                <i className="fa fa-star" aria-hidden="true"></i>
                <i className="fa fa-star-half-o" aria-hidden="true"></i>
              </span>
            </div>
          </div>
        </div>
      </div> */}

			<div className="card card-custom">
				<div className="card-header">
					<h3 className="card-title">All Ratings and Reviews</h3>

					{/* <div className="card-toolbar">
            <select className="form-select" aria-label="Select example">
              <option>Top rated</option>
              <option value="1">Latest</option>
              <option value="2">Oldest</option>
            </select>
          </div> */}
				</div>
				<div className="card-body">
					<div className="reviews-wrap">
						{reviewsData?.length !== 0 ? (
							reviewsData?.map((ele: any) => {
								return (
									<>
										<div
											className="d-flex align-items-start mb-7 review-item"
											key={ele._id}
										>
											<div className="symbol symbol-70px me-5">
												<img
													alt={ele?.username}
													src={
														ele?.user_image.length > 0
															? ele?.user_image
															: toAbsoluteUrl(
																	"/media/img/icons/review-img.jpeg"
															  )
													}
												/>
											</div>
											<div className="flex-grow-1 rev-cont">
												<a className="text-dark fw-bolder text-hover-primary fs-2">
													{ele.username}
												</a>
												<div className="dateNstar">
													<span className="text-muted d-block fw-bold review-date">
														{new Date(ele.createdAt).toLocaleDateString(
															"en-GB"
														)}
													</span>
													<StarRating rating={ele.rating} color="#FFD700" />
												</div>

												<div className="review-content">
													<p>{ele.review}</p>
												</div>
												<div className="del-btn ">
													<span className="del-btn btn btn-icon btn-bg-light btn-active-color-primary btn-sm">
														<span
															className="svg-icon svg-icon-3"
															onClick={() => deleteReview(ele._id)}
														>
															<svg
																width="24"
																height="24"
																viewBox="0 0 24 24"
																fill="none"
																xmlns="http://www.w3.org/2000/svg"
																className="mh-50px"
															>
																<path
																	d="M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z"
																	fill="black"
																></path>
																<path
																	opacity="0.5"
																	d="M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z"
																	fill="black"
																></path>
																<path
																	opacity="0.5"
																	d="M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z"
																	fill="black"
																></path>
															</svg>
														</span>
													</span>
												</div>
											</div>
										</div>
									</>
								);
							})
						) : (
							<h1>No Data Found</h1>
						)}

						{/* <div className="d-flex align-items-start mb-7 review-item">
              <div className="symbol symbol-70px me-5">
                <img
                  src={toAbsoluteUrl("/media/avatars/150-3.jpg")}
                  alt="MyResorts.in"
                />
              </div>
              <div className="flex-grow-1 rev-cont">
                <a
                  href="#"
                  className="text-dark fw-bolder text-hover-primary fs-2"
                >
                  Allina D’croze
                </a>
                <div className="dateNstar">
                  <span className="text-muted d-block fw-bold">
                    Tue, 20 Mar 2020
                  </span>

                  <div className="star-box">
                    <i className="fa fa-star" aria-hidden="true"></i>
                    <i className="fa fa-star" aria-hidden="true"></i>
                    <i className="fa fa-star" aria-hidden="true"></i>
                    <i className="fa fa-star" aria-hidden="true"></i>
                    <i className="fa fa-star-o" aria-hidden="true"></i>
                  </div>
                </div>

                <div className="review-content">
                  <h2>Three-Hour South Beach Lunch Tour</h2>
                  <p>
                    Contrary to popular belief, Lorem Ipsum is not simply random
                    text. It has roots in a piece of classical Latin literature
                    from 45 BC, making it over 2000 years old. Richard
                    McClintock, a Latin professor at Hampden-Sydney College in
                    Virginia, looked up one of the.. <a href="#!">more</a> 
                  </p>

                  <button type="button" className="reply-bt">
                    <i className="fa fa-reply" aria-hidden="true"></i>Reply
                  </button>
                </div>

              </div>
            </div> */}

						{/* <div className="d-flex align-items-start mb-7 review-item">
              <div className="symbol symbol-70px me-5">
                <img
                  src={toAbsoluteUrl("/media/avatars/150-2.jpg")}
                  alt="MyResorts.in"
                />
              </div>
              <div className="flex-grow-1 rev-cont">
                <a
                  href="#"
                  className="text-dark fw-bolder text-hover-primary fs-2"
                >
                  Allina D’croze
                </a>
                <div className="dateNstar">
                  <span className="text-muted d-block fw-bold">
                    Tue, 20 Mar 2020
                  </span>

                  <div className="star-box">
                    <i className="fa fa-star" aria-hidden="true"></i>
                    <i className="fa fa-star" aria-hidden="true"></i>
                    <i className="fa fa-star" aria-hidden="true"></i>
                    <i className="fa fa-star" aria-hidden="true"></i>
                    <i className="fa fa-star-o" aria-hidden="true"></i>
                  </div>
                </div>

                <div className="review-content">
                  <h2>Three-Hour South Beach Lunch Tour</h2>
                  <p>
                    Contrary to popular belief, Lorem Ipsum is not simply random
                    text. It has roots in a piece of classical Latin literature
                    from 45 BC, making it over 2000 years old. Richard
                    McClintock, a Latin professor at Hampden-Sydney College in
                    Virginia, looked up one of the.. <a href="#!">more</a> 
                  </p>

                  <button type="button" className="reply-bt">
                    <i className="fa fa-reply" aria-hidden="true"></i>Reply
                  </button>
                </div>
              </div>
            </div> */}

						{/* <div className="see-all-reviews">
              <ul className="pagination">
                <li className="page-item previous disabled">
                  <a href="#" className="page-link">
                    <i className="previous"></i>
                  </a>
                </li>
                <li className="page-item">
                  <a href="#" className="page-link">
                    1
                  </a>
                </li>
                <li className="page-item active">
                  <a href="#" className="page-link">
                    2
                  </a>
                </li>
                <li className="page-item">
                  <a href="#" className="page-link">
                    3
                  </a>
                </li>
                <li className="page-item">
                  <a href="#" className="page-link">
                    4
                  </a>
                </li>
                <li className="page-item">
                  <a href="#" className="page-link">
                    5
                  </a>
                </li>
                <li className="page-item">
                  <a href="#" className="page-link">
                    6
                  </a>
                </li>
                <li className="page-item next">
                  <a href="#" className="page-link">
                    <i className="next"></i>
                  </a>
                </li>
              </ul>
              <a href="#" className="btn btn-success">
                See All Reviews
              </a>
            </div> */}
					</div>
				</div>
			</div>

			{/* <button type="button"
  className="btn btn-primary"
  data-bs-toggle="modal"
  data-bs-target="#kt_modal_1"
>
  Launch demo modal
</button> */}

			<div className="modal fade" tabIndex={-1} id="kt_modal_1">
				<div className="modal-dialog">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title">Modal title</h5>
							<div
								className="btn btn-icon btn-sm btn-active-light-primary ms-2"
								data-bs-dismiss="modal"
								aria-label="Close"
							>
								<KTSVG
									path="/media/icons/duotune/arrows/arr061.svg"
									className="svg-icon svg-icon-2x"
								/>
							</div>
						</div>
						<div className="modal-body">
							<p>Modal body text goes here.</p>
						</div>
						<div className="modal-footer">
							<button
								type="button"
								className="btn btn-light"
								data-bs-dismiss="modal"
							>
								Close
							</button>
							<button type="button" className="btn btn-primary">
								Save changes
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
export default ReviewsPage;
