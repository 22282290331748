import React, { useEffect, useState } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
// import { PageLink, PageTitle } from "../../../_metronic/layout/core";
import "./Coupon.scss";
import { Col, Form, Nav, Row, Tab } from "react-bootstrap-v5";
import { toAbsoluteUrl } from "../../../../_metronic/helpers";
import axios from "axios";
import ReactPaginate from "react-paginate";

const API_URL = process.env.REACT_APP_API_URL || "";

const CouponPage: React.FC = ({ itemsPerPage = 10 }: any) => {
	const [currentItems, setCurrentItems] = useState<any>(null);
	const [pageCount, setPageCount] = useState(0);
	// Here we use item offsets; we could also use page offsets
	// following the API or data you're working with.
	const [itemOffset, setItemOffset] = useState(0);
	const [form, setForm] = useState<any>(null);
	const [pageNum, setPageNum] = useState<number>(0);

	const [couponMsg, setCouponMsg] = useState<string>("");

	useEffect(() => {
		// Fetch items from another resources.
		axios.get(`${API_URL}/v1/admin/coupon?page_no=0`).then((res) => {
			setCurrentItems(res.data.data.data);
			setPageCount(Math.ceil(res.data.data.metatdata.total / itemsPerPage));
		});
	}, [itemOffset, itemsPerPage]);

	// =========== to Reset The Modal Form Data Or Empty form Data ================
	const handleResetForm = () => {
		return setForm({
			coupon_title: "",
			coupone_code: "",
			coupone_discount: "",
			coupone_discount_type: "",
			coupone_is_one_time: "",
			coupon_max_uses: "",
			coupon_valid_till: "",
			coupon_valid_from: "",
			coupon_status: "",
		});
	};
	// =========== to Reset The Modal Form Data Or Empty form Data ================

	// Invoke when user click to request another page.
	const handlePageClick = (event: any) => {
		axios
			.get(`${API_URL}/v1/admin/coupon?page_no=` + event.selected)
			.then((res) => {
				setCurrentItems(res.data.data.data);
				setPageCount(Math.ceil(res.data.data.metatdata.total / itemsPerPage));
				setPageNum(event.selected);
				// console.log(event.selected, "handle page log", Math.ceil(res.data.data.metatdata.total / itemsPerPage))
			});
	};
	const handleChange = (e: any, value: any) => {
		let obj = { ...form };
		obj[value] = e.target.value;
		setForm(obj);
		// console.log(obj, "obj")
	};
	const updateStatus = (form: any, status: any) => {
		axios
			.put(`${API_URL}/v1/admin/coupon/` + form._id, {
				...form,
				coupon_status: status,
			})
			.then(() => {
				axios
					.get(`${API_URL}/v1/admin/coupon?page_no=${pageNum}`)
					.then((res) => {
						setCurrentItems(res.data.data.data);
						setPageCount(
							Math.ceil(res.data.data.metatdata.total / itemsPerPage)
						);
					});
			});
	};

	const handleCouponMsg = (message: any) => {
		setCouponMsg(message);
		setTimeout(() => {
			setCouponMsg("");
		}, 3000);
	};

	const onSubmit = () => {
		if (form._id) {
			axios
				.put(`${API_URL}/v1/admin/coupon/` + form._id, { ...form })
				.then(() => {
					handleResetForm();
				})
				.then(() => {
					//  axios.get(`${API_URL}/v1/admin/coupon?page_no=0`).then(res => {
					axios
						.get(`${API_URL}/v1/admin/coupon?page_no=${pageNum}`)
						.then((res) => {
							setCurrentItems(res.data.data.data);
							setPageCount(
								Math.ceil(res.data.data.metatdata.total / itemsPerPage)
							);
						});
				});
		} else {
			let couponValidation =
				form.coupon_title !== "" &&
				form.coupone_code !== "" &&
				form.coupone_discount !== "" &&
				form.coupone_discount_type !== "" &&
				form.coupone_is_one_time !== "";
			// console.log(couponValidation, "couponValidation of coupon", form)

			couponValidation
				? axios
						.post(`${API_URL}/v1/admin/coupon`, {
							...form,
							coupon_status: "Active",
						})
						.then(() => {
							handleResetForm();
						})
						.then(() => {
							// axios.get(`${API_URL}/v1/admin/coupon?page_no=${pageNum}`).then(res => {
							axios.get(`${API_URL}/v1/admin/coupon?page_no=0`).then((res) => {
								// console.log(res, "new age of ice age modal")
								setCurrentItems(res.data.data.data);
								setPageCount(
									Math.ceil(res.data.data.metatdata.total / itemsPerPage)
								);
							});
						})
				: handleCouponMsg("Please Fill the Coupon Form ");
		}
	};

	const deleteCoupon = (id: any) => {
		axios.delete(`${API_URL}/v1/admin/coupon/` + id).then(() => {
			axios.get(`${API_URL}/v1/admin/coupon?page_no=${pageNum}`).then((res) => {
				setCurrentItems(res.data.data.data);
				setPageCount(Math.ceil(res.data.data.metatdata.total / itemsPerPage));
			});
		});
	};

	return (
		<div>
			<div className="row mb-8">
				<div className="col-lg-12">
					<a
						className="btn btn-success"
						onClick={() => handleResetForm()}
						data-bs-toggle="modal"
						data-bs-target="#kt_modal_1"
					>
						<i className="fa fa-plus" aria-hidden="true"></i>
						Add new
					</a>

					<span style={{ color: "red", paddingLeft: "50px" }}>
						{" "}
						{couponMsg}{" "}
					</span>
				</div>

				{/* <div className="col-lg-4 d-flex justify-content-end">
          <div className="d-flex my-2">
            <div className="d-flex align-items-center position-relative me-4">
              <span className="svg-icon svg-icon-3 position-absolute ms-3">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="mh-50px"
                >
                  <rect
                    opacity="0.5"
                    x="17.0365"
                    y="15.1223"
                    width="8.15546"
                    height="2"
                    rx="1"
                    transform="rotate(45 17.0365 15.1223)"
                    fill="black"
                  ></rect>
                  <path
                    d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                    fill="black"
                  ></path>
                </svg>
              </span>
              <input
                type="text"
                id="kt_filter_search"
                className="form-control form-control-white form-control-sm w-250px ps-9"
                placeholder="Search"
                wtx-context="666F8DA7-646C-4188-876B-CA6BA1CAF363"
              />
            </div>
            <button className="btn btn-primary btn-sm">Search</button>
          </div>
        </div> */}
			</div>

			<div className="row">
				<div className="col-lg-12">
					<div className="card card-custom">
						<div className="card-header">
							<h3 className="card-title">Coupon</h3>
							{/* <div className="card-toolbar">
        <button type="button" className="btn btn-sm btn-light">
            Last 10 bookings
        </button>
    </div> */}
						</div>
						<div className="card-body p-0 manage-pro-table-wrap">
							<table className="table table-hover table-rounded table-striped border gy-4 gs-7">
								<thead>
									<tr className="fw-bold fs-6 text-gray-800 border-bottom-2 border-gray-200">
										<th>Id</th>
										<th>Title</th>
										<th>Promo code</th>
										<th>Valid till</th>
										<th>Status</th>
										<th>Actions</th>
									</tr>
								</thead>
								<tbody>
									{currentItems &&
										currentItems.map((ele: any) => (
											<tr>
												<td>#{ele._id.slice(ele._id.length - 6)}</td>
												<td>{ele.coupon_title}</td>
												<td>{ele.coupone_code}</td>
												{/* <td>{new Date(ele.coupon_valid_till).toLocaleDateString('en-GB')}</td> */}
												<td>
													{new Date(ele.coupon_valid_till).toLocaleDateString(
														"en-IN",
														{ day: "2-digit", month: "short", year: "numeric" }
													)}
												</td>
												<td>
													<a
														href="javascript:;"
														className={
															"btn" +
															(ele.coupon_status == "Active"
																? " btn-success"
																: " btn-danger") +
															" verify-bt"
														}
														onClick={() =>
															updateStatus(
																ele,
																ele.coupon_status == "Active"
																	? "Inactive"
																	: "Active"
															)
														}
													>
														{ele.coupon_status}
													</a>
												</td>

												<td>
													<div className="d-flex justify-content-start flex-shrink-0">
														<a
															href="javascript:;"
															onClick={() => setForm(ele)}
															data-bs-toggle="modal"
															data-bs-target="#kt_modal_1"
															className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
														>
															<span className="svg-icon svg-icon-3">
																<svg
																	width="24"
																	height="24"
																	viewBox="0 0 24 24"
																	fill="none"
																	xmlns="http://www.w3.org/2000/svg"
																	className="mh-50px"
																>
																	<path
																		opacity="0.3"
																		d="M21.4 8.35303L19.241 10.511L13.485 4.755L15.643 2.59595C16.0248 2.21423 16.5426 1.99988 17.0825 1.99988C17.6224 1.99988 18.1402 2.21423 18.522 2.59595L21.4 5.474C21.7817 5.85581 21.9962 6.37355 21.9962 6.91345C21.9962 7.45335 21.7817 7.97122 21.4 8.35303ZM3.68699 21.932L9.88699 19.865L4.13099 14.109L2.06399 20.309C1.98815 20.5354 1.97703 20.7787 2.03189 21.0111C2.08674 21.2436 2.2054 21.4561 2.37449 21.6248C2.54359 21.7934 2.75641 21.9115 2.989 21.9658C3.22158 22.0201 3.4647 22.0084 3.69099 21.932H3.68699Z"
																		fill="black"
																	></path>
																	<path
																		d="M5.574 21.3L3.692 21.928C3.46591 22.0032 3.22334 22.0141 2.99144 21.9594C2.75954 21.9046 2.54744 21.7864 2.3789 21.6179C2.21036 21.4495 2.09202 21.2375 2.03711 21.0056C1.9822 20.7737 1.99289 20.5312 2.06799 20.3051L2.696 18.422L5.574 21.3ZM4.13499 14.105L9.891 19.861L19.245 10.507L13.489 4.75098L4.13499 14.105Z"
																		fill="black"
																	></path>
																</svg>
															</span>
														</a>
														<a
															href="javascript:;"
															onClick={() => deleteCoupon(ele._id)}
															className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm"
														>
															<span className="svg-icon svg-icon-3">
																<svg
																	width="24"
																	height="24"
																	viewBox="0 0 24 24"
																	fill="none"
																	xmlns="http://www.w3.org/2000/svg"
																	className="mh-50px"
																>
																	<path
																		d="M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z"
																		fill="black"
																	></path>
																	<path
																		opacity="0.5"
																		d="M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z"
																		fill="black"
																	></path>
																	<path
																		opacity="0.5"
																		d="M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z"
																		fill="black"
																	></path>
																</svg>
															</span>
														</a>
													</div>
												</td>
											</tr>
										))}
								</tbody>
							</table>
						</div>
					</div>
				</div>
				<div className="col-lg-12">
					<div className="pagination-wrap">
						<ReactPaginate
							breakLabel="..."
							nextLabel=" >"
							breakClassName="page-item"
							breakLinkClassName="page-link"
							pageClassName="page-item"
							pageLinkClassName="page-link"
							previousClassName="page-item"
							previousLinkClassName="page-link"
							nextClassName="page-item"
							nextLinkClassName="page-link"
							activeClassName="active"
							onPageChange={handlePageClick}
							pageRangeDisplayed={5}
							pageCount={pageCount}
							previousLabel="< "
							className="pagination"
						/>
					</div>
				</div>
			</div>

			<div className="modal fade" tabIndex={-1} id="kt_modal_1">
				<div className="modal-dialog w-600px mw-600px">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title">{form?._id ? "Edit" : "Add"}</h5>
							<div
								className="btn btn-icon btn-sm btn-active-light-primary ms-2"
								data-bs-dismiss="modal"
								aria-label="Close"
							>
								<i className="fa fa-times" aria-hidden="true"></i>
							</div>
						</div>
						<div className="modal-body">
							<div className="row mb-6">
								<label className="col-lg-4 col-form-label required fw-bold fs-6">
									Promo Title
								</label>
								<div className="col-lg-8">
									<div className="row">
										<div className="col-lg-12 fv-row">
											<input
												type="text"
												className="form-control form-control-solid"
												placeholder="Promo Title"
												value={form?.coupon_title}
												onChange={(e) => handleChange(e, "coupon_title")}
											/>
										</div>
									</div>
								</div>
							</div>

							<div className="row mb-6">
								<label className="col-lg-4 col-form-label required fw-bold fs-6">
									Promo code
								</label>
								<div className="col-lg-8">
									<div className="row">
										<div className="col-lg-12 fv-row">
											<input
												type="text"
												className="form-control form-control-solid"
												placeholder="Promo code"
												value={form?.coupone_code}
												onChange={(e) => handleChange(e, "coupone_code")}
											/>
										</div>
									</div>
								</div>
							</div>

							<div className="row mb-6">
								<label className="col-lg-4 col-form-label required fw-bold fs-6">
									Discount
								</label>
								<div className="col-lg-8">
									<div className="row">
										<div className="col-lg-12 fv-row">
											<input
												type="text"
												className="form-control form-control-solid"
												placeholder="Discount"
												value={form?.coupone_discount}
												onChange={(e) => handleChange(e, "coupone_discount")}
											/>
										</div>
									</div>
								</div>
							</div>

							<div className="row mb-6">
								<label className="col-lg-4 col-form-label fw-bold fs-6">
									<span className="required">Discount type</span>
								</label>
								<div className="col-lg-8 fv-row">
									<div className="mt-4 d-flex">
										<div className="form-check form-check-custom form-check-solid form-check-sm me-10">
											<input
												className="form-check-input"
												name="discount"
												type="radio"
												value="Fixed"
												id="flexRadioSm"
												checked={form?.coupone_discount_type == "Fixed"}
												onChange={(e) =>
													handleChange(e, "coupone_discount_type")
												}
											/>
											<label className="form-check-label" htmlFor="flexRadioSm">
												Fixed
											</label>
										</div>

										<div className="form-check form-check-custom form-check-solid form-check-sm">
											<input
												className="form-check-input"
												name="discount"
												type="radio"
												value="Rate"
												id="flexRadioSm"
												checked={form?.coupone_discount_type == "Rate"}
												onChange={(e) =>
													handleChange(e, "coupone_discount_type")
												}
											/>
											<label
												className="form-check-label"
												htmlFor="flexRadioSm1"
											>
												% (rate)
											</label>
										</div>
									</div>
								</div>
							</div>

							<div className="row mb-6">
								<label className="col-lg-4 col-form-label fw-bold fs-6">
									<span className="required">One time coupon</span>
								</label>
								<div className="col-lg-8 fv-row">
									<div className="mt-4 d-flex">
										<div className="form-check form-check-custom form-check-solid form-check-sm me-10">
											<input
												className="form-check-input"
												name="frequency"
												type="radio"
												value="Yes"
												id="flexRadioSm"
												checked={form?.coupone_is_one_time == "Yes"}
												onChange={(e) => handleChange(e, "coupone_is_one_time")}
											/>
											<label className="form-check-label" htmlFor="flexRadioSm">
												Yes
											</label>
										</div>

										<div className="form-check form-check-custom form-check-solid form-check-sm">
											<input
												className="form-check-input"
												name="frequency"
												type="radio"
												value="No"
												id="flexRadioSm"
												checked={form?.coupone_is_one_time == "No"}
												onChange={(e) => handleChange(e, "coupone_is_one_time")}
											/>
											<label
												className="form-check-label"
												htmlFor="flexRadioSm1"
											>
												No
											</label>
										</div>
									</div>
								</div>
							</div>

							<div
								className="row mb-6"
								style={{
									display: `${
										form?.coupone_is_one_time === "No" ? "" : "none"
									}`,
								}}
							>
								<label className="col-lg-4 col-form-label required fw-bold fs-6">
									Coupon Using Limit
								</label>
								<div className="col-lg-8">
									<div className="row">
										<div className="col-lg-12 fv-row">
											<input
												type="text"
												className="form-control form-control-solid"
												placeholder="Coupon Limit"
												value={form?.coupon_max_uses}
												onChange={(e) => handleChange(e, "coupon_max_uses")}
											/>
										</div>
									</div>
								</div>
							</div>

							<div className="row mb-6">
								<label className="col-lg-4 col-form-label fw-bold fs-6">
									<span className="required">Valid till</span>
								</label>
								<div className="col-lg-8 fv-row valid-till">
									<input
										type="date"
										className="form-control form-control-solid"
										placeholder="Check in"
										value={form?.coupon_valid_from}
										onChange={(e) => handleChange(e, "coupon_valid_from")}
										min={new Date().toISOString().split("T")[0]}
									/>
									<h2>To</h2>
									<input
										type="date"
										className="form-control form-control-solid"
										placeholder="Check in"
										value={form?.coupon_valid_till}
										onChange={(e) => handleChange(e, "coupon_valid_till")}
										min={
											form && form["coupon_valid_from"]
												? new Date(form["coupon_valid_from"])
														.toISOString()
														.split("T")[0]
												: new Date().toISOString().split("T")[0]
										}
									/>
								</div>
							</div>
						</div>
						<div className="modal-footer">
							<button
								type="button"
								className="btn btn-light"
								data-bs-dismiss="modal"
							>
								Close
							</button>
							<button
								type="button"
								className="btn btn-primary"
								data-bs-dismiss="modal"
								onClick={onSubmit}
							>
								{form?._id ? "Edit" : "Add"} coupon
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
export default CouponPage;
