import { Alert } from "react-bootstrap-v5";
import { SnackbarProvider, enqueueSnackbar } from "notistack";

interface alertProps {
	variant: string;
	message: string;
	visible?: boolean;
}

function CustomeAlert({ variant, message, visible }: alertProps) {
	return (
		<>
			<Alert key={variant} variant={variant}>
				{message}
			</Alert>
		</>
	);
}

export default CustomeAlert;
