import { useState } from "react";
import { Button, Card } from "react-bootstrap-v5";
import { useHistory } from "react-router-dom";
import { KTSVG, toAbsoluteUrl } from "../../../../_metronic/helpers";
import axios from "axios";
import { useEffect } from "react";
import {
	checkMandatoryPolicies,
	checkMandatoryRules,
} from "../ProfileValidations";

const API_URL = process.env.REACT_APP_API_URL || "";

export default function Policies({ form, setForm }: any) {
	console.log("form 1", form);
	const history = useHistory();
	const [changes, setChanges] = useState(false);
	const [prevPolicyState, setPrevPolicyState] = useState<any>({});
	const [policyDisabled, setPolicyDisabled] = useState(false);
	const [valid, setValid] = useState(true);
	const [touched, setTouched] = useState({
		checkIn: false,
		checkOut: false,
	});
	const navigate = (e: any) => {
		if (e == "continue") {
			if (changes) {
				let body: any = { ...form };
				if (form.hasOwnProperty("_id")) {
					body["property_id"] = form._id;
				}
				if ((body["property_status"] = "Verified"))
					body["property_status"] = "Update";
				axios
					.post(`${API_URL}/v1/vendor/onboarding`, { data: body })
					.then(() => {
						history.push("/crafted/pages/profile/financeLegal");
					});
			} else history.push("/crafted/pages/profile/financeLegal");
		} else history.push("/crafted/pages/profile/photos");
	};

	const checkMandatory = (form: any) => {
		if (checkMandatoryPolicies(form)) setPolicyDisabled(false);
		else setPolicyDisabled(true);
	};

	const handlePolicyChange = (event: any, parent: any, value: any) => {
		let obj = { ...form };
		obj[parent][value] = event.target.value;
		checkMandatory(obj);
		setForm(obj);
		setChanges(true);
		// console.log(obj, "check in time");
	};

	const handleChange = (event: any, parent: any, value: any, index: number) => {
		if (!form[parent][value]) form[parent][value] = [];
		let object = form[parent][value].filter(
			(o: any) =>
				o &&
				o.question ==
					event.target.parentNode.parentNode.parentNode.parentNode.querySelector(
						"label"
					).innerText
		)[0];

		const questionStr =
			event.target.parentNode.parentNode.parentNode.parentNode.querySelector(
				"label"
			).innerText;
		// console.log("form values", questionStr);

		if (!object) {
			form[parent][value][index] = {
				question:
					event.target.parentNode.parentNode.parentNode.parentNode.querySelector(
						"label"
					).innerText,
				value: event.target.value,
			};
		} else {
			object.value = event.target.value;
		}
		setForm(JSON.parse(JSON.stringify(form)));
		setChanges(true);
	};

	const handleIDChange = (event: any, parent: any, value: any, child: any) => {
		form[parent][value][child] = event.target.value;
		setForm(JSON.parse(JSON.stringify(form)));
	};

	const prevPolicyStatus = (policy: string) => {
		// console.log("policy", policy);

		setPrevPolicyState({
			[policy]: JSON.parse(JSON.stringify(form["property_rules"][policy])),
		});
	};

	const restorePolicyStatus = (policy: string) => {
		let property_rules = form.property_rules;
		setForm({
			...form,
			property_rules: {
				...property_rules,
				[policy]: JSON.parse(JSON.stringify(prevPolicyState[policy])),
			},
		});
		setChanges(false);
	};
	useEffect(() => {
		let valid = checkMandatoryRules(form);
		setValid(valid);
		if (valid) setPolicyDisabled(false);
		else setPolicyDisabled(true);
	}, [form?.property_rules]);

	useEffect(() => {
		checkMandatory(form);
	}, []);

	console.log("----------form", form);
	console.log("----------form polices", form["property_policies"]);

	return (
		<div>
			<div className="card card-custom mb-10">
				<div className="card-header">
					<h3 className="card-title align-items-start flex-column">
						<span className="card-label fw-bolder fs-3 mb-1">Policies</span>
						<span className="text-muted fw-bold fs-7">
							Please add hotel policies here
						</span>
					</h3>
				</div>
				<fieldset
					disabled={form?.property_status === "Unverified" ? true : false}
				>
					<div className="card-body">
						<div className="basic-info-wrap">
							<div className="row">
								<div className="col-lg-12">
									<div className="policies-head-title">
										<h3 className="required">Checkin Checkout Time</h3>
									</div>
								</div>
								<div className="col-lg-4 mb-5">
									<label className="required form-label">Checkin Time</label>
									<input
										value={
											form["property_policies"] &&
											form["property_policies"]["checkin_time"]
										}
										onChange={(e) =>
											handlePolicyChange(e, "property_policies", "checkin_time")
										}
										type="time"
										className="form-control form-control-solid"
										placeholder=""
										onBlur={() => setTouched({ ...touched, checkIn: true })}
									/>
									{touched.checkIn && !form?.property_policies?.checkin_time && (
										<span className="text-danger fs-8" role="alert">
											Checkin Time is required field
										</span>
									)}
									<span className="text-white">.</span>
								</div>
								<div className="col-lg-4 mb-5">
									<label className="required form-label">Checkout Time</label>
									<input
										value={
											form["property_policies"] &&
											form["property_policies"]["checkout_time"]
										}
										onChange={(e) =>
											handlePolicyChange(
												e,
												"property_policies",
												"checkout_time"
											)
										}
										type="time"
										className="form-control form-control-solid"
										placeholder=""
										onBlur={() => setTouched({ ...touched, checkOut: true })}
									/>
									{touched.checkOut && !form?.property_policies?.checkout_time && (
										<span className="text-danger fs-8" role="alert">
											Checkout Time is required field
										</span>
									)}
								</div>
							</div>

							<div className="row mt-5">
								<div className="col-lg-12">
									<div className="policies-head-title">
										<h3 className="required">Cancellation Policy</h3>
									</div>
								</div>
								<div className="col-lg-12">
									<div className="mb-5 d-flex canc-pol-wrap">
										<div className="form-check form-check-custom form-check-solid form-check-sm me-10">
											<input
												checked={
													form["property_policies"] &&
													form["property_policies"]["cancellation_policy"] ==
														"Free cancellation upto 24 hrs"
												}
												onChange={(e) =>
													handlePolicyChange(
														e,
														"property_policies",
														"cancellation_policy"
													)
												}
												className="form-check-input"
												name="cancellation"
												type="radio"
												value="Free cancellation upto 24 hrs"
												id="flexRadioSm"
											/>
											<label className="form-check-label" htmlFor="flexRadioSm">
												Free cancellation upto 24 hrs
											</label>
										</div>

										<div className="form-check form-check-custom form-check-solid form-check-sm me-10">
											<input
												checked={
													form["property_policies"] &&
													form["property_policies"]["cancellation_policy"] ==
														"Free cancellation upto 48 hrs"
												}
												onChange={(e) =>
													handlePolicyChange(
														e,
														"property_policies",
														"cancellation_policy"
													)
												}
												className="form-check-input"
												name="cancellation"
												type="radio"
												value="Free cancellation upto 48 hrs"
												id="flexRadioSm"
											/>
											<label
												className="form-check-label"
												htmlFor="flexRadioSm1"
											>
												Free cancellation upto 48 hrs
											</label>
										</div>
										<div className="form-check form-check-custom form-check-solid form-check-sm me-10">
											<input
												checked={
													form["property_policies"] &&
													form["property_policies"]["cancellation_policy"] ==
														"Free cancellation upto 72 hrs"
												}
												onChange={(e) =>
													handlePolicyChange(
														e,
														"property_policies",
														"cancellation_policy"
													)
												}
												className="form-check-input"
												name="cancellation"
												type="radio"
												value="Free cancellation upto 72 hrs"
												id="flexRadioSm"
											/>
											<label
												className="form-check-label"
												htmlFor="flexRadioSm1"
											>
												Free cancellation upto 72 hrs
											</label>
										</div>
										<div className="form-check form-check-custom form-check-solid form-check-sm ">
											<input
												checked={
													form["property_policies"] &&
													form["property_policies"]["cancellation_policy"] ==
														"Non Refundable"
												}
												onChange={(e) =>
													handlePolicyChange(
														e,
														"property_policies",
														"cancellation_policy"
													)
												}
												className="form-check-input"
												name="cancellation"
												type="radio"
												value="Non Refundable"
												id="flexRadioSm"
											/>
											<label
												className="form-check-label"
												htmlFor="flexRadioSm1"
											>
												Non Refundable
											</label>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</fieldset>
			</div>

			<div className="card card-custom mb-10">
				<div className="card-header">
					<h3 className="card-title align-items-start flex-column">
						<span className="card-label fw-bolder fs-3 mb-1">
							Property Rules{" "}
							<span
								className={
									"fs-8 ms-2 " + (valid ? "text-muted" : "text-danger")
								}
							>
								(Please Fill All Options)
							</span>
							{/* Property Rules <span className={"fs-8 ms-2 " + (valid ? "text-muted" : "text-danger")}>(Select at least 3 rules)</span> */}
						</span>
						{/* <span className="text-muted fw-bold fs-7">
              Add room level details
            </span> */}
					</h3>

					<div className="card-toolbar"></div>
				</div>
				<div className="card-body">
					<div className="container ps-0 pe-0">
						<div className="row">
							<div className="col-lg-4 mb-5">
								<Card className="policy-card">
									<div className="policy-card-img">
										<img
											src={toAbsoluteUrl("/media/img/geust-p.jpg")}
											alt="MyResorts.in"
										/>
									</div>
									<Card.Body>
										<Card.Title>Guest Profile</Card.Title>
										<Card.Text>
											Got a preference for guests allowed to stay at your
											property? Fill this section to specify the details.
											{/* <span>7rules are not defined</span> */}
										</Card.Text>
										<Button
											variant="primary"
											data-bs-toggle="modal"
											data-bs-target="#kt_modal_1"
											onClick={() => prevPolicyStatus("guest_profile")}
										>
											Define now
										</Button>
									</Card.Body>
								</Card>
							</div>

							<div className="col-lg-4 mb-5">
								<Card className="policy-card">
									<div className="policy-card-img">
										<img
											src={toAbsoluteUrl("/media/img/d-proof.jpg")}
											alt="MyResorts.in"
										/>
									</div>

									<Card.Body>
										<Card.Title>Acceptable Identity Proofs</Card.Title>
										<Card.Text>
											Specify the type of Identify Proofs accepted or rejected
											at your property during check-in.
											{/* <span>3rules are not defined</span> */}
										</Card.Text>
										<Button
											variant="primary"
											data-bs-toggle="modal"
											data-bs-target="#kt_modal_2"
											onClick={() => prevPolicyStatus("id_proof")}
										>
											Define now
										</Button>
									</Card.Body>
								</Card>
							</div>

							<div className="col-lg-4 mb-5">
								<Card className="policy-card">
									<div className="policy-card-img">
										<img
											src={toAbsoluteUrl("/media/img/hygiene.jpg")}
											alt="MyResorts.in"
										/>
									</div>
									<Card.Body>
										<Card.Title>General Safety & Hygiene Guidelines</Card.Title>
										<Card.Text>
											Mention the details about the type of practices followed
											at your property to ensure the safety of guests.
											{/* <span>8rules are not defined</span> */}
										</Card.Text>
										<Button
											data-bs-toggle="modal"
											data-bs-target="#kt_modal_3"
											variant="primary"
											onClick={() =>
												prevPolicyStatus("general_safety_hygiene_guidelines")
											}
										>
											Define now
										</Button>
									</Card.Body>
								</Card>
							</div>

							<div className="col-lg-4 mb-5">
								<Card className="policy-card">
									<div className="policy-card-img">
										<img
											src={toAbsoluteUrl("/media/img/room-hygiene.jpg")}
											alt="MyResorts.in"
										/>
									</div>
									<Card.Body>
										<Card.Title>Room Safety and Hygiene</Card.Title>
										<Card.Text>
											Please specify the safety and sanitization measures
											followed at your property to ensure proper room hygiene.
											{/* <span>4rules are not defined</span> */}
										</Card.Text>
										<Button
											data-bs-toggle="modal"
											data-bs-target="#kt_modal_4"
											variant="primary"
											onClick={() => prevPolicyStatus("room_safety_hygiene")}
										>
											Define now
										</Button>
									</Card.Body>
								</Card>
							</div>

							<div className="col-lg-4 mb-5">
								<Card className="policy-card">
									<div className="policy-card-img">
										<img
											src={toAbsoluteUrl("/media/img/social-distance.jpg")}
											alt="MyResorts.in"
										/>
									</div>
									<Card.Body>
										<Card.Title>Physical Distancing</Card.Title>
										<Card.Text>
											What kind of physical distancing measures are followed at
											your property? Please specify it here.
											{/* <span>5rules are not defined</span> */}
										</Card.Text>
										<Button
											data-bs-toggle="modal"
											data-bs-target="#kt_modal_5"
											variant="primary"
											onClick={() => prevPolicyStatus("social_distancing")}
										>
											Define now
										</Button>
									</Card.Body>
								</Card>
							</div>

							<div className="col-lg-4 mb-5">
								<Card className="policy-card">
									<div className="policy-card-img">
										<img
											src={toAbsoluteUrl("/media/img/food.jpg")}
											alt="MyResorts.in"
										/>
									</div>
									<Card.Body>
										<Card.Title>Food and Drinks Hygiene</Card.Title>
										<Card.Text>
											Please specify the kind of hygiene measures followed at
											the kitchen and restaurant of your property (Skip this, if
											you don’t have a kitchen or restaurant)
											{/* <span>4rules are not defined</span> */}
										</Card.Text>
										<Button
											data-bs-toggle="modal"
											data-bs-target="#kt_modal_6"
											variant="primary"
											onClick={() => prevPolicyStatus("food_drinks_hygiene")}
										>
											Define now
										</Button>
									</Card.Body>
								</Card>
							</div>

							<div className="col-lg-4 mb-5">
								<Card className="policy-card">
									<div className="policy-card-img">
										<img
											src={toAbsoluteUrl("/media/img/property-restriction.png")}
											alt="MyResorts.in"
										/>
									</div>
									<Card.Body>
										<Card.Title>Property Restrictions</Card.Title>
										<Card.Text>
											If there are some restrictions for the guests staying at
											your property, please mention it in this section.
											{/* <span>7 rules are not defined</span> */}
										</Card.Text>
										<Button
											data-bs-toggle="modal"
											data-bs-target="#kt_modal_7"
											variant="primary"
											onClick={() => prevPolicyStatus("property_restrictions")}
										>
											Define now
										</Button>
									</Card.Body>
								</Card>
							</div>

							<div className="col-lg-4 mb-5">
								<Card className="policy-card">
									<div className="policy-card-img">
										<img
											src={toAbsoluteUrl("/media/img/pet-policy.jpg")}
											alt="MyResorts.in"
										/>
									</div>
									<Card.Body>
										<Card.Title>Pet Policy</Card.Title>
										<Card.Text>
											Do you have any pets living at your property? Do you allow
											guests to bring along their pets during their stay?
											Mention it here.
											{/* <span>2 rules are not defined</span> */}
										</Card.Text>
										<Button
											data-bs-toggle="modal"
											data-bs-target="#kt_modal_8"
											variant="primary"
											onClick={() => prevPolicyStatus("pet_policy")}
										>
											Define now
										</Button>
									</Card.Body>
								</Card>
							</div>

							<div className="col-lg-4 mb-5">
								<Card className="policy-card">
									<div className="policy-card-img">
										<img
											src={toAbsoluteUrl("/media/img/geust-p.jpg")}
											alt="MyResorts.in"
										/>
									</div>
									<Card.Body>
										<Card.Title>Suitability for Guests</Card.Title>
										<Card.Text>
											Is your property suitable for elderly and specially-abled.
											Please mention the details here.
											{/* <span>4 rules are not defined</span> */}
										</Card.Text>
										<Button
											data-bs-toggle="modal"
											data-bs-target="#kt_modal_9"
											variant="primary"
											onClick={() => prevPolicyStatus("guest_suitabilty")}
										>
											Define now
										</Button>
									</Card.Body>
								</Card>
							</div>

							<div className="col-lg-4 mb-5">
								<Card className="policy-card">
									<div className="policy-card-img">
										<img
											src={toAbsoluteUrl("/media/img/check-in.jpg")}
											alt="MyResorts.in"
										/>
									</div>
									<Card.Body>
										<Card.Title>Checkin and Checkout Policies</Card.Title>
										<Card.Text>
											Do you follow any particular check-in and check-out
											timings? Please mention it in this section.
											{/* <span>2 rules are not defined</span> */}
										</Card.Text>
										<Button
											data-bs-toggle="modal"
											data-bs-target="#kt_modal_10"
											variant="primary"
											onClick={() =>
												prevPolicyStatus("checkin_checkout_policy")
											}
										>
											Define now
										</Button>
									</Card.Body>
								</Card>
							</div>

							<div className="col-lg-4 mb-5">
								<Card className="policy-card">
									<div className="policy-card-img">
										<img
											src={toAbsoluteUrl("/media/img/extra-bed.jpg")}
											alt="MyResorts.in"
										/>
									</div>
									<Card.Body>
										<Card.Title>Extra Bed Policies</Card.Title>
										<Card.Text>
											Is there any policy regarding the provision of extra beds
											at your property? Go ahead and mention it here.
											{/*  <span>2 rules are not defined</span> */}
										</Card.Text>
										<Button
											data-bs-toggle="modal"
											data-bs-target="#kt_modal_11"
											variant="primary"
											onClick={() => prevPolicyStatus("extra_bed_policy")}
										>
											Define now
										</Button>
									</Card.Body>
								</Card>
							</div>

							<div className="col-lg-4 mb-5">
								<Card className="policy-card">
									<div className="policy-card-img">
										<img
											src={toAbsoluteUrl("/media/img/custom.jpg")}
											alt="MyResorts.in"
										/>
									</div>
									<Card.Body>
										<Card.Title>Custom Policy</Card.Title>
										<Card.Text>
											Please connect with your BDM to update your custom policy.
										</Card.Text>
										<Button
											data-bs-toggle="modal"
											data-bs-target="#kt_modal_12"
											variant="primary"
											onClick={() => prevPolicyStatus("room_safety_hygiene")}
										>
											Edit rule
										</Button>
									</Card.Body>
								</Card>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="modal fade" tabIndex={-1} id="kt_modal_1">
				<div className="modal-dialog">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title">Guest Profile</h5>
							<div
								className="btn btn-icon btn-sm btn-active-light-primary ms-2"
								data-bs-dismiss="modal"
								aria-label="Close"
							>
								<KTSVG
									path="/media/icons/duotune/arrows/arr061.svg"
									className="svg-icon svg-icon-2x"
								/>
							</div>
						</div>

						<div className="modal-body">
							<fieldset
								disabled={form?.property_status === "Unverified" ? true : false}
							>
								<div className="row">
									<div className="col-lg-8">
										<label className="form-label">
											Do you allow unmarried couples ?
										</label>
									</div>
									<div className="col-lg-4">
										<div className="mb-5 d-flex">
											<div className="form-check form-check-custom form-check-solid form-check-sm me-10">
												<input
													checked={
														form["property_rules"]["guest_profile"][0]
															?.value === "true"
													}
													onChange={(e) =>
														handleChange(
															e,
															"property_rules",
															"guest_profile",
															0
														)
													}
													className="form-check-input"
													name="couple"
													type="radio"
													value="true"
													id="flexRadioSm"
												/>
												<label
													className="form-check-label"
													htmlFor="flexRadioSm"
												>
													Yes
												</label>
											</div>

											<div className="form-check form-check-custom form-check-solid form-check-sm me-10">
												<input
													checked={
														form["property_rules"]["guest_profile"][0]
															?.value === "false"
													}
													onChange={(e) =>
														handleChange(
															e,
															"property_rules",
															"guest_profile",
															0
														)
													}
													className="form-check-input"
													name="couple"
													type="radio"
													value="false"
													id="flexRadioSm"
												/>
												<label
													className="form-check-label"
													htmlFor="flexRadioSm1"
												>
													No
												</label>
											</div>
										</div>
									</div>
								</div>

								<div className="row">
									<div className="col-lg-8">
										<label className="form-label">
											Do you allow bachelors ?
										</label>
									</div>
									<div className="col-lg-4">
										<div className="mb-5 d-flex">
											<div className="form-check form-check-custom form-check-solid form-check-sm me-10">
												<input
													checked={
														form["property_rules"]["guest_profile"][1]?.value ==
														"true"
													}
													onChange={(e) =>
														handleChange(
															e,
															"property_rules",
															"guest_profile",
															1
														)
													}
													className="form-check-input"
													name="bachelors"
													type="radio"
													value="true"
													id="flexRadioSm"
												/>
												<label
													className="form-check-label"
													htmlFor="flexRadioSm"
												>
													Yes
												</label>
											</div>

											<div className="form-check form-check-custom form-check-solid form-check-sm me-10">
												<input
													checked={
														form["property_rules"]["guest_profile"][1]?.value ==
														"false"
													}
													onChange={(e) =>
														handleChange(
															e,
															"property_rules",
															"guest_profile",
															1
														)
													}
													className="form-check-input"
													name="bachelors"
													type="radio"
													value="false"
													id="flexRadioSm"
												/>
												<label
													className="form-check-label"
													htmlFor="flexRadioSm1"
												>
													No
												</label>
											</div>
										</div>
									</div>
								</div>

								<div className="row">
									<div className="col-lg-8">
										<label className="form-label">
											Do you allow guests below 18 years of age ?
										</label>
									</div>
									<div className="col-lg-4">
										<div className="mb-5 d-flex">
											<div className="form-check form-check-custom form-check-solid form-check-sm me-10">
												<input
													checked={
														form["property_rules"]["guest_profile"][2]?.value ==
														"true"
													}
													onChange={(e) =>
														handleChange(
															e,
															"property_rules",
															"guest_profile",
															2
														)
													}
													className="form-check-input"
													name="allowedage"
													type="radio"
													value="true"
													id="flexRadioSm"
												/>
												<label
													className="form-check-label"
													htmlFor="flexRadioSm"
												>
													Yes
												</label>
											</div>

											<div className="form-check form-check-custom form-check-solid form-check-sm me-10">
												<input
													checked={
														form["property_rules"]["guest_profile"][2]?.value ==
														"false"
													}
													onChange={(e) =>
														handleChange(
															e,
															"property_rules",
															"guest_profile",
															2
														)
													}
													className="form-check-input"
													name="allowedage"
													type="radio"
													value="false"
													id="flexRadioSm"
												/>
												<label
													className="form-check-label"
													htmlFor="flexRadioSm1"
												>
													No
												</label>
											</div>
										</div>
									</div>
								</div>

								<div className="row">
									<div className="col-lg-8">
										<label className="form-label">
											Do you allow female guests?
										</label>
									</div>
									<div className="col-lg-4">
										<div className="mb-5 d-flex">
											<div className="form-check form-check-custom form-check-solid form-check-sm me-10">
												<input
													checked={
														form["property_rules"]["guest_profile"][3]?.value ==
														"true"
													}
													onChange={(e) =>
														handleChange(
															e,
															"property_rules",
															"guest_profile",
															3
														)
													}
													className="form-check-input"
													name="female"
													type="radio"
													value="true"
													id="flexRadioSm"
												/>
												<label
													className="form-check-label"
													htmlFor="flexRadioSm"
												>
													Yes
												</label>
											</div>

											<div className="form-check form-check-custom form-check-solid form-check-sm me-10">
												<input
													checked={
														form["property_rules"]["guest_profile"][3]?.value ==
														"false"
													}
													onChange={(e) =>
														handleChange(
															e,
															"property_rules",
															"guest_profile",
															3
														)
													}
													className="form-check-input"
													name="female"
													type="radio"
													value="false"
													id="flexRadioSm"
												/>
												<label
													className="form-check-label"
													htmlFor="flexRadioSm1"
												>
													No
												</label>
											</div>
										</div>
									</div>
								</div>

								<div className="row">
									<div className="col-lg-8">
										<label className="form-label">
											Do you allow male guests?
										</label>
									</div>
									<div className="col-lg-4">
										<div className="mb-5 d-flex">
											<div className="form-check form-check-custom form-check-solid form-check-sm me-10">
												<input
													checked={
														form["property_rules"]["guest_profile"][4]?.value ==
														"true"
													}
													onChange={(e) =>
														handleChange(
															e,
															"property_rules",
															"guest_profile",
															4
														)
													}
													className="form-check-input"
													name="male"
													type="radio"
													value="true"
													id="flexRadioSm"
												/>
												<label
													className="form-check-label"
													htmlFor="flexRadioSm"
												>
													Yes
												</label>
											</div>

											<div className="form-check form-check-custom form-check-solid form-check-sm me-10">
												<input
													checked={
														form["property_rules"]["guest_profile"][4]?.value ==
														"false"
													}
													onChange={(e) =>
														handleChange(
															e,
															"property_rules",
															"guest_profile",
															4
														)
													}
													className="form-check-input"
													name="male"
													type="radio"
													value="false"
													id="flexRadioSm"
												/>
												<label
													className="form-check-label"
													htmlFor="flexRadioSm1"
												>
													No
												</label>
											</div>
										</div>
									</div>
								</div>

								<div className="row">
									<div className="col-lg-8">
										<label className="form-label">Do you allow children?</label>
									</div>
									<div className="col-lg-4">
										<div className="mb-5 d-flex">
											<div className="form-check form-check-custom form-check-solid form-check-sm me-10">
												<input
													checked={
														form["property_rules"]["guest_profile"][5]?.value ==
														"true"
													}
													onChange={(e) =>
														handleChange(
															e,
															"property_rules",
															"guest_profile",
															5
														)
													}
													className="form-check-input"
													name="children"
													type="radio"
													value="true"
													id="flexRadioSm"
												/>
												<label
													className="form-check-label"
													htmlFor="flexRadioSm"
												>
													Yes
												</label>
											</div>

											<div className="form-check form-check-custom form-check-solid form-check-sm me-10">
												<input
													checked={
														form["property_rules"]["guest_profile"][5]?.value ==
														"false"
													}
													onChange={(e) =>
														handleChange(
															e,
															"property_rules",
															"guest_profile",
															5
														)
													}
													className="form-check-input"
													name="children"
													type="radio"
													value="false"
													id="flexRadioSm"
												/>
												<label
													className="form-check-label"
													htmlFor="flexRadioSm1"
												>
													No
												</label>
											</div>
										</div>
									</div>
								</div>

								<div className="row">
									<div className="col-lg-8">
										<label className="form-label">Is stag entry allowed?</label>
									</div>
									<div className="col-lg-4">
										<div className="mb-5 d-flex">
											<div className="form-check form-check-custom form-check-solid form-check-sm me-10">
												<input
													checked={
														form["property_rules"]["guest_profile"][6]?.value ==
														"true"
													}
													onChange={(e) =>
														handleChange(
															e,
															"property_rules",
															"guest_profile",
															6
														)
													}
													className="form-check-input"
													name="stag"
													type="radio"
													value="true"
													id="flexRadioSm"
												/>
												<label
													className="form-check-label"
													htmlFor="flexRadioSm"
												>
													Yes
												</label>
											</div>

											<div className="form-check form-check-custom form-check-solid form-check-sm me-10">
												<input
													checked={
														form["property_rules"]["guest_profile"][6]?.value ==
														"false"
													}
													onChange={(e) =>
														handleChange(
															e,
															"property_rules",
															"guest_profile",
															6
														)
													}
													className="form-check-input"
													name="stag"
													type="radio"
													value="false"
													id="flexRadioSm"
												/>
												<label
													className="form-check-label"
													htmlFor="flexRadioSm1"
												>
													No
												</label>
											</div>
										</div>
									</div>
								</div>
							</fieldset>
						</div>
						<div className="modal-footer">
							<button
								type="button"
								className="btn btn-light"
								data-bs-dismiss="modal"
								onClick={() => restorePolicyStatus("guest_profile")}
							>
								Cancel
							</button>
							<fieldset
								disabled={form?.property_status === "Unverified" ? true : false}
							>
								<button
									type="button"
									className="btn btn-primary"
									data-bs-dismiss="modal"
								>
									Confirm
								</button>
							</fieldset>
						</div>
					</div>
				</div>
			</div>

			<div className="modal fade" tabIndex={-2} id="kt_modal_2">
				<div className="modal-dialog">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title">Acceptable Identity Proofs</h5>
							<div
								className="btn btn-icon btn-sm btn-active-light-primary ms-2"
								data-bs-dismiss="modal"
								aria-label="Close"
							>
								<KTSVG
									path="/media/icons/duotune/arrows/arr061.svg"
									className="svg-icon svg-icon-2x"
								/>
							</div>
						</div>
						<div className="modal-body">
							<fieldset
								disabled={form?.property_status === "Unverified" ? true : false}
							>
								<div className="row mb-5">
									<div className="col-lg-8">
										<label className="form-label">
											Acceptable Identity Proofs
										</label>
									</div>
									<div className="col-lg-4">
										<select
											className="form-select form-select-solid"
											aria-label="Select example"
											value={
												form["property_rules"]["id_proof"]
													?.acceptable_identity_proofs
											}
											onChange={(e) =>
												handleIDChange(
													e,
													"property_rules",
													"id_proof",
													"acceptable_identity_proofs"
												)
											}
										>
											<option value="">Select One</option>
											<option value="Passport">Passport</option>
											<option value="Adhaar">Adhaar</option>
											<option value="Driving Lisence">Driving Lisence</option>
										</select>
									</div>
								</div>

								<div className="row mb-5">
									<div className="col-lg-8">
										<label className="form-label">
											Unacceptable Identity Proofs
										</label>
									</div>
									<div className="col-lg-4">
										<select
											className="form-select form-select-solid"
											aria-label="Select example"
											value={
												form["property_rules"]["id_proof"]
													?.unacceptable_identity_proofs
											}
											onChange={(e) =>
												handleIDChange(
													e,
													"property_rules",
													"id_proof",
													"unacceptable_identity_proofs"
												)
											}
										>
											<option value="">Select One</option>
											<option value="Passport">Passport</option>
											<option value="Adhaar">Adhaar</option>
											<option value="Pan">Pan</option>
										</select>
									</div>
								</div>

								<div className="row mb-5">
									<div className="col-lg-8">
										<label className="form-label">
											Are IDs of the same city as the property allowed?
										</label>
									</div>
									<div className="col-lg-4">
										<div className="mb-5 d-flex">
											<div className="form-check form-check-custom form-check-solid form-check-sm me-10">
												<input
													checked={
														form["property_rules"]["id_proof"]
															?.allow_same_id === "true"
													}
													onChange={(e) =>
														handleIDChange(
															e,
															"property_rules",
															"id_proof",
															"allow_same_id"
														)
													}
													className="form-check-input"
													name="age"
													type="radio"
													value="true"
													id="flexRadioSm"
												/>
												<label
													className="form-check-label"
													htmlFor="flexRadioSm"
												>
													Yes
												</label>
											</div>

											<div className="form-check form-check-custom form-check-solid form-check-sm me-10">
												<input
													checked={
														form["property_rules"]["id_proof"]
															?.allow_same_id === "false"
													}
													onChange={(e) =>
														handleIDChange(
															e,
															"property_rules",
															"id_proof",
															"allow_same_id"
														)
													}
													className="form-check-input"
													name="age"
													type="radio"
													value="false"
													id="flexRadioSm"
												/>
												<label
													className="form-check-label"
													htmlFor="flexRadioSm1"
												>
													No
												</label>
											</div>
										</div>
									</div>
								</div>
							</fieldset>
						</div>
						<div className="modal-footer">
							<button
								type="button"
								className="btn btn-light"
								data-bs-dismiss="modal"
								onClick={() => restorePolicyStatus("id_proof")}
							>
								Cancel
							</button>
							<fieldset
								disabled={form?.property_status === "Unverified" ? true : false}
							>
								<button
									type="button"
									className="btn btn-primary"
									data-bs-dismiss="modal"
								>
									Confirm
								</button>
							</fieldset>
						</div>
					</div>
				</div>
			</div>

			<div className="modal fade" tabIndex={-3} id="kt_modal_3">
				<div className="modal-dialog">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title">
								General Safety and Hygiene Guidelines
							</h5>
							<div
								className="btn btn-icon btn-sm btn-active-light-primary ms-2"
								data-bs-dismiss="modal"
								aria-label="Close"
							>
								<KTSVG
									path="/media/icons/duotune/arrows/arr061.svg"
									className="svg-icon svg-icon-2x"
								/>
							</div>
						</div>
						<div className="modal-body">
							<fieldset
								disabled={form?.property_status === "Unverified" ? true : false}
							>
								<div className="row">
									<div className="col-lg-8">
										<label className="form-label">
											Quarantine protocol followed as per local government
											authorities?
										</label>
									</div>
									<div className="col-lg-4">
										<div className="mb-5 d-flex">
											<div className="form-check form-check-custom form-check-solid form-check-sm me-10">
												<input
													checked={
														form["property_rules"][
															"general_safety_hygiene_guidelines"
														][0]?.value === "true"
													}
													onChange={(e) =>
														handleChange(
															e,
															"property_rules",
															"general_safety_hygiene_guidelines",
															0
														)
													}
													className="form-check-input"
													name="quarantine"
													type="radio"
													value="true"
													id="flexRadioSm"
												/>
												<label
													className="form-check-label"
													htmlFor="flexRadioSm"
												>
													Yes
												</label>
											</div>

											<div className="form-check form-check-custom form-check-solid form-check-sm me-10">
												<input
													checked={
														form["property_rules"][
															"general_safety_hygiene_guidelines"
														][0]?.value === "false"
													}
													onChange={(e) =>
														handleChange(
															e,
															"property_rules",
															"general_safety_hygiene_guidelines",
															0
														)
													}
													className="form-check-input"
													name="quarantine"
													type="radio"
													value="false"
													id="flexRadioSm"
												/>
												<label
													className="form-check-label"
													htmlFor="flexRadioSm1"
												>
													No
												</label>
											</div>
										</div>
									</div>
								</div>
								<div className="row">
									<div className="col-lg-8">
										<label className="form-label">
											Guests from containment zones allowed?
										</label>
									</div>
									<div className="col-lg-4">
										<div className="mb-5 d-flex">
											<div className="form-check form-check-custom form-check-solid form-check-sm me-10">
												<input
													checked={
														form["property_rules"][
															"general_safety_hygiene_guidelines"
														][1]?.value == "true"
													}
													onChange={(e) =>
														handleChange(
															e,
															"property_rules",
															"general_safety_hygiene_guidelines",
															1
														)
													}
													className="form-check-input"
													name="containmentzone"
													type="radio"
													value="true"
													id="flexRadioSm"
												/>
												<label
													className="form-check-label"
													htmlFor="flexRadioSm"
												>
													Yes
												</label>
											</div>

											<div className="form-check form-check-custom form-check-solid form-check-sm me-10">
												<input
													checked={
														form["property_rules"][
															"general_safety_hygiene_guidelines"
														][1]?.value == "false"
													}
													onChange={(e) =>
														handleChange(
															e,
															"property_rules",
															"general_safety_hygiene_guidelines",
															1
														)
													}
													className="form-check-input"
													name="containmentzone"
													type="radio"
													value="false"
													id="flexRadioSm"
												/>
												<label
													className="form-check-label"
													htmlFor="flexRadioSm1"
												>
													No
												</label>
											</div>
										</div>
									</div>
								</div>

								<div className="row">
									<div className="col-lg-8">
										<label className="form-label">
											Shared resources in common areas are properly sanitized?
										</label>
									</div>
									<div className="col-lg-4">
										<div className="mb-5 d-flex">
											<div className="form-check form-check-custom form-check-solid form-check-sm me-10">
												<input
													checked={
														form["property_rules"][
															"general_safety_hygiene_guidelines"
														][2]?.value == "true"
													}
													onChange={(e) =>
														handleChange(
															e,
															"property_rules",
															"general_safety_hygiene_guidelines",
															2
														)
													}
													className="form-check-input"
													name="sanitized"
													type="radio"
													value="true"
													id="flexRadioSm"
												/>
												<label
													className="form-check-label"
													htmlFor="flexRadioSm"
												>
													Yes
												</label>
											</div>

											<div className="form-check form-check-custom form-check-solid form-check-sm me-10">
												<input
													checked={
														form["property_rules"][
															"general_safety_hygiene_guidelines"
														][2]?.value == "false"
													}
													onChange={(e) =>
														handleChange(
															e,
															"property_rules",
															"general_safety_hygiene_guidelines",
															2
														)
													}
													className="form-check-input"
													name="sanitized"
													type="radio"
													value="false"
													id="flexRadioSm"
												/>
												<label
													className="form-check-label"
													htmlFor="flexRadioSm1"
												>
													No
												</label>
											</div>
										</div>
									</div>
								</div>

								<div className="row">
									<div className="col-lg-8">
										<label className="form-label">
											Property staff trained on hygiene guidelines?
										</label>
									</div>
									<div className="col-lg-4">
										<div className="mb-5 d-flex">
											<div className="form-check form-check-custom form-check-solid form-check-sm me-10">
												<input
													checked={
														form["property_rules"][
															"general_safety_hygiene_guidelines"
														][3]?.value == "true"
													}
													onChange={(e) =>
														handleChange(
															e,
															"property_rules",
															"general_safety_hygiene_guidelines",
															3
														)
													}
													className="form-check-input"
													name="staff"
													type="radio"
													value="true"
													id="flexRadioSm"
												/>
												<label
													className="form-check-label"
													htmlFor="flexRadioSm"
												>
													Yes
												</label>
											</div>

											<div className="form-check form-check-custom form-check-solid form-check-sm me-10">
												<input
													checked={
														form["property_rules"][
															"general_safety_hygiene_guidelines"
														][3]?.value == "false"
													}
													onChange={(e) =>
														handleChange(
															e,
															"property_rules",
															"general_safety_hygiene_guidelines",
															3
														)
													}
													className="form-check-input"
													name="staff"
													type="radio"
													value="false"
													id="flexRadioSm"
												/>
												<label
													className="form-check-label"
													htmlFor="flexRadioSm1"
												>
													No
												</label>
											</div>
										</div>
									</div>
								</div>

								<div className="row">
									<div className="col-lg-8">
										<label className="form-label">
											Guest with fever allowed?
										</label>
									</div>
									<div className="col-lg-4">
										<div className="mb-5 d-flex">
											<div className="form-check form-check-custom form-check-solid form-check-sm me-10">
												<input
													checked={
														form["property_rules"][
															"general_safety_hygiene_guidelines"
														][4]?.value == "true"
													}
													onChange={(e) =>
														handleChange(
															e,
															"property_rules",
															"general_safety_hygiene_guidelines",
															4
														)
													}
													className="form-check-input"
													name="fever"
													type="radio"
													value="true"
													id="flexRadioSm"
												/>
												<label
													className="form-check-label"
													htmlFor="flexRadioSm"
												>
													Yes
												</label>
											</div>

											<div className="form-check form-check-custom form-check-solid form-check-sm me-10">
												<input
													checked={
														form["property_rules"][
															"general_safety_hygiene_guidelines"
														][4]?.value == "false"
													}
													onChange={(e) =>
														handleChange(
															e,
															"property_rules",
															"general_safety_hygiene_guidelines",
															4
														)
													}
													className="form-check-input"
													name="fever"
													type="radio"
													value="false"
													id="flexRadioSm"
												/>
												<label
													className="form-check-label"
													htmlFor="flexRadioSm1"
												>
													No
												</label>
											</div>
										</div>
									</div>
								</div>

								<div className="row">
									<div className="col-lg-8">
										<label className="form-label">
											Aarogya Setu app required?
										</label>
									</div>
									<div className="col-lg-4">
										<div className="mb-5 d-flex">
											<div className="form-check form-check-custom form-check-solid form-check-sm me-10">
												<input
													checked={
														form["property_rules"][
															"general_safety_hygiene_guidelines"
														][5]?.value == "true"
													}
													onChange={(e) =>
														handleChange(
															e,
															"property_rules",
															"general_safety_hygiene_guidelines",
															5
														)
													}
													className="form-check-input"
													name="arogya"
													type="radio"
													value="true"
													id="flexRadioSm"
												/>
												<label
													className="form-check-label"
													htmlFor="flexRadioSm"
												>
													Yes
												</label>
											</div>

											<div className="form-check form-check-custom form-check-solid form-check-sm me-10">
												<input
													checked={
														form["property_rules"][
															"general_safety_hygiene_guidelines"
														][5]?.value == "false"
													}
													onChange={(e) =>
														handleChange(
															e,
															"property_rules",
															"general_safety_hygiene_guidelines",
															5
														)
													}
													className="form-check-input"
													name="arogya"
													type="radio"
													value="false"
													id="flexRadioSm"
												/>
												<label
													className="form-check-label"
													htmlFor="flexRadioSm1"
												>
													No
												</label>
											</div>
										</div>
									</div>
								</div>

								<div className="row">
									<div className="col-lg-8">
										<label className="form-label">
											Hand sanitizer is provided in guest accommodation and
											common areas?
										</label>
									</div>
									<div className="col-lg-4">
										<div className="mb-5 d-flex">
											<div className="form-check form-check-custom form-check-solid form-check-sm me-10">
												<input
													checked={
														form["property_rules"][
															"general_safety_hygiene_guidelines"
														][6]?.value == "true"
													}
													onChange={(e) =>
														handleChange(
															e,
															"property_rules",
															"general_safety_hygiene_guidelines",
															6
														)
													}
													className="form-check-input"
													name="handsanitizer"
													type="radio"
													value="true"
													id="flexRadioSm"
												/>
												<label
													className="form-check-label"
													htmlFor="flexRadioSm"
												>
													Yes
												</label>
											</div>

											<div className="form-check form-check-custom form-check-solid form-check-sm me-10">
												<input
													checked={
														form["property_rules"][
															"general_safety_hygiene_guidelines"
														][6]?.value == "false"
													}
													onChange={(e) =>
														handleChange(
															e,
															"property_rules",
															"general_safety_hygiene_guidelines",
															6
														)
													}
													className="form-check-input"
													name="handsanitizer"
													type="radio"
													value="false"
													id="flexRadioSm"
												/>
												<label
													className="form-check-label"
													htmlFor="flexRadioSm1"
												>
													No
												</label>
											</div>
										</div>
									</div>
								</div>
								<div className="row">
									<div className="col-lg-8">
										<label className="form-label">
											Thermal screening is done at entry and exit points?
										</label>
									</div>
									<div className="col-lg-4">
										<div className="mb-5 d-flex">
											<div className="form-check form-check-custom form-check-solid form-check-sm me-10">
												<input
													checked={
														form["property_rules"][
															"general_safety_hygiene_guidelines"
														][7]?.value === "true"
													}
													onChange={(e) =>
														handleChange(
															e,
															"property_rules",
															"general_safety_hygiene_guidelines",
															7
														)
													}
													className="form-check-input"
													name="thermal"
													type="radio"
													value="true"
													id="flexRadioSm"
												/>
												<label
													className="form-check-label"
													htmlFor="flexRadioSm"
												>
													Yes
												</label>
											</div>

											<div className="form-check form-check-custom form-check-solid form-check-sm me-10">
												<input
													checked={
														form["property_rules"][
															"general_safety_hygiene_guidelines"
														][7]?.value === "false"
													}
													onChange={(e) =>
														handleChange(
															e,
															"property_rules",
															"general_safety_hygiene_guidelines",
															7
														)
													}
													className="form-check-input"
													name="thermal"
													type="radio"
													value="false"
													id="flexRadioSm"
												/>
												<label
													className="form-check-label"
													htmlFor="flexRadioSm1"
												>
													No
												</label>
											</div>
										</div>
									</div>
								</div>
							</fieldset>
						</div>
						<div className="modal-footer">
							<button
								type="button"
								className="btn btn-light"
								data-bs-dismiss="modal"
								onClick={() =>
									restorePolicyStatus("general_safety_hygiene_guidelines")
								}
							>
								Cancel
							</button>
							<fieldset
								disabled={form?.property_status === "Unverified" ? true : false}
							>
								<button
									type="button"
									className="btn btn-primary"
									data-bs-dismiss="modal"
								>
									Confirm
								</button>
							</fieldset>
						</div>
					</div>
				</div>
			</div>

			<div className="modal fade" tabIndex={-4} id="kt_modal_4">
				<div className="modal-dialog">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title">Room Safety and Hygiene</h5>
							<div
								className="btn btn-icon btn-sm btn-active-light-primary ms-2"
								data-bs-dismiss="modal"
								aria-label="Close"
							>
								<KTSVG
									path="/media/icons/duotune/arrows/arr061.svg"
									className="svg-icon svg-icon-2x"
								/>
							</div>
						</div>
						<div className="modal-body">
							<fieldset
								disabled={form?.property_status === "Unverified" ? true : false}
							>
								<div className="row">
									<div className="col-lg-8">
										<label className="form-label">
											Are all rooms disinfected using bleach or other
											disinfectant?
										</label>
									</div>
									<div className="col-lg-4">
										<div className="mb-5 d-flex">
											<div className="form-check form-check-custom form-check-solid form-check-sm me-10">
												<input
													checked={
														form["property_rules"]["room_safety_hygiene"][0]
															?.value == "true"
													}
													onChange={(e) =>
														handleChange(
															e,
															"property_rules",
															"room_safety_hygiene",
															0
														)
													}
													className="form-check-input"
													name="room-disinfected"
													type="radio"
													value="true"
													id="flexRadioSm"
												/>
												<label
													className="form-check-label"
													htmlFor="flexRadioSm"
												>
													Yes
												</label>
											</div>

											<div className="form-check form-check-custom form-check-solid form-check-sm me-10">
												<input
													checked={
														form["property_rules"]["room_safety_hygiene"][0]
															?.value == "false"
													}
													onChange={(e) =>
														handleChange(
															e,
															"property_rules",
															"room_safety_hygiene",
															0
														)
													}
													className="form-check-input"
													name="room-disinfected"
													type="radio"
													value="false"
													id="flexRadioSm"
												/>
												<label
													className="form-check-label"
													htmlFor="flexRadioSm1"
												>
													No
												</label>
											</div>
										</div>
									</div>
								</div>
								<div className="row">
									<div className="col-lg-8">
										<label className="form-label">
											Are linens, towels, and laundry washed as per local
											guidelines?
										</label>
									</div>
									<div className="col-lg-4">
										<div className="mb-5 d-flex">
											<div className="form-check form-check-custom form-check-solid form-check-sm me-10">
												<input
													checked={
														form["property_rules"]["room_safety_hygiene"][1]
															?.value == "true"
													}
													onChange={(e) =>
														handleChange(
															e,
															"property_rules",
															"room_safety_hygiene",
															1
														)
													}
													className="form-check-input"
													name="room-linen-washed"
													type="radio"
													value="true"
													id="flexRadioSm"
												/>
												<label
													className="form-check-label"
													htmlFor="flexRadioSm"
												>
													Yes
												</label>
											</div>

											<div className="form-check form-check-custom form-check-solid form-check-sm me-10">
												<input
													checked={
														form["property_rules"]["room_safety_hygiene"][1]
															?.value == "false"
													}
													onChange={(e) =>
														handleChange(
															e,
															"property_rules",
															"room_safety_hygiene",
															1
														)
													}
													className="form-check-input"
													name="room-linen-washed"
													type="radio"
													value="false"
													id="flexRadioSm"
												/>
												<label
													className="form-check-label"
													htmlFor="flexRadioSm1"
												>
													No
												</label>
											</div>
										</div>
									</div>
								</div>

								<div className="row">
									<div className="col-lg-8">
										<label className="form-label">
											Are the rooms are properly sanitized between stays?
										</label>
									</div>
									<div className="col-lg-4">
										<div className="mb-5 d-flex">
											<div className="form-check form-check-custom form-check-solid form-check-sm me-10">
												<input
													checked={
														form["property_rules"]["room_safety_hygiene"][2]
															?.value == "true"
													}
													onChange={(e) =>
														handleChange(
															e,
															"property_rules",
															"room_safety_hygiene",
															2
														)
													}
													className="form-check-input"
													name="room-sanitized"
													type="radio"
													value="true"
													id="flexRadioSm"
												/>
												<label
													className="form-check-label"
													htmlFor="flexRadioSm"
												>
													Yes
												</label>
											</div>

											<div className="form-check form-check-custom form-check-solid form-check-sm me-10">
												<input
													checked={
														form["property_rules"]["room_safety_hygiene"][2]
															?.value == "false"
													}
													onChange={(e) =>
														handleChange(
															e,
															"property_rules",
															"room_safety_hygiene",
															2
														)
													}
													className="form-check-input"
													name="room-sanitized"
													type="radio"
													value="false"
													id="flexRadioSm"
												/>
												<label
													className="form-check-label"
													htmlFor="flexRadioSm1"
												>
													No
												</label>
											</div>
										</div>
									</div>
								</div>

								<div className="row">
									<div className="col-lg-8">
										<label className="form-label">
											Are hand sanitisers available in rooms?
										</label>
									</div>
									<div className="col-lg-4">
										<div className="mb-5 d-flex">
											<div className="form-check form-check-custom form-check-solid form-check-sm me-10">
												<input
													checked={
														form["property_rules"]["room_safety_hygiene"][3]
															?.value == "true"
													}
													onChange={(e) =>
														handleChange(
															e,
															"property_rules",
															"room_safety_hygiene",
															3
														)
													}
													className="form-check-input"
													name="room-hand-sanitizer"
													type="radio"
													value="true"
													id="flexRadioSm"
												/>
												<label
													className="form-check-label"
													htmlFor="flexRadioSm"
												>
													Yes
												</label>
											</div>

											<div className="form-check form-check-custom form-check-solid form-check-sm me-10">
												<input
													checked={
														form["property_rules"]["room_safety_hygiene"][3]
															?.value == "false"
													}
													onChange={(e) =>
														handleChange(
															e,
															"property_rules",
															"room_safety_hygiene",
															3
														)
													}
													className="form-check-input"
													name="room-hand-sanitizer"
													type="radio"
													value="false"
													id="flexRadioSm"
												/>
												<label
													className="form-check-label"
													htmlFor="flexRadioSm1"
												>
													No
												</label>
											</div>
										</div>
									</div>
								</div>
							</fieldset>
						</div>
						<div className="modal-footer">
							<button
								type="button"
								className="btn btn-light"
								data-bs-dismiss="modal"
								onClick={() => restorePolicyStatus("room_safety_hygiene")}
							>
								Cancel
							</button>
							<fieldset
								disabled={form?.property_status === "Unverified" ? true : false}
							>
								<button
									type="button"
									className="btn btn-primary"
									data-bs-dismiss="modal"
								>
									Confirm
								</button>
							</fieldset>
						</div>
					</div>
				</div>
			</div>

			<div className="modal fade" tabIndex={-5} id="kt_modal_5">
				<div className="modal-dialog">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title">Physical Distancing</h5>
							<div
								className="btn btn-icon btn-sm btn-active-light-primary ms-2"
								data-bs-dismiss="modal"
								aria-label="Close"
							>
								<KTSVG
									path="/media/icons/duotune/arrows/arr061.svg"
									className="svg-icon svg-icon-2x"
								/>
							</div>
						</div>
						<div className="modal-body">
							<fieldset
								disabled={form?.property_status === "Unverified" ? true : false}
							>
								<div className="row">
									<div className="col-lg-8">
										<label className="form-label">
											Social distancing protocols are followed?
										</label>
									</div>
									<div className="col-lg-4">
										<div className="mb-5 d-flex">
											<div className="form-check form-check-custom form-check-solid form-check-sm me-10">
												<input
													checked={
														form["property_rules"]["social_distancing"][0]
															?.value == "true"
													}
													onChange={(e) =>
														handleChange(
															e,
															"property_rules",
															"social_distancing",
															0
														)
													}
													className="form-check-input"
													name="socail-distancing"
													type="radio"
													value="true"
													id="flexRadioSm"
												/>
												<label
													className="form-check-label"
													htmlFor="flexRadioSm"
												>
													Yes
												</label>
											</div>

											<div className="form-check form-check-custom form-check-solid form-check-sm me-10">
												<input
													checked={
														form["property_rules"]["social_distancing"][0]
															?.value == "false"
													}
													onChange={(e) =>
														handleChange(
															e,
															"property_rules",
															"social_distancing",
															0
														)
													}
													className="form-check-input"
													name="social-distancing"
													type="radio"
													value="false"
													id="flexRadioSm"
												/>
												<label
													className="form-check-label"
													htmlFor="flexRadioSm1"
												>
													No
												</label>
											</div>
										</div>
									</div>
								</div>
								<div className="row">
									<div className="col-lg-8">
										<label className="form-label">
											Contactless Check-In and Checkout Service Available?
										</label>
									</div>
									<div className="col-lg-4">
										<div className="mb-5 d-flex">
											<div className="form-check form-check-custom form-check-solid form-check-sm me-10">
												<input
													checked={
														form["property_rules"]["social_distancing"][1]
															?.value == "true"
													}
													onChange={(e) =>
														handleChange(
															e,
															"property_rules",
															"social_distancing",
															1
														)
													}
													className="form-check-input"
													name="checkin-checkout"
													type="radio"
													value="true"
													id="flexRadioSm"
												/>
												<label
													className="form-check-label"
													htmlFor="flexRadioSm"
												>
													Yes
												</label>
											</div>

											<div className="form-check form-check-custom form-check-solid form-check-sm me-10">
												<input
													checked={
														form["property_rules"]["social_distancing"][1]
															?.value == "false"
													}
													onChange={(e) =>
														handleChange(
															e,
															"property_rules",
															"social_distancing",
															1
														)
													}
													className="form-check-input"
													name="checkin-checkout"
													type="radio"
													value="false"
													id="flexRadioSm"
												/>
												<label
													className="form-check-label"
													htmlFor="flexRadioSm1"
												>
													No
												</label>
											</div>
										</div>
									</div>
								</div>

								<div className="row">
									<div className="col-lg-8">
										<label className="form-label">
											Contactless Room Service Available?
										</label>
									</div>
									<div className="col-lg-4">
										<div className="mb-5 d-flex">
											<div className="form-check form-check-custom form-check-solid form-check-sm me-10">
												<input
													checked={
														form["property_rules"]["social_distancing"][2]
															?.value == "true"
													}
													onChange={(e) =>
														handleChange(
															e,
															"property_rules",
															"social_distancing",
															2
														)
													}
													className="form-check-input"
													name="room-contactless"
													type="radio"
													value="true"
													id="flexRadioSm"
												/>
												<label
													className="form-check-label"
													htmlFor="flexRadioSm"
												>
													Yes
												</label>
											</div>

											<div className="form-check form-check-custom form-check-solid form-check-sm me-10">
												<input
													checked={
														form["property_rules"]["social_distancing"][2]
															?.value == "false"
													}
													onChange={(e) =>
														handleChange(
															e,
															"property_rules",
															"social_distancing",
															2
														)
													}
													className="form-check-input"
													name="room-contactless"
													type="radio"
													value="false"
													id="flexRadioSm"
												/>
												<label
													className="form-check-label"
													htmlFor="flexRadioSm1"
												>
													No
												</label>
											</div>
										</div>
									</div>
								</div>

								<div className="row">
									<div className="col-lg-8">
										<label className="form-label">
											Physical Barriers are deployed at appropriate places?
										</label>
									</div>
									<div className="col-lg-4">
										<div className="mb-5 d-flex">
											<div className="form-check form-check-custom form-check-solid form-check-sm me-10">
												<input
													checked={
														form["property_rules"]["social_distancing"][3]
															?.value == "true"
													}
													onChange={(e) =>
														handleChange(
															e,
															"property_rules",
															"social_distancing",
															3
														)
													}
													className="form-check-input"
													name="physical-barrier"
													type="radio"
													value="true"
													id="flexRadioSm"
												/>
												<label
													className="form-check-label"
													htmlFor="flexRadioSm"
												>
													Yes
												</label>
											</div>

											<div className="form-check form-check-custom form-check-solid form-check-sm me-10">
												<input
													checked={
														form["property_rules"]["social_distancing"][3]
															?.value == "false"
													}
													onChange={(e) =>
														handleChange(
															e,
															"property_rules",
															"social_distancing",
															3
														)
													}
													className="form-check-input"
													name="physical-barrier"
													type="radio"
													value="false"
													id="flexRadioSm"
												/>
												<label
													className="form-check-label"
													htmlFor="flexRadioSm1"
												>
													No
												</label>
											</div>
										</div>
									</div>
								</div>

								<div className="row">
									<div className="col-lg-8">
										<label className="form-label">
											Cashless Payment Available?
										</label>
									</div>
									<div className="col-lg-4">
										<div className="mb-5 d-flex">
											<div className="form-check form-check-custom form-check-solid form-check-sm me-10">
												<input
													checked={
														form["property_rules"]["social_distancing"][4]
															?.value == "true"
													}
													onChange={(e) =>
														handleChange(
															e,
															"property_rules",
															"social_distancing",
															4
														)
													}
													className="form-check-input"
													name="cashless-pay"
													type="radio"
													value="true"
													id="flexRadioSm"
												/>
												<label
													className="form-check-label"
													htmlFor="flexRadioSm"
												>
													Yes
												</label>
											</div>

											<div className="form-check form-check-custom form-check-solid form-check-sm me-10">
												<input
													checked={
														form["property_rules"]["social_distancing"][4]
															?.value == "false"
													}
													onChange={(e) =>
														handleChange(
															e,
															"property_rules",
															"social_distancing",
															4
														)
													}
													className="form-check-input"
													name="cashless-pay"
													type="radio"
													value="false"
													id="flexRadioSm"
												/>
												<label
													className="form-check-label"
													htmlFor="flexRadioSm1"
												>
													No
												</label>
											</div>
										</div>
									</div>
								</div>
							</fieldset>
						</div>
						<div className="modal-footer">
							<button
								type="button"
								className="btn btn-light"
								data-bs-dismiss="modal"
								onClick={() => restorePolicyStatus("social_distancing")}
							>
								Cancel
							</button>
							<fieldset
								disabled={form?.property_status === "Unverified" ? true : false}
							>
								<button
									type="button"
									className="btn btn-primary"
									data-bs-dismiss="modal"
								>
									Confirm
								</button>
							</fieldset>
						</div>
					</div>
				</div>
			</div>

			<div className="modal fade" tabIndex={-6} id="kt_modal_6">
				<div className="modal-dialog">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title">Food and Drinks Hygiene</h5>
							<div
								className="btn btn-icon btn-sm btn-active-light-primary ms-2"
								data-bs-dismiss="modal"
								aria-label="Close"
							>
								<KTSVG
									path="/media/icons/duotune/arrows/arr061.svg"
									className="svg-icon svg-icon-2x"
								/>
							</div>
						</div>
						<div className="modal-body">
							<fieldset
								disabled={form?.property_status === "Unverified" ? true : false}
							>
								<div className="row">
									<div className="col-lg-8">
										<label className="form-label">
											COVID-19 guidelines for Food Hygiene is followed as per
											government guidelines?
										</label>
									</div>
									<div className="col-lg-4">
										<div className="mb-5 d-flex">
											<div className="form-check form-check-custom form-check-solid form-check-sm me-10">
												<input
													checked={
														form["property_rules"]["food_drinks_hygiene"][0]
															?.value == "true"
													}
													onChange={(e) =>
														handleChange(
															e,
															"property_rules",
															"food_drinks_hygiene",
															0
														)
													}
													className="form-check-input"
													name="covid-guidelines"
													type="radio"
													value="true"
													id="flexRadioSm"
												/>
												<label
													className="form-check-label"
													htmlFor="flexRadioSm"
												>
													Yes
												</label>
											</div>

											<div className="form-check form-check-custom form-check-solid form-check-sm me-10">
												<input
													checked={
														form["property_rules"]["food_drinks_hygiene"][0]
															?.value == "false"
													}
													onChange={(e) =>
														handleChange(
															e,
															"property_rules",
															"food_drinks_hygiene",
															0
														)
													}
													className="form-check-input"
													name="covid-guidelines"
													type="radio"
													value="false"
													id="flexRadioSm"
												/>
												<label
													className="form-check-label"
													htmlFor="flexRadioSm1"
												>
													No
												</label>
											</div>
										</div>
									</div>
								</div>
								<div className="row">
									<div className="col-lg-8">
										<label className="form-label">
											Social distancing is followed in restaurants?
										</label>
									</div>
									<div className="col-lg-4">
										<div className="mb-5 d-flex">
											<div className="form-check form-check-custom form-check-solid form-check-sm me-10">
												<input
													checked={
														form["property_rules"]["food_drinks_hygiene"][1]
															?.value == "true"
													}
													onChange={(e) =>
														handleChange(
															e,
															"property_rules",
															"food_drinks_hygiene",
															1
														)
													}
													className="form-check-input"
													name="rest-social-distancing"
													type="radio"
													value="true"
													id="flexRadioSm"
												/>
												<label
													className="form-check-label"
													htmlFor="flexRadioSm"
												>
													Yes
												</label>
											</div>

											<div className="form-check form-check-custom form-check-solid form-check-sm me-10">
												<input
													checked={
														form["property_rules"]["food_drinks_hygiene"][1]
															?.value == "false"
													}
													onChange={(e) =>
														handleChange(
															e,
															"property_rules",
															"food_drinks_hygiene",
															1
														)
													}
													className="form-check-input"
													name="rest-social-distancing"
													type="radio"
													value="false"
													id="flexRadioSm"
												/>
												<label
													className="form-check-label"
													htmlFor="flexRadioSm1"
												>
													No
												</label>
											</div>
										</div>
									</div>
								</div>

								<div className="row">
									<div className="col-lg-8">
										<label className="form-label">
											Serveware and supplies are sanitized before they are
											brought to the kitchen ?
										</label>
									</div>
									<div className="col-lg-4">
										<div className="mb-5 d-flex">
											<div className="form-check form-check-custom form-check-solid form-check-sm me-10">
												<input
													checked={
														form["property_rules"]["food_drinks_hygiene"][2]
															?.value == "true"
													}
													onChange={(e) =>
														handleChange(
															e,
															"property_rules",
															"food_drinks_hygiene",
															2
														)
													}
													className="form-check-input"
													name="kitchen-sanitized"
													type="radio"
													value="true"
													id="flexRadioSm"
												/>
												<label
													className="form-check-label"
													htmlFor="flexRadioSm"
												>
													Yes
												</label>
											</div>

											<div className="form-check form-check-custom form-check-solid form-check-sm me-10">
												<input
													checked={
														form["property_rules"]["food_drinks_hygiene"][2]
															?.value == "false"
													}
													onChange={(e) =>
														handleChange(
															e,
															"property_rules",
															"food_drinks_hygiene",
															2
														)
													}
													className="form-check-input"
													name="kitchen-sanitized"
													type="radio"
													value="false"
													id="flexRadioSm"
												/>
												<label
													className="form-check-label"
													htmlFor="flexRadioSm1"
												>
													No
												</label>
											</div>
										</div>
									</div>
								</div>

								<div className="row">
									<div className="col-lg-8">
										<label className="form-label">
											Masks and hairnets are mandatory for staff in restaurants?
										</label>
									</div>
									<div className="col-lg-4">
										<div className="mb-5 d-flex">
											<div className="form-check form-check-custom form-check-solid form-check-sm me-10">
												<input
													checked={
														form["property_rules"]["food_drinks_hygiene"][3]
															?.value == "true"
													}
													onChange={(e) =>
														handleChange(
															e,
															"property_rules",
															"food_drinks_hygiene",
															3
														)
													}
													className="form-check-input"
													name="staff-masks"
													type="radio"
													value="true"
													id="flexRadioSm"
												/>
												<label
													className="form-check-label"
													htmlFor="flexRadioSm"
												>
													Yes
												</label>
											</div>

											<div className="form-check form-check-custom form-check-solid form-check-sm me-10">
												<input
													checked={
														form["property_rules"]["food_drinks_hygiene"][3]
															?.value == "false"
													}
													onChange={(e) =>
														handleChange(
															e,
															"property_rules",
															"food_drinks_hygiene",
															3
														)
													}
													className="form-check-input"
													name="staff-masks"
													type="radio"
													value="false"
													id="flexRadioSm"
												/>
												<label
													className="form-check-label"
													htmlFor="flexRadioSm1"
												>
													No
												</label>
											</div>
										</div>
									</div>
								</div>
							</fieldset>
						</div>
						<div className="modal-footer">
							<button
								type="button"
								className="btn btn-light"
								data-bs-dismiss="modal"
								onClick={() => restorePolicyStatus("food_drinks_hygiene")}
							>
								Cancel
							</button>
							<fieldset
								disabled={form?.property_status === "Unverified" ? true : false}
							>
								<button
									type="button"
									className="btn btn-primary"
									data-bs-dismiss="modal"
								>
									Confirm
								</button>
							</fieldset>
						</div>
					</div>
				</div>
			</div>

			<div className="modal fade" tabIndex={-7} id="kt_modal_7">
				<div className="modal-dialog">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title">Property Restrictions</h5>
							<div
								className="btn btn-icon btn-sm btn-active-light-primary ms-2"
								data-bs-dismiss="modal"
								aria-label="Close"
							>
								<KTSVG
									path="/media/icons/duotune/arrows/arr061.svg"
									className="svg-icon svg-icon-2x"
								/>
							</div>
						</div>
						<div className="modal-body">
							<fieldset
								disabled={form?.property_status === "Unverified" ? true : false}
							>
								<div className="row">
									<div className="col-lg-8">
										<label className="form-label">
											Is Non-Veg Food allowed?
										</label>
									</div>
									<div className="col-lg-4">
										<div className="mb-5 d-flex">
											<div className="form-check form-check-custom form-check-solid form-check-sm me-10">
												<input
													checked={
														form["property_rules"]["property_restrictions"][0]
															?.value == "true"
													}
													onChange={(e) =>
														handleChange(
															e,
															"property_rules",
															"property_restrictions",
															0
														)
													}
													className="form-check-input"
													name="nonveg-allowed"
													type="radio"
													value="true"
													id="flexRadioSm"
												/>
												<label
													className="form-check-label"
													htmlFor="flexRadioSm"
												>
													Yes
												</label>
											</div>

											<div className="form-check form-check-custom form-check-solid form-check-sm me-10">
												<input
													checked={
														form["property_rules"]["property_restrictions"][0]
															?.value == "false"
													}
													onChange={(e) =>
														handleChange(
															e,
															"property_rules",
															"property_restrictions",
															0
														)
													}
													className="form-check-input"
													name="nonveg-allowed"
													type="radio"
													value="false"
													id="flexRadioSm"
												/>
												<label
													className="form-check-label"
													htmlFor="flexRadioSm1"
												>
													No
												</label>
											</div>
										</div>
									</div>
								</div>
								<div className="row">
									<div className="col-lg-8">
										<label className="form-label">
											Is outside food allowed?
										</label>
									</div>
									<div className="col-lg-4">
										<div className="mb-5 d-flex">
											<div className="form-check form-check-custom form-check-solid form-check-sm me-10">
												<input
													checked={
														form["property_rules"]["property_restrictions"][1]
															?.value == "true"
													}
													onChange={(e) =>
														handleChange(
															e,
															"property_rules",
															"property_restrictions",
															1
														)
													}
													className="form-check-input"
													name="outside-food"
													type="radio"
													value="true"
													id="flexRadioSm"
												/>
												<label
													className="form-check-label"
													htmlFor="flexRadioSm"
												>
													Yes
												</label>
											</div>

											<div className="form-check form-check-custom form-check-solid form-check-sm me-10">
												<input
													checked={
														form["property_rules"]["property_restrictions"][1]
															?.value == "false"
													}
													onChange={(e) =>
														handleChange(
															e,
															"property_rules",
															"property_restrictions",
															1
														)
													}
													className="form-check-input"
													name="outside-food"
													type="radio"
													value="false"
													id="flexRadioSm"
												/>
												<label
													className="form-check-label"
													htmlFor="flexRadioSm1"
												>
													No
												</label>
											</div>
										</div>
									</div>
								</div>

								<div className="row">
									<div className="col-lg-8">
										<label className="form-label">
											Is smoking allowed within the premises?
										</label>
									</div>
									<div className="col-lg-4">
										<div className="mb-5 d-flex">
											<div className="form-check form-check-custom form-check-solid form-check-sm me-10">
												<input
													checked={
														form["property_rules"]["property_restrictions"][2]
															?.value == "true"
													}
													onChange={(e) =>
														handleChange(
															e,
															"property_rules",
															"property_restrictions",
															2
														)
													}
													className="form-check-input"
													name="smoking-allowed"
													type="radio"
													value="true"
													id="flexRadioSm"
												/>
												<label
													className="form-check-label"
													htmlFor="flexRadioSm"
												>
													Yes
												</label>
											</div>

											<div className="form-check form-check-custom form-check-solid form-check-sm me-10">
												<input
													checked={
														form["property_rules"]["property_restrictions"][2]
															?.value == "false"
													}
													onChange={(e) =>
														handleChange(
															e,
															"property_rules",
															"property_restrictions",
															2
														)
													}
													className="form-check-input"
													name="smoking-allowed"
													type="radio"
													value="false"
													id="flexRadioSm"
												/>
												<label
													className="form-check-label"
													htmlFor="flexRadioSm1"
												>
													No
												</label>
											</div>
										</div>
									</div>
								</div>

								<div className="row">
									<div className="col-lg-8">
										<label className="form-label">
											Is alcohol consumption allowed at the property?
										</label>
									</div>
									<div className="col-lg-4">
										<div className="mb-5 d-flex">
											<div className="form-check form-check-custom form-check-solid form-check-sm me-10">
												<input
													checked={
														form["property_rules"]["property_restrictions"][3]
															?.value == "true"
													}
													onChange={(e) =>
														handleChange(
															e,
															"property_rules",
															"property_restrictions",
															3
														)
													}
													className="form-check-input"
													name="alcohol-allowed"
													type="radio"
													value="true"
													id="flexRadioSm"
												/>
												<label
													className="form-check-label"
													htmlFor="flexRadioSm"
												>
													Yes
												</label>
											</div>

											<div className="form-check form-check-custom form-check-solid form-check-sm me-10">
												<input
													checked={
														form["property_rules"]["property_restrictions"][3]
															?.value == "false"
													}
													onChange={(e) =>
														handleChange(
															e,
															"property_rules",
															"property_restrictions",
															3
														)
													}
													className="form-check-input"
													name="alcohol-allowed"
													type="radio"
													value="false"
													id="flexRadioSm"
												/>
												<label
													className="form-check-label"
													htmlFor="flexRadioSm1"
												>
													No
												</label>
											</div>
										</div>
									</div>
								</div>

								<div className="row">
									<div className="col-lg-8">
										<label className="form-label">
											Are Private parties or events allowed at the property?
										</label>
									</div>
									<div className="col-lg-4">
										<div className="mb-5 d-flex">
											<div className="form-check form-check-custom form-check-solid form-check-sm me-10">
												<input
													checked={
														form["property_rules"]["property_restrictions"][4]
															?.value == "true"
													}
													onChange={(e) =>
														handleChange(
															e,
															"property_rules",
															"property_restrictions",
															4
														)
													}
													className="form-check-input"
													name="party-allowed"
													type="radio"
													value="true"
													id="flexRadioSm"
												/>
												<label
													className="form-check-label"
													htmlFor="flexRadioSm"
												>
													Yes
												</label>
											</div>

											<div className="form-check form-check-custom form-check-solid form-check-sm me-10">
												<input
													checked={
														form["property_rules"]["property_restrictions"][4]
															?.value == "false"
													}
													onChange={(e) =>
														handleChange(
															e,
															"property_rules",
															"property_restrictions",
															4
														)
													}
													className="form-check-input"
													name="party-allowed"
													type="radio"
													value="false"
													id="flexRadioSm"
												/>
												<label
													className="form-check-label"
													htmlFor="flexRadioSm1"
												>
													No
												</label>
											</div>
										</div>
									</div>
								</div>

								<div className="row">
									<div className="col-lg-8">
										<label className="form-label">
											Are Credit/Debit cards accepted?
										</label>
									</div>
									<div className="col-lg-4">
										<div className="mb-5 d-flex">
											<div className="form-check form-check-custom form-check-solid form-check-sm me-10">
												<input
													checked={
														form["property_rules"]["property_restrictions"][5]
															?.value == "true"
													}
													onChange={(e) =>
														handleChange(
															e,
															"property_rules",
															"property_restrictions",
															5
														)
													}
													className="form-check-input"
													name="card-accepted"
													type="radio"
													value="true"
													id="flexRadioSm"
												/>
												<label
													className="form-check-label"
													htmlFor="flexRadioSm"
												>
													Yes
												</label>
											</div>

											<div className="form-check form-check-custom form-check-solid form-check-sm me-10">
												<input
													checked={
														form["property_rules"]["property_restrictions"][5]
															?.value == "false"
													}
													onChange={(e) =>
														handleChange(
															e,
															"property_rules",
															"property_restrictions",
															5
														)
													}
													className="form-check-input"
													name="card-accepted"
													type="radio"
													value="false"
													id="flexRadioSm"
												/>
												<label
													className="form-check-label"
													htmlFor="flexRadioSm1"
												>
													No
												</label>
											</div>
										</div>
									</div>
								</div>

								<div className="row">
									<div className="col-lg-8">
										<label className="form-label">Are Visitors Allowed?</label>
									</div>
									<div className="col-lg-4">
										<div className="mb-5 d-flex">
											<div className="form-check form-check-custom form-check-solid form-check-sm me-10">
												<input
													checked={
														form["property_rules"]["property_restrictions"][6]
															?.value == "true"
													}
													onChange={(e) =>
														handleChange(
															e,
															"property_rules",
															"property_restrictions",
															6
														)
													}
													className="form-check-input"
													name="visitors-allowed"
													type="radio"
													value="true"
													id="flexRadioSm"
												/>
												<label
													className="form-check-label"
													htmlFor="flexRadioSm"
												>
													Yes
												</label>
											</div>

											<div className="form-check form-check-custom form-check-solid form-check-sm me-10">
												<input
													checked={
														form["property_rules"]["property_restrictions"][6]
															?.value == "false"
													}
													onChange={(e) =>
														handleChange(
															e,
															"property_rules",
															"property_restrictions",
															6
														)
													}
													className="form-check-input"
													name="visitors-allowed"
													type="radio"
													value="false"
													id="flexRadioSm"
												/>
												<label
													className="form-check-label"
													htmlFor="flexRadioSm1"
												>
													No
												</label>
											</div>
										</div>
									</div>
								</div>
							</fieldset>
						</div>
						<div className="modal-footer">
							<button
								type="button"
								className="btn btn-light"
								data-bs-dismiss="modal"
								onClick={() => restorePolicyStatus("property_restrictions")}
							>
								Cancel
							</button>
							<fieldset
								disabled={form?.property_status === "Unverified" ? true : false}
							>
								<button
									type="button"
									className="btn btn-primary"
									data-bs-dismiss="modal"
								>
									Confirm
								</button>
							</fieldset>
						</div>
					</div>
				</div>
			</div>

			<div className="modal fade" tabIndex={-8} id="kt_modal_8">
				<div className="modal-dialog">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title">Pet Policy</h5>
							<div
								className="btn btn-icon btn-sm btn-active-light-primary ms-2"
								data-bs-dismiss="modal"
								aria-label="Close"
							>
								<KTSVG
									path="/media/icons/duotune/arrows/arr061.svg"
									className="svg-icon svg-icon-2x"
								/>
							</div>
						</div>
						<div className="modal-body">
							<fieldset
								disabled={form?.property_status === "Unverified" ? true : false}
							>
								<div className="row">
									<div className="col-lg-8">
										<label className="form-label">Are Pets Allowed?</label>
									</div>
									<div className="col-lg-4">
										<div className="mb-5 d-flex">
											<div className="form-check form-check-custom form-check-solid form-check-sm me-10">
												<input
													checked={
														form["property_rules"]["pet_policy"][0]?.value ==
														"true"
													}
													onChange={(e) =>
														handleChange(e, "property_rules", "pet_policy", 0)
													}
													className="form-check-input"
													name="pet-allowed"
													type="radio"
													value="true"
													id="flexRadioSm"
												/>
												<label
													className="form-check-label"
													htmlFor="flexRadioSm"
												>
													Yes
												</label>
											</div>

											<div className="form-check form-check-custom form-check-solid form-check-sm me-10">
												<input
													checked={
														form["property_rules"]["pet_policy"][0]?.value ==
														"false"
													}
													onChange={(e) =>
														handleChange(e, "property_rules", "pet_policy", 0)
													}
													className="form-check-input"
													name="pet-allowed"
													type="radio"
													value="false"
													id="flexRadioSm"
												/>
												<label
													className="form-check-label"
													htmlFor="flexRadioSm1"
												>
													No
												</label>
											</div>
										</div>
									</div>
								</div>
								<div className="row">
									<div className="col-lg-8">
										<label className="form-label">
											Any Pet(s) living on the property?
										</label>
									</div>
									<div className="col-lg-4">
										<div className="mb-5 d-flex">
											<div className="form-check form-check-custom form-check-solid form-check-sm me-10">
												<input
													checked={
														form["property_rules"]["pet_policy"][1]?.value ==
														"true"
													}
													onChange={(e) =>
														handleChange(e, "property_rules", "pet_policy", 1)
													}
													className="form-check-input"
													name="pet-living"
													type="radio"
													value="true"
													id="flexRadioSm"
												/>
												<label
													className="form-check-label"
													htmlFor="flexRadioSm"
												>
													Yes
												</label>
											</div>

											<div className="form-check form-check-custom form-check-solid form-check-sm me-10">
												<input
													checked={
														form["property_rules"]["pet_policy"][1]?.value ==
														"false"
													}
													onChange={(e) =>
														handleChange(e, "property_rules", "pet_policy", 1)
													}
													className="form-check-input"
													name="pet-living"
													type="radio"
													value="false"
													id="flexRadioSm"
												/>
												<label
													className="form-check-label"
													htmlFor="flexRadioSm1"
												>
													No
												</label>
											</div>
										</div>
									</div>
								</div>
							</fieldset>
						</div>
						<div className="modal-footer">
							<button
								type="button"
								className="btn btn-light"
								data-bs-dismiss="modal"
								onClick={() => restorePolicyStatus("pet_policy")}
							>
								Cancel
							</button>
							<fieldset
								disabled={form?.property_status === "Unverified" ? true : false}
							>
								<button
									type="button"
									className="btn btn-primary"
									data-bs-dismiss="modal"
								>
									Confirm
								</button>
							</fieldset>
						</div>
					</div>
				</div>
			</div>

			<div className="modal fade" tabIndex={-9} id="kt_modal_9">
				<div className="modal-dialog">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title">Suitability for Guests</h5>
							<div
								className="btn btn-icon btn-sm btn-active-light-primary ms-2"
								data-bs-dismiss="modal"
								aria-label="Close"
							>
								<KTSVG
									path="/media/icons/duotune/arrows/arr061.svg"
									className="svg-icon svg-icon-2x"
								/>
							</div>
						</div>
						<div className="modal-body">
							<fieldset
								disabled={form?.property_status === "Unverified" ? true : false}
							>
								<div className="row">
									<div className="col-lg-8">
										<label className="form-label">
											Is your property Elderly-friendly/Disabled-friendly?
										</label>
									</div>
									<div className="col-lg-4">
										<div className="mb-5 d-flex">
											<div className="form-check form-check-custom form-check-solid form-check-sm me-10">
												<input
													checked={
														form["property_rules"]["guest_suitabilty"][0]
															?.value == "true"
													}
													onChange={(e) =>
														handleChange(
															e,
															"property_rules",
															"guest_suitabilty",
															0
														)
													}
													className="form-check-input"
													name="elder-friendly"
													type="radio"
													value="true"
													id="flexRadioSm"
												/>
												<label
													className="form-check-label"
													htmlFor="flexRadioSm"
												>
													Yes
												</label>
											</div>

											<div className="form-check form-check-custom form-check-solid form-check-sm me-10">
												<input
													checked={
														form["property_rules"]["guest_suitabilty"][0]
															?.value == "false"
													}
													onChange={(e) =>
														handleChange(
															e,
															"property_rules",
															"guest_suitabilty",
															0
														)
													}
													className="form-check-input"
													name="elder-friendly"
													type="radio"
													value="false"
													id="flexRadioSm"
												/>
												<label
													className="form-check-label"
													htmlFor="flexRadioSm1"
												>
													No
												</label>
											</div>
										</div>
									</div>
								</div>
								<div className="row">
									<div className="col-lg-8">
										<label className="form-label">Accessible bed height?</label>
									</div>
									<div className="col-lg-4">
										<div className="mb-5 d-flex">
											<div className="form-check form-check-custom form-check-solid form-check-sm me-10">
												<input
													checked={
														form["property_rules"]["guest_suitabilty"][1]
															?.value == "true"
													}
													onChange={(e) =>
														handleChange(
															e,
															"property_rules",
															"guest_suitabilty",
															1
														)
													}
													className="form-check-input"
													name="bed-height"
													type="radio"
													value="true"
													id="flexRadioSm"
												/>
												<label
													className="form-check-label"
													htmlFor="flexRadioSm"
												>
													Yes
												</label>
											</div>

											<div className="form-check form-check-custom form-check-solid form-check-sm me-10">
												<input
													checked={
														form["property_rules"]["guest_suitabilty"][1]
															?.value == "false"
													}
													onChange={(e) =>
														handleChange(
															e,
															"property_rules",
															"guest_suitabilty",
															1
														)
													}
													className="form-check-input"
													name="bed-height"
													type="radio"
													value="false"
													id="flexRadioSm"
												/>
												<label
													className="form-check-label"
													htmlFor="flexRadioSm1"
												>
													No
												</label>
											</div>
										</div>
									</div>
								</div>
								<div className="row">
									<div className="col-lg-8">
										<label className="form-label">
											Is the entire unit wheelchair accessible?
										</label>
									</div>
									<div className="col-lg-4">
										<div className="mb-5 d-flex">
											<div className="form-check form-check-custom form-check-solid form-check-sm me-10">
												<input
													checked={
														form["property_rules"]["guest_suitabilty"][2]
															?.value == "true"
													}
													onChange={(e) =>
														handleChange(
															e,
															"property_rules",
															"guest_suitabilty",
															2
														)
													}
													className="form-check-input"
													name="wheelchair"
													type="radio"
													value="true"
													id="flexRadioSm"
												/>
												<label
													className="form-check-label"
													htmlFor="flexRadioSm"
												>
													Yes
												</label>
											</div>

											<div className="form-check form-check-custom form-check-solid form-check-sm me-10">
												<input
													checked={
														form["property_rules"]["guest_suitabilty"][2]
															?.value == "false"
													}
													onChange={(e) =>
														handleChange(
															e,
															"property_rules",
															"guest_suitabilty",
															2
														)
													}
													className="form-check-input"
													name="wheelchair"
													type="radio"
													value="false"
													id="flexRadioSm"
												/>
												<label
													className="form-check-label"
													htmlFor="flexRadioSm1"
												>
													No
												</label>
											</div>
										</div>
									</div>
								</div>
								<div className="row">
									<div className="col-lg-8">
										<label className="form-label">
											Does the property have a wide entryway?
										</label>
									</div>
									<div className="col-lg-4">
										<div className="mb-5 d-flex">
											<div className="form-check form-check-custom form-check-solid form-check-sm me-10">
												<input
													checked={
														form["property_rules"]["guest_suitabilty"][3]
															?.value == "true"
													}
													onChange={(e) =>
														handleChange(
															e,
															"property_rules",
															"guest_suitabilty",
															3
														)
													}
													className="form-check-input"
													name="wide-entry"
													type="radio"
													value="true"
													id="flexRadioSm"
												/>
												<label
													className="form-check-label"
													htmlFor="flexRadioSm"
												>
													Yes
												</label>
											</div>

											<div className="form-check form-check-custom form-check-solid form-check-sm me-10">
												<input
													checked={
														form["property_rules"]["guest_suitabilty"][3]
															?.value == "false"
													}
													onChange={(e) =>
														handleChange(
															e,
															"property_rules",
															"guest_suitabilty",
															3
														)
													}
													className="form-check-input"
													name="wide-entry"
													type="radio"
													value="false"
													id="flexRadioSm"
												/>
												<label
													className="form-check-label"
													htmlFor="flexRadioSm1"
												>
													No
												</label>
											</div>
										</div>
									</div>
								</div>
							</fieldset>
						</div>
						<div className="modal-footer">
							<button
								type="button"
								className="btn btn-light"
								data-bs-dismiss="modal"
								onClick={() => restorePolicyStatus("guest_suitabilty")}
							>
								Cancel
							</button>
							<fieldset
								disabled={form?.property_status === "Unverified" ? true : false}
							>
								<button
									type="button"
									className="btn btn-primary"
									data-bs-dismiss="modal"
								>
									Confirm
								</button>
							</fieldset>
						</div>
					</div>
				</div>
			</div>

			<div className="modal fade" tabIndex={-10} id="kt_modal_10">
				<div className="modal-dialog">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title">Checkin and Checkout Policies</h5>
							<div
								className="btn btn-icon btn-sm btn-active-light-primary ms-2"
								data-bs-dismiss="modal"
								aria-label="Close"
							>
								<KTSVG
									path="/media/icons/duotune/arrows/arr061.svg"
									className="svg-icon svg-icon-2x"
								/>
							</div>
						</div>
						<div className="modal-body">
							<fieldset
								disabled={form?.property_status === "Unverified" ? true : false}
							>
								<div className="row">
									<div className="col-lg-8">
										<label className="form-label">
											Do you have a 24-hour check-in?
										</label>
									</div>
									<div className="col-lg-4">
										<div className="mb-5 d-flex">
											<div className="form-check form-check-custom form-check-solid form-check-sm me-10">
												<input
													checked={
														form["property_rules"]["checkin_checkout_policy"][0]
															?.value == "true"
													}
													onChange={(e) =>
														handleChange(
															e,
															"property_rules",
															"checkin_checkout_policy",
															0
														)
													}
													className="form-check-input"
													name="checkin-24"
													type="radio"
													value="true"
													id="flexRadioSm"
												/>
												<label
													className="form-check-label"
													htmlFor="flexRadioSm"
												>
													Yes
												</label>
											</div>

											<div className="form-check form-check-custom form-check-solid form-check-sm me-10">
												<input
													checked={
														form["property_rules"]["checkin_checkout_policy"][0]
															?.value == "false"
													}
													onChange={(e) =>
														handleChange(
															e,
															"property_rules",
															"checkin_checkout_policy",
															0
														)
													}
													className="form-check-input"
													name="checkin-24"
													type="radio"
													value="false"
													id="flexRadioSm"
												/>
												<label
													className="form-check-label"
													htmlFor="flexRadioSm1"
												>
													No
												</label>
											</div>
										</div>
									</div>
								</div>
								<div className="row">
									<div className="col-lg-8">
										<label className="form-label">
											Do you have a 24-hour checkout?
										</label>
									</div>
									<div className="col-lg-4">
										<div className="mb-5 d-flex">
											<div className="form-check form-check-custom form-check-solid form-check-sm me-10">
												<input
													checked={
														form["property_rules"]["checkin_checkout_policy"][1]
															?.value == "true"
													}
													onChange={(e) =>
														handleChange(
															e,
															"property_rules",
															"checkin_checkout_policy",
															1
														)
													}
													className="form-check-input"
													name="checkout-24"
													type="radio"
													value="true"
													id="flexRadioSm"
												/>
												<label
													className="form-check-label"
													htmlFor="flexRadioSm"
												>
													Yes
												</label>
											</div>

											<div className="form-check form-check-custom form-check-solid form-check-sm me-10">
												<input
													checked={
														form["property_rules"]["checkin_checkout_policy"][1]
															?.value == "false"
													}
													onChange={(e) =>
														handleChange(
															e,
															"property_rules",
															"checkin_checkout_policy",
															1
														)
													}
													className="form-check-input"
													name="checkout-24"
													type="radio"
													value="false"
													id="flexRadioSm"
												/>
												<label
													className="form-check-label"
													htmlFor="flexRadioSm1"
												>
													No
												</label>
											</div>
										</div>
									</div>
								</div>
							</fieldset>
						</div>
						<div className="modal-footer">
							<button
								type="button"
								className="btn btn-light"
								data-bs-dismiss="modal"
								onClick={() => restorePolicyStatus("checkin_checkout_policy")}
							>
								Cancel
							</button>
							<fieldset
								disabled={form?.property_status === "Unverified" ? true : false}
							>
								<button
									type="button"
									className="btn btn-primary"
									data-bs-dismiss="modal"
								>
									Confirm
								</button>
							</fieldset>
						</div>
					</div>
				</div>
			</div>

			<div className="modal fade" tabIndex={-11} id="kt_modal_11">
				<div className="modal-dialog">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title">Extra Bed Policies</h5>
							<div
								className="btn btn-icon btn-sm btn-active-light-primary ms-2"
								data-bs-dismiss="modal"
								aria-label="Close"
							>
								<KTSVG
									path="/media/icons/duotune/arrows/arr061.svg"
									className="svg-icon svg-icon-2x"
								/>
							</div>
						</div>
						<div className="modal-body">
							<fieldset
								disabled={form?.property_status === "Unverified" ? true : false}
							>
								<div className="row">
									<div className="col-lg-4">
										<label className="form-label">
											Do you provide bed to extra adults?
										</label>
									</div>
									<div className="col-lg-8">
										<div className="mb-5 d-flex">
											<div className="form-check form-check-custom form-check-solid form-check-sm me-10">
												<input
													checked={
														form["property_rules"]["extra_bed_policy"][0]
															?.value == "true"
													}
													onChange={(e) =>
														handleChange(
															e,
															"property_rules",
															"extra_bed_policy",
															0
														)
													}
													className="form-check-input"
													name="extra-bed"
													type="radio"
													value="true"
													id="flexRadioSm"
												/>
												<label
													className="form-check-label"
													htmlFor="flexRadioSm"
												>
													Yes
												</label>
											</div>

											<div className="form-check form-check-custom form-check-solid form-check-sm me-10">
												<input
													checked={
														form["property_rules"]["extra_bed_policy"][0]
															?.value == "false"
													}
													onChange={(e) =>
														handleChange(
															e,
															"property_rules",
															"extra_bed_policy",
															0
														)
													}
													className="form-check-input"
													name="extra-bed"
													type="radio"
													value="false"
													id="flexRadioSm"
												/>
												<label
													className="form-check-label"
													htmlFor="flexRadioSm1"
												>
													No
												</label>
											</div>

											<div className="form-check form-check-custom form-check-solid form-check-sm me-10">
												<input
													checked={
														form["property_rules"]["extra_bed_policy"][0]
															?.value == "subject_of_availability"
													}
													onChange={(e) =>
														handleChange(
															e,
															"property_rules",
															"extra_bed_policy",
															0
														)
													}
													className="form-check-input"
													name="extra-bed"
													type="radio"
													value="subject_of_availability"
													id="flexRadioSm"
												/>
												<label
													className="form-check-label"
													htmlFor="flexRadioSm1"
												>
													Subject to availability
												</label>
											</div>
										</div>
									</div>
								</div>
								<div className="row">
									<div className="col-lg-4">
										<label className="form-label">
											Do you provide bed to extra kids?
										</label>
									</div>
									<div className="col-lg-8">
										<div className="mb-5 d-flex">
											<div className="form-check form-check-custom form-check-solid form-check-sm me-10">
												<input
													checked={
														form["property_rules"]["extra_bed_policy"][1]
															?.value == "true"
													}
													onChange={(e) =>
														handleChange(
															e,
															"property_rules",
															"extra_bed_policy",
															1
														)
													}
													className="form-check-input"
													name="extra-bed-kid"
													type="radio"
													value="true"
													id="flexRadioSm"
												/>
												<label
													className="form-check-label"
													htmlFor="flexRadioSm"
												>
													Yes
												</label>
											</div>

											<div className="form-check form-check-custom form-check-solid form-check-sm me-10">
												<input
													checked={
														form["property_rules"]["extra_bed_policy"][1]
															?.value == "false"
													}
													onChange={(e) =>
														handleChange(
															e,
															"property_rules",
															"extra_bed_policy",
															1
														)
													}
													className="form-check-input"
													name="extra-bed-kid"
													type="radio"
													value="false"
													id="flexRadioSm"
												/>
												<label
													className="form-check-label"
													htmlFor="flexRadioSm1"
												>
													No
												</label>
											</div>
											<div className="form-check form-check-custom form-check-solid form-check-sm me-10">
												<input
													checked={
														form["property_rules"]["extra_bed_policy"][1]
															?.value == "subject_of_availability"
													}
													onChange={(e) =>
														handleChange(
															e,
															"property_rules",
															"extra_bed_policy",
															1
														)
													}
													className="form-check-input"
													name="extra-bed-kid"
													type="radio"
													value="subject_of_availability"
													id="flexRadioSm"
												/>
												<label
													className="form-check-label"
													htmlFor="flexRadioSm1"
												>
													Subject to availability
												</label>
											</div>
										</div>
									</div>
								</div>
							</fieldset>
						</div>
						<div className="modal-footer">
							<button
								type="button"
								className="btn btn-light"
								data-bs-dismiss="modal"
								onClick={() => restorePolicyStatus("extra_bed_policy")}
							>
								Cancel
							</button>
							<fieldset
								disabled={form?.property_status === "Unverified" ? true : false}
							>
								<button
									type="button"
									className="btn btn-primary"
									data-bs-dismiss="modal"
								>
									Confirm
								</button>
							</fieldset>
						</div>
					</div>
				</div>
			</div>

			<div className="modal fade" tabIndex={-12} id="kt_modal_12">
				<div className="modal-dialog">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title">Custom Policy</h5>
							<div
								className="btn btn-icon btn-sm btn-active-light-primary ms-2"
								data-bs-dismiss="modal"
								aria-label="Close"
							>
								<KTSVG
									path="/media/icons/duotune/arrows/arr061.svg"
									className="svg-icon svg-icon-2x"
								/>
							</div>
						</div>
						<div className="modal-body">
							<fieldset
								disabled={form?.property_status === "Unverified" ? true : false}
							>
								<div className="row">
									<div className="col-lg-12">
										<textarea name="" id="" placeholder="Type here"></textarea>
									</div>
								</div>
							</fieldset>
						</div>
						<div className="modal-footer">
							<button
								type="button"
								className="btn btn-light"
								data-bs-dismiss="modal"
								//onClick={() => restorePolicyStatus('custom_policy')}
							>
								Cancel
							</button>
							<fieldset
								disabled={form?.property_status === "Unverified" ? true : false}
							>
								<button
									type="button"
									className="btn btn-primary"
									data-bs-dismiss="modal"
								>
									Confirm
								</button>
							</fieldset>
						</div>
					</div>
				</div>
			</div>

			<div className="d-flex flex-stack">
				<div className="me-2">
					<button
						type="button"
						className="btn btn-lg btn-secondary me-3"
						data-kt-stepper-action="previous"
						onClick={() => navigate("back")}
					>
						<span className="svg-icon svg-icon-4 me-1">
							<svg
								width="24"
								height="24"
								viewBox="0 0 24 24"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
								className="mh-50px"
							>
								<rect
									opacity="0.5"
									x="6"
									y="11"
									width="13"
									height="2"
									rx="1"
									fill="black"
								></rect>
								<path
									d="M8.56569 11.4343L12.75 7.25C13.1642 6.83579 13.1642 6.16421 12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75L5.70711 11.2929C5.31658 11.6834 5.31658 12.3166 5.70711 12.7071L11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25C13.1642 17.8358 13.1642 17.1642 12.75 16.75L8.56569 12.5657C8.25327 12.2533 8.25327 11.7467 8.56569 11.4343Z"
									fill="black"
								></path>
							</svg>
						</span>
						Back
					</button>
				</div>
				<div>
					<button
						type="submit"
						className="btn btn-lg btn-primary me-3"
						onClick={() => navigate("continue")}
						disabled={policyDisabled}
					>
						<span className="indicator-label">
							Continue
							<span className="svg-icon svg-icon-3 ms-2 me-0">
								<svg
									width="24"
									height="24"
									viewBox="0 0 24 24"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
									className="mh-50px"
								>
									<rect
										opacity="0.5"
										x="18"
										y="13"
										width="13"
										height="2"
										rx="1"
										transform="rotate(-180 18 13)"
										fill="black"
									></rect>
									<path
										d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z"
										fill="black"
									></path>
								</svg>
							</span>
						</span>
					</button>
					{/* <button type="submit" className="btn btn-lg btn-success me-3">
                <span className="indicator-label">
                  Submit
                  <span className="svg-icon svg-icon-3 ms-2 me-0">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="mh-50px"
                    >
                      <rect
                        opacity="0.5"
                        x="18"
                        y="13"
                        width="13"
                        height="2"
                        rx="1"
                        transform="rotate(-180 18 13)"
                        fill="black"
                      ></rect>
                      <path
                        d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z"
                        fill="black"
                      ></path>
                    </svg>
                  </span>
                </span>
              </button> */}
				</div>
			</div>
		</div>
	);
}
