import React, { Suspense, lazy } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import { RootState } from "../../setup";
import { FallbackView } from "../../_metronic/partials";
import BlogPage from "../modules/admin/blog/Blog";
import BookingsPage from "../modules/admin/bookings/Bookings";
import CouponPage from "../modules/admin/coupon/Coupon";
import CustomerPage from "../modules/admin/customer/Customer";
import FeaturePage from "../modules/admin/feature/Feature";
import FinanceNLelgalPage from "../modules/admin/financenLegal/FinanceNLelgal";
import ManagePropertyPage from "../modules/admin/manageProperty/ManageProperty";
import PricingNTaxPage from "../modules/admin/pricingNTax/PricingNTax";
import SettlementPage from "../modules/admin/settlement/Settlement";
import VendorPerformancePage from "../modules/admin/vendorPerformance/VendorPerformance";
import VendorProfileDataPage from "../modules/admin/vendorProfileData/VendorProfileData";
import PricingPage from "../modules/pricing/Pricing";
import ReviewsPage from "../modules/reviews/Reviews";
import TodoPage from "../modules/todo/Todo";
import { DashboardWrapper } from "../pages/dashboard/DashboardWrapper";
import { MenuTestPage } from "../pages/MenuTestPage";
import CustomerDetails from "../modules/admin/customerDetails/CustomerDetails";
import VendorDetails from "../modules/admin/vendorDetails/VendorDetails";
import AdminProfile from "../modules/admin/profile/AdminProfile";
import BookingDetails from "../modules/admin/bookingDetails/BookingDetails";
import Faq from "../modules/admin/faq/Faq";

export function PrivateRoutes() {
	const BuilderPageWrapper = lazy(
		() => import("../pages/layout-builder/BuilderPageWrapper")
	);
	const ProfilePage = lazy(() => import("../modules/profile/ProfilePage"));
	const WizardsPage = lazy(() => import("../modules/wizards/WizardsPage"));
	const AccountPage = lazy(() => import("../modules/accounts/AccountPage"));
	const WidgetsPage = lazy(() => import("../modules/widgets/WidgetsPage"));
	const ChatPage = lazy(() => import("../modules/apps/chat/ChatPage"));
	const OrderPage = lazy(() => import("../modules/onboarding/OnboardingPage"));
	const ResortInfoPage = lazy(() => import("../modules/resortinfo/resortinfo"));
	const PricingPage = lazy(() => import("../modules/pricing/Pricing"));
	const AdminPage = lazy(() => import("../modules/admin/admin"));
	const ManagePropertyPage = lazy(
		() => import("../modules/admin/manageProperty/ManageProperty")
	);
	const Properties = lazy(() => import("../modules/properties/Properties"));
	const user: any = useSelector<RootState>(
		({ auth }) => auth.user,
		shallowEqual
	);

	return (
		<>
			{user && user.role == "Admin" ? (
				<Suspense fallback={<FallbackView />}>
					<Switch>
						<Route path="/admin/feature" component={FeaturePage} />
						<Route path="/admin/pricingNTax" component={PricingNTaxPage} />
						<Route
							path="/admin/manageProperty"
							component={ManagePropertyPage}
						/>
						<Route
							path="/admin/vendorProfileData"
							component={VendorProfileDataPage}
						/>
						<Route path="/admin/financenLegal" component={FinanceNLelgalPage} />
						<Route
							path="/admin/vendorPerformance"
							component={VendorPerformancePage}
						/>
						<Route path="/admin/settlement" component={SettlementPage} />
						<Route path="/admin/coupon" component={CouponPage} />
						<Route path="/admin/customer" component={CustomerPage} />
						<Route path="/admin/Blog" component={BlogPage} />
						<Route path="/admin/customer-details" component={CustomerDetails} />
						<Route path="/admin/vendor-details" component={VendorDetails} />
						<Route path="/admin/profile" component={AdminProfile} />

						<Route path="/admin/bookings" component={BookingsPage} />
						<Route path="/admin/booking-details" component={BookingDetails} />
						<Route path="/admin/faq" component={Faq} />

						<Route path="/admin" component={AdminPage} />
						{/* <Route path='/admin' component={AdminPage} />
        <Route path='/admin' component={AdminPage} />
        <Route path='/admin' component={AdminPage} />
        <Route path='/admin' component={AdminPage} /> */}
						{/* <Redirect from='/auth' to='/dashboard' /> */}
						<Redirect exact from="/" to="/admin" />
						<Redirect to="error/404" />
					</Switch>
				</Suspense>
			) : (
				<Suspense fallback={<FallbackView />}>
					<Switch>
						<Route path="/dashboard" component={DashboardWrapper} />
						<Route path="/properties" component={Properties} />
						<Route path="/builder" component={BuilderPageWrapper} />
						<Route path="/crafted/pages/profile" component={ProfilePage} />
						<Route path="/crafted/pages/wizards" component={WizardsPage} />
						<Route path="/crafted/widgets" component={WidgetsPage} />
						<Route path="/crafted/account" component={AccountPage} />
						<Route path="/order" component={OrderPage} />
						<Route path="/resortinfo" component={ResortInfoPage} />
						<Route path="/pricing" component={PricingPage} />
						<Route path="/todo" component={TodoPage} />
						<Route path="/reviews" component={ReviewsPage} />
						<Route path="/apps/chat" component={ChatPage} />
						<Route path="/menu-test" component={MenuTestPage} />
						{/* <Redirect from='/auth' to='/dashboard' /> */}
						<Redirect exact from="/" to="/dashboard" />
						<Redirect to="error/404" />
					</Switch>
				</Suspense>
			)}
		</>
	);
}
