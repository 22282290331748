import React, { useEffect, useState } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
// import { PageLink, PageTitle } from "../../../_metronic/layout/core";
import "./VendorPerformance.scss";
import { toAbsoluteUrl } from "../../../../_metronic/helpers";
import { Col, Form, Nav, Row, Tab } from "react-bootstrap-v5";
import axios from "axios";
import ReactPaginate from "react-paginate";

const API_URL = process.env.REACT_APP_API_URL || "";

const VendorPerformancePage: React.FC = ({ itemsPerPage = 10 }: any) => {
	const [currentItems, setCurrentItems] = useState<any>(null);
	const [pageCount, setPageCount] = useState(1);
	// Here we use item offsets; we could also use page offsets
	// following the API or data you're working with.
	const [itemOffset, setItemOffset] = useState(0);

	useEffect(() => {
		// Fetch items from another resources.
		axios.get(`${API_URL}/v1/admin/vendor?page_no=0`).then((res) => {
			setCurrentItems(res.data.data.data);
			// setPageCount(Math.ceil(res.data.data.metadata.total / itemsPerPage));
		});
	}, [itemOffset, itemsPerPage]);

	// Invoke when user click to request another page.
	const handlePageClick = (event: any) => {
		axios
			.get(`${API_URL}/v1/admin/vendor?page_no=` + event.selected)
			.then((res) => {
				setCurrentItems(res.data.data.data);
				// console.log("res.data.data.data", res.data.data.data);
				// console.log("admin useEffect api", pageCount,
				//  "page count", res.data.data.metadata.total / itemsPerPage);
				// setPageCount(Math.ceil(res.data.data.metadata.total / itemsPerPage));
			});
	};

	const getData = (data: any, type: string) => {
		let count: number = 0;
		let rev: number = 0;
		let com: number = 0;
		let star: number = 0;
		// console.log(data);
		let array: any = [];
		if (data?.booking?.length > 0) {
			data?.booking?.map((key: any) => {
				if (key?.booking_status === "Success") {
					count++;
					array.push(key);
				}
			});
		}
		if (type === "totalBooking") {
			return count;
		}
		// if (type === "star") {
		// 	if(count==0){
		// 		return 0;
		// 	}else{
		// 		data?.booking?.
		// 	}
		// 	return count;
		// }
		if (type === "revenue") {
			if (array.length > 0) {
				array.map((key: any) => {
					if (key?.vendor_payble) {
						rev = rev + Number(key?.vendor_payble);
					}
				});
				return rev;
			} else {
				return 0;
			}
		}
		if (type === "commision") {
			if (array.length > 0) {
				array.map((key: any) => {
					com = com + Number(key?.commission);
				});
				return com;
			} else {
				return 0;
			}
		}

		return count;
	};

	return (
		<div>
			<div className="row">
				{/* <div className="col-lg-12 d-flex justify-content-end">
          <div className="d-flex my-2">
            <div className="d-flex align-items-center position-relative me-4">
              <span className="svg-icon svg-icon-3 position-absolute ms-3">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="mh-50px"
                >
                  <rect
                    opacity="0.5"
                    x="17.0365"
                    y="15.1223"
                    width="8.15546"
                    height="2"
                    rx="1"
                    transform="rotate(45 17.0365 15.1223)"
                    fill="black"
                  ></rect>
                  <path
                    d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                    fill="black"
                  ></path>
                </svg>
              </span>
              <input
                type="text"
                id="kt_filter_search"
                className="form-control form-control-white form-control-sm w-250px ps-9 border-secondary"
                placeholder="Search"
                wtx-context="666F8DA7-646C-4188-876B-CA6BA1CAF363"
              />
            </div>
            <button className="btn btn-primary btn-sm">Search</button>
          </div>
        </div> */}

				<div className="col-lg-12">
					<div className="card card-custom">
						<div className="card-header">
							{/* <h3 className="card-title">Best Seller</h3> */}
							{/* <div className="card-toolbar">
        <button type="button" className="btn btn-sm btn-light">
            Last 10 bookings
        </button>
    </div> */}
						</div>
						<div className="card-body p-0 manage-pro-table-wrap">
							<table className="table table-hover table-rounded table-striped border gy-4 gs-7">
								<thead>
									<tr className="fw-bold fs-6 text-gray-800 border-bottom-2 border-gray-200">
										<th>Vendor Property</th>
										<th>Name</th>
										<th>Total Bookings</th>
										<th>Revenue</th>
										<th>Commission</th>
										<th>Star Rating</th>
									</tr>
								</thead>
								<tbody>
									{currentItems &&
										currentItems.map((ele: any) =>
											ele?.property_name &&
											ele?.property_name[0] &&
											ele?.property_name[0]?.length ? (
												<tr>
													<td>
														<span>
															<b>
																{ele?.property_name[0] &&
																ele?.property_name[0]?.length > 0
																	? ele?.property_name
																	: "Not Registered"}
															</b>
														</span>
													</td>
													<td>{ele?.vendor_name}</td>

													<td>{getData(ele, "totalbooking")}</td>
													<td>{getData(ele, "revenue")}</td>
													<td>{getData(ele, "commision")}</td>
													{/* <td>10K</td>
												<td>1000</td> */}
													<td>
														{/* {getData(ele, "star")} */}
														<div className="star-box star-box-container">
															{ele?.booking && ele?.booking.length > 0
																? [
																		...Array(
																			Number(ele?.booking[0].booking_prop_star)
																		),
																  ].map(() => {
																		return (
																			<i
																				className="fa fa-star"
																				aria-hidden="true"
																			></i>
																		);
																  })
																: null}

															{/* <i className="fa fa-star" aria-hidden="true"></i>
														<i className="fa fa-star" aria-hidden="true"></i>
														<i className="fa fa-star" aria-hidden="true"></i>
														<i className="fa fa-star" aria-hidden="true"></i> */}
														</div>
													</td>
												</tr>
											) : null
										)}
								</tbody>
							</table>
						</div>
					</div>
				</div>
				<div className="col-lg-12">
					<div className="pagination-wrap">
						<ReactPaginate
							breakLabel="..."
							nextLabel=" >"
							breakClassName="page-item"
							breakLinkClassName="page-link"
							pageClassName="page-item"
							pageLinkClassName="page-link"
							previousClassName="page-item"
							previousLinkClassName="page-link"
							nextClassName="page-item"
							nextLinkClassName="page-link"
							activeClassName="active"
							onPageChange={handlePageClick}
							pageRangeDisplayed={5}
							pageCount={pageCount}
							previousLabel="< "
							className="pagination"
						/>
						{/* <ul className="pagination">

              <li className="page-item previous disabled">
                <a href="#" className="page-link">
                  <i className="previous"></i>
                </a>
              </li>
              <li className="page-item">
                <a href="#" className="page-link">
                  1
                </a>
              </li>
              <li className="page-item active">
                <a href="#" className="page-link">
                  2
                </a>
              </li>
              <li className="page-item">
                <a href="#" className="page-link">
                  3
                </a>
              </li>
              <li className="page-item">
                <a href="#" className="page-link">
                  4
                </a>
              </li>
              <li className="page-item">
                <a href="#" className="page-link">
                  5
                </a>
              </li>
              <li className="page-item">
                <a href="#" className="page-link">
                  6
                </a>
              </li>
              <li className="page-item next">
                <a href="#" className="page-link">
                  <i className="next"></i>
                </a>
              </li>
            </ul> */}
					</div>
				</div>
			</div>
		</div>
	);
};
export default VendorPerformancePage;
