import { useState, useEffect } from "react";
import { useHistory } from "react-router";
import axios from "axios";
import { checkMandatoryBasicInfo } from "../ProfileValidations";

const API_URL = process.env.REACT_APP_API_URL || "";

export default function BasicInfo({
	form,
	setForm,
	propertyValidation,
	setPropertyValidation,
}: any) {
	const history = useHistory();
	const [basicDisabled, setBasicDisabled] = useState(false);
	const [changes, setChanges] = useState(false);
	const [touched, setTouched] = useState({
		property_name: false,
		property_star_rating: false,
		property_description: false,
		phone_no: false,
		email: false,
	});

	const navigate = (e: any) => {
		if (e === "cancel") {
			// sessionStorage.clear();
			history.push("/properties");
		}
		!changes &&
			e !== "cancel" &&
			history.push("/crafted/pages/profile/Location");

		if (e === "continue") {
			if (!changes) return;
			let body: any = { ...form };
			if (form.hasOwnProperty("_id")) body["property_id"] = body._id;
			if (body["property_status"] === "Verified") {
				body["property_status"] = "Update";
			} else {
				body["property_status"] = "Incomplete";
			}
			axios
				.post(`${API_URL}/v1/vendor/onboarding`, { data: body })
				.then((res) => {
					axios.get(`${API_URL}/v1/vendor/profile`).then((res) => {
						let properties = res.data.data.vendor_properties;
						let property = properties[properties.length - 1];
						sessionStorage.setItem("property_id", property._id);
						sessionStorage.setItem(
							"property_name",
							property.property_basic_info.property_name
						);
						setForm(property);
						history.push("/crafted/pages/profile/Location");
					});
				})
				.catch((err) => {});
		}
	};
	const handleChange = (event: any, parent: any, value: any) => {
		// console.log("value", value);

		if (value === "property_booking_since") {
			// console.log(event.target.value);
			// console.log(event.target.value.length);
			if (event.target.value.length > 4) {
				return false;
			}
		}
		if (value === "phone_no") {
			if (event.target.value.length > 10) {
				return false;
			}
		}

		let obj = { ...form };

		obj[parent][value] = event.target.value;
		setForm(obj);
		checkMandatory(obj);
		setChanges(true);
	};
	const checkMandatory = (form: any) => {
		if (checkMandatoryBasicInfo(form)) {
			setBasicDisabled(true);
			setPropertyValidation({ ...propertyValidation, basicInfo: true });
		} else {
			setBasicDisabled(false);
			setPropertyValidation({ ...propertyValidation, basicInfo: false });
		}
	};
	const maxLengthCheck = (object: any) => {
		if (object.target.value.length > object.target.maxLength) {
			object.target.value = object.target.value.slice(
				0,
				object.target.maxLength
			);
		}
	};
	useEffect(() => {
		checkMandatory(form);
	}, []);
	return (
		<div>
			<fieldset
				disabled={form?.property_status === "Unverified" ? true : false}
			>
				<div className="card card-custom mb-10">
					<div className="card-header">
						<h3 className="card-title align-items-start flex-column">
							<span className="card-label fw-bolder fs-3 mb-1">
								Welcome to Myresorts.in
							</span>
							<span className="text-muted fw-bold fs-7">
								Please fill in basic details of your property.
							</span>
						</h3>

						{/* <div className="card-toolbar">
            <button type="button" className="btn btn-sm btn-light">
              Action
            </button>
          </div> */}
					</div>

					<div className="card-body">
						<div className="basic-info-wrap">
							<div className="row">
								<div className="col-lg-6 mb-5">
									<label className="required form-label">Property Name</label>
									<input
										type="text"
										maxLength={100}
										className="form-control form-control-solid"
										placeholder=""
										value={form["property_basic_info"]["property_name"]}
										// disabled={form["vendor_id"]}
										onChange={(e) =>
											handleChange(e, "property_basic_info", "property_name")
										}
										onBlur={() =>
											setTouched({ ...touched, property_name: true })
										}
									/>
									{touched.property_name &&
										!form?.property_basic_info?.property_name && (
											<span className="text-danger fs-8" role="alert">
												Property Name is required field
											</span>
										)}
									<span className="text-white">.</span>
								</div>
								<div className="col-lg-6">
									<label className="required form-label">
										Hotel Star Rating
									</label>
									<select
										className="form-select form-select-solid"
										aria-label="Select example"
										value={form["property_basic_info"][
											"property_star_rating"
										]?.toString()}
										onChange={(e) =>
											handleChange(
												e,
												"property_basic_info",
												"property_star_rating"
											)
										}
										onBlur={() =>
											setTouched({ ...touched, property_star_rating: true })
										}
									>
										<option value="">Select One</option>
										<option value="1">1</option>
										<option value="2">2</option>
										<option value="3">3</option>
										<option value="4">4</option>
										<option value="5">5</option>
									</select>
									{touched.property_star_rating &&
										!form?.property_basic_info?.property_star_rating && (
											<span className="text-danger fs-8" role="alert">
												Hotel Star Rating is required field
											</span>
										)}
								</div>
								<div className="col-lg-12 mb-10 mt-5">
									<label className="required form-label">
										Property Description
									</label>
									<textarea
										className="form-control form-control-solid room-desc-ta"
										value={form["property_basic_info"]["property_description"]}
										onChange={(e) =>
											handleChange(
												e,
												"property_basic_info",
												"property_description"
											)
										}
										onBlur={() =>
											setTouched({ ...touched, property_description: true })
										}
									></textarea>
									{touched.property_description &&
										!form?.property_basic_info?.property_description && (
											<span className="text-danger fs-8 ms-2" role="alert">
												{" "}
												Property Description is required field
											</span>
										)}
									<span className="text-white">.</span>
								</div>
								<div className="col-lg-6">
									<label className="form-label">
										Taking booking since year
									</label>
									<input
										type="number"
										// onInput={maxLengthCheck}
										className="form-control form-control-solid"
										placeholder="YYYY"
										value={
											form["property_basic_info"]["property_booking_since"]
										}
										// disabled={form["vendor_id"]}
										onChange={(e) =>
											handleChange(
												e,
												"property_basic_info",
												"property_booking_since"
											)
										}
										onBlur={() =>
											setTouched({ ...touched, property_name: true })
										}
									/>

									{/* <select
										className="form-select form-select-solid"
										aria-label="Select example"
										value={
											form["property_basic_info"]["property_booking_since"]
										}
										onChange={(e) =>
											handleChange(
												e,
												"property_basic_info",
												"property_booking_since"
											)
										}
									>
										<option value="">Select One</option>
                    <option value="2023">2023</option>
                    <option value="2022">2022</option>
										<option value="2021">2021</option>
										<option value="2020">2020</option>
										<option value="2019">2019</option>
                    <option value="2018">2018</option>
										<option value="2017">2017</option>
										<option value="2016">2016</option>

									</select> */}
								</div>

								<div className="col-lg-6">
									<label className="form-label">
										Do you work with channel manager?
									</label>
									<div className="mb-10 d-flex">
										<div className="form-check form-check-custom form-check-solid form-check-sm me-10">
											<input
												className="form-check-input"
												name="manager"
												type="radio"
												value="Yes"
												id="flexRadioSm"
												checked={
													form["property_basic_info"][
														"property_channel_manager"
													] === "Yes"
														? true
														: false
												}
												onChange={(e) =>
													handleChange(
														e,
														"property_basic_info",
														"property_channel_manager"
													)
												}
											/>
											<label className="form-check-label" htmlFor="flexRadioSm">
												Yes
											</label>
										</div>

										<div className="form-check form-check-custom form-check-solid form-check-sm">
											<input
												className="form-check-input"
												name="manager"
												type="radio"
												value="No"
												id="flexRadioSm"
												checked={
													form["property_basic_info"][
														"property_channel_manager"
													] === "No"
														? true
														: false
												}
												onChange={(e) =>
													handleChange(
														e,
														"property_basic_info",
														"property_channel_manager"
													)
												}
											/>
											<label
												className="form-check-label"
												htmlFor="flexRadioSm1"
											>
												No
											</label>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					{/* <div className="card-footer">Footer</div> */}
				</div>

				<div className="card card-custom mb-10">
					<div className="card-header">
						<h3 className="card-title">Contact Details</h3>
						{/* <div className="card-toolbar">
            <button type="button" className="btn btn-sm btn-light">
              Action
            </button>
          </div> */}
					</div>
					<div className="card-body py-5">
						<div className="row">
							<div className="col-lg-6">
								<label className="required form-label">Contact Number</label>
								<input
									type="text"
									//style={{ borderColor: /^\d{10}$/.test(form['property_contact_details']['phone_no']) ? '' : 'red' }}
									className="form-control form-control-solid"
									placeholder=""
									value={form["property_contact_details"]["phone_no"]}
									onChange={(e) =>
										handleChange(e, "property_contact_details", "phone_no")
									}
									onBlur={() => setTouched({ ...touched, phone_no: true })}
								/>
								{touched.phone_no &&
									form?.property_contact_details?.phone_no &&
									!/^\d{10}$/.test(
										form?.property_contact_details?.phone_no
									) && (
										<span className="text-danger fs-8" role="alert">
											Enter 10 digit valid phone number
										</span>
									)}
								{touched.phone_no && !form?.property_contact_details?.phone_no && (
									<span className="text-danger fs-8" role="alert">
										Contact number is required field
									</span>
								)}
								<span className="text-white">.</span>
							</div>
							<div className="col-lg-6">
								<label className="required form-label">Email</label>
								<input
									type="email"
									//style={{ borderColor: /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(form['property_contact_details']['email']) ? '' : 'red' }}
									className="form-control form-control-solid"
									placeholder=""
									value={form["property_contact_details"]["email"]}
									onChange={(e) =>
										handleChange(e, "property_contact_details", "email")
									}
									onBlur={() => setTouched({ ...touched, email: true })}
								/>
								{touched.email &&
									form?.property_contact_details?.email &&
									!/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(
										form?.property_contact_details?.email
									) && (
										<span className="text-danger fs-8" role="alert">
											Wrong email format
										</span>
									)}
								{touched.email && !form?.property_contact_details?.email && (
									<span className="text-danger fs-8" role="alert">
										Email is required field
									</span>
								)}
							</div>
						</div>
					</div>
				</div>
			</fieldset>
			<div className="d-flex flex-stack">
				{sessionStorage.getItem("property_id") === "_new_property" && (
					<div className="me-2">
						<button
							type="button"
							className="btn btn-lg btn-secondary me-3"
							data-kt-stepper-action="previous"
							onClick={(e) => navigate("cancel")}
						>
							Cancel
						</button>
					</div>
				)}
				<div>
					<button
						type="submit"
						className="btn btn-lg btn-primary me-3"
						onClick={(e) => navigate("continue")}
						// disabled={!basicDisabled}
					>
						<span className="indicator-label">
							Continue
							<span className="svg-icon svg-icon-3 ms-2 me-0">
								<svg
									width="24"
									height="24"
									viewBox="0 0 24 24"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
									className="mh-50px"
								>
									<rect
										opacity="0.5"
										x="18"
										y="13"
										width="13"
										height="2"
										rx="1"
										transform="rotate(-180 18 13)"
										fill="black"
									></rect>
									<path
										d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z"
										fill="black"
									></path>
								</svg>
							</span>
						</span>
					</button>
					{/* <button type="submit" className="btn btn-lg btn-success me-3">
                <span className="indicator-label">
                  Submit
                  <span className="svg-icon svg-icon-3 ms-2 me-0">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="mh-50px"
                    >
                      <rect
                        opacity="0.5"
                        x="18"
                        y="13"
                        width="13"
                        height="2"
                        rx="1"
                        transform="rotate(-180 18 13)"
                        fill="black"
                      ></rect>
                      <path
                        d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z"
                        fill="black"
                      ></path>
                    </svg>
                  </span>
                </span>
              </button> */}
				</div>
			</div>
		</div>
	);
}
