import React, { useEffect, useState } from "react";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import { PageLink, PageTitle } from "../../../_metronic/layout/core";
import "./Todo.scss";
import { Col, Form, Nav, Row, Tab } from "react-bootstrap-v5";
import { toAbsoluteUrl } from "../../../_metronic/helpers/AssetHelpers";
import { KTSVG } from "../../../_metronic/helpers";
import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL || "";

// const AWS_LINK = "https://myresortsbucket.s3.amazonaws.com/";
const AWS_LINK = "https://myresortsbucket-prod1.s3.ap-south-1.amazonaws.com/";

const TodoPage: React.FC = () => {
	const [form, setForm] = useState<any>({});
	const [activities, setActivities] = useState([]);
	const [propertyId, setPropertyId] = useState("");

	const history = useHistory();
	let formdata = new FormData();
	const handleChange = (event: any, parent: any, value: any) => {
		let obj = { ...form };
		if (value == "value") {
			obj[parent] = event.target.value;
		} else if (value == "file") {
			obj[parent] = event.target.files[0];
		}
		setForm(obj);
	};

	const onSubmit = () => {
		formdata.append("activity_property_id", propertyId);
		if (form["activity_status"]) formdata.append("activity_status", "Active");
		if (form["activity_description"])
			formdata.append("activity_description", form["activity_description"]);
		if (form["activity_title"])
			formdata.append("activity_title", form["activity_title"]);
		if (form["activity_image_file"])
			formdata.append("activity_image_file", form["activity_image_file"]);
		if (form["_id"]) formdata.append("_id", form["_id"]);

		axios.post(`${API_URL}/v1/vendor/activity`, formdata).then((res) => {
			// console.log(formdata, "post api of things to do", form);
			axios
				.get(`${API_URL}/v1/vendor/activity`, {
					params: { activity_property_id: propertyId },
				})
				.then((res) => {
					setActivities(res.data.data);
					setForm({});
				});
		});
		formdata = new FormData();
	};
	const edit = (activity: any) => {
		setForm(activity);
	};
	const statusUpdate = (data: any) => {
		// let value: any = sessionStorage.getItem('property_id');
		// formdata.append('activity_property_id', value);
		formdata.append("activity_property_id", propertyId);
		if (data["activity_status"])
			formdata.append("activity_status", data["activity_status"]);
		if (data["_id"]) formdata.append("_id", data["_id"]);
		axios.post(`${API_URL}/v1/vendor/activity`, formdata).then(() => {
			axios
				.get(`${API_URL}/v1/vendor/activity`, {
					params: { activity_property_id: propertyId },
				})
				.then((res) => {
					setActivities(res.data.data);
					// console.log(res.data.data, "status update");
				});
		});
	};

	const [properties, setProperties] = useState<any[]>([{}]);
	const propSelectHandler = (property: any) => {
		sessionStorage.setItem("property_id", property._id);
		axios
			.get(`${API_URL}/v1/vendor/activity`, {
				params: { activity_property_id: property._id },
			})
			.then((res) => {
				setActivities(res.data.data);
			});
	};

	useEffect(() => {
		axios.get(`${API_URL}/v1/vendor/profile`).then((res) => {
			let properties = res.data.data.vendor_properties;
			setProperties(properties);
			// sessionStorage.setItem('property_id', properties[properties.length - 1]._id);
		});
	}, []);

	useEffect(() => {
		let propertyId = sessionStorage.getItem("property_id") || "empty";
		setPropertyId(propertyId);
		if (propertyId === "_new_property")
			history.push("/crafted/pages/profile/basicinfo");
		else
			axios
				.get(`${API_URL}/v1/vendor/activity`, {
					params: { activity_property_id: propertyId },
				})
				.then((res) => {
					setActivities(res.data.data);
					// console.log(res.data.data);
				})
				.catch(() => {
					setActivities([]);
				});
	}, []);

	return (
		<div>
			{/* <div className='row align-items-center mb-5'>
        <div className='col-5'>
          <PropertySelector properties={properties} onSelect={propSelectHandler} />
        </div>
      </div> */}
			<div className="row">
				<div className="col-lg-12">
					<div className="create-todo">
						<button
							type="button"
							className="btn btn-warning"
							data-bs-toggle="modal"
							data-bs-target="#kt_modal_1"
							onClick={(e) =>
								setForm({
									activity_description: "",
									activity_title: "",
									activity_image_file: { name: "" },
								})
							}
						>
							{" "}
							<i className="fa fa-plus" aria-hidden="true"></i> Create
						</button>
					</div>
				</div>

				{activities.map((e: any) => (
					<div className="col-xl-4 col-lg-6 col-sm-6 col-md-6">
						<div className="todo-wrap">
							<a
								href="javascript:;"
								className="edit-bt"
								data-bs-toggle="modal"
								data-bs-target="#kt_modal_1"
								onClick={(event) => edit(e)}
							>
								<i className="fa fa-pencil" aria-hidden="true"></i>
							</a>
							<h2>{e.activity_title}</h2>
							<div className="todo-img">
								<img
									alt={e?.activity_title}
									src={
										e?.activity_image_file.length > 0
											? `${AWS_LINK}${e?.activity_image_file}`
											: toAbsoluteUrl("/media/img/icons/review-img.jpeg")
									}
									// src={`data:image/png;base64,${Buffer.from(e.activity_image.data).toString('base64')}`}
								/>
							</div>

							<p>{e.activity_description}</p>
							<button
								type="button"
								className={
									e.activity_status == "Deactive"
										? "btn btn-success"
										: "btn btn-danger"
								}
								onClick={() => {
									statusUpdate({
										...e,
										activity_status:
											e.activity_status == "Deactive" ? "Active" : "Deactive",
									});
								}}
							>
								{e.activity_status == "Deactive" ? "Active" : "Deactive"}
							</button>
						</div>
					</div>
				))}
			</div>

			<div className="modal fade" tabIndex={-1} id="kt_modal_1">
				<div className="modal-dialog">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title">Things todo </h5>
							<div
								className="btn btn-icon btn-sm btn-active-light-primary ms-2"
								data-bs-dismiss="modal"
								aria-label="Close"
							>
								<i className="fa fa-times" aria-hidden="true"></i>
							</div>
						</div>
						<div className="modal-body">
							<div className="row mb-6">
								<label className="col-lg-4 col-form-label required fw-bold fs-6">
									Title
								</label>
								<div className="col-lg-8">
									<div className="row">
										<div className="col-lg-12 fv-row">
											<input
												type="text"
												className="form-control form-control-solid"
												placeholder=""
												value={form["activity_title"]}
												onChange={(e) =>
													handleChange(e, "activity_title", "value")
												}
											/>
										</div>
									</div>
								</div>
							</div>

							<div className="row mb-6">
								<label className="col-lg-4 col-form-label fw-bold fs-6">
									<span className="required">Image</span>
								</label>
								<div className="col-lg-8 fv-row">
									<Form.Group controlId="formFile" className="mb-3">
										<Form.Control
											type="file"
											onChange={(e) =>
												handleChange(e, "activity_image_file", "file")
											}
											onClick={(e: any) =>
												handleChange(e, "activity_image_file", "file")
											}
										/>
									</Form.Group>
								</div>
							</div>

							<div className="row mb-6">
								<label className="col-lg-4 col-form-label fw-bold fs-6">
									<span className="required">Description</span>
								</label>
								<div className="col-lg-8 fv-row">
									<textarea
										name=""
										id=""
										value={form["activity_description"]}
										onChange={(e) =>
											handleChange(e, "activity_description", "value")
										}
									></textarea>
									{/* <input
										type="text"
										className="form-control form-control-solid"
										placeholder=""
										value={form["activity_description"]}
										onChange={(e) =>
											handleChange(e, "activity_description", "value")
										}
									/> */}
								</div>
							</div>
						</div>
						<div className="modal-footer">
							<button
								type="button"
								className="btn btn-light"
								data-bs-dismiss="modal"
							>
								Close
							</button>
							<button
								type="button"
								className="btn btn-primary"
								data-bs-dismiss="modal"
								onClick={onSubmit}
							>
								{form._id ? "Edit" : "Create"}
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
export default TodoPage;
