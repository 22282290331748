import { useState, useEffect } from "react";
import { useHistory } from "react-router";
import axios from "axios";
import { checkMandatoryLocation } from "../ProfileValidations";

const API_URL = process.env.REACT_APP_API_URL || "";
const GM_KEY = process.env.REACT_APP_GOOGLE_MAP_API_KEY || "";

export default function Location({
	form,
	setForm,
	propertyValidation,
	setPropertyValidation,
}: any) {
	const history = useHistory();
	const [locationDisabled, setLocationDisabled] = useState(false);
	const [changes, setChanges] = useState(false);
	const [mapPlaceParam, setMapPlaceParam] = useState(
		form?.property_location?.property_name || "Mumbai"
	);
	const [touched, setTouched] = useState({
		nearestGate: false,
		address: false,
		city: false,
		zipcode: false,
		state: false,
		country: false,
	});

	const handleChange = (event: any, parent: any, value: any) => {
		if (value === "property_zip_code") {
			// console.log(event.target.value);

			if (event.target.value.length > 6) {
				return false;
			}
		}
		let obj = { ...form };
		obj[parent][value] = event.target.value;
		setForm(obj);
		checkMandatory(obj);
		setChanges(true);
		if (form.property_location?.property_city) {
			setMapPlaceParam(form.property_location?.property_city);
			if (form.property_location?.property_address)
				setMapPlaceParam(
					(place: string) =>
						place + " " + form.property_location?.property_address
				);
		}
		if (form.property_basic_info?.property_name) {
			if (mapPlaceParam === "Mumbai")
				setMapPlaceParam(
					(place: string) =>
						place + " " + form.property_basic_info?.property_name
				);
			else setMapPlaceParam(form.property_basic_info?.property_name);
		}
	};

	useEffect(() => {
		if (form.property_location?.property_city) {
			setMapPlaceParam(form.property_location?.property_city);
			if (form.property_location?.property_address)
				setMapPlaceParam(
					(place: string) =>
						place + " " + form.property_location?.property_address
				);
		}
		if (form.property_basic_info?.property_name) {
			if (mapPlaceParam === "Mumbai")
				setMapPlaceParam(
					(place: string) =>
						place + " " + form.property_basic_info?.property_name
				);
			else setMapPlaceParam(form.property_basic_info?.property_name);
		}
		checkMandatory(form);
	}, []);

	const checkMandatory = (form: any) => {
		if (checkMandatoryLocation(form)) {
			setLocationDisabled(true);
			setPropertyValidation({ ...propertyValidation, location: true });
		} else {
			setLocationDisabled(false);
			setPropertyValidation({ ...propertyValidation, location: false });
		}
	};

	const navigate = (e: any) => {
		if (e == "continue") {
			if (changes) {
				let body: any = { ...form };
				// console.log(body, "lcoation body");
				if (form.hasOwnProperty("_id")) {
					body["property_id"] = body._id;
				}
				if (body?.property_status === "Verified")
					body["property_status"] = "Update";
				axios
					.post(`${API_URL}/v1/vendor/onboarding`, { data: body })
					.then((res) => {
						history.push("/crafted/pages/profile/amenities");
					});
			} else history.push("/crafted/pages/profile/amenities");
		} else history.push("/crafted/pages/profile/BasicInfo");
	};

	return (
		<div>
			<fieldset
				disabled={form?.property_status === "Unverified" ? true : false}
			>
				<div className="card card-custom mb-10">
					<div className="card-header">
						<h3 className="card-title align-items-start flex-column">
							<span className="card-label fw-bolder fs-3 mb-1">
								Location Details
							</span>
							<span className="text-muted fw-bold fs-7">
								Please fill in basic details of your property.
							</span>
						</h3>

						{/* <div className="card-toolbar">
            <button type="button" className="btn btn-sm btn-light">
              Action
            </button>
          </div> */}
					</div>
					<div className="card-body">
						<div className="basic-info-wrap">
							<div className="row">
								<div className="col-lg-12 mb-10">
									<div className="map-wrap">
										{/*<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d241316.6433216459!2d72.74109722435568!3d19.082522324687428!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7c6306644edc1%3A0x5da4ed8f8d648c69!2sMumbai%2C%20Maharashtra!5e0!3m2!1sen!2sin!4v1630176504927!5m2!1sen!2sin" width="100%" height="350"  loading="lazy"></iframe> */}
										<iframe
											referrerPolicy="no-referrer-when-downgrade"
											src={`https://www.google.com/maps/embed/v1/place?key=${GM_KEY}&q=${mapPlaceParam}`}
											allowFullScreen
											width="100%"
											height="350"
											loading="lazy"
										></iframe>
									</div>
								</div>
								<div className="col-lg-6 mb-5">
									<label className="required form-label">Nearest Gate</label>
									<input
										maxLength={50}
										type="text"
										className="form-control form-control-solid"
										placeholder=""
										value={form["property_location"]["property_nearest_gate"]}
										onChange={(e) =>
											handleChange(
												e,
												"property_location",
												"property_nearest_gate"
											)
										}
										onBlur={() => setTouched({ ...touched, nearestGate: true })}
									/>
									{touched.nearestGate &&
										!form?.property_location?.property_nearest_gate && (
											<span className="text-danger fs-8" role="alert">
												Nearest Gate is required field
											</span>
										)}
									<span className="text-white">.</span>
								</div>
								{/* <div className="col-lg-3 mb-10">
                <label className="form-label">Latitude</label>
                <input
                  type="text"
                  className="form-control form-control-solid"
                  defaultValue={form['property_location']['property_coordinates'][0]}
                  onChange={e => handleCoordinateChange(e, 'property_location', 'property_coordinates', 'lat')}
                  placeholder=""
                />
              </div>

              <div className="col-lg-3 mb-10">
                <label className="form-label">Longitude</label>
                <input
                  type="text"
                  className="form-control form-control-solid"
                  placeholder=""
                  defaultValue={form['property_location']['property_coordinates'][1]}
                  onChange={e => handleCoordinateChange(e, 'property_location', 'property_coordinates', 'lng')}
                />
              </div> */}

								<div className="col-lg-6 mb-5">
									<label className="form-label">Locality</label>
									<input
										maxLength={50}
										type="text"
										className="form-control form-control-solid"
										placeholder=""
										value={form["property_location"]["property_locality"]}
										onChange={(e) =>
											handleChange(e, "property_location", "property_locality")
										}
									/>
								</div>

								<div className="col-lg-6 mb-5">
									<label className="required form-label">Address</label>
									<input
										maxLength={100}
										type="text"
										className="form-control form-control-solid"
										placeholder=""
										value={form["property_location"]["property_address"]}
										onChange={(e) =>
											handleChange(e, "property_location", "property_address")
										}
										onBlur={() => setTouched({ ...touched, address: true })}
									/>
									{touched.address &&
										!form?.property_location?.property_address && (
											<span className="text-danger fs-8" role="alert">
												Address is required field
											</span>
										)}
									<span className="text-white">.</span>
								</div>

								<div className="col-lg-6 mb-5">
									<label className="required form-label">City</label>
									<input
										maxLength={40}
										type="text"
										className="form-control form-control-solid"
										value={form["property_location"]["property_city"]}
										onChange={(e) =>
											handleChange(e, "property_location", "property_city")
										}
										placeholder=""
										onBlur={() => setTouched({ ...touched, city: true })}
									/>
									{touched.city && !form?.property_location?.property_city && (
										<span className="text-danger fs-8" role="alert">
											City is required field
										</span>
									)}
									{form?.property_location?.property_city &&
										!/^[a-zA-Z\s]+$/.test(
											form?.property_location?.property_city
										) && (
											<span className="text-danger fs-8" role="alert">
												Enter valid City name
											</span>
										)}
								</div>

								<div className="col-lg-6 mb-5">
									<label className="required form-label">Pincode</label>
									<input
										type="number"
										className="form-control form-control-solid"
										value={form["property_location"]["property_zip_code"]}
										onChange={(e) =>
											handleChange(e, "property_location", "property_zip_code")
										}
										placeholder=""
										onBlur={() => setTouched({ ...touched, zipcode: true })}
									/>
									{touched.zipcode &&
										!form?.property_location?.property_zip_code && (
											<span className="text-danger fs-8" role="alert">
												Pincode is required field
											</span>
										)}
									{form?.property_location?.property_zip_code &&
										!/^\d{6}$/.test(
											form?.property_location?.property_zip_code
										) && (
											<span className="text-danger fs-8" role="alert">
												Enter valid pincode
											</span>
										)}
									<span className="text-white">.</span>
								</div>

								<div className="col-lg-6">
									<label className="required form-label">State</label>
									<input
										type="text"
										className="form-control form-control-solid"
										value={form["property_location"]["property_state"]}
										onChange={(e) =>
											handleChange(e, "property_location", "property_state")
										}
										placeholder=""
										onBlur={() => setTouched({ ...touched, state: true })}
									/>
									{touched.state && !form?.property_location?.property_state && (
										<span className="text-danger fs-8" role="alert">
											State is required field
										</span>
									)}
									{form?.property_location?.property_state &&
										!/^[a-zA-Z\s]+$/.test(
											form?.property_location?.property_state
										) && (
											<span className="text-danger fs-8" role="alert">
												Enter valid State name
											</span>
										)}
								</div>

								<div className="col-lg-6">
									<label className="form-label">Country</label>
									<input
										type="text"
										className="form-control form-control-solid"
										value={form["property_location"]["property_country"]}
										onChange={(e) =>
											handleChange(e, "property_location", "property_country")
										}
										placeholder=""
									/>
								</div>
							</div>
						</div>
					</div>
					{/* <div className="card-footer">Footer</div> */}
				</div>
			</fieldset>
			<div className="d-flex flex-stack">
				<div className="me-2">
					<button
						type="button"
						className="btn btn-lg btn-secondary me-3"
						data-kt-stepper-action="previous"
						onClick={(e) => navigate("back")}
					>
						<span className="svg-icon svg-icon-4 me-1">
							<svg
								width="24"
								height="24"
								viewBox="0 0 24 24"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
								className="mh-50px"
							>
								<rect
									opacity="0.5"
									x="6"
									y="11"
									width="13"
									height="2"
									rx="1"
									fill="black"
								></rect>
								<path
									d="M8.56569 11.4343L12.75 7.25C13.1642 6.83579 13.1642 6.16421 12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75L5.70711 11.2929C5.31658 11.6834 5.31658 12.3166 5.70711 12.7071L11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25C13.1642 17.8358 13.1642 17.1642 12.75 16.75L8.56569 12.5657C8.25327 12.2533 8.25327 11.7467 8.56569 11.4343Z"
									fill="black"
								></path>
							</svg>
						</span>
						Back
					</button>
				</div>
				<div>
					<button
						type="submit"
						className="btn btn-lg btn-primary me-3"
						onClick={(e) => navigate("continue")}
						disabled={!locationDisabled}
					>
						<span className="indicator-label">
							Continue
							<span className="svg-icon svg-icon-3 ms-2 me-0">
								<svg
									width="24"
									height="24"
									viewBox="0 0 24 24"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
									className="mh-50px"
								>
									<rect
										opacity="0.5"
										x="18"
										y="13"
										width="13"
										height="2"
										rx="1"
										transform="rotate(-180 18 13)"
										fill="black"
									></rect>
									<path
										d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z"
										fill="black"
									></path>
								</svg>
							</span>
						</span>
					</button>
					{/* <button type="submit" className="btn btn-lg btn-success me-3">
                <span className="indicator-label">
                  Submit
                  <span className="svg-icon svg-icon-3 ms-2 me-0">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="mh-50px"
                    >
                      <rect
                        opacity="0.5"
                        x="18"
                        y="13"
                        width="13"
                        height="2"
                        rx="1"
                        transform="rotate(-180 18 13)"
                        fill="black"
                      ></rect>
                      <path
                        d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z"
                        fill="black"
                      ></path>
                    </svg>
                  </span>
                </span>
              </button> */}
				</div>
			</div>
		</div>
	);
}
