import React, { useState, useEffect } from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import { Link } from 'react-router-dom'
import { useFormik } from 'formik'
import { resetPassword } from './redux/AuthCRUD'

const initialValues = {
  password: '',
  changepassword: '',
}

const ResetPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .min(8, 'Minimum 8 symbols')
    .max(16, 'Maximum 16 symbols')
    .required('Password is required')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
    ),
  changepassword: Yup.string()
    .required('Password confirmation is required')
    .when('password', {
      is: (val: string) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf([Yup.ref('password')], "Password and Confirm Password didn't match"),
    }),
})

export function ResetPassword() {
  const [loading, setLoading] = useState(false)
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
  const [show, setShow] = useState(true)
  const userId = window.location.search.split('=')[1]
  const formik = useFormik({
    initialValues,
    validationSchema: ResetPasswordSchema,
    onSubmit: (values, { setSubmitting }) => {
      setLoading(true)
      setHasErrors(undefined)
      setTimeout(() => {
        resetPassword(userId, values.password)
          .then(() => {
            setHasErrors(false)
            setShow(false)
            setLoading(false)
          })
          .catch(() => {
            setHasErrors(true)
            setLoading(false)
            setSubmitting(false)
            //setStatus('Could not reset password')
          })
      }, 1000)
    },
  })
  
  return (
    <>
      <div
        className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'
      // style={{
      //   backgroundImage: `url(${toAbsoluteUrl('/media/illustrations/progress-hd.png')})`,
      // }}
      >
        {/* begin::Content */}
        <div className='d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20'>
          {/* begin::Logo */}
          {/* <a href='#' className='mb-12'>
          <img alt='Logo' src={toAbsoluteUrl('/media/logos/logo-3.svg')} className='h-45px' />
        </a> */}
          {/* end::Logo */}
          {/* begin::Wrapper */}
          <div className='w-lg-800px bg-white rounded shadow-sm p-10 p-lg-15 mx-auto login-bg-vendor'>
            <form
              className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
              noValidate
              id='kt_login_password_reset_form'
              onSubmit={formik.handleSubmit}
            >
              <div className='text-center mb-10'>
                {/* begin::Title */}
                <h1 className='text-dark mb-3'>Reset Password</h1>
                {/* end::Title */}

                {/* begin::Link */}
                {/* <div className='text-gray-400 fw-bold fs-4'>Enter your email to reset your password.</div> */}
                {/* end::Link */}
              </div>

              {/* begin::Title */}
              {hasErrors === true && (
                <div className='mb-lg-15 alert alert-danger'>
                  <div className='alert-text font-weight-bold'>
                    Sorry, something went wrong, please try again.
                  </div>
                </div>
              )}

              {hasErrors === false && (<>
                <div className='mb-10 bg-light-info p-8 rounded'>
                  <div className='text-info'>
                    Password has been reset successfully, goto - <Link to='/auth/login'>
                      <button
                        type='button'
                        id='kt_login_password_reset_form_cancel_button'
                        className='btn btn-sm btn-light-primary fw-bolder'
                      >
                        Login
                      </button>
                    </Link>
                  </div>
                </div>
              </>
              )}
              {/* end::Title */}

              {/* begin::Form group */}
              {show && <><div className='fv-row input-box' data-kt-password-meter='true'>

                {/* <label className='form-label fw-bolder text-dark fs-6'>New Password</label> */}
                <img src="/media/img/password.svg" alt="" />

                <input
                  type='password'
                  placeholder='Password'
                  autoComplete='off'
                  {...formik.getFieldProps('password')}
                  className={clsx(
                    '',
                    {
                      'is-invalid': formik.touched.password && formik.errors.password,
                    },
                    {
                      'is-valid': formik.touched.password && !formik.errors.password,
                    }
                  )}
                />


              </div>

                {formik.touched.password && formik.errors.password && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.password}</span>
                    </div>
                  </div>
                )}

                {/* end::Form group */}

                {/* begin::Form group Confirm password */}
                <div className='fv-row input-box'>
                  {/* <label className='form-label fw-bolder text-dark fs-6'>Confirm Password</label> */}
                  <img src="/media/img/password.svg" alt="" />

                  <input
                    type='password'
                    placeholder='Password confirmation'
                    autoComplete='off'
                    {...formik.getFieldProps('changepassword')}
                    className={clsx(
                      '',
                      {
                        'is-invalid': formik.touched.changepassword && formik.errors.changepassword,
                      },
                      {
                        'is-valid': formik.touched.changepassword && !formik.errors.changepassword,
                      }
                    )}
                  />

                </div>
                {formik.touched.changepassword && formik.errors.changepassword && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.changepassword}</span>
                    </div>
                  </div>
                )}
                {/* end::Form group */}

                {/* begin::Form group */}
                <div className='d-flex flex-wrap justify-content-center pb-lg-0 pt-10'>
                  <button
                    type='submit'
                    id='kt_password_reset_submit'
                    className='btn btn-lg btn-primary fw-bolder me-4'
                  >
                    <span className='indicator-label'>Submit</span>
                    {loading && (
                      <span className='indicator-progress'>
                        Please wait...
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    )}
                  </button>
                  <Link to='/auth/login'>
                    <button
                      type='button'
                      id='kt_login_password_reset_form_cancel_button'
                      className='btn btn-lg btn-light-primary fw-bolder'
                      disabled={formik.isSubmitting || !formik.isValid}
                    >
                      Cancel
                    </button>
                  </Link>
                </div>
              </>}

              {/* end::Form group */}
            </form>
          </div>
        </div>
      </div>
    </>
  )
}
