import axios from "axios";
import React, { useEffect, useState } from "react";
import { Form, Button, Alert } from "react-bootstrap-v5";
import { useHistory } from "react-router-dom";
import { KTSVG, toAbsoluteUrl } from "../../../../_metronic/helpers";
import CustomeAlert from "../../widgets/CustomeAlert";
import { checkMandatoryPhotos } from "../ProfileValidations";
import { SnackbarProvider, useSnackbar } from "notistack";

const API_URL = process.env.REACT_APP_API_URL || "";

interface LocalStorageData {
	user: string;
	accessToken: string;
	_persist: string;
}

export default function Photos({ form, setForm }: any) {
	const [images, setImages] = useState<any[]>([]);
	const [catImages, setCatImages] = useState<any[]>([]);

	const [validationSchema, setValidationSchema] = useState<{
		[key: string]: number;
	}>({});
	const [disabled, setDisabled] = useState(true);

	const [uploadImgRes, setUploadImgRes] = useState<any>("");
	const [uploadImgResCat, setUploadImgResCat] = useState<any>("");
	const [propId, setPropId] = useState<any>(""); // property id from session storage
	const [accessToken, setAccessToken] = useState<any>("");

	const [variant, setVariant] = useState<any>("");
	const [message, setMessage] = useState<any>("");
	const [showAlert, setShowAlert] = useState<boolean>(false);

	const [loading, setLoading] = useState<boolean>(false);
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();

	const history = useHistory();

	const categories = form?.property_room_categories.map(
		(item: any) => item.category_basic_info.name
	);
	// console.log("categories", categories);

	const categoryData = form?.property_room_categories.map((item: any) => item);
	// console.log("categoryData", categoryData);

	// console.log(categoryId[0], "category Id", categoryData);

	const navigate = (e: any) => {
		if (e == "continue") history.push("/crafted/pages/profile/policies");
		else history.push("/crafted/pages/profile/rooms");
	};

	useEffect(() => {
		let propertyId = sessionStorage.getItem("property_id");
		let vendorId = sessionStorage.getItem("vendor_id");

		// console.log(form, "res data data"); // All Date is Present in form

		if (propertyId !== "_new_property") {
			setLoading(true);

			axios
				.get(`${API_URL}/v1/vendor/images?property_id=${form?._id}`)
				.then((res) => {
					setImages(res.data.data);
					setLoading(false);
				});
		}
		if (vendorId !== "") {
			setLoading(true);

			axios
				.get(`${API_URL}/v1/vendor/categoryImages?vendor_id=${vendorId}`)
				.then((res) => {
					setCatImages(res.data.data);
					setLoading(false);
				});
		}
	}, []);

	useEffect(() => {
		const localStorageData: LocalStorageData = JSON.parse(
			localStorage.getItem("persist:v100-demo1-auth") || "{}"
		);
		const accessTokenStr: string = JSON.parse(localStorageData.accessToken);
		setAccessToken(accessTokenStr);

		let propertyId = sessionStorage.getItem("property_id");
		setPropId(propertyId);
		if (propertyId !== "_new_property") {
			let validationObj = checkMandatoryPhotos(images, categories);
			// console.log("validationObj1", validationObj);
			setValidationSchema(validationObj);
			for (let cat of categories) {
				if (validationObj[cat] < 3 || validationObj["property"] < 3) {
					setDisabled(true);
					break;
				} else setDisabled(false);
			}
		}
	}, [images]);

	useEffect(() => {
		// console.log("Vendor Images", catImages);
		let validationObj: any = {};
		catImages &&
			catImages.length > 0 &&
			catImages.map((key: any) => {
				validationObj = {
					...validationObj,
					[key?.image_category]: key.image_bodies && key.image_bodies.length,
				};
			});
		// console.log("validationObj", validationObj);
		setValidationSchema({ ...validationSchema, ...validationObj });
	}, [catImages]);

	const handleFileChangeProperty = (event: any, category: any) => {
		setLoading(true);
		// console.log(event, category, "Category api photo")
		const token = accessToken;

		for (let i = 0; i < event.target.files.length; i++) {
			let file = event.target.files[i];
			// console.log(event.target, "event function", file);
			if (file?.size > 3145728) {
				enqueueSnackbar("Can't upload files larger than 3Mb", {
					variant: "error",
					anchorOrigin: {
						horizontal: "right",
						vertical: "top",
					},
				});

				setLoading(false);

				return false;
			}

			const formData = new FormData();
			formData.append("property_image", file);
			formData.append("category", category);
			formData.append("property_id", propId);

			axios
				.post(`${API_URL}/v1/vendor/images`, formData, {
					headers: {
						"Content-Type": "multipart/form-data",
						Authorization: `Bearer ${token}`,
					},
				})
				.then((response) => {
					// console.log(response.data.message, "response of axios");
					setUploadImgRes(response.data.message);
					let propertyId = sessionStorage.getItem("property_id");

					// console.log(form, "res data data"); // All Date is Present in form

					if (propertyId !== "_new_property") {
						setLoading(true);

						axios
							.get(`${API_URL}/v1/vendor/images?property_id=${form?._id}`)
							.then((res) => {
								setImages(res.data.data);
								setLoading(false);
							});
					}
					setLoading(false);

					if (response.data.message) {
						setTimeout(() => {
							setUploadImgRes("");
						}, 3000);
					}
				})
				.catch((error) => {
					setLoading(false);
					setUploadImgResCat("Failed to Upload Category Images !");
					if (error) {
						setTimeout(() => {
							setUploadImgResCat("");
						}, 3000);
					}
					// console.log(error);
				});
		}
	};

	const handleFileChangeCategory = (event: any, category: any) => {
		setLoading(true);

		const token = accessToken;
		let vendorId = sessionStorage.getItem("vendor_id");

		for (let i = 0; i < event.target.files.length; i++) {
			let file = event.target.files[i];
			// console.log("Files------------", file);
			// console.log("Files  Size------------", file.size);
			if (file?.size > 3145728) {
				enqueueSnackbar("Can't upload files larger than 3Mb", {
					variant: "error",
					anchorOrigin: {
						horizontal: "right",
						vertical: "top",
					},
				});

				setLoading(false);

				return false;
			}

			// console.log("category._id", category._id);

			const formData = new FormData();
			formData.append("category-Image", file);
			formData.append("category", category?.category_basic_info?.name);
			formData.append("category_id", category._id);

			axios
				.post(`${API_URL}/v1/vendor/cate_img`, formData, {
					headers: {
						"Content-Type": "multipart/form-data",
						Authorization: `Bearer ${token}`,
					},
				})
				.then((response) => {
					// console.log(response.data.message, "response of axios");
					if (vendorId !== "") {
						setLoading(true);

						axios
							.get(`${API_URL}/v1/vendor/categoryImages?vendor_id=${vendorId}`)
							.then((res) => {
								setCatImages(res.data.data);
								setLoading(false);
							});
					}
					setLoading(false);
					setUploadImgResCat(response.data.message);
					if (response.data.message) {
						setTimeout(() => {
							setUploadImgResCat("");
						}, 3000);
					}
				})
				.catch((error) => {
					setLoading(false);
					setUploadImgResCat("Failed to Upload Category Images !");
					if (error) {
						setTimeout(() => {
							setUploadImgResCat("");
						}, 3000);
					}
					// console.log(error);
				});
		}
	};

	const deleteImg = (id: any, imageCategory: string, objectId: any) => {
		// console.log("objectId", objectId);

		let body = { property_id: form?._id, image_id: objectId };
		axios
			.post(`${API_URL}/v1/vendor/images/delete`, { data: body })
			.then(() => {
				// commented area
				fetchImage(id, imageCategory, objectId);
			})
			.catch((error: any) => {
				fetchImage(id, imageCategory, objectId);
			});
	};

	const fetchCatImage = (id: any, imageCategory: string, objectId: any) => {
		let vendorId = sessionStorage.getItem("vendor_id");
		setLoading(true);

		axios
			.get(`${API_URL}/v1/vendor/categoryImages?vendor_id=${vendorId}`)
			.then((res) => {
				setCatImages(res.data.data);
				setLoading(false);
			});

		// commented area
		let imagesObj = catImages.slice();
		let index = imagesObj.findIndex(
			(obj: any) => obj.image_category === imageCategory
		);
		let catImagesObj = imagesObj.filter(
			(obj: any) => obj.image_category === imageCategory
		)[0];
		let imageBodies = catImagesObj.image_bodies.filter(
			(obj: any) => obj.image_id !== id
		);
		catImagesObj["image_bodies"] = imageBodies;
		imagesObj[index] = catImagesObj;
		setCatImages(imagesObj);
	};

	const deleteCatImg = (id: any, imageCategory: string, objectId: any) => {
		// console.log("objectId", objectId);
		// console.log("id", id);
		// console.log("imageCategory", imageCategory);

		let vendorId = sessionStorage.getItem("vendor_id");

		let body = { property_id: form?._id, image_id: objectId };
		axios
			.post(`${API_URL}/v1/vendor/catImages/delete`, { data: body })
			.then(() => {
				// commented area
				fetchCatImage(id, imageCategory, objectId);
			})
			.catch((error: any) => {
				// fetchCatImage();
				fetchCatImage(id, imageCategory, objectId);
			});
	};

	const fetchImage = (id: any, imageCategory: string, objectId: any) => {
		axios
			.get(`${API_URL}/v1/vendor/images?property_id=${form?._id}`)
			.then((res) => {
				setImages(res.data.data);
				// console.log(res, "axios res ponse of delteimg");
			});
		// commented area
		let imagesObj = images.slice();
		let index = imagesObj.findIndex(
			(obj: any) => obj.image_category === imageCategory
		);
		let catImagesObj = imagesObj.filter(
			(obj: any) => obj.image_category === imageCategory
		)[0];
		let imageBodies = catImagesObj.image_bodies.filter(
			(obj: any) => obj.image_id !== id
		);
		catImagesObj["image_bodies"] = imageBodies;
		imagesObj[index] = catImagesObj;
		setImages(imagesObj);
	};
	// console.log("categoryData----------------", categoryData);
	return (
		<div>
			<div className="card card-custom mb-10">
				<div className="card-header">
					<h3 className="card-title align-items-start flex-column">
						<span className="card-label fw-bolder fs-3 mb-1">Images</span>
						<span className="text-muted fw-bold fs-7">
							Add property photos to discover more
						</span>
					</h3>
				</div>

				<div className="card-body">
					<div style={{ color: "red", marginBottom: "10px" }}>
						{uploadImgResCat}
					</div>
					<div style={{ color: "red", marginBottom: "10px" }}>
						{uploadImgRes}
					</div>

					<div className="accordion" id="kt_accordion_1">
						<div className="accordion-item">
							<h2 className="accordion-header" id="kt_accordion_1_header_2">
								<button
									className="accordion-button fs-4 fw-bold collapsed"
									type="button"
									data-bs-toggle="collapse"
									data-bs-target="#kt_accordion_1_body_3"
									aria-expanded="false"
									aria-controls="kt_accordion_1_body_3"
								>
									Property Photo
									<span
										className={
											"fs-8 ms-2 " +
											(validationSchema["property"]
												? validationSchema["property"] < 3
													? "text-danger"
													: "text-muted"
												: "text-danger")
										}
									>
										(Minimum 3 photos required)
									</span>
								</button>
							</h2>
							<div
								id="kt_accordion_1_body_3"
								className="accordion-collapse collapse"
								aria-labelledby="kt_accordion_1_header_2"
								data-bs-parent="#kt_accordion_1"
							>
								<fieldset
									disabled={
										form?.property_status === "Unverified" ? true : false
									}
								>
									<div className="accordion-body">
										<div className="row">
											{images.length > 0 &&
												images
													.filter((o: any) => o.image_category == "property")
													.map((img: any) => (
														<>
															{img.image_bodies.map((e: any) => (
																<div className="col-lg-3">
																	<div className="photo-box">
																		<div className="photo-box-image-wrap">
																			<img
																				src={`data:image/png;base64,${Buffer.from(
																					e?.image_file?.data
																				).toString("base64")}`}
																				alt="MyResorts.in"
																			/>
																		</div>
																		<button
																			className="btn btn-light"
																			style={{ width: "100%" }}
																			onClick={() =>
																				deleteImg(
																					e.image_id,
																					img.image_category,
																					e.id
																				)
																			}
																		>
																			Delete
																		</button>
																	</div>
																</div>
															))}
														</>
													))}

											{loading && (
												<div
													style={{ display: "flex", justifyContent: "center" }}
												>
													<img
														style={{ width: "50px" }}
														src="/media/img/icons/imgLoader.gif"
														alt="loading"
													/>
												</div>
											)}

											<div className="col-lg-12">
												<div className="add-photo-wrap">
													<button className="addPhoto btn btn-lg btn-success me-3">
														<i className="fa fa-plus" aria-hidden="true"></i>
														Add Photo
													</button>

													{/* Property Photo Add Images */}

													<Form.Group
														controlId="formFile"
														className="mb-3 input-main"
													>
														<Form.Control
															type="file"
															multiple
															onChange={(e) =>
																handleFileChangeProperty(e, "property")
															}
															// disabled={!form.hasOwnProperty("_id")}
														/>
													</Form.Group>
												</div>
												{/* <Button variant="primary" type="submit">
                        Submit
                      </Button> */}
											</div>
										</div>
									</div>
								</fieldset>
							</div>
						</div>

						{categoryData &&
							categoryData.map((ele: any, index: any) => {
								return (
									<>
										<div className="accordion-item">
											<h2
												className="accordion-header"
												id="kt_accordion_1_header_1"
											>
												<button
													className="accordion-button fs-4 fw-bold collapsed"
													type="button"
													data-bs-toggle="collapse"
													data-bs-target={"#kt_accordion_1_body_" + index}
													aria-expanded="false"
													aria-controls={"kt_accordion_1_body_" + index}
												>
													{ele?.category_basic_info?.name} Photo
													<span
														className={
															"fs-8 ms-2 " +
															(validationSchema[ele?.category_basic_info?.name]
																? validationSchema[
																		ele?.category_basic_info?.name
																  ] < 3
																	? "text-danger"
																	: "text-muted"
																: "text-danger")
														}
													>
														(Minimum 3 photos required)
													</span>
												</button>
											</h2>
											<div
												id={"kt_accordion_1_body_" + index}
												className="accordion-collapse collapse"
												aria-labelledby="kt_accordion_1_header_1"
												data-bs-parent="#kt_accordion_1"
											>
												<fieldset
												// disabled={
												// 	form?.property_status === "Unverified"
												// 		? true
												// 		: false
												// }
												>
													<div className="accordion-body">
														<div className="row">
															{catImages.length > 0 &&
																catImages
																	.filter(
																		(o: any) =>
																			o.image_category ==
																			ele?.category_basic_info?.name
																	)
																	.map((img: any) => (
																		<>
																			{img.image_bodies.map((e: any) => (
																				<div className="col-lg-3">
																					<div className="photo-box">
																						<div className="photo-box-image-wrap">
																							<img
																								src={`data:image/png;base64,${Buffer.from(
																									e?.image_file?.data
																								).toString("base64")}`}
																								alt="MyResorts.in"
																							/>
																						</div>
																						<button
																							className="btn btn-light"
																							style={{ width: "100%" }}
																							onClick={() =>
																								deleteCatImg(
																									e.image_id,
																									img.image_category,
																									e.id
																								)
																							}
																						>
																							Delete
																						</button>
																					</div>
																				</div>
																			))}
																		</>
																	))}

															{loading && (
																<div
																	style={{
																		display: "flex",
																		justifyContent: "center",
																	}}
																>
																	<img
																		style={{ width: "50px" }}
																		src="/media/img/icons/imgLoader.gif"
																		alt="loading"
																	/>
																</div>
															)}

															<div className="col-lg-12">
																<div className="add-photo-wrap">
																	<button className="addPhoto btn btn-lg btn-success me-3">
																		<i
																			className="fa fa-plus"
																			aria-hidden="true"
																		></i>
																		Add Photo
																	</button>
																	{/* Category Images Add Photo */}
																	<Form.Group
																		controlId="formFile"
																		className="mb-3 input-main"
																	>
																		<Form.Control
																			type="file"
																			multiple
																			onChange={(e) =>
																				handleFileChangeCategory(e, ele)
																			}
																			// disabled={!form.hasOwnProperty("_id")}
																		/>
																	</Form.Group>
																</div>
																{/* <Button variant="primary" type="submit">
                        Submit
                      </Button> */}
															</div>
														</div>
													</div>
												</fieldset>
											</div>
										</div>
									</>
								);
							})}
					</div>
				</div>
			</div>

			<div className="d-flex flex-stack">
				<div className="me-2">
					<button
						type="button"
						className="btn btn-lg btn-secondary me-3"
						data-kt-stepper-action="previous"
						onClick={() => navigate("back")}
					>
						<span className="svg-icon svg-icon-4 me-1">
							<svg
								width="24"
								height="24"
								viewBox="0 0 24 24"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
								className="mh-50px"
							>
								<rect
									opacity="0.5"
									x="6"
									y="11"
									width="13"
									height="2"
									rx="1"
									fill="black"
								></rect>
								<path
									d="M8.56569 11.4343L12.75 7.25C13.1642 6.83579 13.1642 6.16421 12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75L5.70711 11.2929C5.31658 11.6834 5.31658 12.3166 5.70711 12.7071L11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25C13.1642 17.8358 13.1642 17.1642 12.75 16.75L8.56569 12.5657C8.25327 12.2533 8.25327 11.7467 8.56569 11.4343Z"
									fill="black"
								></path>
							</svg>
						</span>
						Back
					</button>
				</div>
				<div>
					<button
						type="submit"
						className="btn btn-lg btn-primary me-3"
						onClick={() => navigate("continue")}
						// disabled={disabled}
					>
						<span className="indicator-label">
							Continue
							<span className="svg-icon svg-icon-3 ms-2 me-0">
								<svg
									width="24"
									height="24"
									viewBox="0 0 24 24"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
									className="mh-50px"
								>
									<rect
										opacity="0.5"
										x="18"
										y="13"
										width="13"
										height="2"
										rx="1"
										transform="rotate(-180 18 13)"
										fill="black"
									></rect>
									<path
										d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z"
										fill="black"
									></path>
								</svg>
							</span>
						</span>
					</button>
					{/* <button type="submit" className="btn btn-lg btn-success me-3">
            <span className="indicator-label">
              Submit
              <span className="svg-icon svg-icon-3 ms-2 me-0">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="mh-50px"
                >
                  <rect
                    opacity="0.5"
                    x="18"
                    y="13"
                    width="13"
                    height="2"
                    rx="1"
                    transform="rotate(-180 18 13)"
                    fill="black"
                  ></rect>
                  <path
                    d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z"
                    fill="black"
                  ></path>
                </svg>
              </span>
            </span>
          </button> */}
				</div>
			</div>
			{showAlert && <CustomeAlert variant={variant} message={message} />}
		</div>
	);
}
