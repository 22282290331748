/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import * as auth from '../redux/AuthRedux'
import { register } from '../redux/AuthCRUD'
import { Link, useHistory } from 'react-router-dom'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'
import './Registration.scss'
import { Modal } from 'react-bootstrap-v5'
import jsPDF from 'jspdf';
import PDFViewer from "../../profile/components/pdfviewer/PDFViewer";
import tcPDFFile from './General terms and conditions.pdf'

const initialValues = {
  firstname: '',
  lastname: '',
  email: '',
  password: '',
  changepassword: '',
  phone_no: '',
  vendor_company_name: '',
  tc_agreed: false,
}

const registrationSchema = Yup.object().shape({
  firstname: Yup.string()
    .min(2, 'Minimum 2 symbols')
    .max(20, 'Maximum 20 symbols')
    .required('First name is required')
    .matches(/^[aA-zZ]+$/, "Only alphabets are allowed for this field "),
  email: Yup.string()
    .email('Wrong email format')
    .min(5, 'Minimum 5 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  lastname: Yup.string()
    .min(2, 'Minimum 2 symbols')
    .max(20, 'Maximum 20 symbols')
    .required('Last name is required')
    .matches(/^[aA-zZ]+$/, "Only alphabets are allowed for this field "),
  password: Yup.string()
    .min(8, 'Minimum 8 symbols')
    .max(16, 'Maximum 16 symbols')
    .required('Password is required')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
    ),
  changepassword: Yup.string()
    .required('Password confirmation is required')
    .when('password', {
      is: (val: string) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf([Yup.ref('password')], "Password and Confirm Password didn't match"),
    }),
  phone_no: Yup.string()
    .min(10, 'Enter Valid 10 digit number')
    .max(10, 'Enter Valid 10 digit number')
    .required('Phone number is required')
    .matches(/^[0-9]+$/, "Only numbers are allowed for this field"),
  vendor_company_name: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols'),
  tc_agreed: Yup.bool().required('You must accept the terms and conditions'),
})

export function Registration() {
  const history = useHistory()
  const [loading, setLoading] = useState(false)
  const [successModal, setSuccessModal] = useState(false)
  const dispatch = useDispatch()

  const navigate = () => {
    history.push('/auth/login')
  }

  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true)
      setTimeout(() => {
        register(values.email, values.firstname, values.lastname, values.password, values.phone_no, values.vendor_company_name, values.tc_agreed, 'vendor')
          .then(() => {
            setLoading(false)
            setSuccessModal(true)
            //history.push('/auth/login')
          })
          .catch((err) => {
            setLoading(false)
            setSubmitting(false)
            setStatus(err.response.data.message)
          })
      }, 1000)
    },
  })
  
  return (
    <>
      <form
        className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
        noValidate
        id='kt_login_signup_form'
        onSubmit={formik.handleSubmit}
      >
        {/* begin::Heading */}
        <div className='mb-10 text-center' style={{pointerEvents: "none"}}>
          {/* begin::Title */}
          <h1 className='text-dark mb-3'>Create an Account</h1>
          {/* <div className="social-login">
          <div className="soc-ic">
            <a href="#">
              <img src="/media/img/google-icon.svg" alt="" />
            </a>
            <a href="#">
              <img src="/media/img/facebook-icon.svg" alt="" />
            </a>
          </div>
         
        </div> */}
          {/* end::Title */}

          {/* begin::Link */}
          {/* <div className='text-gray-400 fw-bold fs-4'>
          Already have an account?
          <Link to='/auth/login' className='link-primary fw-bolder' style={{marginLeft: '5px'}}>
            Forgot Password ?
          </Link>
        </div> */}
          {/* end::Link */}
        </div>
        {/* end::Heading */}

        {/* begin::Action */}
        {/* <button type='button' className='btn btn-light-primary fw-bolder w-100 mb-10'>
        <img
          alt='Logo'
          src={toAbsoluteUrl('/media/svg/brand-logos/google-icon.svg')}
          className='h-20px me-3'
        />
        Sign in with Google
      </button> */}
        {/* end::Action */}

        {/* <div className='d-flex align-items-center mb-10'>
        <div className='border-bottom border-gray-300 mw-50 w-100'></div>
        <span className='fw-bold text-gray-400 fs-7 mx-2'>OR</span>
        <div className='border-bottom border-gray-300 mw-50 w-100'></div>
      </div> */}

        {formik.status && (
          <div className='mb-lg-15 alert alert-danger'>
            <div className='alert-text font-weight-bold'>{formik.status}</div>
          </div>
        )}

        {/* begin::Form group Firstname */}
        <div className='row fv-row'>
          <div className='col-xl-6'>
            {/* <label className='className="form-label fw-bolder text-dark fs-6'>First name</label> */}
            <div className="input-box">
              <img src="/media/img/user.svg" alt="" />
              <input
                placeholder='First name'
                type='text'
                autoComplete='off'
                {...formik.getFieldProps('firstname')}
                className={clsx(
                  '',
                  {
                    'is-invalid': formik.touched.firstname && formik.errors.firstname,
                  },
                  {
                    'is-valid': formik.touched.firstname && !formik.errors.firstname,
                  }
                )}
              />
            </div>
            {formik.touched.firstname && formik.errors.firstname && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.firstname}</span>
                </div>
              </div>
            )}
          </div>
          <div className='col-xl-6'>
            {/* begin::Form group Lastname */}
            <div className='fv-row input-box'>
              <img src="/media/img/user.svg" alt="" />
              {/* <label className='form-label fw-bolder text-dark fs-6'>Last name</label> */}
              <input
                placeholder='Last name'
                type='text'
                autoComplete='off'
                {...formik.getFieldProps('lastname')}
                className={clsx(
                  '',
                  {
                    'is-invalid': formik.touched.lastname && formik.errors.lastname,
                  },
                  {
                    'is-valid': formik.touched.lastname && !formik.errors.lastname,
                  }
                )}
              />

            </div>
            {formik.touched.lastname && formik.errors.lastname && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.lastname}</span>
                </div>
              </div>
            )}
            {/* end::Form group */}
          </div>
        </div>
        {/* end::Form group */}

        {/* begin::Form group Email */}
        <div className='fv-row input-box'>
          {/* <label className='form-label fw-bolder text-dark fs-6'>Email</label> */}
          <img src="/media/img/email.png" alt="" />
          <input
            placeholder='Email'
            type='email'
            autoComplete='off'
            {...formik.getFieldProps('email')}
            className={clsx(
              '',
              { 'is-invalid': formik.touched.email && formik.errors.email },
              {
                'is-valid': formik.touched.email && !formik.errors.email,
              }
            )}
          />

        </div>
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.email}</span>
            </div>
          </div>
        )}
        {/* end::Form group */}

        {/* begin::Form group Password */}
        <div className='fv-row input-box' data-kt-password-meter='true'>

          {/* <label className='form-label fw-bolder text-dark fs-6'>Password</label> */}
          <img src="/media/img/password.svg" alt="" />

          <input
            type='password'
            placeholder='Password'
            autoComplete='off'
            {...formik.getFieldProps('password')}
            className={clsx(
              '',
              {
                'is-invalid': formik.touched.password && formik.errors.password,
              },
              {
                'is-valid': formik.touched.password && !formik.errors.password,
              }
            )}
          />


        </div>

        {formik.touched.password && formik.errors.password && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.password}</span>
            </div>
          </div>
        )}

        {/* end::Form group */}

        {/* begin::Form group Confirm password */}
        <div className='fv-row input-box'>
          {/* <label className='form-label fw-bolder text-dark fs-6'>Confirm Password</label> */}
          <img src="/media/img/password.svg" alt="" />

          <input
            type='password'
            placeholder='Password confirmation'
            autoComplete='off'
            {...formik.getFieldProps('changepassword')}
            className={clsx(
              '',
              {
                'is-invalid': formik.touched.changepassword && formik.errors.changepassword,
              },
              {
                'is-valid': formik.touched.changepassword && !formik.errors.changepassword,
              }
            )}
          />

        </div>
        {formik.touched.changepassword && formik.errors.changepassword && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.changepassword}</span>
            </div>
          </div>
        )}
        <div className='fv-row input-box'>
          <img src="/media/img/call.png" alt="" />

          {/* <label className='form-label fw-bolder text-dark fs-6'>Phone Number</label> */}
          <input
            type='text'
            placeholder='Phone Number'
            autoComplete='off'
            {...formik.getFieldProps('phone_no')}
            className={clsx(
              '',
              {
                'is-invalid': formik.touched.phone_no && formik.errors.phone_no,
              },
              {
                'is-valid': formik.touched.phone_no && !formik.errors.phone_no,
              }
            )}
          />
        </div>
        {formik.touched.phone_no && formik.errors.phone_no && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.phone_no}</span>
            </div>
          </div>
        )}
        <div className='fv-row input-box'>
          <img src="/media/img/company.png" alt="" />

          {/* <label className='form-label fw-bolder text-dark fs-6'>Company Name</label> */}
          <input
            type='text'
            placeholder='Company Name'
            autoComplete='off'
            {...formik.getFieldProps('vendor_company_name')}
            className=''
          />
        </div>
        {/* end::Form group */}

        {/* begin::Form group */}
        <div className='fv-row mt-5 mb-5'>
          <div className='form-check form-check-custom form-check-solid'>
            <input
              className='form-check-input'
              type='checkbox'
              id='kt_login_toc_agree'
              {...formik.getFieldProps('tc_agreed')}
            />
            <label
              className='form-check-label fw-bold text-gray-700 fs-6'
              htmlFor='kt_login_toc_agree'
            >
              I Agree the{' '}
              <a href="#" data-bs-toggle="modal" data-bs-target="#kt_modal_1">
                terms and conditions
              </a>
              .
            </label>

          </div>
          {formik.touched.tc_agreed && formik.errors.tc_agreed && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.tc_agreed}</span>
              </div>
            </div>
          )}
          <div className="modal fade overflow-hidden" tabIndex={-1} id="kt_modal_1">
            <div className="modal-dialog modal-lg">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">Terms and Conditions </h5>
                  <div
                    className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    <i className="fa fa-times" aria-hidden="true"></i>
                  </div>
                </div>
                <div className="modal-body">
                  <PDFViewer fileUrl={tcPDFFile} />
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-light"
                    data-bs-dismiss="modal"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* end::Form group */}

        {/* begin::Form group */}
        <div className='login-bt-box'>
          <Link to='/auth/login'>
            <button
              type='button'
              id='kt_login_signup_form_cancel_button'
              className='cancel-login-bt'
            >
              Cancel
            </button>
          </Link>
          <button
            type='submit'
            id='kt_sign_up_submit'
            className='login-bt-user'
            disabled={formik.isSubmitting || !formik.isValid || !formik.values.tc_agreed}
          >
            {!loading && <span className='indicator-label'>Submit</span>}
            {loading && (
              <span className='indicator-progress' style={{ display: 'block' }}>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>


        </div>
        {/* end::Form group */}
      </form>
      <Modal show={successModal}>
        <Modal.Header className="modal-header-style" onHide={navigate}>
          Signup Successful
          <div
            className="btn btn-icon btn-sm btn-active-light ms-2"
            onClick={navigate}
            aria-label="Close"
          >
            <i className="fa fa-times" style={{ color: '#057f24' }} aria-hidden="true"></i>
          </div>
        </Modal.Header>
        <Modal.Body className="modal-body-style">
          Signup completed successfully and verification email has been sent. Please proceed to <a href="#" onClick={navigate}>login</a></Modal.Body>
      </Modal>
    </>
  )
}
