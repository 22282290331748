import { createState, useState } from "@hookstate/core";
const initialState = {
	customeDate: new Date(),
};

const dateState = createState(initialState);

export const setDateState = (props: any) => {
	if (props) {
		dateState.set({
			customeDate: props,
		});
	}
};

export const useDateState = () => {
	return useState(dateState);
};
