import React, { useEffect, useState } from "react";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
// import { PageLink, PageTitle } from "../../../_metronic/layout/core";
import { Col, Form, Nav, Row, Tab } from "react-bootstrap-v5";
import { toAbsoluteUrl } from "../../../../_metronic/helpers";
import ReactPaginate from "react-paginate";
import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL || "";

const BookingDetails: React.FC = ({ itemsPerPage = 10 }: any) => {
	const { search } = useLocation();
	const params = new URLSearchParams(search);
	const BookingDetailsId = params.get("bookingDetailsId") || "";

	const [currentItems, setCurrentItems] = useState<any>(null);
	const [pageCount, setPageCount] = useState(0);
	// Here we use item offsets; we could also use page offsets
	// following the API or data you're working with.
	const [itemOffset, setItemOffset] = useState(0);

	useEffect(() => {
		// Fetch items from another resources.
		// axios.get('https://myresorts.in:3000/v1/admin/property?page_no=0').then(res=>{
		axios.get(`${API_URL}/v1/admin/property?page_no=0`).then((res) => {
			setCurrentItems(res.data.data.data);
			setPageCount(Math.ceil(res.data.data.metadata.total / itemsPerPage));

			// console.log(res.data.data.data, "manage property")
		});
	}, [itemOffset, itemsPerPage]);

	// Invoke when user click to request another page.
	const handlePageClick = (event: any) => {
		// axios.get('https://myresorts.in:3000/v1/admin/property?page_no='+event.selected).then(res=>{
		axios
			.get(`${API_URL}/v1/admin/property?page_no=` + event.selected)
			.then((res) => {
				setCurrentItems(res.data.data.data);
				setPageCount(Math.ceil(res.data.data.metadata.total / itemsPerPage));
			});
	};

	return (
		<div>
			<div className="row">
				<div className="col-lg-12 d-flex justify-content-end">
					<div className="d-flex my-2">
						<div className="d-flex align-items-center position-relative me-4">
							<span className="svg-icon svg-icon-3 position-absolute ms-3">
								<svg
									width="24"
									height="24"
									viewBox="0 0 24 24"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
									className="mh-50px"
								>
									<rect
										opacity="0.5"
										x="17.0365"
										y="15.1223"
										width="8.15546"
										height="2"
										rx="1"
										transform="rotate(45 17.0365 15.1223)"
										fill="black"
									></rect>
									<path
										d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
										fill="black"
									></path>
								</svg>
							</span>
							<input
								type="text"
								id="kt_filter_search"
								className="form-control form-control-white form-control-sm w-250px ps-9 border-secondary "
								placeholder="Search"
								wtx-context="666F8DA7-646C-4188-876B-CA6BA1CAF363"
							/>
						</div>
						<button className="btn btn-primary btn-sm">Search</button>
					</div>
				</div>
				<div className="col-lg-12">
					<div className="card card-custom">
						<div className="card-header">
							<h3 className="card-title">
								Booking Details List - {BookingDetailsId}
							</h3>
							{/* <div className="card-toolbar">
        <button type="button" className="btn btn-sm btn-light">
            Last 10 bookings
        </button>
    </div> */}
						</div>
						<div className="card-body p-0 manage-pro-table-wrap">
							<table className="table table-hover table-rounded table-striped border gy-4 gs-7">
								<thead>
									<tr className="fw-bold fs-6 text-gray-800 border-bottom-2 border-gray-200">
										{/* <th>Photo</th> */}
										<th>Vendor Name</th>
										<th>Contact</th>

										<th>Location</th>
										<th>Date added on</th>
										{/* <th>Status</th> */}
									</tr>
								</thead>
								<tbody>
									{currentItems &&
										currentItems.map((ele: any) => (
											<tr>
												{/* <td>
                    <img
                      src={ele?.property_image?.data ? `data:image/png;base64,${Buffer.from( ele?.property_image?.data).toString('base64')}`:''}
                      alt="MyResorts.in"
                    />
                  </td> */}
												<td>
													<a href="#!">
														<b>{ele?.vendor_name}</b>
														<br />
														<b>{ele?.property_name}</b>
													</a>
												</td>
												<td>
													{ele?.vendor_mobile_no}
													<br />
													{ele?.vendor_email}
												</td>

												<td>{ele?.property_location}</td>
												<td>
													{new Date(ele?.property_added_on).toLocaleDateString(
														"en-GB"
													)}
												</td>
												{/* <td>
                      <a href="#" className={"btn" + (ele.property_status=='Published'?' btn-danger':ele.property_status=='Verified'||ele.property_status=='Unpublished'?' btn-primary':' btn-success') + " verify-bt"} 
                    onClick={()=>statusUpdate(ele.property_id, ele.property_status=='Published'?'Unpublished':ele.property_status=='Verified'||ele.property_status=='Unpublished'?'Published':'Verified')}>
                      {ele.property_status=='Published'?'Unpublish':ele.property_status=='Verified'||ele.property_status=='Unpublished'?'Publish':'Verify'}
                    </a>

                    </td> */}
											</tr>
										))}
								</tbody>
							</table>
						</div>
					</div>
				</div>
				<div className="col-lg-12">
					<div className="pagination-wrap">
						<ReactPaginate
							breakLabel="..."
							nextLabel=" >"
							breakClassName="page-item"
							breakLinkClassName="page-link"
							pageClassName="page-item"
							pageLinkClassName="page-link"
							previousClassName="page-item"
							previousLinkClassName="page-link"
							nextClassName="page-item"
							nextLinkClassName="page-link"
							activeClassName="active"
							onPageChange={handlePageClick}
							pageRangeDisplayed={5}
							pageCount={pageCount}
							previousLabel="< "
							className="pagination"
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default BookingDetails;
