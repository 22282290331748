import React from "react";
import { Button, Modal } from "react-bootstrap-v5";
import { Interface } from "readline";
import UserFooter from "../UserFooter";
import UserHeader from "../UserHeader";

const Privacy: React.FC = () => {
  return (
    <>
      <UserHeader />
      <div style={{ marginLeft: '5rem', marginTop: '9rem', marginRight: '7rem', padding: '5rem' }}>
          
          <p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', fontSize: '13.0pt', fontFamily: '"Montserrat", sans-serif !important;' }}>
            <strong><span style={{ fontSize: '19px', lineHeight: '107%' }}>MyResorts.in Privacy Policy (India)</span></strong>
          </p>

          <p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', fontSize: '13.0pt', fontFamily: '"Montserrat", sans-serif !important;' }}>
            This Privacy Policy describes how MyResorts.in ("MyResorts.in", "we", "us", or "our") collects, uses, and discloses your personal information when you use our website (the "Site") and related services (the "Services") in India. We are committed to protecting your privacy and complying with all applicable Indian data privacy laws, including the Information Technology Act, 2000 and its amendments.
          </p>

          <p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', fontSize: '13.0pt', fontFamily: '"Montserrat", sans-serif !important;' }}>
            &nbsp;
          </p>

          <p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', fontSize: '13.0pt', fontFamily: '"Montserrat", sans-serif !important;' }}>
            <strong><span style={{ fontSize: '19px', lineHeight: '107%' }}>Information We Collect</span></strong>
          </p>

          <p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', fontSize: '13.0pt', fontFamily: '"Montserrat", sans-serif !important;' }}>
            We collect the following types of information from you:
          </p>

          <p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', fontSize: '13.0pt', fontFamily: '"Montserrat", sans-serif !important;' }}>
            <strong>Personal Information:</strong> This includes information that can be used to identify you, such as your name, email address, phone number, postal address, billing information, passport information, Aadhaar number (with your explicit consent), and other government-issued IDs.
          </p>

          <p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', fontSize: '13.0pt', fontFamily: '"Montserrat", sans-serif !important;' }}>
            <strong>Usage Information:</strong> This includes information about how you use the Site and Services, such as the pages you visit, the searches you perform, the bookings you make, and the devices you use to access the Site and Services.
          </p>

          <p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', fontSize: '13.0pt', fontFamily: '"Montserrat", sans-serif !important;' }}>
            <strong>Device Information:</strong> This includes information about the device you use to access the Site and Services, such as the type of device, operating system, IP address, browser type, and unique device identifiers.
          </p>

          <p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', fontSize: '13.0pt', fontFamily: '"Montserrat", sans-serif !important;' }}>
            &nbsp;
          </p>

          <p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', fontSize: '13.0pt', fontFamily: '"Montserrat", sans-serif !important;' }}>
            <strong><span style={{ fontSize: '19px', lineHeight: '107%' }}>How We Collect Information</span></strong>
          </p>

          <p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', fontSize: '13.0pt', fontFamily: '"Montserrat", sans-serif !important;' }}>
            <strong><span style={{ fontSize: '19px', lineHeight: '107%' }}>We collect information from you in the following ways:</span></strong>
          </p>

          <p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', fontSize: '13.0pt', fontFamily: '"Montserrat", sans-serif !important;' }}>
            When you create an account on the Site.
          </p>

          <p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', fontSize: '13.0pt', fontFamily: '"Montserrat", sans-serif !important;' }}>
            When you make a booking through the Site.
          </p>

          <p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', fontSize: '13.0pt', fontFamily: '"Montserrat", sans-serif !important;' }}>
            When you contact us through the Site or by email.
          </p>

          <p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', fontSize: '13.0pt', fontFamily: '"Montserrat", sans-serif !important;' }}>
            When you use the Site or Services.
          </p>

          <p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', fontSize: '13.0pt', fontFamily: '"Montserrat", sans-serif !important;' }}>
            From third-party providers, such as Google Analytics, with your consent.
          </p>

          <p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', fontSize: '13.0pt', fontFamily: '"Montserrat", sans-serif !important;' }}>
            <strong><span style={{ fontSize: '19px', lineHeight: '107%' }}>&nbsp;</span></strong>
          </p>

          <p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', fontSize: '13.0pt', fontFamily: '"Montserrat", sans-serif !important;' }}>
            <strong><span style={{ fontSize: '19px', lineHeight: '107%' }}>How We Use Your Information</span></strong>
          </p>

          <p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', fontSize: '13.0pt', fontFamily: '"Montserrat", sans-serif !important;' }}>
            <strong><span style={{ fontSize: '19px', lineHeight: '107%' }}>We use your information for the following purposes:</span></strong>
          </p>

          <p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', fontSize: '13.0pt', fontFamily: '"Montserrat", sans-serif !important;' }}>
            To provide you with the Site and Services.
          </p>

          <p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', fontSize: '13.0pt', fontFamily: '"Montserrat", sans-serif !important;' }}>
            To process your bookings and facilitate secure transactions.
          </p>

          <p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', fontSize: '13.0pt', fontFamily: '"Montserrat", sans-serif !important;' }}>
            To communicate with you about your bookings, the Site, and Services, including providing customer support and sending promotional offers with your consent.
          </p>

          <p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', fontSize: '13.0pt', fontFamily: '"Montserrat", sans-serif !important;' }}>
            To personalize your experience on the Site and Services.
          </p>

          <p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', fontSize: '13.0pt', fontFamily: '"Montserrat", sans-serif !important;' }}>
            To improve the Site and Services.
          </p>

          <p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', fontSize: '13.0pt', fontFamily: '"Montserrat", sans-serif !important;' }}>
            To comply with legal and regulatory requirements.
          </p>

          <p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', fontSize: '13.0pt', fontFamily: '"Montserrat", sans-serif !important;' }}>
            To prevent, detect, and investigate fraud or other illegal activities.
          </p>

          <p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', fontSize: '13.0pt', fontFamily: '"Montserrat", sans-serif !important;' }}>
            <strong><span style={{ fontSize: '19px', lineHeight: '107%' }}>&nbsp;</span></strong>
          </p>

          <p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', fontSize: '13.0pt', fontFamily: '"Montserrat", sans-serif !important;' }}>
            <strong><span style={{ fontSize: '19px', lineHeight: '107%' }}>How We Share Your Information</span></strong>
          </p>

          <p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', fontSize: '13.0pt', fontFamily: '"Montserrat", sans-serif !important;' }}>
            <strong><span style={{ fontSize: '16px', lineHeight: '107%' }}>We share your information with the following third-party providers only as necessary to fulfill the stated purposes:</span></strong>
          </p>

          <p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', fontSize: '13.0pt', fontFamily: '"Montserrat", sans-serif !important;' }}>
            <strong>Payment processors:</strong> We share your payment information with secure payment processors to process your bookings.
          </p>

          <p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', fontSize: '13.0pt', fontFamily: '"Montserrat", sans-serif !important;' }}>
            <strong>Resort providers:</strong> We share your information with resort providers to confirm your bookings and facilitate your stay.
          </p>

          <p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', fontSize: '13.0pt', fontFamily: '"Montserrat", sans-serif !important;' }}>
            <strong>Marketing partners:</strong> We may share your information with marketing partners with your explicit consent to receive promotional offers.
          </p>

          <p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', fontSize: '13.0pt', fontFamily: '"Montserrat", sans-serif !important;' }}>
            <strong>Law enforcement and government agencies:</strong> We may share your information with law enforcement and government agencies only if required by law or to comply with legal process.
          </p>

          <p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', fontSize: '13.0pt', fontFamily: '"Montserrat", sans-serif !important;' }}>
            <strong><span style={{ fontSize: '19px', lineHeight: '107%' }}>&nbsp;</span></strong>
          </p>

          <p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', fontSize: '13.0pt', fontFamily: '"Montserrat", sans-serif !important;' }}>
            <strong><span style={{ fontSize: '19px', lineHeight: '107%' }}>Your Choices</span></strong>
          </p>

          <p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', fontSize: '13.0pt', fontFamily: '"Montserrat", sans-serif !important;' }}>
            <strong>You have the following choices regarding your information:</strong>
          </p>

          <p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', fontSize: '13.0pt', fontFamily: '"Montserrat", sans-serif !important;' }}>
            You can access, update, or delete your personal information by logging into your account on the Site.
          </p>

          <p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', fontSize: '13.0pt', fontFamily: '"Montserrat", sans-serif !important;' }}>
            You can unsubscribe from our marketing communications by clicking on the unsubscribe link in any marketing email you receive from us.
          </p>

          <p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', fontSize: '13.0pt', fontFamily: '"Montserrat", sans-serif !important;' }}>
            You can control the cookies we use on the Site by adjusting your browser settings.
          </p>

          <p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', fontSize: '13.0pt', fontFamily: '"Montserrat", sans-serif !important;' }}>
            You have the right to request access to your personal data, rectification of inaccurate data, deletion of your data, and restriction of processing your data.
          </p>

          <p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', fontSize: '13.0pt', fontFamily: '"Montserrat", sans-serif !important;' }}>
            <strong><span style={{ fontSize: '19px', lineHeight: '107%' }}>&nbsp;</span></strong>
          </p>

          <p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', fontSize: '13.0pt', fontFamily: '"Montserrat", sans-serif !important;' }}>
            <strong><span style={{ fontSize: '19px', lineHeight: '107%' }}>Data Security</span></strong>
          </p>

          <p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', fontSize: '13.0pt', fontFamily: '"Montserrat", sans-serif !important;' }}>
            We take appropriate technical and organizational measures to protect your information from unauthorized access, disclosure, alteration, or destruction. However, no internet or electronic transmission is completely secure, so we cannot guarantee the security of your information.
          </p>

          <p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', fontSize: '13.0pt', fontFamily: '"Montserrat", sans-serif !important;' }}>
            &nbsp;
          </p>

          <p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', fontSize: '13.0pt', fontFamily: '"Montserrat", sans-serif !important;' }}>
            <strong><span style={{ fontSize: '19px', lineHeight: '107%' }}>Grievance Redressal</span></strong>
          </p>

          <p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', fontSize: '13.0pt', fontFamily: '"Montserrat", sans-serif !important;' }}>
            If you have any questions or concerns about this Privacy Policy or how we handle your information, please contact us by email at contact@myresorts.in. You can also file a grievance with us by contacting us on the email.
          </p>

          <p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', fontSize: '13.0pt', fontFamily: '"Montserrat", sans-serif !important;' }}>
            &nbsp;
          </p>

          <p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', fontSize: '13.0pt', fontFamily: '"Montserrat", sans-serif !important;' }}>
            <strong><span style={{ fontSize: '19px', lineHeight: '107%' }}>Additional Notes:</span></strong>
          </p>

          <p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', fontSize: '13.0pt', fontFamily: '"Montserrat", sans-serif !important;' }}>
            We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on the Site.
          </p>

          <p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', fontSize: '13.0pt', fontFamily: '"Montserrat", sans-serif !important;' }}>
            This Privacy Policy is subject to and governed by the laws of India. Any dispute arising out of or relating to this Privacy Policy shall be subject to the exclusive jurisdiction of the courts in India.
          </p>
      </div>
      <UserFooter />
    </>
  );
};

export default Privacy;
